import { AnyAction } from 'redux';

import { State } from './types';
import { ApplicantType } from 'enums/applicant/type';
import { GovIdType } from 'enums/gov-id/type';
import { BusinessType } from 'enums/business/type';

const INITIAL_STATE: State = {
  processingBoInfo: false,
  owners: [],
  owner: {
    id: '',
    dob: '',
    email: {
      emailAddress: ''
    },
    governmentIdentification: {
      type: BusinessType.INDIVIDUAL,
      value: '',
      sourceType: GovIdType.SSN4
    },
    firstName: '',
    lastName: '',
    phone: {
      nationalNumber: ''
    },
    type: ApplicantType.OWNER,
    isPatching: false
  }
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_OWNERS':
      return {
        ...state,
        owners: action.payload
      };

    case 'SET_PROCESSING_BO_INFO':
      return {
        ...state,
        processingBoInfo: action.payload
      };

    case 'SAVE_OWNER_ID':
      return {
        ...state,
        owner: {
          ...state.owner,
          id: action.payload
        }
      };

    case 'SAVE_OWNER_EMAIL_ADDRESS':
      return {
        ...state,
        owner: {
          ...state.owner,
          email: {
            ...state.owner.email,
            emailAddress: action.payload
          }
        }
      };

    case 'SAVE_OWNER_GOV_IDENTIFICATION':
      return {
        ...state,
        owner: {
          ...state.owner,
          governmentIdentification: {
            id: state.owner?.governmentIdentification?.id || '',
            ...action.payload
          }
        }
      };

    case 'SAVE_OWNER_FIRST_NAME':
      return {
        ...state,
        owner: {
          ...state.owner,
          firstName: action.payload
        }
      };

    case 'SAVE_OWNER_LAST_NAME':
      return {
        ...state,
        owner: {
          ...state.owner,
          lastName: action.payload
        }
      };

    case 'SAVE_OWNER_DATE_OF_BIRTH':
      return {
        ...state,
        owner: {
          ...state.owner,
          dob: action.payload
        }
      };

    case 'SAVE_OWNER_PHONE_NATIONAL_NUMBER':
      return {
        ...state,
        owner: {
          ...state.owner,
          phone: {
            ...state.owner.phone,
            nationalNumber: action.payload
          }
        }
      };

    case 'SAVE_OWNER_STEP_UP_DETAILS':
      return {
        ...state,
        owner: {
          ...state.owner,
          stepUpDetails: action.payload
        }
      };

    case 'SET_OWNER_IS_PATCHING':
      return {
        ...state,
        owner: {
          ...state.owner,
          isPatching: action.payload
        }
      };

    case 'SAVE_OWNER_PERSONA_INQUIRY_ID':
      return {
        ...state,
        owner: {
          ...state.owner,
          personaInquiryId: action.payload
        }
      };
    case 'SAVE_OWNER_PERSONA_SESSION_TOKEN':
      return {
        ...state,
        owner: {
          ...state.owner,
          personaSessionToken: action.payload
        }
      };

    default:
      return state;
  }
}
