export enum ActionType {
  click = 'click',
  select = 'select',
  check = 'check',
  submit = 'submit',
  drag = 'drag',
  login = 'login',
  create = 'create',
  delete = 'delete',
  update = 'update',
  search = 'search',
  tap = 'tap',
  swipe = 'swipe',
  pinch = 'pinch',
  impression = 'impression',
  internal_promotion_impression = 'internal_promotion_impression',
  experiment = 'experiment',
  load = 'load',
  apicall = 'apicall',
  'split-test' = 'split-test',
  redux = 'redux',
  change = 'change',
  custom = 'custom',
  impress = 'impress',
  blur = 'blur',
  view = 'view',
  applog = 'applog',
  show = 'show',
  success = 'success',
  perf = 'perf',
  loaded = 'loaded',
  clicktaleu = 'clicktaleu',
  clicktale = 'clicktale',
  notify = 'notify',
  consent = 'consent',
  timing = 'timing'
}
