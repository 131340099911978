import { toast } from 'react-toastify';

import { call, put, select } from 'redux-saga/effects';

import { Store } from 'reducers/types';

import * as api from 'rest/v2/categories';

export const getApplication = (state: Store) => state.application;

export const getCategoriesLoadingState = (state: Store) =>
  state.categories.loaded;

export function* getBusinessCategories() {
  try {
    const loaded = yield select(getCategoriesLoadingState);

    if (loaded) {
      yield put({
        type: 'GET_CATEGORIES_SUCCESS'
      });

      return;
    }

    yield put({
      payload: true,
      type: 'SAVE_BUSINESS_CATEGORIES_LOADED'
    });

    const categories = yield call(api.getCategories);

    yield put({
      payload: categories,
      type: 'SAVE_BUSINESS_CATEGORIES'
    });

    yield put({
      type: 'GET_CATEGORIES_SUCCESS'
    });
  } catch (error) {
    yield call(toast.error, error.message);
    yield put({
      error: error.message,
      type: 'GET_CATEGORIES_FAILURE'
    });
  }
}
