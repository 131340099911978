import validator from 'validator';

import { invalidBusinessNameRegExp } from 'helpers/validators/invalid-business-name-regexp';

const allowedWhiteSpacesRegex = /^((?!\s{2}).)*$/;
const onlyNumbersRegex = /^[0-9 '?!.,\-()&:@_$]+$/;

export const checkUsaBusinessNameRegExp =
  /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '?!.,\-()&:]{2,}$/;

export const checkCanadaBusinessNameRegExp =
  /^(?!\s)(?!.*\s$)(?=.*[a-zA-ZÀ-Ÿ0-9])[a-zA-ZÀ-Ÿ0-9 '?!.,\-()&:]{2,}$/;

export const checkBusinessName = (
  value: string | null | undefined,
  regularRegex: RegExp,
  isOptional?: boolean
) => {
  const validationValue = (value || '').trim();

  return isOptional && !value
    ? true
    : regularRegex.test(validationValue) &&
        allowedWhiteSpacesRegex.test(validationValue) &&
        !onlyNumbersRegex.test(validationValue) &&
        !invalidBusinessNameRegExp.test(validationValue.toUpperCase());
};

export const businessNameLength = (
  value: string | null | undefined
): boolean => {
  const validationValue = (value || '').trim();

  return validator.isLength(validationValue, { min: 3, max: 64 });
};
