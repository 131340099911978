export enum StepUpActionType {
  BO_INFO = 'BO_INFO',
  BO_VERIFICATION = 'BO_VERIFICATION',
  BUSINESS_INFO = 'BUSINESS_INFO',
  GOV_ID_SELFIE = 'GOV_ID_SELFIE',
  OTP_SMS = 'OTP_SMS',
  EIN_DOC = 'EIN_DOC',
  FULL_ONBOARDING = 'FULL_ONBOARDING',
  FULL_SSN = 'FULL_SSN',
  PHONE_AUTH = 'PHONE_AUTH'
}
