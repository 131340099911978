import { FC, useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ApplicationLevel } from 'enums/application/application-level';
import { DecisionType } from 'enums/application/decision';
import { StepUpActionType } from 'enums/application/step-up';

import { useStore } from 'helpers/store';
import { trackVirtualPathChange } from 'helpers/analytics/track';
import { useUxCore } from 'helpers/ux-core';
import { useTranslation } from 'helpers/translation';

import * as toastHelpers from 'helpers/toast';

import { sendApplicantResumeEmail } from 'rest/v2/application/notification';

import { FullStory } from 'components/elements';

import { Props } from './Content.types';

const ContentContainer: FC<Props> = ({ children }: Props) => {
  const location = useLocation();

  const { t } = useTranslation();
  const { isUxCoreEnabled } = useUxCore();

  const loading = useStore<boolean>(state => !!state.loadingBar.default);
  const applicationId = useStore<string>(state => state.application.id || '');
  const applicationLevel = useStore<ApplicationLevel | undefined>(
    state => state.application.applicationLevel
  );

  const applicationDecision = useStore<DecisionType | undefined>(
    state => state.application.decision
  );

  const stepUpDetails = useStore<StepUpActionType[] | undefined>(
    state => state.application.stepUpDetails
  );

  const [isResumeEmailCase, setIsResumeEmailCase] = useState(false);

  useEffect(() => {
    if (applicationLevel !== ApplicationLevel.FULL) {
      setIsResumeEmailCase(false);
      return;
    }

    if (applicationDecision === DecisionType.PENDING) {
      setIsResumeEmailCase(true);
      return;
    }

    if (
      (applicationDecision === DecisionType.APPROVED_PENDING ||
        applicationDecision === DecisionType.DECLINED_PENDING) &&
      Array.isArray(stepUpDetails) &&
      (stepUpDetails.length > 1 ||
        (stepUpDetails.length === 1 &&
          stepUpDetails[0] !== StepUpActionType.BO_VERIFICATION))
    ) {
      setIsResumeEmailCase(true);
      return;
    }

    setIsResumeEmailCase(false);
  }, [applicationLevel, applicationDecision, stepUpDetails]);

  const handlePageClosing = useCallback(() => {
    if (applicationId && isResumeEmailCase) {
      sendApplicantResumeEmail(applicationId);
    }
  }, [applicationId, isResumeEmailCase]);

  useEffect(() => {
    window.addEventListener('unload', handlePageClosing);

    return () => {
      window.removeEventListener('unload', handlePageClosing);
    };
  }, [handlePageClosing]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('completed')) {
      toastHelpers.toast(t('content.signedIn'), isUxCoreEnabled);
    }

    if (params.get('timeout')) {
      toastHelpers.toast(t('content.sessionExpired'), isUxCoreEnabled);
    }

    trackVirtualPathChange(location.pathname + location.search);
  }, [location.search]);

  return (
    <div className={['page', loading ? 'loading' : ''].join(' ')}>
      <FullStory />
      {children}
    </div>
  );
};

export default ContentContainer;
