import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  categories: [],
  industries: []
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_INDUSTRY':
      return {
        ...state,
        industry: action.payload
      };
    case 'SAVE_INDUSTRY_NAME':
      return {
        ...state,
        industryName: action.payload
      };
    case 'SAVE_INDUSTRIES':
      return {
        ...state,
        industries: action.payload
      };
    case 'SAVE_INDUSTRIES_CATEGORY':
      return {
        ...state,
        category: action.payload
      };
    case 'SAVE_INDUSTRIES_CATEGORIES':
      return {
        ...state,
        categories: action.payload
      };
    case 'SAVE_INDUSTRIES_LOADED':
      return {
        ...state,
        loaded: action.payload
      };
    default:
      return state;
  }
}
