import NPS from 'helpers/analytics/nps/helpers/api';

import { NPSTrackEventArgsInterface } from 'helpers/analytics/nps/types';

export const initNPS = () => NPS.insertScriptsNPS();

export const sendNPS = (event: NPSTrackEventArgsInterface) =>
  NPS.trackEvent(event);

export const restartNPS = () => NPS.restartNPS();
