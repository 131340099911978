import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { ArrowContainer, Popover } from 'react-tiny-popover';

import { useTranslation } from 'helpers/translation';

import { Props } from './Tooltip.types';

import Styles from './Tooltip.module.scss';

const TooltipContent = (props: Props) => {
  return (
    <div
      className={[
        Styles.popoverContainer,
        isMobile ? Styles.popoverContainerMobile : ''
      ].join(' ')}>
      {props.title ? (
        <strong className={Styles.popoverTitle}>{props.title}</strong>
      ) : null}
      <div className={Styles.popoverContent}>
        {props.content}
        {props.link && props.linkTitle ? (
          <>
            &nbsp;
            <a
              className={Styles.popoverLink}
              href={props.link}
              target="_blank"
              rel="noreferrer">
              {props.linkTitle}
            </a>
          </>
        ) : null}
        <button
          id={props.tooltipId + '-close-btn'}
          type={'button'}
          className={Styles.closeButton}
          onClick={() => props?.onCloseClick?.()}></button>
      </div>
    </div>
  );
};

const Tooltip: FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const id = props.tooltipId ? props.tooltipId + '-tooltip' : undefined;
  const boundaryElement = id
    ? (document.getElementById(id) as HTMLElement)
    : undefined;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <div className={[Styles.container, props.classNames].join(' ')}>
      <Popover
        isOpen={isPopoverOpen}
        transform={isMobile ? { left: 0 } : undefined}
        positions={['top', 'left']}
        reposition={!isMobile}
        boundaryElement={boundaryElement}
        onClickOutside={e => {
          const target = e.target as HTMLButtonElement;
          if (target.isConnected) {
            setIsPopoverOpen(false);
          }
        }}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            arrowSize={10}
            arrowColor={'blue'}
            childRect={childRect}
            popoverRect={popoverRect}
            className={isMobile ? '' : Styles.arrowContainer}>
            <TooltipContent
              link={props.link}
              title={props.title}
              content={props.content}
              tooltipId={id}
              linkTitle={props.linkTitle}
              onCloseClick={() => setIsPopoverOpen(false)}
            />
          </ArrowContainer>
        )}>
        <button
          id={id}
          type={'button'}
          className={Styles.tooltipButton}
          onClick={() => setIsPopoverOpen(true)}>
          <img
            src="https://d85ecz8votkqa.cloudfront.net/payfac/onboarding/poster/icon.svg"
            alt={t('components.elements.tooltip.imgAlt')}
          />
        </button>
      </Popover>
    </div>
  );
};

export default Tooltip;
