import { AnyAction } from 'redux';

import { Currency } from 'enums/business/currency';

import {
  Address,
  Location,
  MccDetails,
  GovernmentIdentification
} from 'reducers/company/types';
import { State as CompanyReducer } from 'reducers/company/types';

export function saveCompanyDefaultState(
  state: Partial<CompanyReducer>
): AnyAction {
  return {
    payload: state,
    type: 'SAVE_COMPANY_DEFAULT_STATE'
  };
}

export function saveCompanyAddress(address: Address): AnyAction {
  return {
    payload: address,
    type: 'SAVE_COMPANY_ADDRESS'
  };
}

export function saveCompanyLocation(location?: Location): AnyAction {
  return {
    payload: location,
    type: 'SAVE_COMPANY_LOCATION'
  };
}

export function saveCompanyPhone(phone: string): AnyAction {
  return {
    payload: phone,
    type: 'SAVE_COMPANY_PHONE'
  };
}

export function saveCompanySite(site?: string): AnyAction {
  return {
    payload: site,
    type: 'SAVE_COMPANY_SITE'
  };
}

export function saveCompanyName(name: string): AnyAction {
  return {
    payload: name,
    type: 'SAVE_COMPANY_NAME'
  };
}

export function saveCompanyDba(dba: string): AnyAction {
  return {
    payload: dba,
    type: 'SAVE_COMPANY_DBA'
  };
}

export function saveCompanySegment(id?: string): AnyAction {
  return {
    payload: id,
    type: 'SAVE_COMPANY_SEGMENT'
  };
}

export function saveCompanyIdentification(
  identification: GovernmentIdentification
): AnyAction {
  return {
    payload: identification,
    type: 'SAVE_COMPANY_IDENTIFICATION'
  };
}

export function saveCompanyCurrency(currency: Currency): AnyAction {
  return {
    payload: currency,
    type: 'SAVE_COMPANY_CURRENCY'
  };
}

export function saveCompanyMccDetails(mccDetails: MccDetails): AnyAction {
  return {
    payload: mccDetails,
    type: 'SAVE_COMPANY_MCC_DETAILS'
  };
}

export function saveCompanyPersonaInquiryId(
  personaInquiryId: string | null
): AnyAction {
  return {
    payload: personaInquiryId,
    type: 'SAVE_COMPANY_PERSONA_INQUIRY_ID'
  };
}

export function saveCompanyPersonaSessionToken(
  personaSessionToken: string
): AnyAction {
  return {
    payload: personaSessionToken,
    type: 'SAVE_COMPANY_PERSONA_SESSION_TOKEN'
  };
}
