import { ParseSSN } from 'ssn';

import validator from 'validator';
import SocialInsuranceNumber from 'social-insurance-number';

import { Currency } from 'enums/business/currency';

import { ValidatorForm } from 'react-form-validator-core';

import * as dobValidator from './dob';
import * as nameValidator from './name';
import * as bankValidator from 'helpers/validators/bank';
import * as websiteValidator from 'helpers/validators/website';
import * as addressValidator from 'helpers/validators/address';
import * as phoneNumberValidator from 'helpers/validators/phone-number';
import * as identificationValidator from 'helpers/validators/identification';

export const minCorporationNumberLength = 3;
export const maxCorporationNumberLength = 10;

export const isValidGovId =
  /^(?!(\d)\1{8}$)(?!(123456789|012345678|987654321|876543210)$)\d{9}$/;

export const checkCompanyIdentification = (
  value: string,
  max: number,
  min?: number
) => {
  const companyIdentification = validator.blacklist(value, '-');
  return (
    validator.isNumeric(companyIdentification) &&
    parseFloat(companyIdentification) !== 0 &&
    validator.isLength(companyIdentification, { min: min || max, max })
  );
};

export const checkCorporationNumber = (value: string) => {
  return (
    validator.isAlphanumeric(value) &&
    validator.isLength(value, {
      min: minCorporationNumberLength,
      max: maxCorporationNumberLength
    })
  );
};

ValidatorForm.addValidationRule(
  'isMobilePhone',
  phoneNumberValidator.isTelephoneNumber
);

ValidatorForm.addValidationRule(
  'isBusinessPhoneOrEmpty',
  phoneNumberValidator.isTelephoneNumberOrEmpty
);

ValidatorForm.addValidationRule(
  'isCompanyIdentification',
  identificationValidator.isCompanyIdentification
);

ValidatorForm.addValidationRule(
  'isCompanyIdentificationNotRequired',
  identificationValidator.isCompanyIdentificationNotRequired
);

ValidatorForm.addValidationRule('isCorporationNumberRequired', value =>
  checkCorporationNumber(value)
);

ValidatorForm.addValidationRule('isCorporationNumberNotRequired', value =>
  value ? checkCorporationNumber(value) : true
);

ValidatorForm.addValidationRule(
  'isShortEIN',
  identificationValidator.isShortEIN
);

ValidatorForm.addValidationRule('poBox', addressValidator.pobox);

ValidatorForm.addValidationRule(
  'hasBuildingNumber',
  addressValidator.hasBuildingNumber
);

ValidatorForm.addValidationRule('hasLetters', addressValidator.hasLetters);

ValidatorForm.addValidationRule(
  'startsWithNumbersAndHasLetters',
  addressValidator.startsWithNumbersAndHasLetters
);

ValidatorForm.addValidationRule('isValidGovId', value => {
  return isValidGovId.exec(value);
});

ValidatorForm.addValidationRule(
  'isRoutingNumber',
  bankValidator.isRoutingNumber
);

ValidatorForm.addValidationRule('isValidSSN', value => {
  try {
    const ssn = validator.blacklist(value, '-');
    const parsed = new ParseSSN(ssn || '');
    return !!parsed;
  } catch (error) {
    return false;
  }
});

ValidatorForm.addValidationRule(
  'isValidEIN',
  identificationValidator.isValidEIN
);

ValidatorForm.addValidationRule('isDate', value => {
  return validator.isDate(value, { format: 'DD/MM/YYYY', strictMode: true });
});

ValidatorForm.addValidationRule('isValidSIN', value => {
  const sin = new SocialInsuranceNumber(value);
  const normalized = sin.normalizedValue();
  const parsedSin = new SocialInsuranceNumber(normalized);
  return parsedSin.isValid();
});

ValidatorForm.addValidationRule('isValidUsaName', nameValidator.isValidUsaName);

ValidatorForm.addValidationRule('isValidCanadaName', value => {
  const validationValue = value || '';
  return nameValidator.nameValidator(validationValue, Currency.CAD);
});

ValidatorForm.addValidationRule('isValidBusinessName', value =>
  nameValidator.checkBusinessName(
    value,
    nameValidator.checkUsaBusinessNameRegExp
  )
);

ValidatorForm.addValidationRule('isValidBusinessNameOptional', value =>
  nameValidator.checkBusinessName(
    value,
    nameValidator.checkUsaBusinessNameRegExp,
    true
  )
);

ValidatorForm.addValidationRule('isValidCanadaBusinessName', value =>
  nameValidator.checkBusinessName(
    value,
    nameValidator.checkCanadaBusinessNameRegExp
  )
);

ValidatorForm.addValidationRule('isValidCanadaBusinessNameOptional', value =>
  nameValidator.checkBusinessName(
    value,
    nameValidator.checkCanadaBusinessNameRegExp,
    true
  )
);

ValidatorForm.addValidationRule('isDomainName', websiteValidator.isDomainName);

ValidatorForm.addValidationRule('isUrl', websiteValidator.isUrl);

ValidatorForm.addValidationRule('isValidDob', dobValidator.isValidDob);

ValidatorForm.addValidationRule('isValidDobMin', dobValidator.isValidDobMin);

ValidatorForm.addValidationRule('isValidDobMax', dobValidator.isValidDobMax);

ValidatorForm.addValidationRule('isValidDobCa', dobValidator.isValidDobCa);

ValidatorForm.addValidationRule(
  'isValidDobMinCa',
  dobValidator.isValidDobMinCa
);

ValidatorForm.addValidationRule(
  'isValidDobMaxCa',
  dobValidator.isValidDobMaxCa
);
