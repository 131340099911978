export enum TrafficTrackType {
  signup_start = 'signup_start',
  signup_error = 'signup_error',
  signup_view_tos = 'signup_view_tos',
  signup_enter_ssn = 'signup_enter_ssn',
  signup_enter_ein = 'signup_enter_ein',
  signup_enter_cn = 'signup_enter_cn',
  signup_resume_an_application = 'signup_resume_an_application',
  signup_resume_application_by_email = 'signup_resume_application_by_email',
  signup_application_by_reminder_email = 'signup_application_by_reminder_email',
  signup_plaid_started = 'signup_plaid_started',
  signup_plaid_finished = 'signup_plaid_finished',
  signup_manual_bank_account_started = 'signup_manual_bank_account_started',
  signup_manual_bank_account_finished = 'signup_manual_bank_account_finished',
  signup_bank_account_canceled = 'signup_bank_account_canceled',
  signup_onboard_start = 'signup_onboard_start',
  signup_onboard_success = 'signup_onboard_success',
  signup_onboard_error = 'signup_onboard_error',
  signup_otp_authentication_process_started = 'signup_otp_authentication_process_started',
  signup_otp_authentication_process_cancelled = 'signup_otp_authentication_process_cancelled',
  signup_otp_resend_otp = 'signup_otp_resend_otp',
  signup_otp_code_entered = 'signup_otp_code_entered',
  signup_otp_no_of_attempts = 'signup_otp_no_of_attempts',
  signup_otp_authentication_failed = 'signup_otp_authentication_failed',
  signup_otp_authentication_success = 'signup_otp_authentication_success',
  signup_otp_authentication_error = 'signup_otp_authentication_error',
  signup_phone_authentication_process_started = 'signup_phone_authentication_process_started',
  signup_phone_authentication_process_cancelled = 'signup_phone_authentication_process_cancelled',
  signup_phone_resend_link = 'signup_phone_resend_link',
  signup_phone_authentication_failed = 'signup_phone_authentication_failed',
  signup_phone_authentication_success = 'signup_phone_authentication_success',
  signup_phone_authentication_error = 'signup_phone_authentication_error',
  signup_id_authentication_started = 'signup_id_authentication_started',
  signup_id_authentication_completed = 'signup_id_authentication_completed',
  signup_id_authentication_success = 'signup_id_authentication_success',
  signup_id_authentication_error = 'signup_id_authentication_error',
  signup_full_ssn_form_submit_attempt = 'signup_full_ssn_form_submit_attempt',
  signup_full_ssn_form_landed = 'signup_full_ssn_form_landed',
  signup_ein_verification_started = 'signup_ein_verification_started',
  signup_ein_verification_completed = 'signup_ein_verification_completed',
  signup_ein_verification_failed = 'signup_ein_verification_failed',
  signup_ein_verification_success = 'signup_ein_verification_success',
  signup_ein_verification_error = 'signup_ein_verification_error',
  signup_bo_info_success = 'signup_bo_info_success',
  signup_bo_info_error = 'signup_bo_info_error',
  signup_virtual_path_change = 'signup_virtual_path_change',
  signup_breather_start = 'signup_breather_start',
  signup_breather_confirm = 'signup_breather_confirm',
  signup_account_switcher_loaded = 'signup_account_switcher_loaded',
  signup_account_switcher_application_selected = 'signup_account_switcher_application_selected',
  signup_account_switcher_create_new_application = 'signup_account_switcher_create_new_application',
  signup_account_switcher_application_confirmed = 'signup_account_switcher_application_confirmed',
  signup_store_switcher_loaded = 'signup_store_switcher_loaded',
  signup_store_switcher_used_existing_store = 'signup_store_switcher_used_existing_store',
  signup_store_switcher_used_new_store = 'signup_store_switcher_used_new_store',
  signup_store_switcher_new_business_creation_shown = 'signup_store_switcher_new_business_creation_shown',
  signup_store_switcher_selected_business_creation = 'signup_store_switcher_selected_business_creation',
  signup_store_switcher_scrolling_usage = 'signup_store_switcher_scrolling_usage',
  signup_store_switcher_went_back = 'signup_store_switcher_went_back',
  signup_kyc_kyb_page_loaded = 'signup_kyc_kyb_page_loaded',
  signup_create_application_loaded = 'signup_create_application_loaded',
  signup_auto_attach_store = 'signup_auto_attach_store',
  signup_started_filling_application = 'signup_started_filling_application',
  signup_application_validation_errors = 'signup_application_validation_errors',
  signup_application_submit_attempt = 'signup_application_submit_attempt',
  signup_ein_by_email_submit_attempt = 'signup_ein_by_email_submit_attempt',
  signup_ein_by_email_form_landed = 'signup_ein_by_email_form_landed',
  signup_business_category_selected = 'signup_business_category_selected',
  signup_business_type_selected = 'signup_business_type_selected',
  signup_bo_applicant_error_page_loaded = 'signup_bo_applicant_error_page_loaded',
  signup_add_owners_page_loaded = 'signup_add_owners_page_loaded',
  signup_add_owners_page_click_add_partner = 'signup_add_owners_page_click_add_partner',
  signup_add_owners_page_click_delete_partner = 'signup_add_owners_page_click_delete_partner',
  signup_add_owners_page_click_submit_partner = 'signup_add_owners_page_click_submit_partner',
  signup_add_owners_page_click_show_skip_partners_modal = 'signup_add_owners_page_click_show_skip_partners_modal',
  signup_add_owners_page_click_close_skip_partners_modal = 'signup_add_owners_page_click_close_skip_partners_modal',
  signup_add_owners_page_click_skip_partners = 'signup_add_owners_page_click_skip_partners',
  signup_owner_onboard_start = 'signup_owner_onboard_start',
  signup_owner_onboard_success = 'signup_owner_onboard_success',
  signup_owner_onboard_error = 'signup_owner_onboard_error',
  signup_owner_page_loaded = 'signup_owner_page_loaded',
  signup_owner_page_click_support = 'signup_owner_page_click_support',
  signup_owner_page_click_submit = 'signup_owner_page_click_submit',
  signup_owner_success_page_loaded = 'signup_owner_success_page_loaded',
  signup_owner_success_page_click_cta = 'signup_owner_success_page_click_cta',
  signup_overlap_currency_error = 'signup_overlap_currency_error',
  signup_business_info_form_landed = 'signup_business_info_form_landed',
  signup_business_info_form_submit_attempt = 'signup_business_info_form_submit_attempt',
  signup_multistep_applicant_page_loaded = 'signup_multistep_applicant_page_loaded',
  signup_multistep_wizard_page_submit = 'signup_multistep_wizard_page_submit',
  signup_multistep_wizard_page_error = 'signup_multistep_wizard_page_error',
  signup_multistep_stepup_page_verify_click = 'signup_multistep_stepup_page_verify_click',
  signup_multistep_stepup_page_do_this_later_click = 'signup_multistep_stepup_page_do_this_later_click',
  signup_multistep_add_bank_click = 'signup_multistep_add_bank_click',
  signup_multistep_dashboard_click = 'signup_multistep_dashboard_click',
  signup_multistep_country_switcher_loaded = 'signup_multistep_country_switcher_loaded',
  signup_multistep_country_switcher_canceled = 'signup_multistep_country_switcher_canceled',
  signup_multistep_country_switcher_yes_clicked = 'signup_multistep_country_switcher_yes_clicked',
  signup_multistep_country_switcher_no_clicked = 'signup_multistep_country_switcher_no_clicked',
  signup_incorrect_url = 'signup_incorrect_url',
  signup_cash_advance_offers_page_loaded = 'signup_cash_advance_offers_page_loaded',
  signup_cash_advance_offers_loaded = 'signup_cash_advance_offers_loaded',
  signup_cash_advance_offers_accept_click = 'signup_cash_advance_offers_accept_click',
  signup_cash_advance_offers_back_click = 'signup_cash_advance_offers_back_click'
}
