import { PaymentMethodType } from 'enums/order/payment';
import { TerminalType } from 'enums/order/terminal';

import Environments from 'helpers/environments';

export const isTerminalAvailable = (id: TerminalType) => {
  return Environments.get<string>('terminalsType')
    .split(',')
    .some(terminal => terminal === id);
};

export const isTerminalFirstInList = (id: TerminalType) => {
  const terminalsTypes: string[] = [];

  if (isTerminalAvailable(TerminalType.POYNTC)) {
    terminalsTypes.push(TerminalType.POYNTC);
  }

  if (isTerminalAvailable(TerminalType.N910)) {
    terminalsTypes.push(TerminalType.N910);
  }

  return terminalsTypes.indexOf(id) === 0;
};

export const isPaymentMethodAvailable = (
  method: PaymentMethodType,
  includeAll = true
): boolean => {
  return (
    (includeAll &&
      Environments.get<string>('paymentsType') === PaymentMethodType.ALL) ||
    Environments.get<string>('paymentsType') === method.toUpperCase()
  );
};
