import { ActionType } from 'enums/analytics/traffic/action-type';
import { ApplicantType } from 'enums/applicant/type';
import { BusinessType } from 'enums/business/type';
import { SectionType } from 'enums/analytics/traffic/section-type';
import { TrafficTrackType } from 'enums/analytics/traffic/track-type';
import { MultistepPageType } from 'enums/analytics/multistep-page-type';
import { StepUpActionType } from 'enums/application/step-up';

import Traffic from 'helpers/analytics/traffic/helpers/api';

import { State as ApplicationReducer } from 'reducers/application/types';
import { Owner } from 'reducers/owners/types';

import { Store } from 'rest/v2/accounts/stores/interfaces';
import { Account } from 'rest/v2/accounts/interfaces';

import { TrafficInitTrackArgsInterface } from './types';

export const initTraffic = () => Traffic.insertScriptsTrafficJs();

export const initTrackData = (trackData: TrafficInitTrackArgsInterface) =>
  Traffic.initTrackData(trackData);

export const trackSignUpStart = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) =>
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_start,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      applicationId: applicationId || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });

export const trackVirtualPathChange = (virtualPath: string) =>
  Traffic.trackEvent(
    {
      eventName: TrafficTrackType.signup_virtual_path_change,
      section: SectionType.signup,
      actionType: ActionType.redux,
      virtual_path: virtualPath
    },
    'add_page_request',
    'v1'
  );

export const trackEditEnterSSN = () =>
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_enter_ssn,
    section: SectionType.field,
    actionType: ActionType.update
  });

export const trackEditEnterEIN = () =>
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_enter_ein,
    section: SectionType.field,
    actionType: ActionType.update
  });

export const trackEditEnterCN = (
  length: number,
  isAlpha: boolean,
  isNumeric: boolean,
  isAlphanumeric: boolean,
  province: string
) =>
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_enter_cn,
    section: SectionType.field,
    actionType: ActionType.update,
    data: {
      length,
      isAlpha,
      isNumeric,
      isAlphanumeric,
      province
    }
  });

export const trackViewTOS = () =>
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_view_tos,
    section: SectionType.link,
    actionType: ActionType.click
  });

export const trackError = (error: string) =>
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      error
    }
  });

export const trackResumeApplication = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_resume_an_application,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: applicationId || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackResumeApplicationByEmail = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_resume_application_by_email,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: applicationId || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackSignUpApplicationByReminderEmail = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_application_by_reminder_email,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: applicationId || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackPlaidStarted = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_plaid_started,
    section: SectionType.button,
    actionType: ActionType.click
  });
};

export const trackPlaidFinished = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_plaid_finished,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackManualBankAccountStarted = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_manual_bank_account_started,
    section: SectionType.button,
    actionType: ActionType.click
  });
};

export const trackManualBankAccountFinished = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_manual_bank_account_finished,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackBankAccountCanceled = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_bank_account_canceled,
    section: SectionType.button,
    actionType: ActionType.click
  });
};

export const trackOtpAuthenticationProcessStarted = (
  application: ApplicationReducer
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_authentication_process_started,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: application?.id || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || ''
    }
  });
};

export const trackOtpAuthenticationProcessCancelled = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_authentication_process_cancelled,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackOtpResendOtp = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_resend_otp,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackOtpCodeEntered = (otpCode: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_code_entered,
    section: SectionType.button,
    actionType: ActionType.click,
    data: {
      otpCode
    }
  });
};

export const trackOtpAuthenticationNoOfAttempts = (attempt: number) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_no_of_attempts,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      attempt
    }
  });
};

export const trackOtpAuthenticationFailed = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_authentication_failed,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackOnboardStart = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_onboard_start,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      currentStepUp: stepUp || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || ''
    }
  });
};

export const trackOnboardSuccess = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_onboard_success,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      currentStepUp: stepUp || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || ''
    }
  });
};

export const trackOnboardError = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_onboard_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      currentStepUp: stepUp || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || ''
    }
  });
};

export const trackOtpAuthenticationSuccess = (
  application: ApplicationReducer
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_authentication_success,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || ''
    }
  });
};

export const trackOtpAuthenticationError = (applicationId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_otp_authentication_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId
    }
  });
};

export const trackPhoneAuthenticationSuccess = (
  application: ApplicationReducer
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_phone_authentication_success,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || ''
    }
  });
};

export const trackPhoneAuthenticationError = (applicationId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_phone_authentication_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId
    }
  });
};

export const trackIdAuthenticationStarted = (
  application: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_id_authentication_started,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: application?.id || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackIdAuthenticationCompleted = (
  application?: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_id_authentication_completed,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: application?.id || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackIdAuthenticationSuccess = (
  application: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_id_authentication_success,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || '',
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackIdAuthenticationError = (
  applicationId: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_id_authentication_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId,
      applicantId: applicantId || '',
      applicantType: applicantType || ''
    }
  });
};

export const trackFullSsnFormSubmitAttempt = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_full_ssn_form_submit_attempt,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackFullSsnFormLanded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_full_ssn_form_landed,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackEinVerificationStarted = (
  application: ApplicationReducer
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_verification_started,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: application?.id || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || ''
    }
  });
};

export const trackEinVerificationCompleted = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_verification_completed,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackEinVerificationFailed = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_verification_failed,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackEinVerificationSuccess = (
  application: ApplicationReducer
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_verification_success,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || ''
    }
  });
};

export const trackEinVerificationError = (applicationId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_verification_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId
    }
  });
};

export const trackBoInfoSuccess = (application: ApplicationReducer) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_verification_completed,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      decision: application?.decision || '',
      applicationId: application?.id || '',
      stepUpDetails: JSON.stringify(application?.stepUpDetails),
      enableDeposits: application?.enableDeposits || '',
      applicationStatus: application?.applicationStatus || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || ''
    }
  });
};

export const trackBoInfoError = (applicationId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_bo_info_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId
    }
  });
};

export const trackBreatherStart = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_breather_start,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackBreatherConfirm = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_breather_confirm,
    section: SectionType.link,
    actionType: ActionType.click
  });
};

export const trackAccountSwitcherLoaded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_account_switcher_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackAccountSwitcherApplicationSelected = (account: Account) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_account_switcher_application_selected,
    section: SectionType.signup,
    actionType: ActionType.select,
    data: {
      riskDecision: account.riskDecision,
      applicationId: account.applicationId || ''
    }
  });
};

export const trackAccountSwitcherCreateNewApplication = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_account_switcher_create_new_application,
    section: SectionType.signup,
    actionType: ActionType.create
  });
};

export const trackAccountSwitcherApplicationConfirmed = (
  application: Account
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_account_switcher_application_confirmed,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      riskDecision: application.riskDecision,
      applicationId: application.applicationId || ''
    }
  });
};

export const trackStoreSwitcherLoaded = (stores: Store[] = []) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_store_switcher_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      stores: stores.length
    }
  });
};

export const trackStoreSwitcherUsedExistingStore = (store: Store) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_store_switcher_used_existing_store,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      storeId: store.id,
      businessId: store.businessId
    }
  });
};

export const trackStoreSwitcherUsedNewStore = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_store_switcher_used_new_store,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackStoreSwitcherNewBusinessCreationShown = () => {
  Traffic.trackEvent({
    eventName:
      TrafficTrackType.signup_store_switcher_new_business_creation_shown,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackStoreSwitcherSelectedBusinessCreation = () => {
  Traffic.trackEvent({
    eventName:
      TrafficTrackType.signup_store_switcher_selected_business_creation,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackStoreSwitcherScrollingUsage = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_store_switcher_scrolling_usage,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackStoreSwitcherWentBack = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_store_switcher_went_back,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackKycKybPageLoaded = (
  firstTxnDate?: string,
  referrer?: string
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_kyc_kyb_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      firstTxnDate,
      referrer
    }
  });
};

export const trackCreateApplicationPageLoaded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_create_application_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackAutoAttachStore = (data: Record<string, string>) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_auto_attach_store,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data
  });
};

export const trackStartedFillingApplication = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_started_filling_application,
    section: SectionType.signup,
    actionType: ActionType.change
  });
};

export const trackApplicationValidationErrors = (errors: {
  [key: string]: string;
}) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_application_validation_errors,
    section: SectionType.signup,
    actionType: ActionType.change,
    data: errors
  });
};

export const trackApplicationSubmitAttempt = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_application_submit_attempt,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackEinByEmailSubmitAttempt = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_by_email_submit_attempt,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackEinByEmailFormLanded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_ein_by_email_form_landed,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackBusinessCategorySelect = (label: string, type: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_business_category_selected,
    section: SectionType.signup,
    actionType: ActionType.change,
    data: {
      label,
      type
    }
  });
};

export const trackBusinessTypeSelect = (businessType: BusinessType) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_business_type_selected,
    section: SectionType.signup,
    actionType: ActionType.change,
    data: {
      businessType
    }
  });
};

export const trackOwnerOnboardStart = (owner: Owner, stepUp?: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_onboard_start,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      currentStepUp: stepUp || '',
      applicantId: owner?.id || '',
      stepUpDetails: JSON.stringify(owner?.stepUpDetails)
    }
  });
};

export const trackOwnerOnboardSuccess = (owner: Owner, stepUp?: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_onboard_success,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      currentStepUp: stepUp || '',
      applicantId: owner?.id || '',
      stepUpDetails: JSON.stringify(owner?.stepUpDetails)
    }
  });
};

export const trackOwnerOnboardError = (owner: Owner, stepUp?: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_onboard_error,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      currentStepUp: stepUp || '',
      applicantId: owner?.id || '',
      stepUpDetails: JSON.stringify(owner?.stepUpDetails)
    }
  });
};

export const trackBoApplicantErrorPageLoaded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_bo_applicant_error_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackAddOwnersPageLoaded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_add_owners_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackAddOwnersPageClickAddPartner = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_add_owners_page_click_add_partner,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackAddOwnersPageClickDeletePartner = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_add_owners_page_click_delete_partner,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackAddOwnersPageClickSubmitPartners = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_add_owners_page_click_submit_partner,
    section: SectionType.signup,
    actionType: ActionType.submit
  });
};

export const trackAddOwnersPageClickShowSkipPartnersModal = () => {
  Traffic.trackEvent({
    eventName:
      TrafficTrackType.signup_add_owners_page_click_show_skip_partners_modal,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackAddOwnersPageClickCloseSkipPartnersModal = () => {
  Traffic.trackEvent({
    eventName:
      TrafficTrackType.signup_add_owners_page_click_close_skip_partners_modal,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackAddOwnersPageClickSkipPartners = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_add_owners_page_click_skip_partners,
    section: SectionType.signup,
    actionType: ActionType.submit
  });
};

export const trackOwnerPageLoaded = (ownerId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      applicantId: ownerId
    }
  });
};

export const trackOwnerPageClickSupport = (ownerId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_page_click_support,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      applicantId: ownerId
    }
  });
};

export const trackOwnerPageClickSubmit = (ownerId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_page_click_submit,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      applicantId: ownerId
    }
  });
};

export const trackOwnerSuccessPageLoaded = (ownerId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_success_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      applicantId: ownerId
    }
  });
};

export const trackOwnerSuccessPageClickCTA = (ownerId: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_owner_success_page_click_cta,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      applicantId: ownerId
    }
  });
};

export const trackOverlapCurrencyError = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_overlap_currency_error,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackBusinessInfoFormLanded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_business_info_form_landed,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackBusinessInfoFormSubmitAttempt = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_business_info_form_submit_attempt,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackPhoneAuthenticationProcessStarted = (
  application: ApplicationReducer
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_phone_authentication_process_started,
    section: SectionType.signup,
    actionType: ActionType.redux,
    data: {
      applicationId: application?.id || '',
      applicationLevel: application?.applicationLevel || '',
      originalApplicationLevel: application?.originalApplicationLevel || ''
    }
  });
};

export const trackPhoneAuthenticationProcessCancelled = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_phone_authentication_process_cancelled,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackPhoneResendLink = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_phone_resend_link,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackPhoneAuthenticationFailed = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_phone_authentication_failed,
    section: SectionType.signup,
    actionType: ActionType.redux
  });
};

export const trackMultistepApplicantPageLoaded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_applicant_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackMultistepWizardPageSubmit = (
  page: MultistepPageType,
  current: number,
  remaining: number
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_wizard_page_submit,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      page,
      current,
      remaining
    }
  });
};

export const trackMultistepWizardPageError = (
  page: MultistepPageType,
  current: number
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_wizard_page_error,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      page,
      current
    }
  });
};

export const trackMultistepStepupVerifyClick = (stepup: StepUpActionType) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_stepup_page_verify_click,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      stepup
    }
  });
};

export const trackMultistepStepupDoThisLaterClick = (
  stepup: StepUpActionType
) => {
  Traffic.trackEvent({
    eventName:
      TrafficTrackType.signup_multistep_stepup_page_do_this_later_click,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      stepup
    }
  });
};

export const trackMultistepAddBankClick = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_add_bank_click,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackMultistepDashboardClick = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_dashboard_click,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackMultistepCountrySwitcherLoaded = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_country_switcher_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackMultistepCountrySwitcherCanceled = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_country_switcher_canceled,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackMultistepCountrySwitcherYesClicked = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_country_switcher_yes_clicked,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackMultistepCountrySwitcherNoClicked = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_multistep_country_switcher_no_clicked,
    section: SectionType.signup,
    actionType: ActionType.click
  });
};

export const trackSignupIncorrectUrl = () => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_incorrect_url,
    section: SectionType.signup,
    actionType: ActionType.loaded
  });
};

export const trackCashAdvanceOffersPageLoaded = (businessId?: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_cash_advance_offers_page_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      businessId
    }
  });
};

export const trackCashAdvanceOffersLoaded = (
  businessId?: string,
  offersAmount?: number
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_cash_advance_offers_loaded,
    section: SectionType.signup,
    actionType: ActionType.loaded,
    data: {
      businessId,
      offersAmount
    }
  });
};

export const trackCashAdvanceOffersAcceptClick = (
  businessId?: string,
  offerId?: string
) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_cash_advance_offers_accept_click,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      businessId,
      offerId
    }
  });
};

export const trackCashAdvanceOffersBackClick = (businessId?: string) => {
  Traffic.trackEvent({
    eventName: TrafficTrackType.signup_cash_advance_offers_back_click,
    section: SectionType.signup,
    actionType: ActionType.click,
    data: {
      businessId
    }
  });
};
