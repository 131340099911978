import { Dispatch } from 'redux';

import { Currency } from 'enums/business/currency';
import { ChannelType } from 'enums/channel/type';

import { Category } from 'reducers/categories/types';
import { State as ApplicantReducer } from 'reducers/applicant/types';
import { State as CompanyReducer } from 'reducers/company/types';

import { Account } from 'rest/v2/accounts/interfaces';

import {
  saveApplicantAddress,
  saveApplicantEmail,
  saveApplicantLeadId,
  saveApplicantName,
  saveApplicantPhone,
  saveApplicantUserId
} from 'actions/applicant';
import {
  saveApplicationCampaignName,
  saveApplicationCustomerId,
  saveApplicationMedium,
  saveApplicationContext,
  saveApplicationServiceId,
  saveApplicationServiceType,
  saveApplicationSource,
  saveApplicationToken,
  saveApplicationMasterMid,
  saveApplicationMock,
  saveApplicationVentureId,
  saveApplicationPrefillCheck,
  saveApplicationApiVersion,
  saveApplicationCreateBusinessMode,
  saveIsApplicationPowerSeller,
  saveApplicationBusinessId,
  saveApplicationStoreSwitcherMode,
  saveApplicationKycKybRequiredMode,
  saveApplicationAgentId
} from 'actions/application';
import {
  saveCompanyAddress,
  saveCompanyDba,
  saveCompanyName,
  saveCompanyPhone,
  saveCompanySegment,
  saveCompanySite
} from 'actions/company';
import { saveOrderAddress } from 'actions/order';
import { saveApplicationId } from 'actions/application';
import {
  saveChannelName,
  saveChannelType,
  saveChannelExternalId,
  saveChannelExternalType,
  saveChannelRequested
} from 'actions/channel';

import { getBusinessCategoryIndustry } from 'helpers/categories';

import {
  applicationPrefillParser,
  parseAddress,
  parsePhoneNumber,
  parseEmail
} from './application-prefill-parser';

import { parseName } from './parse-name';

export const applicationPrefill = (
  query: URLSearchParams,
  dispatch: Dispatch,
  account?: Account,
  currency?: Currency
): void => {
  const data = applicationPrefillParser(query);
  const [applicantAddress, companyAddress] = parseAddress(data, currency);
  const phone = parsePhoneNumber(data.phone, account);
  const name = parseName(data.name, currency, account);
  const email = parseEmail(data.email, account);
  const companySegment = data.mcc || data.companySegment;

  if (phone) {
    dispatch(saveApplicantPhone(phone));
  }

  if (email) {
    dispatch(saveApplicantEmail(email));
  }

  if (data.agentId) {
    dispatch(saveApplicationAgentId(data.agentId));
  }

  if (data.lead_id) {
    dispatch(saveApplicantLeadId(data.lead_id));
  }

  if (data.user_id) {
    dispatch(saveApplicantUserId(data.user_id));
  }

  if (name) {
    dispatch(saveApplicantName(name));
  }

  if (data.mock) {
    dispatch(saveApplicationMock(!!data.mock));
  }

  if (data.token) {
    dispatch(saveApplicationToken(data.token));
  }

  if (data.medium) {
    dispatch(saveApplicationMedium(data.medium));
  }

  if (data.context) {
    dispatch(saveApplicationContext(data.context));
  }

  if (data.source) {
    dispatch(saveApplicationSource(data.source));
  }

  if (data.masterMid) {
    dispatch(saveApplicationMasterMid(!!data.masterMid));
  }

  if (data.campaign) {
    dispatch(saveApplicationCampaignName(data.campaign));
  }

  if (data.createBusiness) {
    dispatch(saveApplicationCreateBusinessMode(!!data.createBusiness));
  }

  if (data.apiVersion) {
    dispatch(saveApplicationApiVersion(data.apiVersion));
  }

  const channelType = data.channelType?.toUpperCase() as ChannelType;

  if (!Object.values(ChannelType).includes(channelType)) {
    data.channelType = '';
  }

  if (data.channelName) {
    dispatch(saveChannelName(data.channelName));
  }

  if (data.channelType) {
    dispatch(saveChannelType(channelType));
  }

  if (data.channelName && data.channelType) {
    dispatch(saveChannelRequested());
  }

  if (data.powerSeller) {
    dispatch(saveIsApplicationPowerSeller(!!data.powerSeller));
  }

  if (data.customerId) {
    dispatch(saveApplicationCustomerId(data.customerId));
  }

  if (data.serviceId) {
    dispatch(saveApplicationServiceId(data.serviceId));
    dispatch(saveChannelExternalId(data.serviceId));
  }

  if (data.serviceType) {
    dispatch(saveApplicationServiceType(data.serviceType));
    dispatch(saveChannelExternalType(data.serviceType));
  }

  if (data.ventureId) {
    dispatch(saveApplicationVentureId(data.ventureId));
  }

  if (data.businessId) {
    dispatch(saveApplicationBusinessId(data.businessId));
  }

  if (data.showStoreSwitcher) {
    dispatch(saveApplicationStoreSwitcherMode(!!data.showStoreSwitcher));
  }

  if (data.kycKybRequired) {
    dispatch(saveApplicationKycKybRequiredMode(!!data.kycKybRequired));
  }

  if (data.companyName) {
    dispatch(saveCompanyName(data.companyName));
  }

  if (data.companyDba) {
    dispatch(saveCompanyDba(data.companyDba));
  }

  if (data.companyPhone) {
    dispatch(saveCompanyPhone(data.companyPhone));
  }

  if (Object.keys(applicantAddress).length) {
    dispatch(saveApplicantAddress(applicantAddress));
    dispatch(saveOrderAddress(applicantAddress));
  }

  if (Object.keys(companyAddress).length) {
    dispatch(saveCompanyAddress(companyAddress));
  }

  if (data.applicationId) {
    dispatch(saveApplicationId(data.applicationId));
  }

  if (companySegment) {
    dispatch(saveCompanySegment(companySegment));
  }

  if (data.companyWebsite) {
    dispatch(saveCompanySite(data.companyWebsite));
  }

  dispatch(saveApplicationPrefillCheck());
};

export const isApplicationFullPrefill = (
  applicant: ApplicantReducer,
  company: CompanyReducer,
  categories: Category[]
): boolean =>
  Boolean(
    company.address.city &&
      company.address.territory &&
      company.address.line1 &&
      company.address.zip &&
      company.segment &&
      (categories?.length
        ? getBusinessCategoryIndustry(company.segment, categories)
        : true) &&
      company.name &&
      applicant.phone.localPhoneNumber &&
      applicant.phone.localPhoneNumber.length === 10 &&
      applicant.name.lastName &&
      applicant.name.firstName &&
      applicant.email.emailAddress
  );
