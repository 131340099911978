import axios from 'axios';

export const onboardApplicationThroughProxyMethod = async (
  applicationId: string
): Promise<unknown> => {
  const response = await axios.post(
    `/api/v1/commerce/onboarding-applications/${applicationId}/onboard`,
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000'
      }
    }
  );

  if (response.data) {
    return {
      application: {
        applicationId: response.data.id,
        businessId: response.data.metadata.businessId,
        boStatus: response.data.metadata.boStatus,
        storeId: response.data.metadata.storeId,
        decision: response.data.metadata.riskDecision,
        stepUpDetails: response.data.metadata.stepUpDetails,
        applicationLevel: response.data.metadata.applicationLevel,
        originalApplicationLevel:
          response.data.metadata.originalApplicationLevel,
        originalIntent: response.data.intent
      }
    };
  }
};
