import axios from 'axios';

import { ApplicationResponseInterface } from './../interfaces';

export const onboardApplication = async (
  applicationId: string
): Promise<ApplicationResponseInterface> => {
  const response = await axios.post(
    `/api/v2/applications/${applicationId}/onboard`,
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000'
      }
    }
  );

  return response.data;
};
