import { LocaleType } from 'enums/applicant/locale-type';

import Environments from 'helpers/environments';

import * as analyticsHelper from './../analytics/track';
import { useEffect, useState } from 'react';

export const isGodaddy = (): boolean => {
  const godaddyHost = Environments.configuration.godaddyCookieHost as string;

  return location.host.includes(godaddyHost);
};

export const getRedirectUrl = (
  url?: string,
  serviceId?: string,
  context?: string,
  authCode?: string,
  applicationCompleted?: boolean,
  ventureId?: string
) => {
  const redirectUrl = (url || '')
    .replace('{%ID%}', serviceId || '')
    .replace('{%VENTUREID%}', ventureId || '');

  const query: string[] = [];

  // authCode includes context
  if (authCode) {
    query.push(authCode);
  } else if (context) {
    query.push(`context=${encodeURIComponent(context)}`);
  }

  if (applicationCompleted) {
    query.push('applicationCompleted=true');
  }

  if (!query.length) {
    return redirectUrl;
  } else if (redirectUrl.indexOf('?') !== -1) {
    return `${redirectUrl}&${query.join('&')}`;
  } else {
    return `${redirectUrl}?${query.join('&')}`;
  }
};

export const googleScriptStatus: Record<string, boolean> = {
  'googleScriptLoaded': false,
  'googleScriptLoading': false
};

export const insertScriptGoogle = (
  onloadCallback: (value: boolean) => void,
  language: string
) => {
  let script;
  let retry = 0;

  if (
    googleScriptStatus.googleScriptLoaded ||
    googleScriptStatus.googleScriptLoading
  ) {
    return;
  }

  googleScriptStatus.googleScriptLoading = true;

  const addScript = () => {
    if (retry >= 3) {
      return;
    }

    retry++;

    if (script) {
      document.body.removeChild(script);
    }

    script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    document.body.appendChild(script);

    const formattedLanguage =
      language === LocaleType['fr-CA']
        ? language
        : (language || LocaleType['en-US']).split('-')?.[0];

    script.setAttribute(
      'src',
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCG9fDqUh9QnZD8AyMYXRJ16tiw81YR1gE&libraries=places&callback=onloadScriptGoogle&language=${formattedLanguage}`
    );

    (
      window as Window &
        typeof global & {
          onloadScriptGoogle: Function;
        }
    ).onloadScriptGoogle = script.onload = () => {
      if (googleScriptStatus.googleScriptLoaded) {
        return;
      }

      googleScriptStatus.googleScriptLoading = false;
      googleScriptStatus.googleScriptLoaded = true;
      onloadCallback(true);
    };

    script.onerror = () => {
      setTimeout(addScript, 1000);
      analyticsHelper.trackError('Error Insert Google script');
    };
  };

  addScript();

  return () => {
    document.body.removeChild(script);
  };
};

export const useGoogleScript = (language: string): boolean => {
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);

  useEffect(() => {
    if (!googleScriptLoaded) {
      insertScriptGoogle(setGoogleScriptLoaded, language);
    }
  }, [googleScriptLoaded, googleScriptStatus.googleScriptLoaded]);

  return googleScriptLoaded || googleScriptStatus.googleScriptLoaded;
};
