import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  offers: [],
  loaded: false
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_CASH_ADVANCE_OFFERS':
      return {
        ...state,
        offers: action.payload,
        loaded: true
      };
    default:
      return state;
  }
}
