import { AnyAction } from 'redux';

import { Account, PlaidAccount } from 'reducers/bank/types';

export function saveBankSubmitted(status: boolean): AnyAction {
  return {
    payload: status,
    type: 'SAVE_BANK_SUBMITTED'
  };
}

export function createBankAccountSuccess(): AnyAction {
  return {
    type: 'CREATE_BANK_ACCOUNT_V2_SUCCESS'
  };
}

export function saveMerchantAccount(account: Account): AnyAction {
  return {
    payload: account,
    type: 'SAVE_MERCHANT_ACCOUNT'
  };
}

export function createBankAccountRequest(): AnyAction {
  return {
    type: 'CREATE_BANK_ACCOUNT_V2_REQUEST'
  };
}

export function createPlaidAccountRequest(): AnyAction {
  return {
    type: 'CREATE_PLAID_ACCOUNT_V2_REQUEST'
  };
}

export function saveBankAccountId(id: number): AnyAction {
  return {
    payload: id,
    type: 'SAVE_BANK_ACCOUNT_ID'
  };
}

export function createBankAccountFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'CREATE_BANK_ACCOUNT_V2_FAILURE'
  };
}

export function createPlaidAccountSuccess(): AnyAction {
  return {
    type: 'CREATE_PLAID_ACCOUNT_V2_SUCCESS'
  };
}

export function savePlaidBalance(availableBalance: number): AnyAction {
  return {
    payload: availableBalance,
    type: 'SAVE_PLAID_BALANCE'
  };
}

export function savePlaidAccount(account: PlaidAccount): AnyAction {
  return {
    payload: account,
    type: 'SAVE_MERCHANT_PLAID_ACCOUNT'
  };
}

export function savePlaidInstantAuth(instantAuth: boolean): AnyAction {
  return {
    payload: instantAuth,
    type: 'SAVE_PLAID_INSTANT_AUTH'
  };
}

export function savePlaidAccountId(id: string): AnyAction {
  return {
    payload: id,
    type: 'SAVE_PLAID_ACCOUNT_ID'
  };
}

export function createPlaidAccountFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'CREATE_PLAID_ACCOUNT_V2_FAILURE'
  };
}

export function saveCreditCard(
  token: string,
  brand: string,
  last4: string,
  verify: boolean
): AnyAction {
  return {
    payload: {
      brand,
      last4,
      token,
      verify
    },
    type: 'SAVE_CREDIT_CARD'
  };
}

export function createCreditCard(redirect = true): AnyAction {
  return {
    payload: {
      redirect
    },
    type: 'CREATE_CREDIT_CARD_REQUEST'
  };
}
