export enum MixpanelTrackType {
  SIGNUP_START = 'SIGNUP_START',
  SIGNUP_ERROR = 'SIGNUP_ERROR',
  SIGNUP_VIEW_TOS = 'SIGNUP_VIEW_TOS',
  SIGNUP_ENTER_SSN = 'SIGNUP_ENTER_SSN',
  SIGNUP_ENTER_EIN = 'SIGNUP_ENTER_EIN',
  SIGNUP_ENTER_CN = 'SIGNUP_ENTER_CN',
  SIGNUP_RESUME_AN_APPLICATION = 'SIGNUP_RESUME_AN_APPLICATION',
  SIGNUP_RESUME_APPLICATION_BY_EMAIL = 'SIGNUP_RESUME_APPLICATION_BY_EMAIL',
  SIGNUP_APPLICATION_BY_REMINDER_EMAIL = 'SIGNUP_APPLICATION_BY_REMINDER_EMAIL',
  SIGNUP_PLAID_STARTED = 'SIGNUP_PLAID_STARTED',
  SIGNUP_PLAID_FINISHED = 'SIGNUP_PLAID_FINISHED',
  SIGNUP_MANUAL_BANK_ACCOUNT_STARTED = 'SIGNUP_MANUAL_BANK_ACCOUNT_STARTED',
  SIGNUP_MANUAL_BANK_ACCOUNT_FINISHED = 'SIGNUP_MANUAL_BANK_ACCOUNT_FINISHED',
  SIGNUP_BANK_ACCOUNT_CANCELED = 'SIGNUP_BANK_ACCOUNT_CANCELED',
  SIGNUP_OTP_AUTHENTICATION_PROCESS_STARTED = 'SIGNUP_OTP_AUTHENTICATION_PROCESS_STARTED',
  SIGNUP_OTP_AUTHENTICATION_PROCESS_CANCELLED = 'SIGNUP_OTP_AUTHENTICATION_PROCESS_CANCELLED',
  SIGNUP_OTP_RESEND_OTP = 'SIGNUP_OTP_RESEND_OTP',
  SIGNUP_OTP_CODE_ENTERED = 'SIGNUP_OTP_CODE_ENTERED',
  SIGNUP_OTP_NO_OF_ATTEMPTS = 'SIGNUP_OTP_NO_OF_ATTEMPTS',
  SIGNUP_ONBOARD_START = 'SIGNUP_ONBOARD_START',
  SIGNUP_ONBOARD_SUCCESS = 'SIGNUP_ONBOARD_SUCCESS',
  SIGNUP_ONBOARD_ERROR = 'SIGNUP_ONBOARD_ERROR',
  SIGNUP_OTP_AUTHENTICATION_FAILED = 'SIGNUP_OTP_AUTHENTICATION_FAILED',
  SIGNUP_OTP_AUTHENTICATION_SUCCESS = 'SIGNUP_OTP_AUTHENTICATION_SUCCESS',
  SIGNUP_OTP_AUTHENTICATION_ERROR = 'SIGNUP_OTP_AUTHENTICATION_ERROR',
  SIGNUP_PHONE_AUTHENTICATION_PROCESS_STARTED = 'SIGNUP_PHONE_AUTHENTICATION_PROCESS_STARTED',
  SIGNUP_PHONE_AUTHENTICATION_PROCESS_CANCELLED = 'SIGNUP_PHONE_AUTHENTICATION_PROCESS_CANCELLED',
  SIGNUP_PHONE_RESEND_LINK = 'SIGNUP_PHONE_RESEND_LINK',
  SIGNUP_PHONE_AUTHENTICATION_FAILED = 'SIGNUP_PHONE_AUTHENTICATION_FAILED',
  SIGNUP_PHONE_AUTHENTICATION_SUCCESS = 'SIGNUP_PHONE_AUTHENTICATION_SUCCESS',
  SIGNUP_PHONE_AUTHENTICATION_ERROR = 'SIGNUP_PHONE_AUTHENTICATION_ERROR',
  SIGNUP_ID_AUTHENTICATION_STARTED = 'SIGNUP_ID_AUTHENTICATION_STARTED',
  SIGNUP_ID_AUTHENTICATION_COMPLETED = 'SIGNUP_ID_AUTHENTICATION_COMPLETED',
  SIGNUP_ID_AUTHENTICATION_SUCCESS = 'SIGNUP_ID_AUTHENTICATION_SUCCESS',
  SIGNUP_ID_AUTHENTICATION_ERROR = 'SIGNUP_ID_AUTHENTICATION_ERROR',
  SIGNUP_FULL_SSN_FORM_SUBMIT_ATTEMPT = 'SIGNUP_FULL_SSN_FORM_SUBMIT_ATTEMPT',
  SIGNUP_FULL_SSN_FORM_LANDED = 'SIGNUP_FULL_SSN_FORM_LANDED',
  SIGNUP_EIN_VERIFICATION_STARTED = 'SIGNUP_EIN_VERIFICATION_STARTED',
  SIGNUP_EIN_VERIFICATION_COMPLETED = 'SIGNUP_EIN_VERIFICATION_COMPLETED',
  SIGNUP_EIN_VERIFICATION_FAILED = 'SIGNUP_EIN_VERIFICATION_FAILED',
  SIGNUP_EIN_VERIFICATION_SUCCESS = 'SIGNUP_EIN_VERIFICATION_SUCCESS',
  SIGNUP_EIN_VERIFICATION_ERROR = 'SIGNUP_EIN_VERIFICATION_ERROR',
  SIGNUP_BO_INFO_SUCCESS = 'SIGNUP_BO_INFO_SUCCESS',
  SIGNUP_BO_INFO_ERROR = 'SIGNUP_BO_INFO_ERROR',
  SIGNUP_BREATHER_START = 'SIGNUP_BREATHER_START',
  SIGNUP_BREATHER_CONFIRM = 'SIGNUP_BREATHER_CONFIRM',
  SIGNUP_ACCOUNT_SWITCHER_LOADED = 'SIGNUP_ACCOUNT_SWITCHER_LOADED',
  SIGNUP_ACCOUNT_SWITCHER_APPLICATION_SELECTED = 'SIGNUP_ACCOUNT_SWITCHER_APPLICATION_SELECTED',
  SIGNUP_ACCOUNT_SWITCHER_CREATE_NEW_APPLICATION = 'SIGNUP_ACCOUNT_SWITCHER_CREATE_NEW_APPLICATION',
  SIGNUP_ACCOUNT_SWITCHER_APPLICATION_CONFIRMED = 'SIGNUP_ACCOUNT_SWITCHER_APPLICATION_CONFIRMED',
  SIGNUP_STORE_SWITCHER_LOADED = 'SIGNUP_STORE_SWITCHER_LOADED',
  SIGNUP_STORE_SWITCHER_USED_EXISTING_STORE = 'SIGNUP_STORE_SWITCHER_USED_EXISTING_STORE',
  SIGNUP_STORE_SWITCHER_USED_NEW_STORE = 'SIGNUP_STORE_SWITCHER_USED_NEW_STORE',
  SIGNUP_STORE_SWITCHER_NEW_BUSINESS_CREATION_SHOWN = 'SIGNUP_STORE_SWITCHER_NEW_BUSINESS_CREATION_SHOWN',
  SIGNUP_STORE_SWITCHER_SELECTED_BUSINESS_CREATION = 'SIGNUP_STORE_SWITCHER_SELECTED_BUSINESS_CREATION',
  SIGNUP_STORE_SWITCHER_SCROLLING_USAGE = 'SIGNUP_STORE_SWITCHER_SCROLLING_USAGE',
  SIGNUP_STORE_SWITCHER_WENT_BACK = 'SIGNUP_STORE_SWITCHER_WENT_BACK',
  SIGNUP_KYC_KYB_PAGE_LOADED = 'SIGNUP_KYC_KYB_PAGE_LOADED',
  SIGNUP_CREATE_APPLICATION_PAGE_LOADED = 'SIGNUP_CREATE_APPLICATION_PAGE_LOADED',
  SIGNUP_AUTO_ATTACH_STORE = 'SIGNUP_AUTO_ATTACH_STORE',
  SIGNUP_STARTED_FILLING_APPLICATION = 'SIGNUP_STARTED_FILLING_APPLICATION',
  SIGNUP_APPLICATION_VALIDATION_ERRORS = 'SIGNUP_APPLICATION_VALIDATION_ERRORS',
  SIGNUP_APPLICATION_SUBMIT_ATTEMPT = 'SIGNUP_APPLICATION_SUBMIT_ATTEMPT',
  SIGNUP_EIN_BY_EMAIL_SUBMIT_ATTEMPT = 'SIGNUP_EIN_BY_EMAIL_SUBMIT_ATTEMPT',
  SIGNUP_EIN_BY_EMAIL_FORM_LANDED = 'SIGNUP_EIN_BY_EMAIL_FORM_LANDED',
  SIGNUP_BUSINESS_CATEGORY_SELECTED = 'SIGNUP_BUSINESS_CATEGORY_SELECTED',
  SIGNUP_BUSINESS_TYPE_SELECTED = 'SIGNUP_BUSINESS_TYPE_SELECTED',
  SIGNUP_BO_APPLICANT_ERROR_PAGE_LOADED = 'SIGNUP_BO_APPLICANT_ERROR_PAGE_LOADED',
  SIGNUP_ADD_OWNERS_PAGE_LOADED = 'SIGNUP_ADD_OWNERS_PAGE_LOADED',
  SIGNUP_ADD_OWNERS_PAGE_CLICK_ADD_PARTNER = 'SIGNUP_ADD_OWNERS_PAGE_CLICK_ADD_PARTNER',
  SIGNUP_ADD_OWNERS_PAGE_CLICK_DELETE_PARTNER = 'SIGNUP_ADD_OWNERS_PAGE_CLICK_DELETE_PARTNER',
  SIGNUP_ADD_OWNERS_PAGE_CLICK_SUBMIT_PARTNERS = 'SIGNUP_ADD_OWNERS_PAGE_CLICK_SUBMIT_PARTNERS',
  SIGNUP_ADD_OWNERS_PAGE_CLICK_SHOW_SKIP_PARTNERS_MODAL = 'SIGNUP_ADD_OWNERS_PAGE_CLICK_SHOW_SKIP_PARTNERS_MODAL',
  SIGNUP_ADD_OWNERS_PAGE_CLICK_CLOSE_SKIP_PARTNERS_MODAL = 'SIGNUP_ADD_OWNERS_PAGE_CLICK_CLOSE_SKIP_PARTNERS_MODAL',
  SIGNUP_ADD_OWNERS_PAGE_CLICK_SKIP_PARTNERS = 'SIGNUP_ADD_OWNERS_PAGE_CLICK_SKIP_PARTNERS',
  SIGNUP_OWNER_ONBOARD_START = 'SIGNUP_OWNER_ONBOARD_START',
  SIGNUP_OWNER_ONBOARD_SUCCESS = 'SIGNUP_OWNER_ONBOARD_SUCCESS',
  SIGNUP_OWNER_ONBOARD_ERROR = 'SIGNUP_OWNER_ONBOARD_ERROR',
  SIGNUP_OWNER_PAGE_LOADED = 'SIGNUP_OWNER_PAGE_LOADED',
  SIGNUP_OWNER_PAGE_CLICK_SUPPORT = 'SIGNUP_OWNER_PAGE_CLICK_SUPPORT',
  SIGNUP_OWNER_PAGE_CLICK_SUBMIT = 'SIGNUP_OWNER_PAGE_CLICK_SUBMIT',
  SIGNUP_OWNER_SUCCESS_PAGE_LOADED = 'SIGNUP_OWNER_SUCCESS_PAGE_LOADED',
  SIGNUP_OWNER_SUCCESS_PAGE_CLICK_CTA = 'SIGNUP_OWNER_SUCCESS_PAGE_CLICK_CTA',
  SIGNUP_OVERLAP_CURRENCY_ERROR = 'SIGNUP_OVERLAP_CURRENCY_ERROR',
  SIGNUP_BUSINESS_INFO_FORM_LANDED = 'SIGNUP_BUSINESS_INFO_FORM_LANDED',
  SIGNUP_BUSINESS_INFO_FORM_SUBMIT_ATTEMPT = 'SIGNUP_BUSINESS_INFO_FORM_SUBMIT_ATTEMPT',
  SIGNUP_MULTISTEP_APPLICANT_PAGE_LOADED = 'SIGNUP_MULTISTEP_APPLICANT_PAGE_LOADED',
  SIGNUP_MULTISTEP_WIZARD_PAGE_SUBMIT = 'SIGNUP_MULTISTEP_WIZARD_PAGE_SUBMIT',
  SIGNUP_MULTISTEP_WIZARD_PAGE_ERROR = 'SIGNUP_MULTISTEP_WIZARD_PAGE_ERROR',
  SIGNUP_MULTISTEP_STEPUP_PAGE_VERIFY_CLICK = 'SIGNUP_MULTISTEP_STEPUP_PAGE_VERIFY_CLICK',
  SIGNUP_MULTISTEP_STEPUP_PAGE_DO_THIS_LATER_CLICK = 'SIGNUP_MULTISTEP_STEPUP_PAGE_DO_THIS_LATER_CLICK',
  SIGNUP_MULTISTEP_ADD_BANK_CLICK = 'SIGNUP_MULTISTEP_ADD_BANK_CLICK',
  SIGNUP_MULTISTEP_DASHBOARD_CLICK = 'SIGNUP_MULTISTEP_DASHBOARD_CLICK',
  SIGNUP_MULTISTEP_COUNTRY_SWITCHER_LOADED = 'SIGNUP_MULTISTEP_COUNTRY_SWITCHER_LOADED',
  SIGNUP_MULTISTEP_COUNTRY_SWITCHER_CANCELED = 'SIGNUP_MULTISTEP_COUNTRY_SWITCHER_CANCELED',
  SIGNUP_MULTISTEP_COUNTRY_SWITCHER_YES_CLICKED = 'SIGNUP_MULTISTEP_COUNTRY_SWITCHER_YES_CLICKED',
  SIGNUP_MULTISTEP_COUNTRY_SWITCHER_NO_CLICKED = 'SIGNUP_MULTISTEP_COUNTRY_SWITCHER_NO_CLICKED',
  SIGNUP_INCORRECT_URL = 'SIGNUP_INCORRECT_URL',
  SIGNUP_CASH_ADVANCE_OFFERS_PAGE_LOADED = 'SIGNUP_CASH_ADVANCE_OFFERS_PAGE_LOADED',
  SIGNUP_CASH_ADVANCE_OFFERS_LOADED = 'SIGNUP_CASH_ADVANCE_OFFERS_LOADED',
  SIGNUP_CASH_ADVANCE_OFFERS_ACCEPT_CLICK = 'SIGNUP_CASH_ADVANCE_OFFERS_ACCEPT_CLICK',
  SIGNUP_CASH_ADVANCE_OFFERS_BACK_CLICK = 'SIGNUP_CASH_ADVANCE_OFFERS_BACK_CLICK'
}
