export const parseUrlPathFoOwnerId = (url: string): string => {
  const segments = url.split('/');

  const ownersIndex = segments.indexOf('owners');

  if (
    ownersIndex !== -1 &&
    ownersIndex < segments.length - 1 &&
    segments[ownersIndex + 1].startsWith('urn:apl:')
  ) {
    return segments[ownersIndex + 1];
  }

  return '';
};
