import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export function redirectTo(path: string) {
  window.scrollTo(0, 0);
  history.push(path);
}

export function redirectBack() {
  history.goBack();
}

export function changeLocation(target?: string) {
  window.location.href = target || '';
}

export function closeWindow(): void {
  window.opener = null;
  window.open('', '_self');
  window.close();
}

export default history;
