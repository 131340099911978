import { AnyAction } from 'redux';

import { LocaleType } from 'enums/applicant/locale-type';

import { Name } from 'reducers/applicant/types';
import { Address } from 'reducers/company/types';
import { GovernmentIdentification } from 'reducers/company/types';
import { State as ApplicantReducer } from 'reducers/applicant/types';

export function saveApplicantDefaultState(
  state: Partial<ApplicantReducer>
): AnyAction {
  return {
    payload: state,
    type: 'SAVE_APPLICANT_DEFAULT_STATE'
  };
}

export function saveApplicantName(name: Name): AnyAction {
  return {
    payload: name,
    type: 'SAVE_APPLICANT_NAME'
  };
}

export function saveApplicantEmail(email: string): AnyAction {
  return {
    payload: email,
    type: 'SAVE_APPLICANT_EMAIL'
  };
}

export function saveApplicantPhone(phone: string): AnyAction {
  return {
    payload: phone,
    type: 'SAVE_APPLICANT_PHONE'
  };
}

export function saveApplicantTerms(signed: boolean): AnyAction {
  return {
    payload: signed,
    type: 'SAVE_APPLICANT_TERMS'
  };
}

export function saveApplicantLeadId(leadId?: string): AnyAction {
  return {
    payload: leadId,
    type: 'SAVE_APPLICANT_LEAD_ID'
  };
}

export function saveApplicantUserId(userId?: string): AnyAction {
  return {
    payload: userId,
    type: 'SAVE_APPLICANT_USER_ID'
  };
}

export function saveApplicantIdentification(
  governmentIdentification: GovernmentIdentification
) {
  return {
    type: 'SAVE_APPLICANT_IDENTIFICATION',
    payload: governmentIdentification
  };
}

export function saveApplicantAddress(address: Address): AnyAction {
  return {
    payload: address,
    type: 'SAVE_APPLICANT_ADDRESS'
  };
}

export function saveApplicantDob(dob: string): AnyAction {
  return {
    payload: dob,
    type: 'SAVE_APPLICANT_DOB'
  };
}

export function saveApplicantSin(sin: string): AnyAction {
  return {
    payload: sin,
    type: 'SAVE_APPLICANT_SIN'
  };
}

export function saveApplicantCommunicationLanguage(
  language: LocaleType
): AnyAction {
  return {
    payload: language,
    type: 'SAVE_APPLICANT_COMMUNICATION_LANGUAGE'
  };
}

export function saveApplicantId(applicantId: string): AnyAction {
  return {
    payload: applicantId,
    type: 'SAVE_APPLICANT_ID'
  };
}

export function saveApplicantPersonaInquiryId(
  personaInquiryId: string | null
): AnyAction {
  return {
    payload: personaInquiryId,
    type: 'SAVE_PERSONA_INQUIRY_ID'
  };
}

export function saveApplicantPersonaSessionToken(
  personaSessionToken: string
): AnyAction {
  return {
    payload: personaSessionToken,
    type: 'SAVE_PERSONA_SESSION_TOKEN'
  };
}
