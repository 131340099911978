import axios from 'axios';

import { StepUpOwnerActionType } from 'enums/owner/step-up';

import { Owner } from 'reducers/owners/types';

export const createApplicant = async (
  applicationId: string,
  applicant
): Promise<unknown> => {
  const response = await axios.post(
    `/api/v1/commerce/onboarding-applications/${applicationId}/individuals`,
    applicant
  );

  if (response.data) {
    return {
      applicantId: response.data.id
    };
  }
};

export const patchApplicantById = async (
  applicationId: string,
  applicant: Owner
): Promise<unknown> => {
  const changes: unknown[] = [
    {
      op: 'replace',
      path: '/email',
      value: applicant.email
    },
    {
      op: 'replace',
      path: '/firstName',
      value: applicant.firstName
    },
    {
      op: 'replace',
      path: '/lastName',
      value: applicant.lastName
    },
    {
      op: 'replace',
      path: '/phone',
      value: applicant.phone
    },
    {
      op: 'replace',
      path: '/governmentIdentification',
      value: applicant.governmentIdentification
    }
  ];

  if (applicant.dob) {
    changes.push({
      op: 'replace',
      path: '/dob',
      value: applicant.dob
    });
  }

  const response = await axios.patch(
    `/api/v1/commerce/onboarding-applications/${applicationId}/individuals/${applicant.id}`,
    changes
  );

  if (response.data) {
    return response.data;
  }
};

export const onboardApplicantData = async (
  applicationId: string,
  applicantId: string
): Promise<unknown> => {
  const response = await axios.post(
    `/api/v1/commerce/onboarding-applications/${applicationId}/individuals/${applicantId}/onboard`
  );

  if (response.data) {
    return {
      id: response.data.id,
      stepUpDetails: (response.data.stepUpDetails || '').split(',')
    };
  }
};

export const updateApplicantWithStepUpInformation = async (
  applicationId: string,
  applicantId: string,
  type: string,
  data: { inquiryId?: string; code?: string; skip?: boolean }
): Promise<unknown> => {
  let path;
  let value;

  switch (type) {
    case StepUpOwnerActionType.GOV_ID_SELFIE: {
      path = '/personaInquiryId';
      value = data.inquiryId;
      break;
    }
  }

  const response = await axios.patch(
    `/api/v1/commerce/onboarding-applications/${applicationId}/individuals/${applicantId}/`,
    [
      {
        op: 'add',
        path,
        value
      }
    ]
  );

  if (response.data) {
    return response.data;
  }
};
