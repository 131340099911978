import { AnyAction } from 'redux';

export function track(type: string, ...params: unknown[]): AnyAction {
  return {
    payload: { type, params },
    type: 'TRACK_ANALYTICS'
  };
}

export function trackOnce(type: string, ...params: unknown[]): AnyAction {
  return {
    payload: {
      type,
      oneTimeTrack: true,
      params
    },
    type: 'TRACK_ANALYTICS'
  };
}
