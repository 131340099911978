import { AnyAction } from 'redux';
import { call, put, select, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { StatusCodes } from 'http-status-codes';

import * as applicantActions from 'actions/applicant';
import * as applicationActions from 'actions/application';
import * as bankActions from 'actions/bank';
import * as companyActions from 'actions/company';
import * as orderAction from 'actions/order';
import * as otpActions from 'actions/otp';
import * as ownersActions from 'actions/owners';

import { Currency } from 'enums/business/currency';
import { ApiVersion } from 'enums/application/version';
import { CountryType } from 'enums/address/country-type';
import { ApplicationLevel } from 'enums/application/application-level';
import { DecisionType } from 'enums/application/decision';
import { StepUpActionType } from 'enums/application/step-up';
import { StepUpOwnerActionType } from 'enums/owner/step-up';
import { PortalType } from 'enums/portal/type';
import { GovIdType } from 'enums/gov-id/type';
import { ApplicationIntent } from 'enums/application/intent';
import { GodaddySsoPermissions } from 'enums/godaddy-sso-permissions';
import { BeneficialOwnersStatus } from 'enums/application/beneficial-owners-status';
import { InquirySessionStatus } from 'enums/persona/inquiry-session-status';
import { StepUpType } from 'enums/application/step-up-type';

import * as analyticsHelper from 'helpers/analytics/track';
import * as countryResolverHelper from 'helpers/country/country-resolver';
import * as applicationCacheHelper from 'helpers/application/cache';

import { getRedirectUrl } from 'helpers/godaddy';
import { changeLocation, redirectBack, redirectTo } from 'helpers/history';
import * as toastHelpers from 'helpers/toast';
import * as uxCoreHelper from 'helpers/ux-core';

import { State as AccountsReducer } from 'reducers/account/types';
import { State as ApplicationReducer } from 'reducers/application/types';
import { State as BankReducer } from 'reducers/bank/types';
import { State as EnvironmentReducer } from 'reducers/environment/types';
import { State as ApplicantReducer } from 'reducers/applicant/types';
import { State as ChannelReducer } from 'reducers/channel/types';
import { State as CompanyReducer } from 'reducers/company/types';

import { Store } from 'reducers/types';

import * as apiV1 from 'rest/v1/commerce/onboarding-applications';
import * as apiV1Onboard from 'rest/v1/commerce/onboarding-applications/onboard';

import * as apiV2 from 'rest/v2/application';
import * as apiV2Accounts from 'rest/v2/accounts';
import * as apiV2AuthCode from 'rest/v2/application/auth-code';
import * as apiV2Onboarding from 'rest/v2/application/onboard';
import * as apiV2StepUps from 'rest/v2/application/step-ups';
import * as apiV2Permissions from 'rest/v2/permissions';
import * as apiV2GovernmentIdentifications from 'rest/v2/application/government-identifications';

import {
  ApplicationResponseInterface,
  ApplicationUpdateResponseInterface,
  CreateApplicationArgsInterface,
  GetApplicationResponseInterface
} from 'rest/v2/application/interfaces';
import { Account } from 'rest/v2/accounts/interfaces';

const getCompany = (state: Store) => state.company;
const getApplicant = (state: Store) => state.applicant;
const getApplication = (state: Store) => state.application;
const getAccounts = (state: Store) => state.accounts;
const getBank = (state: Store) => state.bank;
const getEnvironment = (state: Store) => state.environment;
const getApplicationId = (state: Store) => state.application.id;
const getChannel = (state: Store) => state.channel;

export function* onboardApplication(action?: AnyAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const applicationId: string = yield select(getApplicationId);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);
  const company: CompanyReducer = yield select(getCompany);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  yield call(
    analyticsHelper.trackOnboardStart,
    application,
    action?.payload?.type
  );

  const referralUrlIdPath = environment.cobrand.referralUrlId
    ? `/r/${environment.cobrand.referralUrlId}`
    : '';

  try {
    if (!applicationId) {
      yield call(
        analyticsHelper.trackError,
        `Application update Error during getting applicationId in onboarding: ${action?.payload?.type}`
      );

      throw new Error('Application not found, please reload the page.');
    }

    const useNewApi = application.apiVersion === ApiVersion.V1;

    const onboardApplicationApi = useNewApi
      ? apiV1Onboard.onboardApplicationThroughProxyMethod
      : apiV2Onboarding.onboardApplication;

    const onboardedApplication: ApplicationResponseInterface = yield call(
      onboardApplicationApi,
      applicationId
    );

    if (action?.payload?.timeout) {
      yield delay(action?.payload?.timeout);
    }

    yield put(
      applicationActions.saveApplicationBusinessId(
        onboardedApplication?.application.businessId
      )
    );

    yield put(
      applicationActions.saveApplicationBoStatus(
        onboardedApplication?.application.boStatus
      )
    );

    yield put(
      applicationActions.saveApplicationStoreId(
        onboardedApplication?.application.storeId
      )
    );

    yield put(
      applicationActions.saveApplicationId(
        onboardedApplication?.application.applicationId
      )
    );
    yield put(
      applicationActions.saveApplicationDecision(
        onboardedApplication?.application.decision
      )
    );

    yield put(
      applicationActions.saveApplicationIntent(
        onboardedApplication?.application.originalIntent
      )
    );

    yield put(
      applicationActions.saveApplicationLevel(
        onboardedApplication?.application.applicationLevel
      )
    );

    yield put(
      applicationActions.saveOriginalApplicationLevel(
        onboardedApplication?.application.originalApplicationLevel
      )
    );

    if (onboardedApplication?.application.decision === DecisionType.ERROR) {
      yield call(
        redirectTo,
        `${referralUrlIdPath}/${onboardedApplication.application.applicationId}/error`
      );
    } else {
      yield put(
        applicationActions.saveApplicationStatus(
          onboardedApplication.application.applicationStatus
        )
      );

      yield put(
        applicationActions.saveApplicationEnableDeposits(
          onboardedApplication.application.enableDeposits
        )
      );

      yield put(
        applicationActions.saveApplicationStepUpDetails(
          onboardedApplication?.application.stepUpDetails
        )
      );

      let account;

      // if kyc/kyb required we have to find existing account in account list
      if (application.kycKybRequired) {
        account = accounts.list.find(
          account =>
            account.businessId === onboardedApplication.application.businessId
        );
      }

      // if account exists we have to redirect user to KYC/KYB page (and fetch account info)
      if (
        account &&
        account.applicationLevel === ApplicationLevel.BASIC &&
        onboardedApplication.application.applicationLevel ===
          ApplicationLevel.BASIC
      ) {
        const referralUrlIdPath = account.referralUrlId
          ? `/r/${account.referralUrlId}`
          : '';

        const redirectLink = `${referralUrlIdPath}/${account.applicationId}/${isUxCore ? 'applicant' : 'update'}`;
        yield call(redirectTo, redirectLink);
      } else {
        yield put(applicationActions.parseApplicationStepUp());
        yield put(applicationActions.completeApplication());
      }
    }

    const updatedApplication: ApplicationReducer = yield select(getApplication);

    yield call(
      analyticsHelper.trackOnboardSuccess,
      updatedApplication,
      action?.payload?.type
    );

    if (environment.eHopperFlowEnabled) {
      yield put(
        applicationActions.setSsoPermissions(GodaddySsoPermissions.ehopper)
      );
    }
    yield put(applicationActions.onboardApplicationSuccess());
  } catch (error) {
    yield call(
      analyticsHelper.trackOnboardError,
      application,
      action?.payload?.type
    );

    if (
      applicationId &&
      (!error?.response?.status ||
        error?.response?.status === StatusCodes.REQUEST_TIMEOUT ||
        error?.response?.status === StatusCodes.GATEWAY_TIMEOUT ||
        error?.response?.status === StatusCodes.SERVICE_UNAVAILABLE)
    ) {
      yield call(redirectTo, `${referralUrlIdPath}/${applicationId}/success`);
      yield put(applicationActions.onboardApplicationFailure());
      return;
    }

    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        yield put(applicationActions.onboardApplicationFailure(message));
      }
    } else {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message;

      yield call(analyticsHelper.trackError, message);
      toastHelpers.toast(message, isUxCore);
      yield put(applicationActions.onboardApplicationFailure(message));
    }

    if (applicationId) {
      yield call(redirectTo, `${referralUrlIdPath}/${applicationId}/error`);
    }
  }
}

export function* redirectApplication(action: AnyAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const channel: ChannelReducer = yield select(getChannel);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);
  const company: CompanyReducer = yield select(getCompany);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    const applicationCompleted = action?.payload;

    if (applicationCompleted && !environment.redirectUrl) {
      yield put(applicationActions.redirectApplicationSuccess());
      return;
    }

    const isGoDaddyPortal = environment.portal === PortalType.GODADDY;

    const authCode = yield call(
      apiV2AuthCode.getAuthCodeForApplication,
      application.id || '',
      application.context,
      channel.id
    );

    let isTheSameOrganization = true;

    if (isGoDaddyPortal) {
      isTheSameOrganization =
        application.organizationId === environment.cobrand.organizationId;

      if (!isTheSameOrganization) {
        const account = accounts?.list?.find(account => {
          let orgId;

          if (account?.applicationId === application.id) {
            orgId = account?.organizationId;
          }

          if (!orgId) {
            const linkedAccount = account.linkedApplications?.find(
              linkedApplication =>
                linkedApplication.applicationId === application.id
            );

            if (linkedAccount) {
              orgId = account?.organizationId;
            }
          }

          return orgId;
        });

        isTheSameOrganization =
          (!application.organizationId && !account?.organizationId) ||
          account?.organizationId === environment.cobrand.organizationId;
      }
    }

    const url =
      !isTheSameOrganization && environment.redirectUrlForSelectedAccount
        ? environment.redirectUrlForSelectedAccount
        : environment.redirectUrl;

    const redirectUrl = getRedirectUrl(
      url,
      application.serviceId,
      application.context,
      authCode.code,
      applicationCompleted,
      application.ventureId
    );

    yield call(changeLocation, redirectUrl);
    yield put(applicationActions.redirectApplicationSuccess());
  } catch (error) {
    const message =
      error?.response?.data.message ||
      error?.response?.data.error ||
      error.message;

    yield call(analyticsHelper.trackError, message);
    toastHelpers.toast(message, isUxCore);
    yield put(applicationActions.redirectApplicationFailure(message));
  }
}

export function* parseApplicationStepUps(action?: AnyAction) {
  try {
    const application: ApplicationReducer = yield select(getApplication);
    const company: CompanyReducer = yield select(getCompany);
    const bank: BankReducer = yield select(getBank);
    const environment: EnvironmentReducer = yield select(getEnvironment);
    const channel: ChannelReducer = yield select(getChannel);
    const accounts: AccountsReducer = yield select(getAccounts);

    const isUxCore = uxCoreHelper.isUxCoreEnabled({
      company,
      accounts,
      environment,
      application
    });

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    const isGoDaddyPortal = environment.portal === PortalType.GODADDY;

    const isApproved =
      application.decision === DecisionType.APPROVED ||
      application.decision === DecisionType.APPROVED_PENDING;

    const stepUpsWithAction =
      application?.stepUpDetails?.filter(
        stepUp => stepUp !== StepUpActionType.BO_VERIFICATION
      ) || [];

    const stepUpAction: StepUpActionType | undefined = stepUpsWithAction[0];

    const channelRequested =
      application.apiVersion === ApiVersion.V1 && channel.requested;

    if (
      environment.onAddStorePagesEnabled &&
      application.intent === ApplicationIntent.ADD_STORE
    ) {
      const page = isApproved ? 'store-complete' : 'store-success';
      yield call(redirectTo, `${referralUrlIdPath}/${application.id}/${page}`);

      return;
    }

    if (!stepUpAction && bank.submitted && !channelRequested) {
      yield put(applicationActions.redirectApplicationRequest(true));
      yield put(applicationActions.redirectApplicationSuccess());

      return;
    }

    if (
      !stepUpAction &&
      application.decision === DecisionType.PENDING &&
      application.addProductIntent
    ) {
      const redirectLink = `${referralUrlIdPath}/${application.id}/${isUxCore ? 'applicant' : 'update'}`;
      yield call(redirectTo, redirectLink);
      return;
    }

    if (
      !stepUpAction &&
      application.decision === DecisionType.PENDING &&
      isGoDaddyPortal
    ) {
      const isEhopper = environment.eHopperFlowEnabled;
      const redirectLink = `${referralUrlIdPath}/${application.id}/${
        isEhopper ? 'ehopper-order' : 'verifying'
      }`;
      yield call(redirectTo, redirectLink);
      return;
    }

    if (
      !stepUpAction &&
      application.decision === DecisionType.PENDING &&
      !isGoDaddyPortal
    ) {
      return;
    }

    if (!stepUpAction && application.id) {
      if (
        application.boStatus == BeneficialOwnersStatus.INFO_PROVIDED ||
        application.boStatus == BeneficialOwnersStatus.WAITING_FOR_INFO
      ) {
        let redirectLink = '';

        if (isGoDaddyPortal) {
          if (isApproved && !isUxCore) {
            redirectLink = `${referralUrlIdPath}/${application.id}/owners/bank`;
          } else {
            redirectLink = `${referralUrlIdPath}/${application.id}/owners`;
          }
        } else {
          if (isApproved) {
            redirectLink = `${referralUrlIdPath}/${application.id}/${
              bank.submitted ? 'owners/further-steps' : 'owners'
            }`;
          } else {
            redirectLink = `${referralUrlIdPath}/${application.id}/owners`;
          }
        }

        yield call(redirectTo, redirectLink);
        return;
      }

      let redirectLink = `${referralUrlIdPath}/${application.id}/success`;

      if (isApproved && channelRequested) {
        redirectLink = `${referralUrlIdPath}/${application.id}/add-channel`;
      } else if (isApproved) {
        const redirectOnBank =
          application.originalApplicationLevel === ApplicationLevel.BASIC &&
          application.applicationLevel === ApplicationLevel.FULL;

        const page = redirectOnBank ? 'bank' : 'complete';
        redirectLink = `${referralUrlIdPath}/${application.id}/${page}`;
      }

      yield call(redirectTo, redirectLink);
      yield put(applicationActions.redirectApplicationSuccess());
      return;
    }

    switch (stepUpAction) {
      case StepUpActionType.BO_INFO:
        yield call(redirectTo, `${referralUrlIdPath}/${application.id}/owners`);
        break;
      case StepUpActionType.BUSINESS_INFO:
        yield call(
          redirectTo,
          `${referralUrlIdPath}/${application.id}/business-info`
        );
        break;
      case StepUpActionType.OTP_SMS:
      case StepUpActionType.PHONE_AUTH:
        yield call(
          redirectTo,
          `${referralUrlIdPath}/${application.id}/${
            !environment.isPhoneAuthEnabled || company.currency === Currency.CAD
              ? 'otp'
              : 'mobile-verification' + location.search
          }`
        );
        break;
      case StepUpActionType.GOV_ID_SELFIE:
        yield call(
          redirectTo,
          `${referralUrlIdPath}/${application.id}/identification`
        );
        break;
      case StepUpActionType.EIN_DOC:
        yield call(
          redirectTo,
          `${referralUrlIdPath}/${application.id}/verification`
        );
        break;
      case StepUpActionType.FULL_SSN:
        yield call(redirectTo, `${referralUrlIdPath}/${application.id}/ssn`);
        break;
      case StepUpActionType.FULL_ONBOARDING:
        yield put({
          type: 'NOT_COMPLETE_APPLICATION'
        });
        yield call(
          redirectTo,
          `${referralUrlIdPath}/${application.id}/${isUxCore ? 'applicant' : 'update'}${
            action?.payload?.query || ''
          }`
        );
        break;
      default:
        throw new Error(`Current step up "${stepUpAction}" does not support`);
    }
  } catch (error) {
    yield call(analyticsHelper.trackError, error.message);
    yield call(toast.error, error.message);
  }
}

export function* updateApplicationStepUp(action?: AnyAction) {
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company: CompanyReducer = yield select(getCompany);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  if (!application.id) {
    yield call(
      analyticsHelper.trackError,
      `Application update Error during getting applicationId in step-up: ${action?.payload?.type}`
    );

    throw new Error('Application fetch Error, please reload the page.');
  }

  const useNewApi = application.apiVersion === ApiVersion.V1;

  const updateApplicationWithStepUpInformationApi = useNewApi
    ? apiV1.updateApplicationWithStepUpInformation
    : apiV2StepUps.updateApplicationWithStepUpInformation;

  try {
    const updateResponse: ApplicationUpdateResponseInterface = yield call(
      updateApplicationWithStepUpInformationApi,
      application.id,
      action?.payload?.type,
      action?.payload?.data
    );

    if (!updateResponse.applicationId) {
      yield call(
        analyticsHelper.trackStepUpError,
        application.id,
        action?.payload?.type,
        environment.isPhoneAuthEnabled
      );

      throw new Error('Application update Error, please reload the page.');
    }

    yield call(
      analyticsHelper.trackStepUpSuccess,
      application,
      action?.payload?.type || '',
      environment.isPhoneAuthEnabled
    );

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    yield put(applicationActions.updateApplicationSuccess());

    switch (action?.payload?.type) {
      case StepUpActionType.BO_INFO &&
        action?.payload?.data?.boStatus ===
          BeneficialOwnersStatus.INFO_PROVIDED:
        return redirectTo(
          `${referralUrlIdPath}/${application.id}/owners/verifying`
        );

      default:
        redirectTo(
          `${referralUrlIdPath}/${application.id}/verifying?step-up=${action?.payload?.type}`
        );
    }
  } catch (error) {
    yield call(
      analyticsHelper.trackStepUpError,
      application.id,
      action?.payload?.type,
      environment.isPhoneAuthEnabled
    );

    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
      }
      yield put(
        applicationActions.updateApplicationFailure(
          error?.response?.data?.errors.join(',')
        )
      );
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);
      yield put(applicationActions.updateApplicationFailure(error.message));
    }

    switch (action?.payload?.type) {
      case StepUpActionType.OTP_SMS:
      case StepUpActionType.PHONE_AUTH:
        if (environment.isPhoneAuthEnabled) {
          break;
        }
        yield put(otpActions.createOTPCodeRequest());
        yield put(otpActions.saveOTPError(true));
        yield put(otpActions.changeOTPState(false));
        break;
      case StepUpActionType.GOV_ID_SELFIE:
      case StepUpActionType.BO_INFO:
      case StepUpActionType.BUSINESS_INFO:
        break;
      case StepUpActionType.FULL_SSN:
        break;
      default:
        throw new Error(
          `Current step up "${action?.payload?.type}" does not support`
        );
    }
  }
}

export function* submitApplication() {
  try {
    yield put({
      type: 'SUBMIT_APPLICATION_SUCCESS'
    });

    yield put({
      type: 'COMPLETE_APPLICATION'
    });
  } catch (error) {
    yield call(toast.error, error.message);
    yield put({
      error: error.message,
      type: 'SUBMIT_APPLICATION_FAILURE'
    });
  }
}

export function* createApplication(action: AnyAction) {
  const company = yield select(getCompany);
  const accounts: AccountsReducer = yield select(getAccounts);
  const applicant: ApplicantReducer = yield select(getApplicant);
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  const isGoDaddyPortal = environment.portal === PortalType.GODADDY;
  const isEhopperFlow = environment.eHopperFlowEnabled;
  const useNewApi = application.apiVersion === ApiVersion.V1;

  try {
    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    let updatedAccounts: Account[] = [];

    if (
      isGoDaddyPortal &&
      !accounts.list.length &&
      !action.payload.useBasicTemplate
    ) {
      updatedAccounts = yield call(apiV2Accounts.getAccounts);
    }

    // In case if we already have open BASIC account with FULL_ONBOARDING stepUp
    // we are going to use this account instead of creation new account
    const basicApplication = (updatedAccounts || []).find(
      account =>
        account.applicationLevel === ApplicationLevel.BASIC &&
        (account.stepUpDetails as unknown as string[]).includes(
          StepUpActionType.FULL_ONBOARDING
        )
    );

    let existingApplicationId =
      basicApplication?.applicationId || action.payload.applicationId || null;

    let countryCode = company?.address?.countryCode;

    const accountsList =
      (updatedAccounts?.length && updatedAccounts) ||
      (accounts?.list?.length && accounts.list) ||
      [];

    const account = accountsList.find(
      account => account.businessId === action.payload.accountId
    );

    if (!countryCode && account?.company?.address?.[0]?.countryCode) {
      countryCode = account?.company?.address?.[0]?.countryCode;
    }

    if (!countryCode) {
      countryCode =
        countryResolverHelper.resolveCountryByCurrency(
          environment.pricing.currency
        )?.countryCode || CountryType.US;
    }

    if (!applicant.name.firstName && account?.applicant?.firstName) {
      applicant.name.firstName = account?.applicant?.firstName;
    }

    if (!applicant.name.lastName && account?.applicant?.lastName) {
      applicant.name.lastName = account?.applicant?.lastName;
    }

    const request: CreateApplicationArgsInterface = {
      applicant,
      application: {
        agentId: application.agentId,
        godaddyServiceId:
          application.serviceId || environment.godaddy.serviceId,
        godaddyServiceType:
          application.serviceType || environment.godaddy.serviceType,
        godaddyVentureId:
          application.ventureId || environment.godaddy.ventureId,
        godaddyShopperId:
          application.shopperId || environment.godaddy.shopperId,
        godaddyCustomerId:
          application.customerId || environment.godaddy.customerId,
        context: application.context,
        boStatus: application.boStatus,
        masterMid: application.masterMid,
        mock: application.mock,
        organizationId: environment?.cobrand.organizationId || '',
        referralUrlId: environment.cobrand.referralUrlId,
        medium: application.medium,
        source: application.source,
        campaignName: application.campaignName
      },
      company: {
        ...company,
        address: {
          ...company.address,
          countryCode
        },
        site: company.site,
        segment: company.segment,
        mccDetails: {
          label: company.mccDetails?.label,
          type: company.mccDetails?.type
        }
      },
      featureFlags: {
        newApplicationFormEnabled: environment.newApplicationFormEnabled
      }
    };

    if (application.addProductIntent) {
      request.application.businessId = application.businessId;
      existingApplicationId = undefined;
    }

    const createApplicationApi = useNewApi
      ? apiV1.createApplicationThroughProxyMethod
      : apiV2.createApplication;

    const updateApplicationApi = useNewApi
      ? apiV1.updateApplication
      : apiV2.updateApplication;

    const applicationResponse: ApplicationResponseInterface =
      existingApplicationId
        ? yield call(updateApplicationApi, existingApplicationId, request)
        : yield call(
            createApplicationApi,
            request,
            action.payload.useBasicTemplate,
            action.payload.createStore,
            action.payload.accountId,
            action.payload.storeId
          );

    if (!applicationResponse) {
      yield put(applicationActions.saveApplicationResumeCheck());
      return;
    }

    if (applicationResponse.application) {
      yield put(
        bankActions.saveBankSubmitted(
          applicationResponse.application.hasFinancialInstrument
        )
      );

      yield put(
        applicationActions.saveApplicationId(
          applicationResponse.application.applicationId
        )
      );

      yield put(
        applicationActions.saveApplicationDecision(
          applicationResponse.application.decision
        )
      );

      yield put(
        applicationActions.saveApplicationStepUpDetails(
          applicationResponse.application.stepUpDetails
        )
      );

      yield put(
        applicationActions.saveApplicationLevel(
          applicationResponse.application.applicationLevel
        )
      );

      yield put(
        applicationActions.saveOriginalApplicationLevel(
          applicationResponse.application.originalApplicationLevel
        )
      );

      yield put(
        orderAction.saveOrderId(applicationResponse.application.orderId)
      );

      yield put(
        applicationActions.saveApplicationIntent(
          applicationResponse.application.originalIntent
        )
      );
    }

    if (applicationResponse.applicantId) {
      yield put(
        applicantActions.saveApplicantId(applicationResponse.applicantId)
      );
    }

    if (applicationResponse.application.decision !== DecisionType.PENDING) {
      yield put(applicationActions.completeApplication());
    }

    yield put(applicationActions.createApplicationSuccess());

    redirectTo(
      `${referralUrlIdPath}/${applicationResponse.application.applicationId}/${
        isGoDaddyPortal
          ? isEhopperFlow
            ? 'ehopper-order'
            : 'verifying'
          : 'order'
      }`
    );
  } catch (error) {
    if (useNewApi && error?.response?.data?.details?.length) {
      const details = error?.response?.data?.details
        .map(detail => detail.description)
        .filter(detail => !!detail);

      const messages = details?.length
        ? details
        : [error?.response?.data?.message];

      for (const message of messages) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
        yield put(applicationActions.createApplicationFailure(message));
      }
    } else if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
        yield put(applicationActions.createApplicationFailure(message));
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;

      yield call(analyticsHelper.trackError, message);
      toastHelpers.toast(message, isUxCore);
      yield put(applicationActions.createApplicationFailure(error.message));
    }

    // in case of basic applications we have to redirect user back to stores list
    if (action.payload.useBasicTemplate && action.payload.storeId) {
      yield call(redirectBack);
    }
  }

  yield put(applicationActions.saveApplicationResumeCheck());
}

export function* getApplicationById(action: AnyAction) {
  const actionKey =
    applicationCacheHelper.getApplicationByIdCacheKey(action) || '';

  try {
    applicationCacheHelper.setCacheForGetApplicationById(actionKey, action);

    const environment: EnvironmentReducer = yield select(getEnvironment);

    yield put(applicationActions.saveIsApplicationFetched(false));

    const applicationResponse: GetApplicationResponseInterface = yield call(
      apiV2.getApplicationById,
      {
        applicationId: action.payload.applicationId,
        basicInfo: action.payload.basicInfo,
        ownerId: action.payload.ownerId
      }
    );

    const resolvedAddress = applicationResponse.company?.address?.[0]?.line1
      ? applicationResponse.company?.address?.[0]
      : applicationResponse.applicant?.address?.[0];

    if (applicationResponse.applicant) {
      yield put(
        applicantActions.saveApplicantName({
          firstName: applicationResponse.applicant.firstName || '',
          lastName: applicationResponse.applicant.lastName || ''
        })
      );

      yield put(
        applicantActions.saveApplicantPhone(
          applicationResponse.applicant.phone?.[0]?.localPhoneNumber || ''
        )
      );

      const email = applicationResponse.applicant.email?.[0].emailAddress || '';
      yield put(applicantActions.saveApplicantEmail(email));

      if (applicationResponse.applicant?.address?.[0]) {
        yield put(
          applicantActions.saveApplicantAddress(
            applicationResponse.applicant?.address?.[0]
          )
        );
      }

      if (applicationResponse.applicant?.applicantId) {
        yield put(
          applicantActions.saveApplicantId(
            applicationResponse.applicant.applicantId
          )
        );
      }

      if (applicationResponse.applicant?.personaInquiryId) {
        yield put(
          applicantActions.saveApplicantPersonaInquiryId(
            applicationResponse.applicant?.personaInquiryId
          )
        );
      }
    }

    if (applicationResponse.owner) {
      const owner = applicationResponse.owner;

      yield put(ownersActions.saveOwnerId(owner.applicantId || ''));
      yield put(ownersActions.saveOwnerFirstName(owner.firstName || ''));
      yield put(ownersActions.saveOwnerLastName(owner.lastName || ''));

      yield put(
        ownersActions.saveOwnerPhoneNationalNumber(
          owner.phone?.[0]?.localPhoneNumber || ''
        )
      );

      yield put(
        ownersActions.saveOwnerEmailAddress(owner.email?.[0].emailAddress || '')
      );

      yield put(
        ownersActions.saveOwnerStepUpDetails(
          (applicationResponse.owner.riskStepUps || '')
            .split(',')
            .map(stepUp => StepUpOwnerActionType[stepUp])
        )
      );

      if (owner.personaInquiryId) {
        yield put(
          ownersActions.saveOwnerPersonaInquiryId(owner.personaInquiryId)
        );
      }
    }

    if (resolvedAddress) {
      yield put(orderAction.saveOrderAddress(resolvedAddress));
    }

    yield put(
      bankActions.saveBankSubmitted(
        !!applicationResponse.hasFinancialInstrument
      )
    );

    yield put(
      applicationActions.saveApplicationBoStatus(
        applicationResponse.boStatus as BeneficialOwnersStatus
      )
    );

    if (
      applicationResponse.originalIntent ===
        ApplicationIntent.CREATE_BUSINESS &&
      applicationResponse.riskDecision === DecisionType.PENDING &&
      applicationResponse.businessId &&
      !applicationResponse.riskStepUps
    ) {
      yield put(applicationActions.saveAddProductIntent());
    }

    yield put(
      applicationActions.saveApplicationBusinessId(
        applicationResponse.businessId
      )
    );

    yield put(
      applicationActions.saveApplicationStoreId(applicationResponse.storeId)
    );

    yield put(
      applicationActions.saveApplicationLevel(
        applicationResponse.applicationLevel
      )
    );

    yield put(
      applicationActions.saveApplicationApiVersion(
        applicationResponse.apiVersion
      )
    );

    yield put(applicationActions.saveApplicationMock(applicationResponse.mock));

    yield put(
      applicationActions.saveApplicationAgentId(applicationResponse.agentId)
    );

    yield put(
      applicationActions.saveApplicationFirstTxnDate(
        applicationResponse.firstTxnDate
      )
    );

    yield put(
      applicationActions.saveOriginalApplicationLevel(
        applicationResponse.originalApplicationLevel
      )
    );

    yield put(
      applicationActions.saveApplicationIntent(
        applicationResponse.originalIntent
      )
    );

    yield put(
      applicationActions.saveApplicationDecision(
        applicationResponse.riskDecision
      )
    );

    yield put(
      applicationActions.saveApplicationServiceId(
        applicationResponse.godaddyServiceId
      )
    );

    yield put(
      applicationActions.saveApplicationServiceType(
        applicationResponse.godaddyServiceType
      )
    );

    yield put(
      applicationActions.saveApplicationVentureId(
        applicationResponse.godaddyVentureId
      )
    );

    if (
      applicationResponse.organizationId &&
      applicationResponse.originalIntent === ApplicationIntent.CREATE_BUSINESS
    ) {
      yield put(
        applicationActions.saveApplicationOrganizationId(
          applicationResponse.organizationId
        )
      );
    }

    yield put(
      applicationActions.saveApplicationStepUpDetails(
        (applicationResponse.riskStepUps || '')
          .split(',')
          .filter(stepUp => stepUp.trim() !== '')
          .map(stepUp => StepUpActionType[stepUp])
      )
    );

    yield put(orderAction.saveOrderId(applicationResponse.orderId || ''));

    if (applicationResponse.company) {
      yield put(
        companyActions.saveCompanyName(applicationResponse.company?.name || '')
      );

      yield put(
        companyActions.saveCompanyDba(applicationResponse.company?.dba || '')
      );

      if (applicationResponse.company?.address?.[0]) {
        yield put(
          companyActions.saveCompanyAddress(
            applicationResponse.company?.address?.[0]
          )
        );
      }

      if (applicationResponse?.company?.currency) {
        yield put(
          companyActions.saveCompanyCurrency(
            applicationResponse?.company?.currency
          )
        );
      }

      if (applicationResponse.company.governmentIdentification?.[0].type) {
        yield put(
          companyActions.saveCompanyIdentification({
            type: applicationResponse.company.governmentIdentification?.[0]
              .type,
            value: '',
            sourceType: GovIdType.SSN4
          })
        );
        yield put({
          payload: applicationResponse.company.governmentIdentification?.[0],
          type: 'SAVE_COMPANY_GOVERNMENT_ID'
        });
      }

      if (applicationResponse.company?.personaInquiryId) {
        yield put(
          companyActions.saveCompanyPersonaInquiryId(
            applicationResponse.company?.personaInquiryId
          )
        );
      }
    }

    if (applicationResponse.parent) {
      yield put(
        applicationActions.saveParentApplication(applicationResponse.parent)
      );
    }

    if (
      applicationResponse.applicationLevel === ApplicationLevel.FULL &&
      applicationResponse.riskDecision !== DecisionType.PENDING
    ) {
      yield put(applicationActions.completeApplication());
    }

    yield put(applicationActions.saveIsApplicationFetched(true));

    yield put(
      applicationActions.saveApplicationId(applicationResponse.applicationId)
    );

    if (!action.payload.skipStepUpParsing) {
      if (applicationResponse.owner) {
        yield put(ownersActions.parseOwnerStepUp());
      } else {
        yield put(applicationActions.parseApplicationStepUp(action.payload));
      }
    }

    yield put(applicationActions.getApplicationByIdSuccess());

    applicationCacheHelper.deleteCacheGetApplicationById(actionKey);
  } catch (error) {
    const environment: EnvironmentReducer = yield select(getEnvironment);

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    applicationCacheHelper.deleteCacheGetApplicationById(actionKey);

    if (
      action.payload.ownerId &&
      error?.response?.status === StatusCodes.FORBIDDEN
    ) {
      yield put(applicationActions.getApplicationByIdFailure());
      yield call(
        redirectTo,
        `${referralUrlIdPath}/${action.payload.applicationId}/owners/${action.payload.ownerId}/applicant-error`
      );
      return;
    }

    if (error?.response?.status === StatusCodes.FORBIDDEN) {
      yield put(applicationActions.getApplicationByIdSuccess());
      yield call(redirectTo, `${referralUrlIdPath}/?completed=true`);
      return;
    }
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        yield call(toast.error, message);
        yield put(applicationActions.getApplicationByIdFailure(message));
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;

      yield call(analyticsHelper.trackError, message);
      yield call(toast.error, message);
      yield put(applicationActions.getApplicationByIdFailure(message));
    }

    yield put(applicationActions.saveIsApplicationFetched(false));
  }
}

export function* setPermissions(action: AnyAction) {
  try {
    const type: GodaddySsoPermissions = action.payload?.type;
    const applicationId: string = yield select(getApplicationId);

    yield call(apiV2Permissions.setSsoPermissions, applicationId, type);

    yield put({
      type: 'SET_SSO_PERMISSIONS_SUCCESS'
    });
  } catch (error) {
    yield call(toast.error, error.message);
    yield put({
      error: error.message,
      type: 'SET_SSO_PERMISSIONS_ERROR'
    });
  }
}

export function* addGovIdForApplication(action: AnyAction) {
  const company = yield select(getCompany);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    const applicationId: string = yield select(getApplicationId);

    yield call(
      apiV2GovernmentIdentifications.addGovernmentIdentifications,
      applicationId,
      action.payload.taxId,
      action.payload.taxType
    );

    yield put({
      type: 'ADD_GOV_ID_SUCCESS'
    });
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error.message;

    toastHelpers.toast(message, isUxCore);
    yield put({
      error: message,
      type: 'ADD_GOV_ID_FAILURE'
    });
  }
}

export function* updateApplicationPersonaStepUpWithLongPolling(
  action?: AnyAction
) {
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company = yield select(getCompany);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  if (!application.id) {
    yield call(
      analyticsHelper.trackError,
      `Application update Error during getting applicationId in step-up: ${action?.payload?.type}`
    );

    throw new Error('Application fetch Error, please reload the page.');
  }

  try {
    while (true) {
      const updateResponse: ApplicationUpdateResponseInterface = yield call(
        apiV2StepUps.updateApplicationWithStepUpInformation,
        application.id,
        action?.payload?.type,
        action?.payload?.data
      );

      if (
        updateResponse.inquirySessionStatus === InquirySessionStatus.RECEIVED ||
        updateResponse.inquirySessionStatus === InquirySessionStatus.TIMEOUT
      ) {
        break;
      }

      if (!updateResponse.applicationId) {
        yield call(
          analyticsHelper.trackStepUpError,
          application.id,
          action?.payload?.type,
          environment.isPhoneAuthEnabled
        );

        throw new Error('Application update Error, please reload the page.');
      }

      yield delay(1000);
    }

    yield call(
      analyticsHelper.trackStepUpSuccess,
      application,
      action?.payload?.type || '',
      environment.isPhoneAuthEnabled
    );

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    yield put(
      applicationActions.updateApplicationRequestWithPersonaLongPollingSuccess()
    );

    redirectTo(
      `${referralUrlIdPath}/${application.id}/verifying?step-up=${action?.payload?.type}`
    );
  } catch (error) {
    yield call(
      analyticsHelper.trackStepUpError,
      application.id,
      action?.payload?.type,
      environment.isPhoneAuthEnabled
    );

    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
      }

      yield put(
        applicationActions.updateApplicationRequestWithPersonaLongPollingFailure(
          error?.response?.data?.errors.join(',')
        )
      );
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);

      yield put(
        applicationActions.updateApplicationRequestWithPersonaLongPollingFailure(
          error.message
        )
      );
    }
  }
}

export function* getInquirySessionTokenById(action?: AnyAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const application: ApplicationReducer = yield select(getApplication);

  const referralUrlIdPath = environment.cobrand.referralUrlId
    ? `/r/${environment.cobrand.referralUrlId}`
    : '';

  try {
    const { isEinStepUp, inquiryId } = action?.payload || {};

    const type = isEinStepUp
      ? StepUpType.verification
      : StepUpType.identification;

    const sessionResponse = yield call(
      apiV2StepUps.getPersonaInquirySessionToken,
      inquiryId,
      application.id as string,
      type
    );

    const { sessionToken, isInquiryCompleted } = sessionResponse;

    if (isEinStepUp && !!sessionToken && !isInquiryCompleted) {
      yield put(companyActions.saveCompanyPersonaSessionToken(sessionToken));
    }

    if (!isEinStepUp && !!sessionToken && !isInquiryCompleted) {
      yield put(
        applicantActions.saveApplicantPersonaSessionToken(sessionToken)
      );
    }

    if (isEinStepUp && !sessionToken && !isInquiryCompleted) {
      yield put(companyActions.saveCompanyPersonaInquiryId(null));
    }

    if (!isEinStepUp && !sessionToken && !isInquiryCompleted) {
      yield put(applicantActions.saveApplicantPersonaInquiryId(null));
    }

    yield put(applicationActions.getInquirySessionTokenByIdSuccess());

    if (isInquiryCompleted) {
      yield put(
        applicationActions.updateApplicationRequestWithPersonaLongPolling(
          isEinStepUp
            ? StepUpActionType.EIN_DOC
            : StepUpActionType.GOV_ID_SELFIE,
          { inquiryId }
        )
      );
    }
  } catch (error) {
    yield put(applicationActions.getInquirySessionTokenByIdFailure());
    yield call(redirectTo, `${referralUrlIdPath}/${application.id}/error`);
  }
}
