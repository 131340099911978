import axios from 'axios';
import { stringify } from 'qs';

import { Payload, Plan } from './types';

export const getConfigs = (
  referralUrlId?: string,
  params?: { [key: string]: string }
): Promise<Payload> => {
  const query = referralUrlId ? `/?referralUrlId=${referralUrlId}` : '';
  return axios
    .request({
      method: 'get',
      url: `/api/v2/configs${query}`,
      params: params || {}
    })
    .then(response => response.data)
    .catch(error => {
      if (
        error.response &&
        error.response.status === 417 &&
        error.response.data?.location
      ) {
        const pathname = window.location.pathname;
        const search = window.location.search;

        window.location.href = `${error.response.data.location}${pathname}${search}`;
      } else {
        throw error;
      }
    });
};

export const getSaasPlans = (businessId?: string): Promise<Plan[] | never> => {
  return axios
    .request({
      method: 'get',
      url: `/api/v2/configs/plans?${stringify({ businessId })}`
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const getWebsite = async (websiteId: string) => {
  const response = await axios.get(`/api/v2/configs/websites/${websiteId}`);
  return response.data;
};
