import validator from 'validator';

import { convertDateFormat } from 'helpers/application/date-of-birth-format';

import { ValidatorType } from '../types';

export const maxAge = 100;
export const minAge = 18;
export const fullDateOfBirthLength = 10;

const parseDob = (value: string) => {
  const separator = value.includes('-') ? '-' : '/';
  const [_year, _month, _day] = value.split(separator);
  const now = new Date();
  const year = parseInt(_year, 10);
  const currentYear = now.getFullYear();
  const month = parseInt(_month[0] === '0' ? _month[1] : _month, 10);
  const day = parseInt(_day[0] === '0' ? _day[1] : _day, 10);

  return {
    day,
    year,
    month,
    currentYear
  };
};

export const isValidDob = (
  value: ValidatorType,
  format = 'MM/DD/YYYY'
): boolean => {
  if (!validator.isDate(value || '', { format, strictMode: true })) {
    return false;
  }

  const { day, year, month, currentYear } = parseDob(
    convertDateFormat(value || '', format, 'YYYY/MM/DD')
  );

  return !(
    year >= currentYear ||
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31
  );
};

export const isValidDobMin = (
  value: ValidatorType,
  format = 'MM/DD/YYYY'
): boolean => {
  if (!validator.isDate(value || '', { format, strictMode: true })) {
    return false;
  }

  const { year, currentYear } = parseDob(
    convertDateFormat(value || '', format, 'YYYY/MM/DD')
  );

  return !(currentYear - year < minAge);
};

export const isValidDobMax = (
  value: ValidatorType,
  format = 'MM/DD/YYYY'
): boolean => {
  if (!validator.isDate(value || '', { format, strictMode: true })) {
    return false;
  }

  const { year, currentYear } = parseDob(
    convertDateFormat(value || '', format, 'YYYY/MM/DD')
  );

  return !(currentYear - year > maxAge);
};

export const isValidDobCa = (
  value: ValidatorType,
  format = 'YYYY/MM/DD'
): boolean => {
  return isValidDob(value, format);
};

export const isValidDobMinCa = (
  value: ValidatorType,
  format = 'YYYY/MM/DD'
): boolean => {
  return isValidDobMin(value, format);
};

export const isValidDobMaxCa = (
  value: ValidatorType,
  format = 'YYYY/MM/DD'
): boolean => {
  return isValidDobMax(value, format);
};
