import { combineReducers, Reducer } from 'redux';

import { loadingBarReducer } from 'react-redux-loading-bar';

import ApplicantReducer from './applicant';
import AccountsReducer from './account';
import ApplicationReducer from './application';
import BankReducer from './bank';
import CompanyReducer from './company';
import EnvironmentReducer from './environment';
import IndustriesReducer from './industries';
import CategoriesReducer from './categories';
import OrderReducer from './order';
import OTPReducer from './otp';
import OwnersReducer from './owners';
import ChannelReducer from './channel';
import PhoneAuthReducer from './phone-auth';
import CashAdvanceOffers from './case-advance-offers';

import { Reducers, State } from 'reducers/types';

const reducers: Reducers = {
  accounts: AccountsReducer,
  applicant: ApplicantReducer,
  application: ApplicationReducer,
  bank: BankReducer,
  company: CompanyReducer,
  environment: EnvironmentReducer,
  industries: IndustriesReducer,
  categories: CategoriesReducer,
  order: OrderReducer,
  otp: OTPReducer,
  owners: OwnersReducer,
  channel: ChannelReducer,
  phoneAuth: PhoneAuthReducer,
  cashAdvanceOffers: CashAdvanceOffers
};

// @ts-ignore
const rootReducer: Reducer<State> = combineReducers<State>({
  ...reducers,
  loadingBar: loadingBarReducer
});

export default rootReducer;
