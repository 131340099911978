import axios from 'axios';

import { Name } from 'reducers/applicant/types';
import { Account, PlaidAccount } from 'reducers/bank/types';

import { Payload } from './types';

export const savePlaidBankAccount = (
  account: PlaidAccount,
  applicationId: string
): Promise<unknown> => {
  return axios
    .request({
      data: {
        primary: true,
        requestMetadata: account,
        source: 'PLAID',
        status: 'P',
        value: account.public_token
      },
      method: 'post',
      url: `/api/v2/applications/${applicationId}/financial-instruments/plaid`
    })
    .then(response => response.data);
};

export const saveManualBankAccount = (
  account: Account,
  applicantName: Name,
  applicationId: string
): Promise<unknown> => {
  return axios
    .request({
      data: {
        accountNumber: account.accountNumber,
        accountType: account.accountType || '',
        fullName: account.accountName || '',
        bankName: 'Default',
        primary: true,
        routingNumber: account.routingNumber
      },
      method: 'post',
      url: `/api/v2/applications/${applicationId}/financial-instruments/manual`
    })
    .then(response => response.data);
};

export const saveCreditCard = (
  token: string,
  applicationId: string
): Promise<Payload> => {
  return axios
    .request({
      data: {
        primary: false,
        token
      },
      method: 'post',
      url: `/api/v2/applications/${applicationId}/financial-instruments/credit-cards`
    })
    .then(response => response.data);
};
