import { AnyAction } from 'redux';

import { OrderType } from 'enums/order/type';
import { PaymentMethodType } from 'enums/order/payment';
import { BundleType } from 'enums/ehopper-order/bundles';

import { State } from './types';

const INITIAL_STATE: State = {
  paymentMethod: PaymentMethodType.BANK,
  quantity: {
    NO_DEVICE: 0,
    N910: 0,
    PoyntC: 0,
    SunmiT2S2: 0,
    SunmiT2S1: 0,
    license: 0,
    total: 1
  },
  bundleQuantity: {
    POYNTC: 0,
    POYNTC_SUNMIT2S2: 0,
    POYNTC_SUNMIT2S1: 0,
    N910_SUNMIT2S2: 0,
    N910_SUNMIT2S1: 0,
    LICENSE: 0,
    total: 0
  },
  displayBundles: {
    [BundleType.POYNTC]: true,
    [BundleType.POYNTC_SUNMIT2S2]: false,
    [BundleType.POYNTC_SUNMIT2S1]: true,
    [BundleType.N910_SUNMIT2S2]: false,
    [BundleType.N910_SUNMIT2S1]: true
  },
  type: OrderType.RENT
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_ORDER_ADDRESS':
      return {
        ...state,
        address: action.payload
      };
    case 'SAVE_ORDER_QUANTITY':
      return {
        ...state,
        quantity: {
          NO_DEVICE: action.payload.NO_DEVICE,
          N910: action.payload.N910,
          PoyntC: action.payload.PoyntC,
          SunmiT2S1: action.payload.SunmiT2S1,
          SunmiT2S2: action.payload.SunmiT2S2,
          license: action.payload.license,
          total:
            (action.payload.N910 || 0) +
            (action.payload.PoyntC || 0) +
            (action.payload.SunmiT2S1 || 0) +
            (action.payload.SunmiT2S2 || 0) +
            (action.payload.license || 0)
        }
      };
    case 'SAVE_ORDER_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.payload
      };
    case 'SAVE_ORDER_TYPE':
      return {
        ...state,
        type: action.payload
      };
    case 'SAVE_ORDER_ID':
      return {
        ...state,
        id: action.payload
      };
    case 'SAVE_ORDER_SUBMITTED':
      return {
        ...state,
        submitted: action.payload
      };
    case 'SAVE_ORDER_IN_PROGRESS':
      return {
        ...state,
        inProgress: action.payload
      };
    case 'SAVE_BUNDLE_QUANTITY':
      return {
        ...state,
        bundleQuantity: {
          POYNTC: action.payload.POYNTC,
          POYNTC_SUNMIT2S2: action.payload.POYNTC_SUNMIT2S2,
          POYNTC_SUNMIT2S1: action.payload.POYNTC_SUNMIT2S1,
          N910_SUNMIT2S2: action.payload.N910_SUNMIT2S2,
          N910_SUNMIT2S1: action.payload.N910_SUNMIT2S1,
          LICENSE: action.payload.LICENSE,
          total:
            (action.payload.POYNTC || 0) +
            (action.payload.POYNTC_SUNMIT2S2 || 0) +
            (action.payload.POYNTC_SUNMIT2S1 || 0) +
            (action.payload.N910_SUNMIT2S2 || 0) +
            (action.payload.N910_SUNMIT2S1 || 0)
        }
      };
    case 'SAVE_BUNDLE_DISPLAY':
      return {
        ...state,
        displayBundles: {
          [BundleType.POYNTC]: action.payload[BundleType.POYNTC],
          [BundleType.POYNTC_SUNMIT2S2]:
            action.payload[BundleType.POYNTC_SUNMIT2S2],
          [BundleType.POYNTC_SUNMIT2S1]:
            action.payload[BundleType.POYNTC_SUNMIT2S1],
          [BundleType.N910_SUNMIT2S2]:
            action.payload[BundleType.N910_SUNMIT2S2],
          [BundleType.N910_SUNMIT2S1]: action.payload[BundleType.N910_SUNMIT2S1]
        }
      };
    default:
      return state;
  }
}
