import axios from 'axios';

import { Payload } from './types';

export const getCategories = (): Promise<Payload[]> => {
  return axios
    .request({
      method: 'get',
      url: `/api/v2/categories`
    })
    .then(response => response.data);
};
