import { Name } from 'reducers/applicant/types';

import { Currency } from 'enums/business/currency';

import { Account } from 'rest/v2/accounts/interfaces';

import { nameValidator } from 'helpers/validators/name';

export const parseName = (
  name: string,
  currency?: Currency,
  account?: Account
): Name | undefined => {
  const fullName = name.split(' ');

  const firstName = fullName[0] || account?.applicant?.firstName || '';
  const lastName = fullName[1] || account?.applicant?.lastName || '';

  if (
    !nameValidator(firstName, currency) ||
    !nameValidator(lastName, currency)
  ) {
    return;
  }

  return {
    firstName,
    lastName
  };
};
