import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  list: []
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_BUSINESS_CATEGORIES':
      return {
        ...state,
        list: action.payload
      };
    case 'SAVE_BUSINESS_CATEGORIES_LOADED':
      return {
        ...state,
        loaded: action.payload
      };
    default:
      return state;
  }
}
