import 'vite/modulepreload-polyfill';

import { createRoot } from 'react-dom/client';

import { isSsoRedirection } from './app/helpers/sso';

import './i18n';
import App from './app';

if (isSsoRedirection()) {
  createRoot(document.getElementById('root')!).render(<App />);
}
