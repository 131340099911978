import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import * as OTP from './otp';
import * as Bank from './financial-instruments';
import * as Analytics from './analytics';
import * as Application from './application';
import * as Environment from './environment';
import * as Payment from './payment';
import * as Order from './order';
import * as Account from './account';
import * as Categories from './categories';
import * as Channel from './channel';
import * as PhoneAuth from './phone-auth';
import * as Owners from './owners';
import * as CashAdvanceOffers from './cash-advance-offers';

const godaddySagas = [
  takeLatest('ONBOARD_APPLICATION_REQUEST', Application.onboardApplication),
  takeLatest('FETCH_ACCOUNTS_REQUEST', Account.fetchAccounts),
  takeEvery('FETCH_ACCOUNT_INFO_REQUEST', Account.fetchAccountInfo),
  takeLatest('FETCH_STORES_FOR_ACCOUNT_REQUEST', Account.fetchStoresForAccount),
  takeLatest('GET_CATEGORIES_REQUEST', Categories.getBusinessCategories),
  takeLatest('REDIRECT_APPLICATION_REQUEST', Application.redirectApplication),
  takeLatest('PARSE_APPLICATION_STEP_UP', Application.parseApplicationStepUps),
  takeLatest('CREATE_PLAID_ACCOUNT_V2_REQUEST', Bank.createPlaidAccount),
  takeLatest('CREATE_BANK_ACCOUNT_V2_REQUEST', Bank.createBankAccount),
  takeLatest('CREATE_OTP_V2_REQUEST', OTP.createOTP),
  takeLatest('VERIFY_OTP_V2_REQUEST', OTP.verifyOTP),
  takeLatest('GET_ENVIRONMENT_REQUEST', Environment.getEnvironment),
  takeLatest('GET_WEBSITE_REQUEST', Environment.getWebsite),
  takeLatest('GET_SAAS_PLANS', Environment.getSaasPlans),
  takeLatest('CREATE_PAYMENT_REQUEST', Payment.createPayment),
  takeLatest('CREATE_ORDER_REQUEST', Order.createOrder),
  takeLatest('GET_ORDER_TYPE_REQUEST', Order.getOrderTypeById),
  takeLatest('CREATE_CREDIT_CARD_REQUEST', Bank.createCreditCard),
  takeLatest('SUBMIT_APPLICATION_REQUEST', Application.submitApplication),
  takeLatest(
    'UPDATE_APPLICATION_STEP_UP_V2_REQUEST',
    Application.updateApplicationStepUp
  ),
  takeLatest('CREATE_APPLICATION_V2_REQUEST', Application.createApplication),
  takeLatest('GET_APPLICATION_BY_ID_REQUEST', Application.getApplicationById),
  takeLatest('TRACK_ANALYTICS', Analytics.trackAnalytics),
  takeLatest('SET_SSO_PERMISSIONS', Application.setPermissions),
  takeLatest('MOBILE_VERIFICATION_REQUEST', PhoneAuth.getMobileVerification),
  takeLatest('PHONE_AUTH_REQUEST', PhoneAuth.createPhoneAuth),
  takeLatest('CHECK_PHONE_CODE_REQUEST', PhoneAuth.checkPhoneCode),
  takeLatest('VERIFY_SMS_LINK_REQUEST', PhoneAuth.verifySmsLink),
  takeLatest('ADD_GOV_ID_REQUEST', Application.addGovIdForApplication),
  takeLatest('CREATE_CHANNEL_REQUEST', Channel.createCustomChannel),
  takeLatest('PROCESS_BO_INFO', Owners.processBOInfoStepUp),
  takeLatest('CREATE_OWNERS_REQUEST', Owners.createOwners),
  takeLatest('PATCH_OWNER_REQUEST', Owners.patchOwner),
  takeLatest('ONBOARD_OWNER_REQUEST', Owners.onboardOwner),
  takeLatest('PARSE_OWNER_STEP_UP', Owners.parseOwnerStepUps),
  takeLatest('PROCESS_OWNER_IDENTIFICATION', Owners.processOwnerIdentification),
  takeLatest('UPDATE_OWNER_STEP_UP_REQUEST', Owners.updateOwnerStepUp),
  takeLatest(
    'UPDATE_APPLICATION_PERSONA_STEPUP_WITH_LONGPOLLING_REQUEST',
    Application.updateApplicationPersonaStepUpWithLongPolling
  ),
  takeLatest(
    'UPDATE_OWNER_PERSONA_STEPUP_WITH_LONGPOLLING_REQUEST',
    Owners.updateOwnerPersonaStepUpWithLongPolling
  ),
  takeLatest(
    'GET_INQUIRY_SESSION_TOKEN_BY_ID_REQUEST',
    Application.getInquirySessionTokenById
  ),
  takeLatest(
    'GET_ONWER_INQUIRY_SESSION_TOKEN_BY_ID_REQUEST',
    Owners.getOwnerInquirySessionTokenById
  ),
  takeLatest(
    'GET_CASH_ADVANCE_OFFERS_REQUEST',
    CashAdvanceOffers.getCashAdvanceOffers
  )
];

export default function* rootSaga() {
  yield all([...godaddySagas]);
}
