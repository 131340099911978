export const parseReferralCode = (url: string): string | undefined => {
  if (url.startsWith('/r/')) {
    const params = url.replace('/r/', '').split('/');
    return params && params.length ? params[0] : undefined;
  }
};

export const getReferralUrl = (referralUrlId?: string): string => {
  return referralUrlId ? `/r/${referralUrlId}` : ``;
};

export const getUrlWithQuery = (
  redirectUrl: string,
  locationSearch: string,
  locationPathName: string
): string => {
  const queryParams = locationSearch;
  const referralUrlId = parseReferralCode(locationPathName);
  const url = getReferralUrl(referralUrlId);

  return `${url}${redirectUrl}${queryParams}`;
};
