import { AnyAction } from 'redux';

import { ApiVersion } from 'enums/application/version';

import { State } from './types';

const INITIAL_STATE: State = {
  completed: false,
  standalonePreviewShowed: false,
  apiVersion: ApiVersion.V2
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_APPLICATION_DEFAULT_STATE':
      return {
        ...state,
        ...action.payload
      };
    case 'SAVE_APPLICATION_ID':
      return {
        ...state,
        id: action.payload
      };
    case 'SAVE_APPLICATION_TOKEN':
      return {
        ...state,
        token: action.payload
      };
    case 'COMPLETE_APPLICATION':
      return {
        ...state,
        completed: true
      };
    case 'NOT_COMPLETE_APPLICATION':
      return {
        ...state,
        completed: false
      };
    case 'SAVE_APPLICATION_SOURCE':
      return {
        ...state,
        source: action.payload
      };
    case 'SAVE_APPLICATION_MASTER_MID':
      return {
        ...state,
        masterMid: action.payload
      };
    case 'SAVE_APPLICATION_MOCK':
      return {
        ...state,
        mock: action.payload
      };
    case 'SAVE_APPLICATION_AGENT_ID':
      return {
        ...state,
        agentId: action.payload
      };
    case 'SAVE_APPLICATION_FIRST_TXN_DATE':
      return {
        ...state,
        firstTxnDate: action.payload
      };
    case 'SAVE_APPLICATION_CREATE_BUSINESS_MODE':
      return {
        ...state,
        createBusiness: action.payload
      };
    case 'SAVE_APPLICATION_API_VERSION':
      return {
        ...state,
        apiVersion: action.payload || ApiVersion.V2
      };
    case 'SAVE_APPLICATION_CREATE_STORE_MODE':
      return {
        ...state,
        createStore: action.payload
      };
    case 'SAVE_APPLICATION_STORE_SWITCHER_MODE':
      return {
        ...state,
        storeSwitcher: action.payload
      };
    case 'SAVE_APPLICATION_KYC_KYB_REQUIRED_MODE':
      return {
        ...state,
        kycKybRequired: action.payload
      };
    case 'SAVE_APPLICATION_MEDIUM':
      return {
        ...state,
        medium: action.payload
      };
    case 'SAVE_APPLICATION_CONTEXT':
      return {
        ...state,
        context: action.payload
      };
    case 'SAVE_APPLICATION_CAMPAIGN_NAME':
      return {
        ...state,
        campaignName: action.payload
      };
    case 'SAVE_APPLICATION_DECISION':
      return {
        ...state,
        decision: action.payload
      };
    case 'SAVE_APPLICATION_INTENT':
      return {
        ...state,
        intent: action.payload
      };
    case 'SAVE_APPLICATION_STATUS':
      return {
        ...state,
        applicationStatus: action.payload
      };
    case 'SAVE_APPLICATION_ENABLE_DEPOSITS':
      return {
        ...state,
        enableDeposits: action.payload
      };
    case 'SAVE_APPLICATION_STEP_UP_DETAILS':
      return {
        ...state,
        stepUpDetails: action.payload
      };
    case 'SAVE_APPLICATION_SERVICE_TYPE':
      return {
        ...state,
        serviceType: action.payload
      };
    case 'SAVE_APPLICATION_VENTURE_ID':
      return {
        ...state,
        ventureId: action.payload
      };
    case 'SAVE_APPLICATION_BUSINESS_ID':
      return {
        ...state,
        businessId: action.payload
      };
    case 'SAVE_APPLICATION_STORE_ID':
      return {
        ...state,
        storeId: action.payload
      };
    case 'SAVE_APPLICATION_SERVICE_ID':
      return {
        ...state,
        serviceId: action.payload
      };
    case 'SAVE_APPLICATION_CUSTOMER_ID':
      return {
        ...state,
        customerId: action.payload
      };
    case 'SAVE_APPLICATION_RESUMED':
      return {
        ...state,
        resumed: true
      };
    case 'SAVE_APPLICATION_RESUME_CHECK':
      return {
        ...state,
        resumeCheck: action.payload
      };
    case 'SAVE_APPLICATION_PREFILL_STATUS':
      return {
        ...state,
        prefillStatus: true
      };
    case 'HIDE_STANDALONE_PREVIEW':
      return {
        ...state,
        standalonePreviewShowed: true
      };
    case 'IS_APPLICATION_FETCHED':
      return {
        ...state,
        isApplicationFetched: action.payload
      };
    case 'SAVE_APPLICATION_LEVEL':
      return {
        ...state,
        applicationLevel: action.payload
      };
    case 'SAVE_ORIGINAL_APPLICATION_LEVEL':
      return {
        ...state,
        originalApplicationLevel: action.payload
      };
    case 'SAVE_IS_APPLICATION_POWER_SELLER':
      return {
        ...state,
        isPowerSeller: action.payload
      };
    case 'SAVE_PARENT_APPLICATION':
      return {
        ...state,
        parentApplication: action.payload
      };
    case 'SAVE_APPLICATION_ORGANIZATION_ID':
      return {
        ...state,
        organizationId: action.payload
      };
    case 'SAVE_APPLICATION_BO_STATUS':
      return {
        ...state,
        boStatus: action.payload
      };

    case 'ADD_GOV_ID_SUCCESS':
      return {
        ...state,
        taxIdSubmitted: true
      };
    case 'SAVE_ADD_PRODUCT_INTENT':
      return {
        ...state,
        addProductIntent: true
      };
    default:
      return state;
  }
}
