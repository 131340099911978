import { FC } from 'react';

import { Modal } from 'components/elements';
import { Button } from 'components/form';

import { useStore } from 'helpers/store';
import { useTranslation } from 'helpers/translation';

import Styles from './WamErrorModal.module.scss';

const WamErrorModal: FC = () => {
  const { t } = useTranslation('portal.update.wamError');

  const redirectUrl = useStore<string>(
    state => state.environment.redirectUrl || ''
  );

  return (
    <Modal className={Styles.wamErrorModal}>
      <h3>{t('title')}</h3>
      <p>{t('description')}</p>
      {redirectUrl ? (
        <Button
          className={Styles.button}
          onClick={() => (window.location.href = redirectUrl)}>
          {t('button')}
        </Button>
      ) : null}
    </Modal>
  );
};

export default WamErrorModal;
