import React, { FC, lazy, Suspense } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Props } from './Loader.types';

import Styles from './Loader.module.scss';

const DotsLoader = lazy(() => import('./assets/icons/dots/Dots'));

const Loader: FC<Props> = (props: Props) => {
  return props.lightLoader ? (
    <div className={Styles.lightLoader}>
      <div className={Styles.content} />
    </div>
  ) : props.isGodaddy ? (
    <div className={Styles.godaddyLoaderContainer}>
      <LazyLoadImage
        src={
          'https://d85ecz8votkqa.cloudfront.net/payfac/onboarding/tax-information/tax-id-success.gif'
        }
        alt="Godaddy loader"
        width={props.completeSize || '36px'}
        height={props.completeSize || '36px'}
        className={[
          Styles.godaddyLoaderComplete,
          props.complete ? '' : Styles.hidden
        ].join(' ')}
      />
      <div
        className={[
          Styles.godaddyLoader,
          props.complete ? Styles.hidden : ''
        ].join(' ')}
        style={{
          fontSize: props.godaddySize,
          boxShadow: `inset 0 0 0 ${props.godaddyThinSize || '1em'}`
        }}
      />
    </div>
  ) : props.dots ? (
    <Suspense fallback={'...'}>
      <DotsLoader size={props.dotsSize} />
    </Suspense>
  ) : (
    <div className={Styles.loader}>
      <div className={Styles.animateLoader}>
        {[...Array.from({ length: 8 }).keys()].map(key => (
          <div key={key} />
        ))}
      </div>
    </div>
  );
};

export default Loader;
