import axios from 'axios';

import * as analyticsHelper from 'helpers/analytics/track';

import { PhoneAuthStatus, MobileVerificationStatus } from './types';

export const getMobileVerificationStatus = async (
  applicationId: string,
  finalTargetUrl?: string,
  vfp?: string
): Promise<MobileVerificationStatus> => {
  const response = await axios.post(
    `/api/v2/applications/${applicationId}/phone-auth/mobile-verifications`,
    {
      finalTargetUrl,
      vfp
    }
  );

  return response.data;
};

export const createPhoneAuth = async (
  applicationId: string,
  finalTargetUrl: string,
  retry?: boolean,
  skip?: boolean
): Promise<PhoneAuthStatus> => {
  const queryParams = Object.entries({ skip, retry })
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const response = await axios.post(
    `/api/v2/applications/${applicationId}/phone-auth/sms-verifications${
      queryParams ? '?' + queryParams : ''
    }`,
    {
      finalTargetUrl
    }
  );

  return response.data;
};

export const validateCode = async (
  applicationId: string,
  code: string
): Promise<void> => {
  await axios.post(
    `/api/v2/applications/${applicationId}/phone-auth/sms-verifications/${code}`
  );
};

export const validateTargetUrl = async (
  targetUrl: string
): Promise<string | undefined> => {
  let vfp = '';

  try {
    const validate = axios.create({
      headers: {
        Accept: ''
      }
    });

    const result = await validate.get<{ vfp: string }>(`${targetUrl}&r=f`, {
      headers: {}
    });

    vfp = result?.data?.vfp;
  } catch (error) {
    analyticsHelper.trackError(error);
  }

  return vfp;
};

export const getVerificationUrl = async (
  applicationId: string
): Promise<void> => {
  const response = await axios.get(
    `/api/v2/applications/${applicationId}/phone-auth/verification-url`
  );

  return response.data;
};
