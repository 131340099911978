import validator from 'validator';

import { Currency } from 'enums/business/currency';

import { invalidNameRegExp } from './invalid-name-regexp';

const noNumbersRegexp = /^[^0-9]*$/;

const countryDependedValidation = (name = '', currency?: Currency): boolean => {
  switch (currency) {
    case Currency.CAD:
      return /^(?!\s)(?!.*\s$)[a-zA-ZÀ-Ÿ][a-zA-ZÀ-Ÿ\s’'-]*$/.test(name);
    case Currency.USD:
    default:
      return /^(?!\s)(?!.*\s$)[a-zA-Z][a-zA-Z\s’'-]*$/.test(name);
  }
};

export const nameValidator = (name = '', currency?: Currency): boolean => {
  return !(
    name.length > 64 ||
    !noNumbersRegexp.test(name) ||
    invalidNameRegExp.test(name.toUpperCase()) ||
    !countryDependedValidation(name, currency)
  );
};

export const isRequired = (value?: string | undefined | null): boolean =>
  !!value;

export const isValidUsaName = (value: string | undefined | null): boolean => {
  const validationValue = value || '';

  return nameValidator(validationValue, Currency.USD);
};

export const isValidEmail = (value: string | undefined | null): boolean => {
  return validator.isEmail(value || '');
};
