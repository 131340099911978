import { toast } from 'react-toastify';

import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import { OrderType } from 'enums/order/type';
import { PaymentMethodType } from 'enums/order/payment';

import * as api from 'rest/v2/payments';

import { redirectTo } from 'helpers/history';
import { hasTrialPeriod } from 'helpers/trial';

import { Store } from 'reducers/types';
import { State as OrderReducer } from 'reducers/order/types';
import { State as EnvironmentReducer } from 'reducers/environment/types';
import { FinancialInstruments } from 'reducers/bank/types';

import * as bankActions from 'actions/bank';

export const getApplicationId = (state: Store) => state.application.id;

const getFinancialInstruments = (state: Store): FinancialInstruments =>
  state.bank.financialInstruments;

const getEnvironment = (state: Store): EnvironmentReducer => state.environment;

const getOrder = (state: Store): OrderReducer => state.order;

export function* createPayment(action: AnyAction) {
  const isBundle: boolean = action?.payload?.isBundle;

  try {
    const applicationId: string = yield select(getApplicationId);
    const order: OrderReducer = yield select(getOrder);
    const environment: EnvironmentReducer = yield select(getEnvironment);
    const financialInstruments: FinancialInstruments = yield select(
      getFinancialInstruments
    );

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    let n910Amount = 0;
    let poyntCAmount = 0;
    let noDeviceAmount = 0;
    let licenseAmount = 0;
    let sunmiT2S1Amount = 0;
    let sunmiT2S2Amount = 0;
    let totalAmount = 0;

    if (isBundle) {
      n910Amount =
        order.quantity.N910 *
        ((environment.eHopperBundles?.buyN910Amount || 0) * 100);
      poyntCAmount =
        order.quantity.PoyntC *
        ((environment.eHopperBundles?.buyPoyntCAmount || 0) * 100);
      sunmiT2S1Amount =
        order.quantity.SunmiT2S1 *
        ((environment.eHopperBundles?.buySunmiT2S1Amount || 0) * 100);
      sunmiT2S2Amount =
        order.quantity.SunmiT2S2 *
        ((environment.eHopperBundles?.buySunmiT2S2Amount || 0) * 100);
      licenseAmount =
        order.quantity.license *
        ((environment.eHopperBundles?.buyLicenseAmount || 0) * 100);

      totalAmount =
        n910Amount +
        poyntCAmount +
        sunmiT2S1Amount +
        sunmiT2S2Amount +
        licenseAmount;
    } else {
      switch (order.type) {
        case OrderType.RENT:
          n910Amount =
            order.quantity.N910 * ((environment.plans.n910.perUnit || 0) * 100);
          poyntCAmount =
            order.quantity.PoyntC *
            ((environment.plans.poyntC.perUnit || 0) * 100);
          break;
        case OrderType.BUY:
          n910Amount = order.quantity.N910 * (environment.buyN910Amount * 100);
          poyntCAmount =
            order.quantity.PoyntC * (environment.buyPoyntCAmount * 100);
          break;
        case OrderType.NO_DEVICE:
          noDeviceAmount =
            order.quantity.NO_DEVICE *
            ((environment.plans.noDevice.perUnit || 0) * 100);
      }
    }

    if (environment.paymentsType !== PaymentMethodType.NONE && !isBundle) {
      const hasTrial = hasTrialPeriod(
        environment.plans,
        order.type,
        n910Amount,
        poyntCAmount
      );

      const shippingCost = environment.shippingCost * 100;

      if (order.type === OrderType.NO_DEVICE) {
        totalAmount = !hasTrial ? noDeviceAmount : 0;
      } else {
        totalAmount = !hasTrial ? n910Amount + poyntCAmount : 0;
        totalAmount += shippingCost;
      }
    }

    yield put({
      payload: true,
      type: 'SAVE_ORDER_IN_PROGRESS'
    });

    if (
      totalAmount &&
      (environment.paymentsType !== PaymentMethodType.NONE || isBundle)
    ) {
      yield call(
        api.createPayment,
        applicationId,
        financialInstruments.cardId || '',
        totalAmount
      );
    }

    yield put({
      type: 'CREATE_PAYMENT_SUCCESS'
    });

    yield put({
      type: 'CREATE_ORDER_REQUEST'
    });

    redirectTo(`${referralUrlIdPath}/${applicationId}/verifying`);
  } catch (error) {
    const applicationId: string = yield select(getApplicationId);
    const environment: EnvironmentReducer = yield select(getEnvironment);

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';
    const errorMessage =
      error?.response?.data?.error ||
      'Your selected payment method was declined by bank. Please use another one.';

    yield put({
      payload: PaymentMethodType.CARD,
      type: 'SAVE_ORDER_PAYMENT_METHOD'
    });

    yield put({
      payload: '',
      type: 'SAVE_CREDIT_CARD_TOKEN'
    });

    yield put({
      payload: '',
      type: 'SAVE_CREDIT_CARD_ID'
    });

    yield call(toast.error, errorMessage);

    if (isBundle) {
      yield put(bankActions.saveCreditCard('', '', '', false));
    } else {
      yield call(
        redirectTo,
        `${referralUrlIdPath}/${applicationId}/order/card`
      );
    }

    yield put({
      error: errorMessage,
      type: 'CREATE_PAYMENT_FAILURE'
    });

    yield put({
      payload: false,
      type: 'SAVE_ORDER_IN_PROGRESS'
    });
  }
}
