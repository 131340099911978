const invalidNames = [
  'TEST',
  'DO NOT CONTACT',
  'BUSINESS USE ONLY',
  'FOR BUSINESS USE ONLY',
  'FOR OFFICIAL USE ONLY',
  'TRAVEL & EXPENSE ONLY',
  'UC BUSINESS ONLY',
  'NULL',
  'ACCTS PAYABLE',
  'EMERGENCY REPLACEMENT',
  'EMERGENCY CARD',
  'CENTRAL BILL',
  'BUSINESS CARD',
  'CARDMEMBER',
  'GENERIC CARD',
  'FUEL ONLY',
  'CARD BUSINESS',
  'NEED FROM CLIENT',
  'PAYABLE',
  'ATTN',
  'ACCOUNTS PAYABLE',
  'IRA PLAN',
  'SCHOOL',
  'CO',
  'ACCOUNT CLOSED',
  'PLLC',
  'INC',
  'LP',
  'LTD',
  'LLP',
  'THE',
  'NA',
  'N A',
  ",'",
  'NOT REQUIRED',
  'NRQ',
  'ADMIN',
  'HEALTH',
  'UNKNOWN',
  'PODCAST',
  'CLOTHING'
];

const invalidPartsOfNames = [
  'CORPORATE',
  ' INC',
  ' CORPORATION',
  ' LLC',
  ' PC',
  ' PLLC',
  'COMPANY',
  ' LTD',
  'GROUP',
  'MANAGEMENT',
  'MARKET',
  ' LP',
  'NATIONAL',
  ' ASSOC',
  ' MUNICIPALS',
  ' MUNICIPAL',
  ' CORP',
  ' TRUST',
  ' ACCOUNT',
  ' SERVICES',
  ' EXCHANGE',
  ' SECURITIES',
  ' SDFS',
  'DO NOT USE',
  ' PORTFOLIO',
  ' PARTNERSHIP',
  ' INDUSTRIAL',
  ' GLOBAL',
  ' SA DE CV',
  ' TECHNOLOGIES',
  ' HOSPITALITY',
  ' COMMUNITY',
  ' EDUCATION',
  ' UNIVERSITY',
  ' HOA',
  ' ASSOCIATION'
].map(item => `.*${item}.*`);

export const invalidNameRegExp = new RegExp(
  `^(${[...invalidNames, ...invalidPartsOfNames].join('|')})$`
);
