import {
  FC,
  Suspense,
  useEffect,
  useState,
  lazy,
  useLayoutEffect
} from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import * as accountsActions from 'actions/account';
import * as applicantActions from 'actions/applicant';
import * as categoriesActions from 'actions/categories';
import * as environmentActions from 'actions/environment';

import { Overlay } from 'components/elements';

import { ApiVersion } from 'enums/application/version';
import { ApplicantType } from 'enums/applicant/type';
import { BeneficialOwnersStatus } from 'enums/application/beneficial-owners-status';
import { PortalType } from 'enums/portal/type';
import { Currency } from 'enums/business/currency';
import { LocaleType } from 'enums/applicant/locale-type';
import { DecisionType } from 'enums/application/decision';
import { ApplicationLevel } from 'enums/application/application-level';
import { AnalyticsPortalType } from 'enums/analytics/portal-type';

import { useStore } from 'helpers/store';
import { useUxCore } from 'helpers/ux-core';
import { redirectTo } from 'helpers/history';
import { useResumeHook } from 'helpers/application/hooks';
import { useTranslation } from 'helpers/translation';
import { resolveCountryByCurrency } from 'helpers/country/country-resolver';

import * as cookiesHelper from 'helpers/cookies';
import * as godaddyHelper from 'helpers/godaddy';
import * as ownersHelpers from 'helpers/owners';
import * as analyticsHelper from 'helpers/analytics/track';
import * as referralsHelper from 'helpers/referrals';
import * as categoriesHelpers from 'helpers/categories';
import * as applicationsHelper from 'helpers/application';
import * as countryResolverHelper from 'helpers/country/country-resolver';
import * as toastHelpers from 'helpers/toast';

import Environments from 'helpers/environments';

import { State as Environment } from 'reducers/environment/types';
import { State as ApplicationReducer } from 'reducers/application/types';
import { State as CompanyReducer } from 'reducers/company/types';
import { State as AccountReducer } from 'reducers/account/types';
import { Category } from 'reducers/categories/types';

import { InitTrackDataArgsInterface } from 'helpers/analytics/track/types';
import { MixpanelTrackArgsInterface } from 'helpers/analytics/mixpanel/types';
import { TrafficInitTrackArgsInterface } from 'helpers/analytics/traffic/types';

import ContentContainer from './content';

import 'helpers/validators';

const Accounts = lazy(() => import('./accounts'));
const GetStarted = lazy(() => import('./get-started'));
const Portal = lazy(() => import('./portal'));
const PortalV2 = lazy(() => import('./portal-v2'));
const Store = lazy(() => import('./store'));
const BusinessInfo = lazy(() => import('./business-info'));
const TaxInformation = lazy(() => import('./tax-information'));
const FullEin = lazy(() => import('./full-ein'));
const FullSsn = lazy(() => import('./full-ssn'));
const Owners = lazy(() => import('./owners'));
const FullEinContent = lazy(() => import('./full-ein/content'));
const FullEinFormContent = lazy(() => import('./full-ein/form/content'));
const FullSsnContent = lazy(() => import('./full-ssn/content'));
const FullSsnFormContent = lazy(() => import('./full-ssn/form/content'));
const BusinessInfoContent = lazy(() => import('./business-info/content'));
const BusinessInfoFormContent = lazy(
  () => import('./business-info/forms/us/content')
);
const IncorrectUrl = lazy(() => import('./incorrect-url'));

import WamErrorModal from './portal/wam-error-modal';

const Onboarding: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { isUxCoreEnabled } = useUxCore();

  const [canUseRoutes, setCanUseRoutes] = useState<boolean>(false);

  const apiVersion = useStore<string | undefined>(
    state => state.application.apiVersion
  );
  const environment = useStore<Environment>(state => state.environment);
  const application = useStore<ApplicationReducer>(state => state.application);
  const company = useStore<CompanyReducer>(state => state.company);
  const accounts = useStore<AccountReducer>(state => state.accounts);
  const loading = useStore<boolean>(state => !!state.loadingBar.default);
  const serviceType = useStore<string | undefined>(
    state => state.application.serviceType
  );
  const serviceId = useStore<string | undefined>(
    state => state.application.serviceId
  );

  const ownerId = ownersHelpers.parseUrlPathFoOwnerId(location.pathname);
  const applicationId =
    /urn:app:(\w{4,12}-?){5}/.exec(location.pathname)?.[0] || '';

  const applicationResumed = useStore<boolean>(
    state => !!state.application.resumed
  );

  const isGoDaddyPortal = useStore<boolean>(
    state => state.environment.portal === PortalType.GODADDY
  );

  const websiteId = useStore<string | undefined>(
    state => state.environment.websiteId
  );

  const isEnvironmentLoaded = useStore<boolean>(
    state => state.environment.loaded
  );

  const { applicationId: id, skipResume } = useResumeHook({
    skipResume: !!ownerId,
    accountsLoaded: accounts.loaded,
    skipStepUpParsing: ['/ein', '/ssn', '/tax-information'].some(path =>
      location.pathname.endsWith(path)
    ),
    environmentLoaded: isEnvironmentLoaded,
    newSignupFormEnabled: environment.newSignupFlowEnabled,
    newSignupFlowPbdEnabled: environment.newSignupFlowPbdEnabled
  });

  const categories = useStore<Category[]>(state => state.categories.list);

  const standaloneFlowEnable = useStore<boolean | undefined>(
    state => state.environment.cobrand.standaloneFlowEnable
  );

  const [pageLoaded, setPageLoaded] = useState<boolean>();
  const [isWam, setIsWam] = useState<boolean>();

  const isCanada = useStore<boolean>(
    store => store.company.currency === Currency.CAD
  );

  const [isOverlapErrorShowed, setIsOverlapErrorShowed] =
    useState<boolean>(false);

  const approvedAccounts = accounts?.list?.filter(
    account =>
      account.riskDecision === DecisionType.APPROVED ||
      account.riskDecision === DecisionType.APPROVED_PENDING
  );

  let latestApprovedAccount;

  if (
    !(
      !isGoDaddyPortal ||
      environment.eHopperFlowEnabled ||
      (!environment.enableMasterMidForCanada && isCanada)
    )
  ) {
    latestApprovedAccount = approvedAccounts.sort(
      (a, b) =>
        new Date(b?.createdAt as string).getTime() -
        new Date(a?.createdAt as string).getTime()
    )?.[0];
  }

  useEffect(() => {
    if (
      applicationId ||
      apiVersion !== ApiVersion.V2 ||
      !serviceType?.includes('gopay.')
    ) {
      return;
    }

    setIsWam(true);

    if (serviceId) {
      dispatch(environmentActions.getWebsite(serviceId));
    }
  }, [applicationId, apiVersion, serviceType, serviceId]);

  useEffect(() => {
    dispatch(
      applicantActions.saveApplicantCommunicationLanguage(
        (i18n.language as LocaleType) || LocaleType['en-US']
      )
    );
  }, [i18n.language]);

  useEffect(() => {
    const referralUrlId = referralsHelper.parseReferralCode(location.pathname);
    const queryParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(queryParams);

    const currency = countryResolverHelper.resolveCurrencyByLanguage(
      i18n.language,
      environment.portal === PortalType.GODADDY
    );

    applicationsHelper.applicationPrefill(
      queryParams,
      dispatch,
      latestApprovedAccount,
      currency || environment.pricing.currency
    );
    dispatch(environmentActions.getEnvironment(referralUrlId, params));
    dispatch(categoriesActions.getCategoriesList());
    dispatch(accountsActions.fetchAccountsRequest());

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  useEffect(() => {
    const queryParams = location.search;
    const applicationId =
      /urn:app:(\w{4,12}-?){5}/.exec(location.pathname)?.[0] || '';

    // if URL contain applicationId, user should not see switcher
    if (applicationId || application.id) {
      setCanUseRoutes(true);
      return;
    }

    // should wait when configs will be loaded
    if (!isEnvironmentLoaded) {
      return;
    }

    const referralUrlId = referralsHelper.parseReferralCode(location.pathname);
    const url = referralsHelper.getReferralUrl(referralUrlId);

    applicationsHelper.applicationPrefill(
      new URLSearchParams(location.search),
      dispatch,
      latestApprovedAccount,
      environment.pricing.currency
    );

    // user should not use switcher functionality
    if (
      application.createBusiness ||
      !isGoDaddyPortal ||
      environment.eHopperFlowEnabled ||
      (!environment.enableMasterMidForCanada && isCanada)
    ) {
      setCanUseRoutes(true);
      redirectTo(`${url || '/'}${queryParams}`);
      return;
    }

    //if standalone flow and no accounts, redirect to breather page.
    if ((!accounts.loaded || !accounts.list?.length) && standaloneFlowEnable) {
      setCanUseRoutes(true);
      redirectTo(`${url || '/'}${queryParams}`);
      return;
    }

    // should wait when accounts will be loaded
    if (!accounts.loaded) {
      return;
    }

    // if accounts list is empty use should not see account switcher
    if (!accounts.list.length) {
      setCanUseRoutes(true);
      return;
    }

    let selectedAccountId: string | null = null;

    if (application.businessId) {
      const account = accounts.list.find(
        element =>
          element.businessId === application.businessId &&
          (element.riskDecision === DecisionType.APPROVED ||
            element.riskDecision === DecisionType.APPROVED_PENDING)
      );

      selectedAccountId = account ? application.businessId : null;
    }

    // if businessId context is provided
    if (selectedAccountId) {
      setCanUseRoutes(true);
      redirectTo(`${url}/accounts/${selectedAccountId}/stores${queryParams}`);
      return;
    }

    // if user has more than 1 account OR has 1 FULL account, we should to show account switcher
    if (
      accounts.list.length > 1 ||
      accounts.list[0].applicationLevel === ApplicationLevel.FULL
    ) {
      setCanUseRoutes(true);
      redirectTo(`${url}/accounts${queryParams}`);
      return;
    }

    // if user has 1 account what is not FULL
    setCanUseRoutes(true);
    redirectTo(
      application.serviceId || application.serviceType
        ? `${url}/accounts/${accounts.list[0].businessId}/stores${queryParams}`
        : `${url}/${accounts.list[0].applicationId}/${isUxCoreEnabled ? 'applicant' : 'update'}`
    );
  }, [isEnvironmentLoaded, accounts.loaded, application.id]);

  useEffect(() => {
    if (
      isEnvironmentLoaded &&
      categories.length &&
      !company.mccDetails?.label
    ) {
      categoriesHelpers.setDefaultEHopperCompanySegment(
        environment.eHopperSolution,
        dispatch,
        categories
      );
    }
  }, [
    company.segment,
    isEnvironmentLoaded,
    categories.length,
    company.mccDetails?.label
  ]);

  useEffect(() => {
    if (
      loading ||
      !pageLoaded ||
      !accounts.loaded ||
      !categories.length ||
      !accounts.list.length ||
      !isEnvironmentLoaded ||
      !company.currency ||
      isOverlapErrorShowed
    ) {
      return;
    }

    const isExistsSameCurrency = accounts.list.some(
      account => account.company.currency === company.currency
    );

    const profileCurrencies = environment.pricing.currencies || [];
    const proposedCurrency =
      company.currency === Currency.CAD ? Currency.USD : Currency.CAD;

    if (!isExistsSameCurrency && profileCurrencies.includes(proposedCurrency)) {
      cookiesHelper.setGodaddyCurrencyCookie(
        proposedCurrency,
        environment.godaddyCookieHost
      );

      window.location.reload();
    } else if (
      !isExistsSameCurrency &&
      !profileCurrencies.includes(proposedCurrency)
    ) {
      const translation = t(
        `accounts.overlapCurrencyError.${
          resolveCountryByCurrency(accounts.list[0].company.currency)
            ?.countryCode
        }`
      );
      toastHelpers.toast(translation, isUxCoreEnabled);
      setIsOverlapErrorShowed(true);
      analyticsHelper.trackOverlapCurrencyError();
    }
  }, [
    loading,
    pageLoaded,
    accounts.list,
    accounts.loaded,
    company.currency,
    categories.length,
    isEnvironmentLoaded,
    isOverlapErrorShowed
  ]);

  useEffect(() => {
    if (!isEnvironmentLoaded) {
      return;
    }

    onPageLoad();

    const queryParams = new URLSearchParams(location.search);

    if (application.id && queryParams.get('resumeEmail')) {
      analyticsHelper.trackResumeApplicationByEmail(
        application.id,
        ownerId || '',
        ownerId ? ApplicantType.OWNER : undefined
      );
    }

    if (application.id && queryParams.get('reminderEmail')) {
      analyticsHelper.trackSignUpApplicationByReminderEmail(
        application.id,
        ownerId || '',
        ownerId ? ApplicantType.OWNER : undefined
      );
    }
  }, [isEnvironmentLoaded, application.id, applicationResumed]);

  useEffect(() => {
    const parsedCategories = categories.map(category => {
      const localizedLabel = i18n.t(
        `portal.update.categories.list.${category.id}`
      );

      if (localizedLabel) {
        category.label = localizedLabel;
      }

      category.industries = (category.industries || []).map(industry => {
        const localizedLabel = i18n.t(
          `portal.update.industries.list.${industry.id}`
        );

        if (localizedLabel) {
          industry.label = localizedLabel;
        }

        const localizedKeywords = i18n.t(
          `portal.update.keywords.list.${industry.id}`
        );

        if (localizedKeywords) {
          industry.keywords = localizedKeywords;
        }

        return industry;
      });

      return category;
    });

    categoriesActions.saveCategoriesList(parsedCategories);
  }, [categories, i18n.language]);

  useLayoutEffect(() => {
    if (!isEnvironmentLoaded) {
      return;
    }

    analyticsHelper.initTrackData(
      initAnalyticsSettings(environment, application),
      dispatch
    );
  }, [
    i18n.language,
    application.id,
    isUxCoreEnabled,
    applicationResumed,
    isEnvironmentLoaded,
    application.boStatus,
    application.ventureId,
    application.applicationLevel,
    application.originalApplicationLevel
  ]);

  const onPageLoad = () => {
    if (pageLoaded || isEnvironmentLoaded) {
      return;
    }

    setPageLoaded(true);

    setTimeout(() => {
      if (!applicationResumed) {
        analyticsHelper.trackSignUpStart(
          application.id,
          ownerId || '',
          ownerId ? ApplicantType.OWNER : undefined
        );
      }
    }, 0);
  };

  const initAnalyticsSettings = (
    environment: Environment,
    application: ApplicationReducer
  ): InitTrackDataArgsInterface => {
    const settings: TrafficInitTrackArgsInterface | MixpanelTrackArgsInterface =
      {
        ...(isGoDaddyPortal
          ? {
              ...environment.godaddy,
              serviceId: application.serviceId,
              ventureId: application.ventureId,
              serviceType: application.serviceType,
              referralUrlId: environment.cobrand.referralUrlId,
              organizationId: environment.cobrand.organizationId
            }
          : {}),
        beneficiaryOwnerEnabled: environment.beneficiaryOwnerEnabled,
        hasBeneficialOwners: application.boStatus
          ? application.boStatus !== BeneficialOwnersStatus.NONE
          : false,
        mock: application.mock,
        portal: isGoDaddyPortal
          ? AnalyticsPortalType.gdp
          : AnalyticsPortalType.poynt,
        source: application.source,
        medium: application.medium,
        currency: environment.pricing.currency,
        language: i18n.language,
        businessId: (application.id || '').slice(8) || '',
        inPersonPricingFirstEnabled: environment.inPersonPricingFirstEnabled,
        isPowerSeller: application.isPowerSeller,
        isMultistepFlow: isUxCoreEnabled,
        applicationLevel: application.applicationLevel || ApplicationLevel.FULL,
        originalApplicationLevel:
          application.originalApplicationLevel || ApplicationLevel.FULL,
        dateOfBirthForUSAEnabled: environment.dateOfBirthForUSAEnabled,
        newApplicationFormEnabled: environment.newApplicationFormEnabled
      };

    return {
      traffic: settings,
      mixpanel: settings
    };
  };

  const showFormDuringLoading = () => {
    return (
      isEnvironmentLoaded &&
      ((!environment.newSignupFlowEnabled &&
        !environment.newSignupFlowPbdEnabled) ||
        (accounts.loaded &&
          categories.length &&
          (skipResume || !applicationId || id)))
    );
  };

  return showFormDuringLoading() ? (
    !isGoDaddyPortal || canUseRoutes ? (
      <ContentContainer>
        <Suspense fallback={<Overlay additionalText={false} />}>
          <Switch>
            <Route
              path={['/accounts', '/r/:referralUrl/accounts']}
              component={Accounts}
            />
            {!isUxCoreEnabled ? (
              <Route
                path={[
                  '/:id/business-info',
                  '/r/:referralUrl/:id/business-info'
                ]}
                render={props => (
                  <BusinessInfo
                    {...props}
                    child={BusinessInfoContent}
                    form={BusinessInfoFormContent}
                  />
                )}
              />
            ) : null}
            <Route
              path={[
                '/:id/tax-information',
                '/r/:referralUrl/:id/tax-information'
              ]}
              component={TaxInformation}
            />
            {!isUxCoreEnabled ? (
              <Route
                path={['/:id/ein', '/r/:referralUrl/:id/ein']}
                render={props => (
                  <FullEin
                    {...props}
                    child={FullEinContent}
                    form={FullEinFormContent}
                  />
                )}
              />
            ) : null}
            {!isUxCoreEnabled ? (
              <Route
                path={['/:id/ssn', '/r/:referralUrl/:id/ssn']}
                render={props => (
                  <FullSsn
                    {...props}
                    child={FullSsnContent}
                    form={FullSsnFormContent}
                  />
                )}
              />
            ) : null}
            <Route path={['/incorrect-url']} component={IncorrectUrl} />
            <Route
              path={['/get-started', '/r/:referralUrl/get-started']}
              component={isUxCoreEnabled ? PortalV2 : GetStarted}
            />
            {isUxCoreEnabled && !ownerId ? null : (
              <Route
                path={
                  isUxCoreEnabled && ownerId
                    ? ['/:id/owners/*', '/r/:referralUrl/:id/owners/*']
                    : ['/:id/owners*', '/r/:referralUrl/:id/owners*']
                }
                component={Owners}
              />
            )}
            <Route
              path={[
                '/:id/store-complete',
                '/:id/store-success',
                '/r/:referralUrl/:id/store-complete',
                '/r/:referralUrl/:id/store-success'
              ]}
              component={Store}
            />
            <Route path="/" component={isUxCoreEnabled ? PortalV2 : Portal} />
          </Switch>
        </Suspense>

        {isWam && websiteId === null ? <WamErrorModal /> : null}
      </ContentContainer>
    ) : (
      <Overlay />
    )
  ) : (
    <Overlay additionalText={false} />
  );
};

export default Onboarding;
