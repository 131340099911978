import Environments from 'helpers/environments';

import { NPSTrackEventArgsInterface } from './../types';

const timoutForNPSRestart = 1500;

class NPS {
  restartNPS() {
    if (window.QSI && window.QSI.API) {
      // wait timeout to end all current NPS requests
      setTimeout(() => {
        window.QSI.API.unload();
        window.QSI.API.load();
        window.QSI.API.run();
      }, timoutForNPSRestart);
    }
  }

  trackEvent(event: NPSTrackEventArgsInterface) {
    window.qualtrics = { ...window.qualtrics, ...event };

    if (event.bankAccountAdded) {
      sessionStorage.setItem('npsAddedBankAccount', '1');
    }

    console.log('NPS track: ', JSON.stringify(window.qualtrics));
  }

  insertScriptsNPS() {
    const scriptConfig = document.createElement('script');

    scriptConfig.setAttribute('type', 'text/javascript');
    scriptConfig.setAttribute('async', '');
    scriptConfig.src = `${window.location.origin}/scripts/nps/nps.js`;

    document.head.appendChild(scriptConfig);
    //End configure global values in NPS

    //Fill script
    const scriptFilling = document.createElement('div');
    scriptFilling.id = Environments.get('godaddy.npsId');
    document.head.appendChild(scriptFilling);
    //End Filling script

    return () => {
      document.head.removeChild(scriptConfig);
      document.head.removeChild(scriptFilling);
    };
  }
}

export default new NPS();
