import { Currency } from 'enums/business/currency';
import { CountryType } from 'enums/address/country-type';
import { BusinessType } from 'enums/business/type';
import { CategoryType } from 'enums/category';

import { CreateApplicationArgsInterface } from 'rest/v2/application/interfaces';

const resolveCompanyGovernmentIdentification = (
  request: CreateApplicationArgsInterface
) => {
  if (request.featureFlags?.newApplicationFormEnabled) {
    return request.company.governmentIdentification;
  }

  return request.company.governmentIdentification.value.length
    ? request.company.governmentIdentification
    : request.applicant.governmentIdentification;
};

export const prepareApplicationRequest = (
  request: CreateApplicationArgsInterface,
  isBasicApplication = false,
  accountId?: string,
  storeId?: string
): unknown => {
  let applicantGovernmentIdentification, companyGovernmentIdentification;

  switch (request.company.address.countryCode) {
    case CountryType.US:
      applicantGovernmentIdentification =
        request.applicant.governmentIdentification;
      companyGovernmentIdentification =
        resolveCompanyGovernmentIdentification(request);
      break;
    case CountryType.CA:
      companyGovernmentIdentification = request.company.governmentIdentification
        .value.length
        ? request.company.governmentIdentification
        : undefined;
      applicantGovernmentIdentification = request.applicant
        .governmentIdentification.value.length
        ? request.applicant.governmentIdentification
        : undefined;
      break;
    default:
      applicantGovernmentIdentification =
        request.applicant.governmentIdentification;
      companyGovernmentIdentification =
        request.company.governmentIdentification;
  }

  const applicationRequest = {
    agentId: request.application.agentId,
    mock: request.application.mock,
    masterMid: request.application.masterMid,
    context: request.application.context,
    medium: request.application.medium,
    source: request.application.source,
    godaddyShopperId: request.application.godaddyShopperId || undefined,
    godaddyCustomerId: request.application.godaddyCustomerId || undefined,
    godaddyServiceId: request.application.godaddyServiceId || undefined,
    godaddyServiceType: request.application.godaddyServiceType || undefined,
    godaddyVentureId: request.application.godaddyVentureId || undefined,
    referralUrlId: request.application.referralUrlId,
    applicationLevel: isBasicApplication ? 'BASIC' : 'FULL',
    campaignName: request.application.campaignName,
    businessId: accountId,
    storeId: storeId,
    boStatus: request.application.boStatus || undefined
  };

  const firstName = (request.applicant.name.firstName || '').trim();
  const lastName = (request.applicant.name.lastName || '').trim();
  const companyName = (
    request.company.name || `${firstName} ${lastName}`
  ).trim();
  const companyDba = (request.company.dba || '').trim();

  if (isBasicApplication) {
    return {
      applicant: {
        dob: request.applicant.dob || undefined,
        email: request.applicant.email || undefined,
        firstName: request.applicant.name.firstName.trim() || undefined,
        lastName: request.applicant.name.lastName.trim() || undefined,
        leadId: request.applicant.leadId,
        userId: request.applicant.userId,
        communicationLanguage: request.applicant.communicationLanguage
      },
      application: applicationRequest,
      company: {
        address: {
          countryCode: request.company.address.countryCode
        },
        name: companyName || undefined,
        site: request.company.site || undefined
      }
    };
  }

  const applicantAddress =
    request.company.address.countryCode === CountryType.US
      ? undefined
      : {
          ...request.applicant.address,
          countryCode: request.applicant.address.countryCode || CountryType.US
        };

  return {
    applicant: {
      ein: request.applicant.sin,
      dob: request.applicant.dob || undefined,
      email: request.applicant.email,
      phone: request.applicant.phone,
      terms: request.applicant.terms,
      leadId: request.applicant.leadId,
      userId: request.applicant.userId,
      firstName,
      lastName,
      communicationLanguage: request.applicant.communicationLanguage,
      ...(applicantAddress ? { address: applicantAddress } : {}),
      ...(applicantGovernmentIdentification
        ? { governmentIdentification: applicantGovernmentIdentification }
        : {})
    },
    application: applicationRequest,
    company: {
      address: {
        ...request.company.address,
        countryCode: request.company.address.countryCode || CountryType.US
      },
      ...(companyGovernmentIdentification
        ? { governmentIdentification: companyGovernmentIdentification }
        : {}),
      name: companyName,
      dba: companyDba || undefined,
      phone: request.company?.phone?.localPhoneNumber
        ? request.company.phone
        : undefined,
      site: request.company.site,
      mcc: request.company.segment,
      currency: request.company.currency || Currency.USD,
      mccDetails: {
        label: request.company.mccDetails?.label,
        type: request.company.mccDetails?.type
      }
    }
  };
};
