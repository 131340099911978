import { AnyAction } from 'redux';

export function mobileVerificationRequest(): AnyAction {
  return {
    type: 'MOBILE_VERIFICATION_REQUEST'
  };
}

export function mobileVerificationSuccess(): AnyAction {
  return {
    type: 'MOBILE_VERIFICATION_SUCCESS'
  };
}

export function mobileVerificationFailure(): AnyAction {
  return {
    type: 'MOBILE_VERIFICATION_FAILURE'
  };
}

export function phoneAuthRequest(retry?: boolean, skip?: boolean): AnyAction {
  return {
    type: 'PHONE_AUTH_REQUEST',
    payload: { skip, retry }
  };
}

export function phoneAuthSuccess(): AnyAction {
  return {
    type: 'PHONE_AUTH_SUCCESS'
  };
}

export function phoneAuthFailure(): AnyAction {
  return {
    type: 'PHONE_AUTH_FAILURE'
  };
}

export function changePhoneAuthAttempt(attempt: number): AnyAction {
  return {
    payload: attempt,
    type: 'CHANGE_PHONE_AUTH_ATTEMPT'
  };
}

export function changePhoneAuthState(verified: boolean): AnyAction {
  return {
    payload: verified,
    type: 'CHANGE_PHONE_AUTH_STATE'
  };
}

export function saveMobileVerificationError(error: boolean): AnyAction {
  return {
    payload: error,
    type: 'SAVE_MOBILE_VERIFICATION_ERROR'
  };
}

export function savePhoneAuthError(error: boolean): AnyAction {
  return {
    payload: error,
    type: 'SAVE_PHONE_AUTH_ERROR'
  };
}

export function checkPhoneCodeRequest(vfp: string, applicationId: string) {
  return {
    payload: {
      vfp,
      applicationId
    },
    type: 'CHECK_PHONE_CODE_REQUEST'
  };
}

export function checkPhoneCodeSuccess() {
  return {
    type: 'CHECK_PHONE_CODE_SUCCESS'
  };
}

export function checkPhoneCodeFailure() {
  return {
    type: 'CHECK_PHONE_CODE_FAILURE'
  };
}

export function verifySmsLinkRequest(
  applicationId: string,
  errorRedirectPath: string
) {
  return {
    payload: {
      applicationId,
      errorRedirectPath
    },
    type: 'VERIFY_SMS_LINK_REQUEST'
  };
}

export function verifySmsLinkSuccess() {
  return {
    type: 'VERIFY_SMS_LINK_SUCCESS'
  };
}

export function verifySmsLinkFailure() {
  return {
    type: 'VERIFY_SMS_LINK_FAILURE'
  };
}
