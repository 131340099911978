import { FC } from 'react';
import {
  useTranslation as i18nextTranslation,
  Trans as I18nextTrans
} from 'react-i18next';
import { TFunction as I18TFunction, TOptions } from 'i18next';

import { TFunction, TransProps } from './types';

export const useTranslation = (keyPrefix?: string) => {
  const i18n = i18nextTranslation('', { keyPrefix });
  return {
    t: <T = string,>(key: string, options: TOptions = {}) =>
      i18n.t(key, options) as T,
    i18n: i18n.i18n
  };
};

export const Trans: FC<TransProps> = (props: TransProps) => {
  return (
    <I18nextTrans
      t={
        props.t as TFunction &
          I18TFunction &
          I18TFunction<'translation', undefined>
      }
      i18nKey={props.i18nKey}
      values={props.values}
      components={props.components || {}}
    />
  );
};
