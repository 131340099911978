import RCTooltip from 'rc-tooltip';
import Select, { components } from 'react-select';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';

import { ValidatorComponent } from 'react-form-validator-core';

import { CategoryType } from 'enums/category';

import * as categoriesHelpers from 'helpers/categories';

import { Tooltip } from 'components/elements';

import { Props, SelectedOption, State } from './CategorySelect.types';

import Styles from './CategorySelect.module.scss';

import 'rc-tooltip/assets/bootstrap_white.css';

class CategorySelect extends ValidatorComponent<Props, State> {
  private prepareOptions() {
    return categoriesHelpers.prepareOptions(this.props.options);
  }

  private getDefaultValue(): SelectedOption | undefined {
    return categoriesHelpers.getBusinessCategoryIndustry(
      this.props.value,
      this.props.options
    ) as SelectedOption | undefined;
  }

  private handleHeaderClick(id: string) {
    const node = document?.getElementById(id)?.parentElement?.parentElement;
    const classes = node?.classList;

    if (classes?.contains('collapsed')) {
      node?.classList.remove('collapsed');
    } else {
      node?.classList.add('collapsed');
    }
  }

  private handleMenuOpen() {
    this.setState({
      inputValue: this.state.selectedOption?.label
    });
    this.handleChange(null);
  }

  private handleInputValueChange(option: string, { action }) {
    this.setState({
      inputValue: action !== 'set-value' ? option : undefined
    });
  }

  public handleChange(selectedOption) {
    this.setState({ selectedOption }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(selectedOption);
      }
    });
  }

  public renderValidatorComponent() {
    const { overlayClassName, t, increaseFont, linkTitle, subLabel } =
      this.props;

    const showOptional =
      this.props.optionallabel !== undefined ? this.props.optionallabel : true;

    return (
      <div
        className={[
          Styles.container,
          increaseFont ? Styles.increaseFont : ''
        ].join(' ')}>
        {this.props.label && (
          <label
            htmlFor={this.props.id}
            className={!this.state.isValid ? Styles.error : ''}>
            {this.props.label}
            {(this.props.validators || []).includes('required') ? (
              <span className={Styles.required}>*</span>
            ) : showOptional ? (
              <span className={Styles.optional}>
                &nbsp;- {t('components.form.select.optional')}
              </span>
            ) : null}
            {this.props.tooltipText ? (
              <Tooltip
                tooltipId={this.props.id}
                title={this.props.tooltipTitle}
                classNames={Styles.informationTooltip}
                content={this.props.tooltipText}
                link={this.props.link}
                linkTitle={linkTitle}
              />
            ) : null}
          </label>
        )}
        <RCTooltip
          placement={this.props.tooltipPosition || 'bottomLeft'}
          visible={!this.state.isValid}
          overlayClassName={
            overlayClassName ? overlayClassName : Styles.overlay
          }
          overlay={
            this.props.rcErrorTooltipDefault ? (
              <></>
            ) : (
              <span className={Styles.rcTooltipInner}>{this.getMessage()}</span>
            )
          }>
          <>
            <Select
              inputId={this.props.id}
              placeholder={this.props.placeholder}
              tabIndex={this.props.tabIndex}
              onMenuOpen={() => this.handleMenuOpen()}
              classNames={{
                control: () =>
                  [
                    Styles.select,
                    !this.state.isValid ||
                    this.state.selectedOption?.type ===
                      CategoryType.SUPER_PROHIBITED
                      ? Styles.error
                      : ''
                  ].join(' '),
                placeholder: () => Styles.placeholder,
                input: () => Styles.input,
                singleValue: () => Styles.input,
                menu: props => {
                  return [
                    Styles.selectMenu,
                    props.selectProps.inputValue ? Styles.searchable : ''
                  ].join(' ');
                },
                indicatorsContainer: () => Styles.indicatorsContainer,
                group: () => Styles.group,
                groupHeading: () => Styles.groupHeading,
                option: () => Styles.option,
                noOptionsMessage: () => Styles.noOptionsMessage
              }}
              value={this.state.selectedOption}
              isClearable
              inputValue={this.state.inputValue}
              onInputChange={(option, test) =>
                this.handleInputValueChange(option, test)
              }
              defaultValue={this.getDefaultValue()}
              filterOption={(option, search: string) => {
                const text = (search || '').toLowerCase();
                const groupName = (option.data.category || '').toLowerCase();
                const item = (option.label || '').toLowerCase();
                const keywords = (option.data.keywords || '').toLowerCase();
                return (
                  item.includes(text) ||
                  keywords.includes(text) ||
                  groupName.includes(text)
                );
              }}
              onChange={selectedOption => this.handleChange(selectedOption)}
              noOptionsMessage={() => this.props.notFoundText}
              options={this.prepareOptions()}
              components={{
                GroupHeading: props => (
                  <div onClick={() => this.handleHeaderClick(props.id)}>
                    <components.GroupHeading {...props} />
                    <span className={Styles.dropDownIcon}></span>
                  </div>
                ),
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: ({
                  innerProps: { ref, ...restInnerProps }
                }) => (
                  <span {...restInnerProps} ref={ref} className={Styles.clear}>
                    <img
                      src={
                        'https://d85ecz8votkqa.cloudfront.net/payfac/onboarding/multistepimg/clear.svg'
                      }
                      alt="clear"
                    />
                  </span>
                ),
                Option: props => {
                  return (
                    <components.Option
                      {...props}
                      className={[
                        props.className,
                        props.isFocused ? Styles.focused : ''
                      ].join(' ')}>
                      <Highlighter
                        searchWords={[
                          categoriesHelpers.escapeValue(
                            props.selectProps?.inputValue
                          )
                        ]}
                        textToHighlight={props.label}
                      />
                    </components.Option>
                  );
                }
              }}
            />
            <p
              className={[
                Styles.subLabel,
                this.props.rcErrorTooltipDefault && !this.state.isValid
                  ? Styles.subLabelError
                  : '',
                !this.props.rcErrorTooltipDefault && !subLabel
                  ? Styles.subLabelHidden
                  : ''
              ].join(' ')}>
              {(this.props.rcErrorTooltipDefault ? this.state.isValid : true)
                ? subLabel
                : this.getMessage()}
            </p>
          </>
        </RCTooltip>
      </div>
    );
  }

  private getMessage(): string {
    const message = this.state.errorMessages;
    return this.invalid.length > 0 && message[this.invalid[0]];
  }
}

export default withTranslation()(CategorySelect);
