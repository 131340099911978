import { AnyAction } from 'redux';

import { Store } from 'rest/v2/accounts/stores/interfaces';
import { Account } from 'rest/v2/accounts/interfaces';

export function saveAccounts(accounts?: Account[]): AnyAction {
  return {
    payload: accounts,
    type: 'SAVE_ACCOUNTS'
  };
}

export function saveStoresForAccount(stores?: Store[]): AnyAction {
  return {
    payload: stores,
    type: 'SAVE_STORES_FOR_ACCOUNT'
  };
}

export function clearStoresForAccount(): AnyAction {
  return {
    type: 'CLEAR_STORES_FOR_ACCOUNT'
  };
}

export function fetchAccountsRequest(): AnyAction {
  return {
    type: 'FETCH_ACCOUNTS_REQUEST'
  };
}

export function fetchAccountsSuccess(): AnyAction {
  return {
    type: 'FETCH_ACCOUNTS_SUCCESS'
  };
}

export function fetchAccountsFailure(error: string): AnyAction {
  return {
    payload: error,
    type: 'FETCH_ACCOUNTS_FAILURE'
  };
}

export function fetchStoresForAccountRequest(accountId: string): AnyAction {
  return {
    payload: {
      accountId
    },
    type: 'FETCH_STORES_FOR_ACCOUNT_REQUEST'
  };
}

export function fetchStoresForAccountSuccess(): AnyAction {
  return {
    type: 'FETCH_STORES_FOR_ACCOUNT_SUCCESS'
  };
}

export function fetchStoresForAccountFailure(error: string): AnyAction {
  return {
    payload: error,
    type: 'FETCH_STORES_FOR_ACCOUNT_FAILURE'
  };
}

export function fetchAccountInfoRequest(data: {
  accountId: string;
}): AnyAction {
  return {
    payload: data,
    type: 'FETCH_ACCOUNT_INFO_REQUEST'
  };
}

export function fetchAccountInfoSuccess(): AnyAction {
  return {
    type: 'FETCH_ACCOUNT_INFO_SUCCESS'
  };
}

export function fetchAccountInfoFailure(): AnyAction {
  return {
    type: 'FETCH_ACCOUNT_INFO_FAILURE'
  };
}

export function createStoreForAccount(
  accountId: string,
  storeId?: string,
  createStore = false
): AnyAction {
  return {
    payload: {
      useBasicTemplate: true,
      createStore: createStore,
      accountId,
      storeId
    },
    type: 'CREATE_APPLICATION_V2_REQUEST'
  };
}
