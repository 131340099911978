import { AnyAction } from 'redux';

import { call, put } from 'redux-saga/effects';

import * as api from 'rest/v2/analytics';

export function* trackAnalytics(action: AnyAction) {
  try {
    const type = action.payload.type;
    const params = action.payload.params;
    const storageKeys = (action.payload.params || []).map(
      event => `${type}${event.eventName ? '_' : ''}${event.eventName || ''}`
    );
    const oneTimeUsage = action.payload.oneTimeTrack || false;

    const storageName = 'analytics-events';

    const events = JSON.parse(sessionStorage.getItem(storageName) || '{}');

    if (oneTimeUsage && storageKeys.find(storageKey => events[storageKey])) {
      return;
    }

    if (oneTimeUsage) {
      sessionStorage.setItem(
        storageName,
        JSON.stringify({
          ...events,
          ...storageKeys.reduce(
            (previousValue, storageKey) => ({
              ...previousValue,
              [storageKey]: true
            }),
            {}
          )
        })
      );
    }

    yield call(api.trackAnalytics, type, params);
  } catch (error) {
    yield put({
      error: error.message,
      type: 'TRACK_ANALYTICS_FAILURE'
    });
  }
}
