import axios, { HttpStatusCode } from 'axios';
import { toast } from 'react-toastify';

import { redirectToSso } from 'helpers/sso';

axios.interceptors.request.use(config => {
  config.headers['browser-request'] = true;
  config.headers['content-type'] = 'application/json';
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === HttpStatusCode.Unauthorized) {
      redirectToSso();
      // close all future notifications to not confuse the customer
      toast.onChange(event => toast.dismiss(event.id));
    }

    return Promise.reject(error);
  }
);
