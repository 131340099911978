import { toast } from 'react-toastify';
import { call, put, select } from 'redux-saga/effects';

import { Store } from 'reducers/types';
import { OrderType } from 'enums/order/type';

import * as api from 'rest/v2/order';

import { State as EnvironmentReducer } from 'reducers/environment/types';

const getApplicationId = (state: Store) => state.application.id;

const getOrder = (state: Store) => state.order;

const getOrderId = (state: Store) => state.order.id;

const getEnvironment = (state: Store): EnvironmentReducer => state.environment;

export function* createOrder() {
  try {
    const order = yield select(getOrder);
    const applicationId: string = yield select(getApplicationId);
    const environment: EnvironmentReducer = yield select(getEnvironment);

    yield put({
      payload: true,
      type: 'SAVE_ORDER_IN_PROGRESS'
    });

    const createdOrder = yield call(
      api.createOrder,
      order,
      environment,
      applicationId
    );

    yield put({
      payload: createdOrder.orderId,
      type: 'SAVE_ORDER_ID'
    });

    yield put({
      payload: true,
      type: 'SAVE_ORDER_SUBMITTED'
    });

    yield put({
      payload: false,
      type: 'SAVE_ORDER_IN_PROGRESS'
    });

    yield put({
      type: 'CREATE_ORDER_SUCCESS'
    });

    yield put({
      type: 'SUBMIT_APPLICATION_REQUEST'
    });
  } catch (error) {
    yield call(toast.error, error.message);
    yield put({
      error: error.message,
      type: 'CREATE_ORDER_FAILURE'
    });
    yield put({
      payload: false,
      type: 'SAVE_ORDER_IN_PROGRESS'
    });
  }
}

export function* getOrderTypeById() {
  try {
    const applicationId = yield select(getApplicationId);
    const orderId = yield select(getOrderId);
    const response = yield call(api.getOrder, applicationId, orderId);

    let orderType;
    let terminalProfile;

    if (
      response &&
      response.order &&
      response.order.terminalProfile &&
      response.order.terminalProfile.length
    ) {
      terminalProfile = response.order.terminalProfile[0];
    }

    if (
      terminalProfile &&
      terminalProfile.name === 'NO_DEVICE_OPTION' &&
      terminalProfile.frequency === 'M'
    ) {
      orderType = OrderType.NO_DEVICE;
    }

    if (
      terminalProfile &&
      (terminalProfile.name === 'Smart Terminal' ||
        terminalProfile.name === 'Poynt 5') &&
      terminalProfile.frequency === 'M'
    ) {
      orderType = OrderType.RENT;
    }

    if (
      terminalProfile &&
      (terminalProfile.name === 'Smart Terminal' ||
        terminalProfile.name === 'Poynt 5') &&
      !terminalProfile.frequency
    ) {
      orderType = OrderType.BUY;
    }

    if (orderType) {
      yield put({
        payload: orderType,
        type: 'SAVE_ORDER_TYPE'
      });
    }

    yield put({
      type: 'GET_ORDER_TYPE_SUCCESS'
    });
  } catch (error) {
    yield call(toast.error, error.message);
    yield put({
      error: error.message,
      type: 'GET_ORDER_TYPE_FAILURE'
    });
  }
}
