import axios from 'axios';

import { State as EnvironmentReducer } from 'reducers/environment/types';

import { OrderType } from 'enums/order/type';

import { State as Order } from 'reducers/order/types';

import { Payload, Profile } from './types';

export const createOrder = (
  order: Order,
  environment: EnvironmentReducer,
  applicationId: string
): Promise<Payload> => {
  const description =
    order.type === OrderType.RENT
      ? 'A monthly rental for'
      : 'Terminal purchase of';

  const profiles: Profile[] = [];

  if (order.quantity.NO_DEVICE) {
    profiles.push({
      amount: (environment.plans.noDevice.perUnit || 0) * 100,
      description: 'Ecommerce Only (Poynt HQ)',
      frequency: 'M',
      name: 'NO_DEVICE_OPTION',
      quantity: order.quantity.NO_DEVICE
    });
  }

  if (!order.quantity.NO_DEVICE && order.quantity.N910) {
    profiles.push({
      amount:
        order.type === OrderType.BUY
          ? (environment.buyN910Amount || 0) * 100
          : (environment.plans.n910.perUnit || 0) * 100,
      description: `${description} Newland n910 Terminal`,
      frequency: order.type === OrderType.RENT ? 'M' : null,
      name: 'Newland n910 Terminal',
      quantity: order.quantity.N910
    });
  }

  if (!order.quantity.NO_DEVICE && order.quantity.PoyntC) {
    profiles.push({
      amount:
        order.type === OrderType.BUY
          ? (environment.buyPoyntCAmount || 0) * 100
          : (environment.plans.poyntC.perUnit || 0) * 100,
      description: `${description} Poynt C`,
      frequency: order.type === OrderType.RENT ? 'M' : null,
      name: 'Poynt C',
      quantity: order.quantity.PoyntC
    });
  }

  if (!order.quantity.NO_DEVICE && order.quantity.SunmiT2S1) {
    profiles.push({
      amount:
        order.type === OrderType.BUY
          ? (environment.eHopperBundles?.buySunmiT2S1Amount || 0) * 100
          : (environment.plans.sunmiT2S1.perUnit || 0) * 100,
      description: `${description} Sunmi T2s 1 screen`,
      frequency: order.type === OrderType.RENT ? 'M' : null,
      name: 'Sunmi T2s 1 screen',
      quantity: order.quantity.SunmiT2S1
    });
  }

  if (!order.quantity.NO_DEVICE && order.quantity.SunmiT2S2) {
    profiles.push({
      amount:
        order.type === OrderType.BUY
          ? (environment.eHopperBundles?.buySunmiT2S2Amount || 0) * 100
          : (environment.plans.sunmiT2S2.perUnit || 0) * 100,
      description: `${description} Sunmi T2s 2 screens`,
      frequency: order.type === OrderType.RENT ? 'M' : null,
      name: 'Sunmi T2s 2 screens',
      quantity: order.quantity.SunmiT2S2
    });
  }

  if (!order.quantity.NO_DEVICE && order.quantity.license) {
    profiles.push({
      amount: (environment.eHopperBundles?.buyLicenseAmount || 0) * 100,
      description: 'eHopper Software Subscription',
      frequency: 'M',
      name: `SOFTWARE_${environment.eHopperSolution}`,
      quantity: order.quantity.license
    });
  }

  return axios
    .request({
      data: {
        address: order.address,
        feeProfiles: profiles,
        profiles
      },
      method: 'post',
      url: `/api/v2/applications/${applicationId}/orders`
    })
    .then(response => response.data);
};

export const getOrder = (
  applicationId: string,
  orderId: string
): Promise<Payload> => {
  return axios
    .request({
      method: 'get',
      url: `/api/v2/applications/${applicationId}/orders/${orderId}`
    })
    .then(response => response.data);
};
