import { AnyAction } from 'redux';

import { GovIdType } from 'enums/gov-id/type';
import { LocaleType } from 'enums/applicant/locale-type';
import { BusinessType } from 'enums/business/type';

import Environments from 'helpers/environments';

import { parseAddress } from 'helpers/parsers';

import { State } from './types';

const INITIAL_STATE: State = {
  dob: '',
  sin: '',
  email: {
    emailAddress: ''
  },
  name: {
    firstName: '',
    lastName: ''
  },
  phone: {
    localPhoneNumber: ''
  },
  terms: {
    signed: false,
    version: Environments.get<string>('terms.version')
  },
  governmentIdentification: {
    type: BusinessType.INDIVIDUAL,
    value: '',
    sourceType: GovIdType.SSN4
  },
  address: {
    city: '',
    countryCode: '',
    line1: '',
    line2: '',
    state: '',
    territory: '',
    zip: '',
    website: ''
  }
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_APPLICANT_DEFAULT_STATE':
      return {
        ...state,
        ...action.payload
      };
    case 'SAVE_APPLICANT_NAME':
      return {
        ...state,
        name: action.payload
      };
    case 'SAVE_APPLICANT_EMAIL':
      return {
        ...state,
        email: {
          ...state.email,
          emailAddress: action.payload
        }
      };
    case 'SAVE_APPLICANT_PHONE':
      return {
        ...state,
        phone: {
          ...state.phone,
          localPhoneNumber: action.payload
        }
      };
    case 'SAVE_APPLICANT_TERMS':
      return {
        ...state,
        terms: {
          ...state.terms,
          signed: action.payload,
          signedAt: new Date()
        }
      };
    case 'SAVE_APPLICANT_LEAD_ID':
      return {
        ...state,
        leadId: action.payload
      };
    case 'SAVE_APPLICANT_USER_ID':
      return {
        ...state,
        userId: action.payload
      };
    case 'SAVE_APPLICANT_ID':
      return {
        ...state,
        id: action.payload
      };
    case 'SAVE_APPLICANT_EMAIL_ID':
      return {
        ...state,
        email: {
          ...state.email,
          id: action.payload
        }
      };
    case 'SAVE_APPLICANT_PHONE_ID':
      return {
        ...state,
        phone: {
          ...state.phone,
          id: action.payload
        }
      };
    case 'SAVE_APPLICANT_TERMS_ID':
      return {
        ...state,
        terms: {
          ...state.terms,
          id: action.payload
        }
      };
    case 'SAVE_APPLICANT_SUBMITTED':
      return {
        ...state,
        submitted: action.payload
      };
    case 'SAVE_APPLICANT_IDENTIFICATION':
      return {
        ...state,
        governmentIdentification: {
          id: state.governmentIdentification.id,
          ...action.payload
        }
      };
    case 'SAVE_APPLICANT_GOVERNMENT_ID':
      return {
        ...state,
        governmentIdentification: {
          ...state.governmentIdentification,
          id: action.payload
        }
      };
    case 'SAVE_APPLICANT_ADDRESS':
      return {
        ...state,
        address: parseAddress(action.payload)
      };
    case 'SAVE_APPLICANT_DOB':
      return {
        ...state,
        dob: action.payload
      };
    case 'SAVE_APPLICANT_SIN':
      return {
        ...state,
        sin: action.payload
      };
    case 'SAVE_APPLICANT_COMMUNICATION_LANGUAGE':
      return {
        ...state,
        communicationLanguage: LocaleType[action.payload] || LocaleType['en-US']
      };
    case 'SAVE_PERSONA_INQUIRY_ID':
      return {
        ...state,
        personaInquiryId: action.payload
      };
    case 'SAVE_PERSONA_SESSION_TOKEN':
      return {
        ...state,
        personaSessionToken: action.payload
      };
    default:
      return state;
  }
}
