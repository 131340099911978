import { UnknownAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import * as apiV2Otp from 'rest/v2/application/otp';

import {
  changeOTPAttempt,
  changeOTPState,
  createOTPCodeFailure,
  createOTPCodeRequest,
  createOTPCodeSuccess,
  saveOTPError,
  verifyOTPCodeFailure,
  verifyOTPCodeSuccess
} from 'actions/otp';
import { updateApplicationRequest } from 'actions/application';

import { StepUpActionType } from 'enums/application/step-up';

import {
  trackError,
  trackOtpAuthenticationFailed,
  trackOtpAuthenticationNoOfAttempts
} from 'helpers/analytics/track';

import * as toastHelpers from 'helpers/toast';
import * as uxCoreHelper from 'helpers/ux-core';

import { State as EnvironmentReducer } from 'reducers/environment/types';
import { State as CompanyReducer } from 'reducers/company/types';
import { State as ApplicationReducer } from 'reducers/application/types';

import { Store } from 'reducers/types';
import { OtpPayload } from 'rest/v2/application/otp/types';
import { State as AccountsReducer } from 'reducers/account/types';

const getEnvironment = (state: Store) => state.environment;
const getCompany = (state: Store) => state.company;
const getApplication = (state: Store) => state.application;
const getAccounts = (state: Store) => state.accounts;

export const getStepUp = (state: Store) =>
  state.application.stepUpDetails?.[0] || StepUpActionType.OTP_SMS;
export const getApplicationId = (state: Store) => state.application.id;

export function* createOTP() {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company: CompanyReducer = yield select(getCompany);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    const applicationId = yield select(getApplicationId);

    const otp: OtpPayload = yield call(apiV2Otp.createOTP, applicationId);

    yield put(createOTPCodeSuccess());
    yield put(changeOTPAttempt(otp.attempts || 1));

    trackOtpAuthenticationNoOfAttempts(otp.attempts || 1);
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(trackError, message);
        toastHelpers.toast(message, isUxCore);
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;
      toastHelpers.toast(message, isUxCore);
      yield call(trackError, message);
    }

    yield put(createOTPCodeFailure());
  }
}

export function* verifyOTP(action: UnknownAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company: CompanyReducer = yield select(getCompany);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    const stepUp: StepUpActionType = yield select(getStepUp);

    yield put(updateApplicationRequest(stepUp, action.payload));
    yield put(changeOTPState(true));
    yield put(verifyOTPCodeSuccess());
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(trackError, message);
        toastHelpers.toast(message, isUxCore);
      }

      yield put(verifyOTPCodeFailure(error?.response?.data?.errors.join(',')));
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;
      toastHelpers.toast(message, isUxCore);
      yield call(trackError, message);
      yield put(verifyOTPCodeFailure(message));
    }

    yield put(saveOTPError(true));
    yield put(createOTPCodeRequest());

    yield call(trackOtpAuthenticationFailed);
  }
}
