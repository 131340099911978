import { AnyAction } from 'redux';

import { Category } from 'reducers/categories/types';

export function getCategoriesList(): AnyAction {
  return {
    type: 'GET_CATEGORIES_REQUEST'
  };
}

export function saveCategoriesList(categories: Category[]): AnyAction {
  return {
    type: 'SAVE_BUSINESS_CATEGORIES',
    payload: categories
  };
}
