import validator from 'validator';

import { LocaleType } from 'enums/applicant/locale-type';

import i18n from '../../../../i18n';

import { ValidatorType } from '../types';

export const maxTelephoneNumberLength = 10;

export const isTelephoneNumber = (value: ValidatorType): boolean => {
  const phoneNumber = validator.blacklist(value || '', '- ()');

  const locale =
    (LocaleType[i18n.language] === LocaleType['fr-CA']
      ? LocaleType['en-CA']
      : LocaleType[i18n.language]) || LocaleType['en-US'];

  return (
    validator.isMobilePhone(phoneNumber, locale) &&
    validator.isNumeric(phoneNumber) &&
    validator.isLength(phoneNumber, { min: 10, max: 10 })
  );
};

export const isTelephoneNumberOrEmpty = (value: ValidatorType): boolean => {
  if (!value) {
    return true;
  }

  return isTelephoneNumber(value);
};
