import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  account: {},
  creditCard: {
    token: ''
  },
  financialInstruments: {},
  plaidAccount: {}
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_MERCHANT_ACCOUNT':
      return {
        ...state,
        account: action.payload,
        creditCard: {
          token: ''
        },
        plaidAccount: {}
      };
    case 'SAVE_CREDIT_CARD':
      return {
        ...state,
        creditCard: action.payload
      };
    case 'SAVE_CREDIT_CARD_VERIFY':
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          verify: action.payload
        }
      };
    case 'CREATE_CREDIT_CARD_FAILURE':
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          error: action.error
        }
      };
    case 'SAVE_MERCHANT_PLAID_ACCOUNT':
      return {
        ...state,
        account: {},
        plaidAccount: action.payload
      };
    case 'SAVE_PLAID_BALANCE':
      return {
        ...state,
        plaidAccount: {
          ...state.plaidAccount,
          availableBalance: action.payload
        }
      };
    case 'SAVE_PLAID_INSTANT_AUTH':
      return {
        ...state,
        plaidAccount: {
          ...state.plaidAccount,
          instantAuth: action.payload
        }
      };
    case 'SAVE_CREDIT_CARD_ID':
      return {
        ...state,
        financialInstruments: {
          ...state.financialInstruments,
          cardId: action.payload
        }
      };
    case 'SAVE_PLAID_ACCOUNT_ID':
      return {
        ...state,
        financialInstruments: {
          ...state.financialInstruments,
          plaidAccountId: action.payload
        }
      };
    case 'SAVE_BANK_ACCOUNT_ID':
      return {
        ...state,
        financialInstruments: {
          ...state.financialInstruments,
          bankAccountId: action.payload
        }
      };
    case 'SAVE_BANK_SUBMITTED':
      return {
        ...state,
        submitted: action.payload
      };
    default:
      return state;
  }
}
