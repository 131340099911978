import RCTooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';

import { ValidatorComponent } from 'react-form-validator-core';

import { Design } from 'enums/design/design-type';

import { Tooltip } from 'components/elements';

import { Props, State } from './Select.types';

import Styles from './Select.module.scss';

import 'rc-tooltip/assets/bootstrap_white.css';

class Select extends ValidatorComponent<Props, State> {
  public onKeyDown(event) {
    if (event.shiftKey && event.key === 'Tab') {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index - 1].focus();
      event.preventDefault();
    }
  }
  public renderValidatorComponent() {
    const {
      design,
      isEhopper,
      errorMessages,
      options,
      placeholder,
      onSelect,
      validatorListener,
      overlayClassName,
      subPlaceholder,
      tooltipPosition,
      t,
      tReady,
      tooltipText,
      tooltipTitle,
      increaseFont,
      linkTitle,
      optionallabel,
      subLabel,
      required,
      rcErrorTooltipDefault,
      ...rest
    } = this.props;

    const showOptional =
      this.props.optionallabel !== undefined ? this.props.optionallabel : true;

    return (
      <div
        className={[
          Styles.container,
          increaseFont ? Styles.increaseFont : '',
          isEhopper ? Styles.ehopperContainer : '',
          isEhopper && this.props.disabled ? Styles.ehopperDisabled : '',
          isEhopper && !this.state.isValid ? Styles.ehopperError : '',
          isEhopper && this.props.value ? Styles.ehopperDirty : '',
          isEhopper && this.state.isFocus ? Styles.ehopperFocus : '',
          design === Design.NEW_DESIGN && Styles.newDesign
        ].join(' ')}>
        {this.props.label && (
          <label
            htmlFor={this.props.id}
            className={!this.state.isValid ? Styles.error : ''}>
            {this.props.label}
            {(this.props.validators || []).includes('required') ? (
              <span className={Styles.required}>*</span>
            ) : showOptional ? (
              <span className={Styles.optional}>
                &nbsp;- {t('components.form.select.optional')}
              </span>
            ) : null}
            {this.props.tooltipText ? (
              <Tooltip
                title={this.props.tooltipTitle}
                classNames={Styles.informationTooltip}
                content={this.props.tooltipText}
                link={this.props.link}
                linkTitle={linkTitle}
              />
            ) : null}
          </label>
        )}
        <RCTooltip
          placement={this.props.tooltipPosition || 'bottomRight'}
          visible={!this.state.isValid}
          overlayClassName={
            overlayClassName ? overlayClassName : Styles.overlay
          }
          overlay={
            this.props.rcErrorTooltipDefault ? (
              <></>
            ) : (
              <span className={Styles.rcTooltipInner}>{this.getMessage()}</span>
            )
          }>
          <>
            <select
              onFocus={() => this.setFocus(true)}
              onBlur={() => this.setFocus(false)}
              onKeyDown={this.onKeyDown.bind(this)}
              className={[
                this.props.className,
                !this.state.isValid ? Styles.error : '',
                !this.props.value ? Styles.empty : ''
              ].join(' ')}
              onChange={onSelect}
              disabled={this.props.disabled}
              {...rest}>
              {!required && (
                <option value="">
                  {t('components.form.select.defaultOption')}
                </option>
              )}
              {options.map(state => (
                <option key={state.value} value={state.value}>
                  {state.label}
                </option>
              ))}
            </select>
            <p
              className={[
                Styles.subLabel,
                this.props.rcErrorTooltipDefault && !this.state.isValid
                  ? Styles.subLabelError
                  : '',
                !this.props.rcErrorTooltipDefault && !subLabel
                  ? Styles.subLabelHidden
                  : ''
              ].join(' ')}>
              {(this.props.rcErrorTooltipDefault ? this.state.isValid : true)
                ? subLabel
                : this.getMessage()}
            </p>
          </>
        </RCTooltip>
      </div>
    );
  }

  private getMessage(): string {
    const message = this.state.errorMessages;
    return this.invalid.length > 0 && message[this.invalid[0]];
  }

  private setFocus(value: boolean): void {
    this.setState({ isFocus: value });
  }
}

export default withTranslation()(Select);
