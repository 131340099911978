import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import * as applicationActions from 'actions/application';

import { ApplicationLevel } from 'enums/application/application-level';
import { ApplicationIntent } from 'enums/application/intent';
import { PaymentMethodType } from 'enums/order/payment';
import { BeneficialOwnersStatus } from 'enums/application/beneficial-owners-status';

import * as uxCoreHelper from 'helpers/ux-core';
import * as historyHelper from 'helpers/history';
import * as analyticsHelper from 'helpers/analytics/track';
import * as toastHelpers from 'helpers/toast';

import * as api from 'rest/v2/financial-instruments';

import { Name } from 'reducers/applicant/types';
import { Store } from 'reducers/types';
import { Account } from 'reducers/bank/types';
import { State as Environment } from 'reducers/environment/types';
import { State as EnvironmentReducer } from 'reducers/environment/types';

import {
  createBankAccountFailure,
  createBankAccountSuccess,
  createPlaidAccountFailure,
  createPlaidAccountSuccess,
  saveBankAccountId,
  saveBankSubmitted,
  savePlaidAccountId,
  savePlaidBalance,
  savePlaidInstantAuth,
  saveCreditCard
} from 'actions/bank';
import { State as AccountsReducer } from 'reducers/account/types';

const getApplication = (state: Store) => state.application;

const getApplicationId = (state: Store) => state.application.id;

const getPlaidAccount = (state: Store) => state.bank.plaidAccount;

const getBankAccount = (state: Store) => state.bank.account;

const getApplicantName = (state: Store) => state.applicant.name;

const getEnvironment = (state: Store) => state.environment;

const getCreditCardToken = (state: Store) => state.bank.creditCard.token;

const getCompany = (state: Store) => state.company;

const getAccounts = (state: Store) => state.accounts;

const getParentApplication = (state: Store) =>
  state.application.parentApplication;

const errorMessage =
  'Sorry, something went wrong. Please try again. If you have any questions, please contact support.';

export function* createPlaidAccount() {
  const application = yield select(getApplication);
  const company = yield select(getCompany);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const accounts: AccountsReducer = yield select(getAccounts);
  let isKycKybFlow = false;
  let referralUrlIdPath;

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    yield call(analyticsHelper.trackPlaidStarted);

    const account = yield select(getPlaidAccount);
    const parentApplication = yield select(getParentApplication);

    isKycKybFlow =
      application.originalApplicationLevel === ApplicationLevel.BASIC &&
      application.applicationLevel === ApplicationLevel.FULL;

    referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    const isStore = application?.intent === ApplicationIntent.ADD_STORE;

    const financialInstrument = yield call(
      api.savePlaidBankAccount,
      account,
      isStore ? parentApplication?.applicationId : application.id
    );

    yield put(saveBankSubmitted(true));
    yield put(createPlaidAccountSuccess());
    yield put(savePlaidAccountId(financialInstrument.financialInstrumentId));
    yield put(savePlaidBalance(financialInstrument.availableBalance));
    yield put(savePlaidInstantAuth(financialInstrument.instantAuth));
    yield call(analyticsHelper.trackPlaidFinished);

    if (application.boStatus !== BeneficialOwnersStatus.NONE) {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/owners/bank`
      );
      return;
    }

    if (environment.portal === 'POYNT') {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/connected`
      );
      return;
    }

    if (isKycKybFlow) {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/${isUxCore ? 'bank/success' : 'set-up'}`
      );
      return;
    }

    if (environment.cobrand.standaloneFlowEnable) {
      historyHelper.changeLocation(environment.godaddy.paymentsHubPage);
      return;
    } else if (environment.redirectUrl) {
      yield put(applicationActions.redirectApplicationRequest(true));
    } else {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/complete`
      );
    }
  } catch (error) {
    if (application) {
      const url = !isKycKybFlow ? `complete` : `bank`;
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/${url}`
      );
    }

    yield call(analyticsHelper.trackError, errorMessage);
    toastHelpers.toast(errorMessage, isUxCore);
    yield put(createPlaidAccountFailure(error.message));
  }
}

export function* createBankAccount() {
  const application = yield select(getApplication);
  const company = yield select(getCompany);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const accounts: AccountsReducer = yield select(getAccounts);
  let isKycKybFlow = false;
  let referralUrlIdPath;

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    yield call(analyticsHelper.trackManualBankAccountStarted);

    const account: Account = yield select(getBankAccount);
    const applicantName: Name = yield select(getApplicantName);
    const parentApplication = yield select(getParentApplication);

    referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    isKycKybFlow =
      application.originalApplicationLevel === ApplicationLevel.BASIC &&
      application.applicationLevel === ApplicationLevel.FULL;

    const isStore = application?.intent === ApplicationIntent.ADD_STORE;
    const finalApplicantName = isStore
      ? {
          firstName: parentApplication?.applicant?.firstName,
          lastName: parentApplication?.applicant?.lastName
        }
      : applicantName;

    const financialInstrument = yield call(
      api.saveManualBankAccount,
      account,
      finalApplicantName,
      (isStore ? parentApplication?.applicationId : application.id) as string
    );

    yield put(saveBankSubmitted(true));
    yield put(createBankAccountSuccess());
    yield put(saveBankAccountId(financialInstrument.financialInstrumentId));
    yield call(analyticsHelper.trackManualBankAccountFinished);

    if (application.boStatus !== BeneficialOwnersStatus.NONE) {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/owners/bank`
      );
      return;
    }

    if (environment.portal === 'POYNT') {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/connected`
      );
      return;
    }

    if (isKycKybFlow) {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/${isUxCore ? 'bank/success' : 'set-up'}`
      );
      return;
    }

    if (isUxCore) {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/complete/bank/success`
      );
      return;
    }

    if (environment.cobrand.standaloneFlowEnable) {
      historyHelper.changeLocation(environment.godaddy.paymentsHubPage);
      return;
    } else if (environment.redirectUrl) {
      yield put(applicationActions.redirectApplicationRequest(true));
    } else {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/complete`
      );
    }
  } catch (error) {
    if (application) {
      const url = !isKycKybFlow ? `complete` : `bank`;
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${application.id}/${url}`
      );
    }

    yield call(analyticsHelper.trackError, errorMessage);
    toastHelpers.toast(errorMessage, isUxCore);
    yield put(createBankAccountFailure(error.message));
  }
}

export function* createCreditCard(action: AnyAction) {
  const redirect = action?.payload?.redirect;

  try {
    const applicationId = yield select(getApplicationId);
    const creditCardToken = yield select(getCreditCardToken);
    const environment: Environment = yield select(getEnvironment);

    yield put({
      payload: false,
      type: 'SAVE_CREDIT_CARD_VERIFY'
    });

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    const financialInstrument = yield call(
      api.saveCreditCard,
      creditCardToken,
      applicationId
    );

    yield put({
      type: 'CREATE_CREDIT_CARD_SUCCESS'
    });

    yield put({
      payload: financialInstrument.financial_instrument_id,
      type: 'SAVE_CREDIT_CARD_ID'
    });

    yield put({
      payload: true,
      type: 'SAVE_CREDIT_CARD_VERIFY'
    });

    yield put({
      payload: PaymentMethodType.CARD,
      type: 'SAVE_ORDER_PAYMENT_METHOD'
    });

    if (redirect) {
      yield call(
        historyHelper.redirectTo,
        `${referralUrlIdPath}/${applicationId}/order`
      );
    }
  } catch (error) {
    const errorMessage =
      error?.response?.data?.error ||
      error?.response?.data?.message ||
      'Service temporarily unavailable. Please try again later.';

    yield put({
      error: errorMessage,
      type: 'CREATE_CREDIT_CARD_FAILURE'
    });

    yield put({
      payload: '',
      type: 'SAVE_CREDIT_CARD_TOKEN'
    });

    if (!redirect) {
      yield put(saveCreditCard('', '', '', false));
    }

    yield call(toast.error, errorMessage);
  }
}
