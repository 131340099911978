import { AnyAction } from 'redux';
import { all, call, put, select, delay } from 'redux-saga/effects';

import { ApplicantType } from 'enums/applicant/type';
import { StepUpActionType } from 'enums/application/step-up';
import { StepUpOwnerActionType } from 'enums/owner/step-up';
import { InquirySessionStatus } from 'enums/persona/inquiry-session-status';
import { StepUpType } from 'enums/application/step-up-type';

import * as ownersActions from 'actions/owners';
import * as applicationActions from 'actions/application';

import { updateApplicationRequest } from 'actions/application';
import { onboardOwnerRequest } from 'actions/owners';

import { Owner } from 'reducers/owners/types';
import { Store } from 'reducers/types';
import { State as ApplicationReducer } from 'reducers/application/types';
import { State as EnvironmentReducer } from 'reducers/environment/types';
import { State as CompanyReducer } from 'reducers/company/types';
import { State as AccountsReducer } from 'reducers/account/types';

import * as analyticsHelper from 'helpers/analytics/track';
import * as toastHelpers from 'helpers/toast';
import * as uxCoreHelper from 'helpers/ux-core';
import { redirectTo } from 'helpers/history';

import * as apiV1Individuals from 'rest/v1/commerce/onboarding-applications/individuals';
import * as apiV2StepUps from 'rest/v2/application/step-ups';

const getApplication = (state: Store) => state.application;
const getApplicationId = (state: Store) => state.application.id;
const getEnvironment = (state: Store) => state.environment;
const getOwners = (state: Store) => state.owners.owners;
const getOwner = (state: Store) => state.owners.owner;
const getCompany = (state: Store) => state.company;
const getAccounts = (state: Store) => state.accounts;

export function* processBOInfoStepUp(action: AnyAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company: CompanyReducer = yield select(getCompany);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    yield put({
      type: 'SET_PROCESSING_BO_INFO',
      payload: true
    });

    const applicationId: string = yield select(getApplicationId);

    if (!applicationId) {
      throw new Error('Application not found, please reload the page.');
    }

    yield call(createOwners);

    yield put(
      updateApplicationRequest(StepUpActionType.BO_INFO, {
        boStatus: action.payload
      })
    );

    yield put({
      type: 'SET_PROCESSING_BO_INFO',
      payload: false
    });
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
      }
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);
    }

    yield put({
      type: 'SET_PROCESSING_BO_INFO',
      payload: false
    });
  }
}

export function* createOwners() {
  const applicationId: string = yield select(getApplicationId);
  const owners: Owner[] = yield select(getOwners);

  try {
    if (!applicationId) {
      throw new Error('Application not found, please reload the page.');
    }

    yield all(
      owners.map(owner =>
        call(apiV1Individuals.createApplicant, applicationId, owner)
      )
    );

    yield put({
      type: 'CREATE_OWNERS_REQUEST_SUCCESS'
    });
  } catch (error) {
    yield put({
      error: error.message,
      type: 'CREATE_OWNERS_REQUEST_FAILURE'
    });
    throw error;
  }
}

export function* patchOwner(action: AnyAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company: CompanyReducer = yield select(getCompany);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    yield put({
      type: 'SET_OWNER_IS_PATCHING',
      payload: true
    });

    const applicationId: string = yield select(getApplicationId);
    const owner = yield select(getOwner);

    yield call(apiV1Individuals.patchApplicantById, applicationId, owner);

    yield put({
      type: 'SET_OWNER_IS_PATCHING',
      payload: false
    });

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    yield call(
      redirectTo,
      `${referralUrlIdPath}/${applicationId}/owners/${owner.id}/verifying`
    );
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
        yield put(ownersActions.patchOwnerFail(message));
      }
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);
      yield put(ownersActions.patchOwnerFail(userError));
    }

    yield put({
      type: 'SET_OWNER_IS_PATCHING',
      payload: false
    });
  }
}

export function* onboardOwner(action: AnyAction) {
  const applicationId: string = yield select(getApplicationId);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const owner = yield select(getOwner);

  try {
    yield call(
      analyticsHelper.trackOwnerOnboardStart,
      owner,
      action?.payload?.type
    );

    if (!applicationId) {
      yield call(
        analyticsHelper.trackError,
        `Application update Error during getting applicationId in onboarding: ${action?.payload?.type}`
      );

      throw new Error('Application not found, please reload the page.');
    }

    const onboardedOwner = yield call(
      apiV1Individuals.onboardApplicantData,
      applicationId,
      owner.id
    );

    yield put(
      ownersActions.saveOwnerStepUpDetails(onboardedOwner?.stepUpDetails)
    );

    yield put(ownersActions.parseOwnerStepUp());

    yield put(ownersActions.onboardOwnerSuccess());

    yield call(
      analyticsHelper.trackOwnerOnboardSuccess,
      owner,
      action?.payload?.type
    );
  } catch (error) {
    yield call(
      analyticsHelper.trackOwnerOnboardError,
      owner,
      action?.payload?.type
    );

    yield put(ownersActions.onboardOwnerFail(error.message));

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';
    yield call(
      redirectTo,
      `${referralUrlIdPath}/${applicationId}/owners/${owner.id}/error`
    );
  }
}

export function* parseOwnerStepUps(action?: AnyAction) {
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const company: CompanyReducer = yield select(getCompany);
  const application: ApplicationReducer = yield select(getApplication);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  try {
    const application: ApplicationReducer = yield select(getApplication);
    const environment: EnvironmentReducer = yield select(getEnvironment);
    const owner = yield select(getOwner);

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';

    const ownerLink = `${referralUrlIdPath}/${application.id}/owners/${owner.id}`;

    const stepUpAction: StepUpOwnerActionType | undefined =
      owner?.stepUpDetails?.[0];

    if (!stepUpAction) {
      const redirectLink = `${ownerLink}/success`;
      yield call(redirectTo, redirectLink);

      return;
    }

    switch (stepUpAction) {
      case StepUpOwnerActionType.FULL_ONBOARDING:
        yield call(redirectTo, `${ownerLink}`);
        break;

      case StepUpOwnerActionType.GOV_ID_SELFIE:
        yield call(redirectTo, `${ownerLink}/identification`);
        break;

      default:
        throw new Error(`Current step up "${stepUpAction}" does not support`);
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
      }
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);
    }
  }
}

export function* updateOwnerStepUp(action?: AnyAction) {
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const owner = yield select(getOwner);
  const company: CompanyReducer = yield select(getCompany);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  if (!application.id) {
    yield call(
      analyticsHelper.trackError,
      `Application update Error during getting applicationId in step-up: ${action?.payload?.type}`
    );

    throw new Error('Application fetch Error, please reload the page.');
  }

  try {
    const updateResponse = yield call(
      apiV1Individuals.updateApplicantWithStepUpInformation,
      application.id,
      owner.id,
      action?.payload?.type,
      action?.payload?.data
    );

    if (!updateResponse.id) {
      yield call(
        analyticsHelper.trackStepUpError,
        application.id,
        action?.payload?.type,
        environment.isPhoneAuthEnabled,
        owner.id,
        ApplicantType.OWNER
      );

      throw new Error('Applicant update Error, please reload the page.');
    }

    yield call(
      analyticsHelper.trackStepUpSuccess,
      application,
      action?.payload?.type || '',
      environment.isPhoneAuthEnabled,
      owner.id,
      ApplicantType.OWNER
    );

    yield put(ownersActions.updateOwnerStepUpRequestSuccess());
  } catch (error) {
    yield call(
      analyticsHelper.trackStepUpError,
      application.id,
      action?.payload?.type,
      environment.isPhoneAuthEnabled,
      owner.id,
      ApplicantType.OWNER
    );

    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
      }
      yield put(
        ownersActions.updateOwnerStepUpRequestFailure(
          error?.response?.data?.errors.join(',')
        )
      );
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);
      yield put(ownersActions.updateOwnerStepUpRequestFailure(userError));
    }
    throw error;
  }
}

export function* processOwnerIdentification(action: AnyAction) {
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const owner = yield select(getOwner);

  try {
    yield put({ type: 'PROCESS_OWNER_IDENTIFICATION_REQUEST' });

    if (environment.isPersonaWebhooksEnabled) {
      yield call(updateOwnerPersonaStepUpWithLongPolling, action);
    } else {
      yield call(updateOwnerStepUp, action);
    }

    yield put(onboardOwnerRequest(StepUpOwnerActionType.GOV_ID_SELFIE));
    yield put({ type: 'PROCESS_OWNER_IDENTIFICATION_SUCCESS' });
  } catch (error) {
    yield put(ownersActions.patchOwnerFail(error.message));
    yield put({ type: 'PROCESS_OWNER_IDENTIFICATION_FAILURE' });

    const referralUrlIdPath = environment.cobrand.referralUrlId
      ? `/r/${environment.cobrand.referralUrlId}`
      : '';
    yield call(
      redirectTo,
      `${referralUrlIdPath}/${application.id}/owners/${owner.id}/error`
    );
  }
}

export function* updateOwnerPersonaStepUpWithLongPolling(action?: AnyAction) {
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const owner = yield select(getOwner);
  const company: CompanyReducer = yield select(getCompany);
  const accounts: AccountsReducer = yield select(getAccounts);

  const isUxCore = uxCoreHelper.isUxCoreEnabled({
    company,
    accounts,
    environment,
    application
  });

  if (!application.id) {
    yield call(
      analyticsHelper.trackError,
      `Application update Error during getting applicationId in step-up: ${action?.payload?.type}`
    );

    throw new Error('Application fetch Error, please reload the page.');
  }

  try {
    while (true) {
      const updateResponse = yield call(
        apiV2StepUps.updateApplicantWithStepUpInformation,
        application.id,
        owner.id,
        action?.payload?.type,
        { ...action?.payload?.data, applicantId: owner.id }
      );

      if (
        updateResponse.inquirySessionStatus === InquirySessionStatus.RECEIVED ||
        updateResponse.inquirySessionStatus === InquirySessionStatus.TIMEOUT
      ) {
        break;
      }

      if (!updateResponse.applicantId) {
        yield call(
          analyticsHelper.trackStepUpError,
          application.id,
          action?.payload?.type,
          environment.isPhoneAuthEnabled,
          owner.id,
          ApplicantType.OWNER
        );

        throw new Error('Applicant update Error, please reload the page.');
      }

      yield delay(1000);
    }

    yield call(
      analyticsHelper.trackStepUpSuccess,
      application,
      action?.payload?.type || '',
      environment.isPhoneAuthEnabled,
      owner.id,
      ApplicantType.OWNER
    );

    yield put(ownersActions.updateOwnerPersonaStepUpWithLongPollingSuccess());
  } catch (error) {
    yield call(
      analyticsHelper.trackStepUpError,
      application.id,
      action?.payload?.type,
      environment.isPhoneAuthEnabled,
      owner.id,
      ApplicantType.OWNER
    );

    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
        toastHelpers.toast(message, isUxCore);
      }

      yield put(
        ownersActions.updateOwnerPersonaStepUpWithLongPollingFailure(
          error?.response?.data?.errors.join(',')
        )
      );
    } else {
      const { userError, serverError } =
        toastHelpers.parseToastErrorMessage(error);

      toastHelpers.toast(userError, isUxCore);
      yield call(analyticsHelper.trackError, serverError);

      yield put(
        ownersActions.updateOwnerPersonaStepUpWithLongPollingFailure(
          error.message
        )
      );
    }
  }
}

export function* getOwnerInquirySessionTokenById(action?: AnyAction) {
  const applicationId: string = yield select(getApplicationId);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const owner = yield select(getOwner);

  const referralUrlIdPath = environment.cobrand.referralUrlId
    ? `/r/${environment.cobrand.referralUrlId}`
    : '';

  const { inquiryId } = action?.payload || {};

  try {
    const sessionResponse = yield call(
      apiV2StepUps.getPersonaInquirySessionToken,
      action?.payload,
      applicationId,
      StepUpType.identification,
      owner.id
    );

    const { sessionToken, isInquiryCompleted } = sessionResponse;

    if (!!sessionToken && !isInquiryCompleted) {
      yield put(ownersActions.saveOwnerPersonaSessionToken(sessionToken));
    }

    if (!sessionToken && !isInquiryCompleted) {
      yield put(ownersActions.saveOwnerPersonaInquiryId(null));
    }

    yield put(ownersActions.getOwnerInquirySessionTokenByIdSuccess());

    if (isInquiryCompleted) {
      yield put(
        ownersActions.processOwnerIdentification(
          StepUpOwnerActionType.GOV_ID_SELFIE,
          { inquiryId, applicantId: owner.id }
        )
      );
    }
  } catch (error) {
    yield put(ownersActions.getOwnerInquirySessionTokenByIdFailure());

    yield call(
      redirectTo,
      `${referralUrlIdPath}/${applicationId}/owners/${owner.id}/error`
    );
  }
}
