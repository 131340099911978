import { Dispatch } from 'redux';

import { ApplicantType } from 'enums/applicant/type';
import { BusinessType } from 'enums/business/type';
import { MixpanelTrackType } from 'enums/analytics/mixpanel/track-type';
import { MultistepPageType } from 'enums/analytics/multistep-page-type';
import { StepUpActionType } from 'enums/application/step-up';

import { State as ApplicationReducer } from 'reducers/application/types';
import { Owner } from 'reducers/owners/types';

import Mixpanel from 'helpers/analytics/mixpanel/helpers/api';

import { Store } from 'rest/v2/accounts/stores/interfaces';
import { Account } from 'rest/v2/accounts/interfaces';

import { MixpanelTrackArgsInterface } from './types';

export const initTrackData = (
  trackData: MixpanelTrackArgsInterface,
  dispatch: Dispatch
) => Mixpanel.initTrackData(trackData, dispatch);

export const trackSignUpStart = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_START, {
    trackOnce: true,
    applicationId: applicationId || '',
    applicantId: applicantId || '',
    applicantType: applicantType || '',
    url:
      window.location.protocol +
      '://' +
      window.location.hostname +
      window.location.pathname
  });
};

export const trackEditEnterSSN = () =>
  Mixpanel.track(MixpanelTrackType.SIGNUP_ENTER_SSN);

export const trackEditEnterEIN = () =>
  Mixpanel.track(MixpanelTrackType.SIGNUP_ENTER_EIN);

export const trackEditEnterCN = (
  length: number,
  isAlpha: boolean,
  isNumeric: boolean,
  isAlphanumeric: boolean,
  province: string
) =>
  Mixpanel.track(MixpanelTrackType.SIGNUP_ENTER_CN, {
    length,
    isAlpha,
    isNumeric,
    isAlphanumeric,
    province
  });

export const trackViewTOS = () =>
  Mixpanel.track(MixpanelTrackType.SIGNUP_VIEW_TOS);

export const trackError = (error: string) =>
  Mixpanel.track(MixpanelTrackType.SIGNUP_ERROR, {
    error
  });

export const trackResumeApplication = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_RESUME_AN_APPLICATION, {
    trackOnce: true,
    applicationId: applicationId || '',
    applicantId: applicantId || '',
    applicantType: applicantType || ''
  });
};

export const trackResumeApplicationByEmail = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_RESUME_APPLICATION_BY_EMAIL, {
    trackOnce: true,
    applicationId: applicationId || '',
    applicantId: applicantId || '',
    applicantType: applicantType || ''
  });
};

export const trackSignUpApplicationByReminderEmail = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_APPLICATION_BY_REMINDER_EMAIL, {
    trackOnce: true,
    applicationId: applicationId || '',
    applicantId: applicantId || '',
    applicantType: applicantType || ''
  });
};

export const trackPlaidStarted = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_PLAID_STARTED);
};

export const trackPlaidFinished = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_PLAID_FINISHED);
};

export const trackManualBankAccountStarted = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MANUAL_BANK_ACCOUNT_STARTED);
};

export const trackManualBankAccountFinished = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MANUAL_BANK_ACCOUNT_FINISHED);
};

export const trackBankAccountCanceled = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BANK_ACCOUNT_CANCELED);
};

export const trackOtpAuthenticationProcessStarted = (
  application: ApplicationReducer
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_AUTHENTICATION_PROCESS_STARTED, {
    applicationId: application?.id,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel
  });
};

export const trackOtpAuthenticationProcessCancelled = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_AUTHENTICATION_PROCESS_CANCELLED);
};

export const trackOtpResendOtp = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_RESEND_OTP);
};

export const trackOtpCodeEntered = (otpCode: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_CODE_ENTERED, {
    otpCode
  });
};

export const trackOtpAuthenticationNoOfAttempts = (attempt: number) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_NO_OF_ATTEMPTS, {
    attempt
  });
};

export const trackOtpAuthenticationFailed = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_AUTHENTICATION_FAILED);
};

export const trackOnboardStart = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ONBOARD_START, {
    decision: application?.decision,
    currentStepUp: stepUp,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus
  });
};

export const trackOnboardSuccess = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ONBOARD_SUCCESS, {
    decision: application?.decision,
    currentStepUp: stepUp,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus
  });
};

export const trackOnboardError = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ONBOARD_ERROR, {
    decision: application?.decision,
    currentStepUp: stepUp,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus
  });
};

export const trackOtpAuthenticationSuccess = (
  application: ApplicationReducer
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_AUTHENTICATION_SUCCESS, {
    decision: application?.decision,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel
  });
};

export const trackOtpAuthenticationError = (applicationId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OTP_AUTHENTICATION_ERROR, {
    applicationId
  });
};

export const trackPhoneAuthenticationSuccess = (
  application: ApplicationReducer
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_PHONE_AUTHENTICATION_SUCCESS, {
    decision: application?.decision,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel
  });
};

export const trackPhoneAuthenticationError = (applicationId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_PHONE_AUTHENTICATION_ERROR, {
    applicationId
  });
};

export const trackIdAuthenticationStarted = (
  application: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ID_AUTHENTICATION_STARTED, {
    applicationId: application?.id,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel,
    applicantId,
    applicantType
  });
};

export const trackIdAuthenticationCompleted = (
  application?: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ID_AUTHENTICATION_COMPLETED, {
    applicationId: application?.id,
    applicantId,
    applicantType
  });
};

export const trackIdAuthenticationSuccess = (
  application: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ID_AUTHENTICATION_SUCCESS, {
    decision: application?.decision,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel,
    applicantId,
    applicantType
  });
};

export const trackIdAuthenticationError = (
  applicationId: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ID_AUTHENTICATION_ERROR, {
    applicationId,
    applicantId,
    applicantType
  });
};

export const trackFullSsnFormSubmitAttempt = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_FULL_SSN_FORM_SUBMIT_ATTEMPT);
};

export const trackFullSsnFormLanded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_FULL_SSN_FORM_LANDED);
};

export const trackEinVerificationStarted = (
  application: ApplicationReducer
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_VERIFICATION_STARTED, {
    applicationId: application?.id,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel
  });
};

export const trackEinVerificationCompleted = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_VERIFICATION_COMPLETED);
};

export const trackEinVerificationFailed = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_VERIFICATION_FAILED);
};

export const trackEinVerificationSuccess = (
  application: ApplicationReducer
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_VERIFICATION_SUCCESS, {
    decision: application?.decision,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel
  });
};

export const trackEinVerificationError = (applicationId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_VERIFICATION_ERROR, {
    applicationId
  });
};

export const trackBoInfoSuccess = (application: ApplicationReducer) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BO_INFO_SUCCESS, {
    decision: application?.decision,
    applicationId: application?.id,
    stepUpDetails: JSON.stringify(application?.stepUpDetails),
    enableDeposits: application?.enableDeposits,
    applicationStatus: application?.applicationStatus,
    originalApplicationLevel: application?.originalApplicationLevel,
    applicationLevel: application?.applicationLevel
  });
};

export const trackBoInfoError = (applicationId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BO_INFO_ERROR, {
    applicationId
  });
};

export const trackBreatherStart = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BREATHER_START);
};

export const trackBreatherConfirm = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BREATHER_CONFIRM);
};

export const trackAccountSwitcherLoaded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ACCOUNT_SWITCHER_LOADED);
};

export const trackAccountSwitcherApplicationSelected = (account: Account) => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_ACCOUNT_SWITCHER_APPLICATION_SELECTED,
    {
      applicationId: account.applicationId || '',
      riskDecision: account.riskDecision || ''
    }
  );
};

export const trackAccountSwitcherCreateNewApplication = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_ACCOUNT_SWITCHER_CREATE_NEW_APPLICATION
  );
};

export const trackAccountSwitcherApplicationConfirmed = (account: Account) => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_ACCOUNT_SWITCHER_APPLICATION_CONFIRMED,
    {
      applicationId: account.applicationId || '',
      riskDecision: account.riskDecision || ''
    }
  );
};

export const trackStoreSwitcherLoaded = (stores: Store[] = []) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_STORE_SWITCHER_LOADED, {
    stores: stores.length
  });
};

export const trackStoreSwitcherUsedExistingStore = (store: Store) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_STORE_SWITCHER_USED_EXISTING_STORE, {
    storeId: store.id,
    businessId: store.businessId
  });
};

export const trackStoreSwitcherUsedNewStore = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_STORE_SWITCHER_USED_NEW_STORE);
};

export const trackStoreSwitcherNewBusinessCreationShown = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_STORE_SWITCHER_NEW_BUSINESS_CREATION_SHOWN
  );
};

export const trackStoreSwitcherSelectedBusinessCreation = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_STORE_SWITCHER_SELECTED_BUSINESS_CREATION
  );
};

export const trackStoreSwitcherScrollingUsage = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_STORE_SWITCHER_SCROLLING_USAGE, {
    trackOnce: true
  });
};

export const trackStoreSwitcherWentBack = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_STORE_SWITCHER_WENT_BACK);
};

export const trackKycKybPageLoaded = (
  firstTxnDate?: string,
  referrer?: string
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_KYC_KYB_PAGE_LOADED, {
    trackOnce: true,
    firstTxnDate,
    referrer
  });
};

export const trackCreateApplicationPageLoaded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_CREATE_APPLICATION_PAGE_LOADED, {
    trackOnce: true
  });
};

export const trackAutoAttachStore = (data: Record<string, string>) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_AUTO_ATTACH_STORE, data);
};

export const trackStartedFillingApplication = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_STARTED_FILLING_APPLICATION, {
    trackOnce: true
  });
};

export const trackApplicationValidationErrors = (errors: {
  [key: string]: string;
}) => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_APPLICATION_VALIDATION_ERRORS,
    errors
  );
};

export const trackApplicationSubmitAttempt = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_APPLICATION_SUBMIT_ATTEMPT);
};

export const trackEinByEmailSubmitAttempt = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_BY_EMAIL_SUBMIT_ATTEMPT);
};

export const trackEinByEmailFormLanded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_EIN_BY_EMAIL_FORM_LANDED);
};

export const trackBusinessCategorySelect = (label: string, type: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BUSINESS_CATEGORY_SELECTED, {
    label,
    type
  });
};

export const trackBusinessTypeSelect = (businessType: BusinessType) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BUSINESS_TYPE_SELECTED, {
    businessType
  });
};

export const trackBoApplicantErrorPageLoaded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BO_APPLICANT_ERROR_PAGE_LOADED);
};

export const trackAddOwnersPageLoaded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_LOADED);
};

export const trackAddOwnersPageClickAddPartner = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_CLICK_ADD_PARTNER);
};

export const trackAddOwnersPageClickDeletePartner = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_CLICK_DELETE_PARTNER);
};

export const trackAddOwnersPageClickSubmitPartners = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_CLICK_SUBMIT_PARTNERS
  );
};

export const trackAddOwnersPageClickShowSkipPartnersModal = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_CLICK_SHOW_SKIP_PARTNERS_MODAL
  );
};

export const trackAddOwnersPageClickCloseSkipPartnersModal = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_CLICK_CLOSE_SKIP_PARTNERS_MODAL
  );
};

export const trackAddOwnersPageClickSkipPartners = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_ADD_OWNERS_PAGE_CLICK_SKIP_PARTNERS);
};

export const trackOwnerOnboardStart = (owner: Owner, stepUp?: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_ONBOARD_START, {
    currentStepUp: stepUp,
    applicantId: owner?.id,
    stepUpDetails: JSON.stringify(owner?.stepUpDetails)
  });
};

export const trackOwnerOnboardSuccess = (owner: Owner, stepUp?: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_ONBOARD_SUCCESS, {
    currentStepUp: stepUp,
    applicantId: owner?.id,
    stepUpDetails: JSON.stringify(owner?.stepUpDetails)
  });
};

export const trackOwnerOnboardError = (owner: Owner, stepUp?: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_ONBOARD_ERROR, {
    currentStepUp: stepUp,
    applicantId: owner?.id,
    stepUpDetails: JSON.stringify(owner?.stepUpDetails)
  });
};

export const trackOwnerPageLoaded = (ownerId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_PAGE_LOADED, {
    applicantId: ownerId
  });
};

export const trackOwnerPageClickSupport = (ownerId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_PAGE_CLICK_SUPPORT, {
    applicantId: ownerId
  });
};

export const trackOwnerPageClickSubmit = (ownerId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_PAGE_CLICK_SUBMIT, {
    applicantId: ownerId
  });
};

export const trackOwnerSuccessPageLoaded = (ownerId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_SUCCESS_PAGE_LOADED, {
    applicantId: ownerId
  });
};

export const trackOwnerSuccessPageClickCTA = (ownerId: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OWNER_SUCCESS_PAGE_CLICK_CTA, {
    applicantId: ownerId
  });
};

export const trackOverlapCurrencyError = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_OVERLAP_CURRENCY_ERROR);
};

export const trackBusinessInfoFormLanded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BUSINESS_INFO_FORM_LANDED);
};

export const trackBusinessInfoFormSubmitAttempt = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_BUSINESS_INFO_FORM_SUBMIT_ATTEMPT);
};

export const trackPhoneAuthenticationProcessStarted = (
  application: ApplicationReducer
) => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_PHONE_AUTHENTICATION_PROCESS_STARTED,
    {
      applicationId: application?.id,
      originalApplicationLevel: application?.originalApplicationLevel,
      applicationLevel: application?.applicationLevel
    }
  );
};

export const trackPhoneAuthenticationProcessCancelled = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_PHONE_AUTHENTICATION_PROCESS_CANCELLED
  );
};

export const trackPhoneResendLink = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_PHONE_RESEND_LINK);
};

export const trackPhoneAuthenticationFailed = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_PHONE_AUTHENTICATION_FAILED);
};

export const trackMultistepApplicantPageLoaded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_APPLICANT_PAGE_LOADED);
};

export const trackMultistepWizardPageSubmit = (
  page: MultistepPageType,
  current: number,
  remaining: number
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_WIZARD_PAGE_SUBMIT, {
    page,
    current,
    remaining
  });
};

export const trackMultistepWizardPageError = (
  page: MultistepPageType,
  current: number
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_WIZARD_PAGE_ERROR, {
    page,
    current
  });
};

export const trackMultistepStepupVerifyClick = (stepup: StepUpActionType) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_STEPUP_PAGE_VERIFY_CLICK, {
    stepup
  });
};

export const trackMultistepStepupDoThisLaterClick = (
  stepup: StepUpActionType
) => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_MULTISTEP_STEPUP_PAGE_DO_THIS_LATER_CLICK,
    {
      stepup
    }
  );
};

export const trackMultistepAddBankClick = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_ADD_BANK_CLICK);
};

export const trackMultistepDashboardClick = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_DASHBOARD_CLICK);
};

export const trackMultistepCountrySwitcherLoaded = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_COUNTRY_SWITCHER_LOADED);
};

export const trackMultistepCountrySwitcherCanceled = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_MULTISTEP_COUNTRY_SWITCHER_CANCELED);
};

export const trackMultistepCountrySwitcherYesClicked = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_MULTISTEP_COUNTRY_SWITCHER_YES_CLICKED
  );
};

export const trackMultistepCountrySwitcherNoClicked = () => {
  Mixpanel.track(
    MixpanelTrackType.SIGNUP_MULTISTEP_COUNTRY_SWITCHER_NO_CLICKED
  );
};

export const trackSignupIncorrectUrl = () => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_INCORRECT_URL);
};

export const trackCashAdvanceOffersPageLoaded = (businessId?: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_CASH_ADVANCE_OFFERS_PAGE_LOADED, {
    businessId
  });
};

export const trackCashAdvanceOffersLoaded = (
  businessId?: string,
  offersAmount?: number
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_CASH_ADVANCE_OFFERS_LOADED, {
    businessId,
    offersAmount
  });
};

export const trackCashAdvanceOffersAcceptClick = (
  businessId?: string,
  offerId?: string
) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_CASH_ADVANCE_OFFERS_ACCEPT_CLICK, {
    businessId,
    offerId
  });
};

export const trackCashAdvanceOffersBackClick = (businessId?: string) => {
  Mixpanel.track(MixpanelTrackType.SIGNUP_CASH_ADVANCE_OFFERS_BACK_CLICK, {
    businessId
  });
};
