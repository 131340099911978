import { AnyAction } from 'redux';

const getApplicationByIdCache = new Map<string, AnyAction>();

export const getApplicationByIdCacheKey = (action: AnyAction) => {
  return (
    (action?.payload?.applicationId || '') + (action?.payload?.ownerId || '')
  );
};

export const setCacheForGetApplicationById = (
  actionKey: string,
  action: AnyAction
) => {
  getApplicationByIdCache.set(actionKey, action);
};

export const getCacheGetApplicationById = (actionKey: string) => {
  return getApplicationByIdCache.get(actionKey);
};

export const deleteCacheGetApplicationById = (actionKey: string) => {
  getApplicationByIdCache.delete(actionKey);
};
