import axios from 'axios';

import { ApplicationUpdateResponseInterface } from './../interfaces';

import { OtpPayload } from './types';

export const createOTP = async (applicationId: string): Promise<OtpPayload> => {
  const response = await axios.post(
    `/api/v2/applications/${applicationId}/otp-code`
  );

  return response.data;
};

export const verifyOTP = async (
  applicationId: string,
  code: string,
  skip?: string
): Promise<ApplicationUpdateResponseInterface> => {
  const response = await axios.post(
    `/api/v2/applications/${applicationId}/step-ups/otp?skip=${skip}`,
    { code }
  );

  return response.data;
};
