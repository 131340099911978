import { FC } from 'react';

import { Props } from './Title.types';

import Styles from './Title.module.scss';

const Title: FC<Props> = (props: Props) => {
  return (
    <div>
      <h2
        className={[
          Styles.title,
          props.className,
          props.font ? Styles[props.font] : '',
          props.increaseFont ? Styles.increaseFont : ''
        ].join(' ')}
        style={props.fontSize ? { fontSize: props.fontSize } : {}}>
        {props.children}
      </h2>
      {props.subtitle ? (
        <h3 className={Styles.description}>{props.subtitle}</h3>
      ) : null}
    </div>
  );
};

export default Title;
