import { FC, lazy, Suspense, useMemo } from 'react';

import { Loader } from '../index';

import { Props } from './CardIcon.types';

const forms = {
  amex: lazy(() => import('./icons/Amex')),
  americanexpress: lazy(() => import('./icons/Amex')),
  carddefault: lazy(() => import('./icons/CardDefault')),
  guideamex: lazy(() => import('./icons/GuideAmex')),
  dinersclub: lazy(() => import('./icons/DinersClub')),
  discover: lazy(() => import('./icons/Discover')),
  guidecard: lazy(() => import('./icons/GuideCard')),
  guidecode: lazy(() => import('./icons/GuideCode')),
  jcb: lazy(() => import('./icons/Jcb')),
  mastercard: lazy(() => import('./icons/MasterCard')),
  visa: lazy(() => import('./icons/Visa')),
  unionpay: lazy(() => import('./icons/UnionPay'))
};

const CardIcon: FC<Props> = ({
  icon,
  loader = true,
  width = '100',
  height = '65',
  className
}: Props) =>
  useMemo(() => {
    if (!icon) {
      return null;
    }

    const Form =
      forms[icon.toLowerCase().replaceAll(' ', '')] || forms.carddefault;

    return (
      <Suspense
        fallback={
          loader ? <Loader isGodaddy={true} godaddySize="5px" /> : <></>
        }>
        <Form width={width} height={height} className={className} />
      </Suspense>
    );
  }, [icon, width, height]);

export default CardIcon;
