import objectPath from 'object-path';

import { PortalType } from 'enums/portal/type';

import ci from './../../../environments/environment.ci.json';
import local from './../../../environments/environment.json';
import test from './../../../environments/environment.test.json';
import ote from './../../../environments/environment.ote.json';
import us from './../../../environments/environment.us.json';

type Types = string | number | unknown | boolean;

interface Configuration {
  [key: string]: Types;
}

class Environments {
  public configuration: Configuration = {};

  constructor() {
    const buildEnv = import.meta.env.VITE_REACT_APP_ENV || 'local';

    switch (buildEnv.toLowerCase()) {
      case 'ci':
        this.configuration = ci;
        break;
      case 'test':
        this.configuration = test;
        break;
      case 'ote':
        this.configuration = ote;
        break;
      case 'us':
      case 'prod':
      case 'production':
        this.configuration = us;
        break;
      default:
        this.configuration = local;
    }

    this.updateDefaultPortalType();
  }

  public get<T extends Types>(field: string): T {
    return objectPath.get<T>(this.configuration, field, '' as T);
  }

  public update(configuration: Configuration) {
    this.configuration = {
      ...this.configuration,
      ...configuration
    };
    this.updateDefaultPortalType();
  }

  private updateDefaultPortalType() {
    //isGoDaddy will always return false on localhost. Tweak this manually if you need to get godaddy environment on localhost
    const godaddyHost = this.configuration.godaddyCookieHost as string;
    const isGoDaddy = location.host.includes(godaddyHost);
    this.configuration.portal = isGoDaddy
      ? PortalType.GODADDY
      : PortalType.POYNT;
  }
}

export default new Environments();
