import Growl from '@ux/growl';
import { AxiosError, isAxiosError } from 'axios';
import { toast as reactToastifyToast } from 'react-toastify';

import i18n from './../../../i18n';

export const toast = (message: string, isUxCore?: boolean) => {
  if (isUxCore) {
    Growl.addGrowlMessage({
      id: message.replace(/\s/g, ''),
      emphasis: 'critical',
      children: message,
      lifetime: 2000
    });

    return;
  }

  reactToastifyToast.error(message);
};

export const parseToastErrorMessage = (
  error: Error | AxiosError
): { serverError: string; userError: string } => {
  let userError = error?.message;
  let serverError = userError;

  if (isAxiosError(error)) {
    userError =
      error?.response?.data?.details?.[0]?.description ||
      error?.response?.data?.message ||
      error?.response?.data?.error;
    serverError = userError;

    const isUnHandledError =
      userError?.includes('Unhandled error') ||
      userError?.includes('TypeError: ') ||
      userError?.includes('ValidationError: ');

    userError = isUnHandledError
      ? i18n.t('portal.defaultErrorMessage')
      : userError;
  }

  return { serverError, userError };
};
