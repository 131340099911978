import { call, put, retry, select, delay } from 'redux-saga/effects';

import * as channelActions from 'actions/channel';
import * as applicationActions from 'actions/application';

import { ApplicationIntent } from 'enums/application/intent';

import { Store } from 'reducers/types';

import { State as ChannelReducer } from 'reducers/channel/types';
import { State as ApplicationReducer } from 'reducers/application/types';
import { State as EnvironmentReducer } from 'reducers/environment/types';

import * as apiV1 from 'rest/v1/commerce/onboarding-applications';

import { Application } from 'rest/v1/commerce/onboarding-applications/interfaces';

const getEnvironment = (state: Store) => state.environment;
const getApplication = (state: Store) => state.application;
const getChannel = (state: Store) => state.channel;

export function* addChannelAttempt() {
  const application: ApplicationReducer = yield select(getApplication);
  const environment: EnvironmentReducer = yield select(getEnvironment);
  const channel: ChannelReducer = yield select(getChannel);

  const applicationResponse: Application = yield call(
    apiV1.createApplication,
    {
      channel: {
        name: channel.name,
        type: channel.type,
        externalChannelId: channel.externalId,
        externalChannelType: channel.externalType
      },
      metadata: {
        referralUrlId: environment.cobrand.referralUrlId,
        businessId: application.businessId,
        storeId: application.storeId
      },
      intent: ApplicationIntent.ADD_CHANNEL
    },
    true
  );

  if (applicationResponse) {
    yield put(
      channelActions.saveChannelId(applicationResponse.metadata.channelId)
    );
  }
}

export function* createCustomChannel() {
  try {
    const timeForBusinessSetup = 1000;
    yield delay(timeForBusinessSetup);
    yield retry(5, timeForBusinessSetup, addChannelAttempt);
    yield put(channelActions.createChannelSuccess());
    yield put(applicationActions.parseApplicationStepUp());
  } catch (error) {
    yield put(channelActions.createChannelSuccess());
  }
}
