import { CSSProperties, FC, lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';

import LoadingContainer from 'react-redux-loading-bar';

import { ToastContainer } from 'react-toastify';

import { initTracking } from 'helpers/analytics/track';
import Environments from 'helpers/environments';
import history from 'helpers/history';
import store from 'helpers/store';

import 'helpers/axios';
import 'helpers/validators';

import { Overlay } from './components/elements';

import Onboarding from './containers/onboarding';

const PhoneAuth = lazy(() => import('./containers/phone-auth'));
const CashAdvanceOffers = lazy(() => import('./containers/cashadvanceoffers'));

const App: FC = () => {
  const styles: CSSProperties = {
    backgroundColor: '#1E9AF7',
    position: 'fixed',
    zIndex: 120
  };

  return (
    <Provider store={store}>
      <Router history={history}>
        <LoadingContainer style={styles} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ zIndex: styles.zIndex }}
        />
        <Suspense fallback={<Overlay additionalText={false} />}>
          <Switch>
            <Route
              path={['/:id/phone-auth/*', '/r/:referralUrl/:id/phone-auth/*']}
              component={PhoneAuth}
            />
            <Route path="/cashadvanceoffers" component={CashAdvanceOffers} />
            <Route path={['*']} component={Onboarding} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
};

initTracking(Environments.get<string>('godaddy.gtmId'));

export default App;
