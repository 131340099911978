import { SsoCookiesType } from 'enums/cookies/sso-cookies';

import Environments from 'helpers/environments';

import { isGodaddy } from './../godaddy';
import { getGodaddySsoCookie } from './../cookies';

export const redirectToSso = () => {
  const redirect = `${window.location.pathname || ''}${
    window.location.search || ''
  }`;

  const path = redirect && redirect !== '/' ? encodeURIComponent(redirect) : '';

  window.location.replace(
    Environments.get('godaddySsoHost') + (path ? `&path=${path}` : '')
  );
};

export const redirectToSsoAccountCreate = (redirectPath?: string) => {
  const redirect =
    redirectPath ||
    `${window.location.pathname || ''}${window.location.search || ''}`;

  const path = redirect && redirect !== '/' ? encodeURIComponent(redirect) : '';

  window.location.replace(
    Environments.get('godaddy.createAccountUrl') +
      '/?realm=idp&app=signup.payments' +
      (path ? `&path=${path}` : '')
  );
};

export const isSsoRedirection = (): boolean => {
  const isGoDaddy = isGodaddy();
  const ssoCookie = getGodaddySsoCookie(SsoCookiesType.info_idp);
  const isSmsVerify = location.href.includes('/phone-auth/verify');

  const isMobileVerification =
    location.href.includes('/phone-auth/confirmation') &&
    location.href.includes('?vfp=');

  if (isGoDaddy && !ssoCookie && !isMobileVerification && !isSmsVerify) {
    redirectToSso();
    return false;
  }

  return true;
};
