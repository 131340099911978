import { AnyAction } from 'redux';

export function getEnvironment(
  referralUrlId?: string,
  params?: { [key: string]: string }
): AnyAction {
  return {
    payload: {
      params,
      referralUrlId
    },
    type: 'GET_ENVIRONMENT_REQUEST'
  };
}

export function changeEnvironmentProperty(
  propertyName: string,
  value: unknown
): AnyAction {
  return {
    payload: {
      propertyName,
      value
    },
    type: 'CHANGE_ENVIRONMENT_PROPERTY'
  };
}

export function getSaasPlans(businessId?: string): AnyAction {
  return {
    type: 'GET_SAAS_PLANS',
    payload: {
      businessId
    }
  };
}

export function getWebsite(websiteId?: string): AnyAction {
  return {
    payload: {
      websiteId
    },
    type: 'GET_WEBSITE_REQUEST'
  };
}
