import { AnyAction } from 'redux';

import { call, delay, put, select } from 'redux-saga/effects';

import { PhoneAuthStatusType } from 'enums/phone-auth/type';

import * as analyticsHelper from 'helpers/analytics/track';

import * as historyHelper from 'helpers/history';

import * as apiV2PhoneAuth from 'rest/v2/application/phone-auth';

import * as phoneAuthActions from 'actions/phone-auth';

import { Store } from 'reducers/types';
import { State as EnvironmentReducer } from 'reducers/environment/types';
import { State as ApplicationReducer } from 'reducers/application/types';

import {
  PhoneAuthStatus,
  MobileVerificationStatus
} from 'rest/v2/application/phone-auth/types';

const getApplication = (state: Store) => state.application;

const getCreateRedirectUrl = (state: Store) => {
  const application: ApplicationReducer = state.application;
  const environment: EnvironmentReducer = state.environment;

  const referralUrlIdPath = environment.cobrand.referralUrlId
    ? `/r/${environment.cobrand.referralUrlId}`
    : '';
  const baseUrl = `${referralUrlIdPath}/${application.id}`;
  const targetUrl = `${
    location.host === 'localhost:3000'
      ? 'https://local.signup.payments.dev-godaddy.com'
      : location.origin
  }${baseUrl}`;
  const redirectUrl = `${baseUrl}/verifying?step-up=${application?.stepUpDetails?.[0]}`;

  return {
    targetUrl,
    redirectUrl,
    referralUrlIdPath
  };
};

export function* getMobileVerification() {
  try {
    const application: ApplicationReducer = yield select(getApplication);
    const {
      targetUrl,
      redirectUrl,
      referralUrlIdPath
    }: {
      targetUrl: string;
      redirectUrl: string;
      referralUrlIdPath: string;
    } = yield select(getCreateRedirectUrl);

    let vfp = '';
    let validateTargetUrl = '';
    let mobileVerificationStatus: MobileVerificationStatus = yield call(
      apiV2PhoneAuth.getMobileVerificationStatus,
      application.id as string,
      `${targetUrl}/mobile-verification`
    );

    if (mobileVerificationStatus.redirectUrl) {
      validateTargetUrl = mobileVerificationStatus.redirectUrl || '';
      vfp = yield call(apiV2PhoneAuth.validateTargetUrl, validateTargetUrl);
    }

    if (vfp) {
      mobileVerificationStatus = yield call(
        apiV2PhoneAuth.getMobileVerificationStatus,
        application.id as string,
        '',
        vfp
      );
    }

    const finalTargetUrl =
      !vfp ||
      !validateTargetUrl ||
      mobileVerificationStatus.status !== PhoneAuthStatusType.SUCCESS
        ? `${referralUrlIdPath}/${application.id}/phone-auth`
        : redirectUrl;

    yield call(historyHelper.redirectTo, finalTargetUrl);
    yield put(phoneAuthActions.mobileVerificationSuccess());
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;
      yield call(analyticsHelper.trackError, message);
    }

    const application: ApplicationReducer = yield select(getApplication);
    const {
      referralUrlIdPath
    }: {
      referralUrlIdPath: string;
    } = yield select(getCreateRedirectUrl);

    yield put(phoneAuthActions.saveMobileVerificationError(true));
    yield put(phoneAuthActions.mobileVerificationFailure());

    historyHelper.redirectTo(
      `${referralUrlIdPath}/${application.id}/phone-auth`
    );
  }
}

export function* createPhoneAuth(action: AnyAction) {
  try {
    const application: ApplicationReducer = yield select(getApplication);
    const {
      targetUrl,
      redirectUrl
    }: {
      targetUrl: string;
      redirectUrl: string;
      referralUrlIdPath: string;
    } = yield select(getCreateRedirectUrl);

    let { skip, retry } = action.payload;

    let finishLongPooling: boolean;
    let phoneAuthStatus: PhoneAuthStatus;

    do {
      phoneAuthStatus = yield call(
        apiV2PhoneAuth.createPhoneAuth,
        application.id as string,
        `${targetUrl}/phone-auth/confirmation`,
        retry,
        skip
      );

      finishLongPooling = [
        PhoneAuthStatusType.SUCCESS,
        PhoneAuthStatusType.FAILED
      ].includes(phoneAuthStatus?.status);

      if (!finishLongPooling) {
        skip = false;
        retry = false;

        yield delay(1000);
      }

      yield put(phoneAuthActions.phoneAuthSuccess());
    } while (!finishLongPooling && !skip);

    yield call(historyHelper.redirectTo, redirectUrl);

    yield put(phoneAuthActions.phoneAuthSuccess());
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;
      yield call(analyticsHelper.trackError, message);
    }

    const {
      redirectUrl
    }: {
      redirectUrl: string;
    } = yield select(getCreateRedirectUrl);

    yield put(phoneAuthActions.savePhoneAuthError(true));
    yield put(phoneAuthActions.phoneAuthFailure());

    yield call(analyticsHelper.trackPhoneAuthenticationFailed);
    historyHelper.redirectTo(redirectUrl);
  }
}

export function* checkPhoneCode(action: AnyAction) {
  try {
    const vfp: string = action.payload.vfp;
    const applicationId: string = action.payload.applicationId;

    yield call(apiV2PhoneAuth.validateCode, applicationId, vfp);
    yield put(phoneAuthActions.changePhoneAuthState(true));
    yield put(phoneAuthActions.checkPhoneCodeSuccess());
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;
      yield call(analyticsHelper.trackError, message);
    }

    const {
      redirectUrl
    }: {
      redirectUrl: string;
    } = yield select(getCreateRedirectUrl);

    yield put(phoneAuthActions.checkPhoneCodeFailure());

    historyHelper.redirectTo(redirectUrl);
  }
}

export function* verifySmsLink(action: AnyAction) {
  try {
    const applicationId: string = action.payload.applicationId;
    const redirectUrl = yield call(
      apiV2PhoneAuth.getVerificationUrl,
      applicationId
    );
    window.location.href = redirectUrl;
  } catch (error) {
    if (error?.response?.data?.errors) {
      for (const message of error?.response?.data?.errors) {
        yield call(analyticsHelper.trackError, message);
      }
    } else {
      const message =
        error?.response?.data.message ||
        error?.response?.data.error ||
        error.message;
      yield call(analyticsHelper.trackError, message);
    }

    yield put(phoneAuthActions.verifySmsLinkFailure());
    historyHelper.redirectTo(action.payload.errorRedirectPath);
  }
}
