import { toast } from 'react-toastify';

import { call, put } from 'redux-saga/effects';

import * as api from 'rest/v2/cash-advance-offers';
import { AnyAction } from 'redux';

export function* getCashAdvanceOffers(action: AnyAction) {
  try {
    const offers = yield call(
      api.getCashAdvanceOffers,
      action.payload?.businessId
    );

    yield put({
      payload: offers,
      type: 'SAVE_CASH_ADVANCE_OFFERS'
    });

    yield put({
      type: 'GET_CASH_ADVANCE_OFFERS_SUCCESS'
    });
  } catch (error) {
    yield call(toast.error, error.message);
    yield put({
      error: error.message,
      type: 'GET_CASH_ADVANCE_OFFERS_FAILURE'
    });
  }
}
