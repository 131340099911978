import Cookies from 'universal-cookie';

import { Currency } from 'enums/business/currency';
import { LocaleType } from 'enums/applicant/locale-type';
import { SsoCookiesType } from 'enums/cookies/sso-cookies';

export const getGodaddySsoCookie = (
  name: SsoCookiesType
): Record<string, string> => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export const getGodaddyCurrencyCookie = (): Currency | undefined => {
  return getGodaddySsoCookie(SsoCookiesType.info_idp)?.currency as Currency;
};

export const setGodaddyCurrencyCookie = (
  currency: Currency,
  godaddyCookieHost: string
) => {
  const domain = location.hostname.includes(godaddyCookieHost)
    ? `.${godaddyCookieHost}`
    : location.hostname;
  const path = '/';
  const cookies = new Cookies();
  const ssoCookieInfo = getGodaddySsoCookie(SsoCookiesType.info_idp);
  const ssoCookieCustomerInfo = getGodaddySsoCookie(
    SsoCookiesType.info_cust_idp
  );

  [
    { name: 'currency', body: currency },
    { name: 'info_idp', body: { ...ssoCookieInfo, currency } },
    { name: 'info_cust_idp', body: { ...ssoCookieCustomerInfo, currency } }
  ].forEach(({ name, body }) => {
    cookies.remove(name, { domain, path });
    cookies.set(name, body, {
      domain,
      path
    });
  });
};

export const setGodaddyMarketCookie = (
  locale: LocaleType,
  godaddyCookieHost: string
) => {
  const domain = location.hostname.includes(godaddyCookieHost)
    ? `.${godaddyCookieHost}`
    : location.hostname;
  const path = '/';
  const cookies = new Cookies();

  cookies.remove('market', { domain, path });
  cookies.set('market', locale, {
    domain,
    path
  });
};

export const deleteGodaddyCookies = (godaddyCookieHost: string) => {
  const cookies = new Cookies();
  const domain = location.hostname.includes(godaddyCookieHost)
    ? `.${godaddyCookieHost}`
    : location.hostname;
  const path = '/';

  cookies.remove(SsoCookiesType.info_idp, { domain, path });
  cookies.remove(SsoCookiesType.auth_idp, { domain, path });
};
