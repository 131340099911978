import { useSelector } from 'react-redux';
import {
  applyMiddleware,
  compose,
  legacy_createStore,
  Middleware
} from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { loadingBarMiddleware } from 'react-redux-loading-bar';

import { Store } from 'reducers/types';

import rootReducer from './../../reducers';
import rootSaga from './../../sagas';

const sagaMiddleware = createSagaMiddleware();

const store = legacy_createStore(
  rootReducer,
  // @ts-ignore
  {},
  compose(
    applyMiddleware(sagaMiddleware as Middleware<SagaMiddleware>),
    applyMiddleware(
      loadingBarMiddleware({
        promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE']
      })
    )
  )
);

sagaMiddleware.run(rootSaga);

export function useStore<T>(state: (state: Store) => T) {
  return useSelector<Store, T>(state);
}

export default store;
