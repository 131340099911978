import { AnyAction } from 'redux';

export function createOTPCodeRequest(): AnyAction {
  return {
    type: 'CREATE_OTP_V2_REQUEST'
  };
}

export function createOTPCodeSuccess(): AnyAction {
  return {
    type: 'CREATE_OTP_V2_SUCCESS'
  };
}

export function createOTPCodeFailure(): AnyAction {
  return {
    type: 'CREATE_OTP_V2_FAILURE'
  };
}

export function verifyOTPCodeRequest(payload: {
  code: string;
  skip?: boolean;
}): AnyAction {
  return {
    payload,
    type: 'VERIFY_OTP_V2_REQUEST'
  };
}

export function verifyOTPCodeSuccess(code?: string): AnyAction {
  return {
    payload: code,
    type: 'VERIFY_OTP_V2_SUCCESS'
  };
}

export function verifyOTPCodeFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'VERIFY_OTP_V2_FAILURE'
  };
}

export function changeOTPAttempt(attempt: number): AnyAction {
  return {
    payload: attempt,
    type: 'CHANGE_OTP_ATTEMPT'
  };
}

export function resetOTPState(): AnyAction {
  return {
    type: 'RESET_OTP_STATE'
  };
}

export function changeOTPState(verified: boolean): AnyAction {
  return {
    payload: verified,
    type: 'CHANGE_OTP_STATE'
  };
}

export function saveOTPError(hasError: boolean): AnyAction {
  return {
    payload: hasError,
    type: 'SAVE_OTP_ERROR'
  };
}
