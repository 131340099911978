import { Currency } from 'enums/business/currency';
import { LocaleType } from 'enums/applicant/locale-type';
import { CountryType } from 'enums/address/country-type';
import { TerritoryType } from 'enums/address/territory-type';

import { getGodaddyCurrencyCookie } from './../cookies';

import { CountrySetting } from './types';

export const countrySettingsMap: Record<CountryType, CountrySetting> = {
  [CountryType.US]: {
    countryCode: CountryType.US,
    currency: Currency.USD,
    dialingCode: '+1',
    territoryType: TerritoryType.STATE,
    languages: {
      default: LocaleType['en-US'],
      [LocaleType['en-US']]: LocaleType['en-US']
    }
  },
  [CountryType.CA]: {
    currency: Currency.CAD,
    countryCode: CountryType.CA,
    dialingCode: '+1',
    territoryType: TerritoryType.PROVINCE,
    languages: {
      default: LocaleType['en-CA'],
      [LocaleType['en-CA']]: LocaleType['en-CA'],
      [LocaleType['fr-CA']]: LocaleType['fr-CA']
    }
  }
};

export const resolveCurrencyByLanguage = (
  language: string,
  isGodaddy: boolean
): Currency | undefined => {
  if (isGodaddy) {
    return (
      getGodaddyCurrencyCookie() ||
      (language === LocaleType['en-US']
        ? countrySettingsMap[CountryType.US].currency
        : undefined)
    );
  }

  return (
    Object.values(countrySettingsMap).find(
      settings => settings.languages[language]
    )?.currency || Currency.USD
  );
};

export const resolveCountryByCurrency = (
  currency?: Currency
): CountrySetting | undefined =>
  Object.values(countrySettingsMap).find(
    country => country.currency === currency
  );

export const resolveCountryCodeByLanguage = (
  language: string
): string | undefined =>
  Object.values(countrySettingsMap).find(country => country.languages[language])
    ?.countryCode || CountryType.US;
