import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Currency } from 'enums/business/currency';
import { PortalType } from 'enums/portal/type';
import { ApplicationIntent } from 'enums/application/intent';
import { ApplicationLevel } from 'enums/application/application-level';

import { useStore } from 'helpers/store';
import { useApplicationId } from 'helpers/application/hooks';
import * as historyHelpers from 'helpers/history';
import * as referralsHelper from 'helpers/referrals';
import * as analyticsHelper from 'helpers/analytics/track';
import * as storeStateHelper from 'helpers/store/state';

import * as companyActions from 'actions/company';
import * as applicantActions from 'actions/applicant';
import * as applicationActions from 'actions/application';

import { Store } from 'reducers/types';
import { State as CompanyReducer } from 'reducers/company/types';
import { State as AccountReducer } from 'reducers/account/types';
import { State as Environment } from 'reducers/environment/types';
import { State as ApplicationReducer } from 'reducers/application/types';
import { State as ApplicantReducer } from 'reducers/applicant/types';

import { Category } from 'reducers/categories/types';

export function isUxCoreEnabled({
  company,
  accounts,
  environment,
  application
}: {
  company: CompanyReducer;
  accounts: AccountReducer;
  environment: Environment;
  application: ApplicationReducer;
}): boolean {
  const applicationId =
    /urn:app:(\w{4,12}-?){5}/.exec(window.location.pathname)?.[0] || '';
  const isOnlyUS = !company.currency || company.currency === Currency.USD;
  const isOnlyOldIntents =
    !application.intent ||
    application.intent === ApplicationIntent.CREATE_BUSINESS ||
    application.intent === ApplicationIntent.ADD_STORE;
  const isNotBasic =
    application.applicationLevel !== ApplicationLevel.BASIC &&
    application.originalApplicationLevel !== ApplicationLevel.BASIC;
  const isNotSingleAccountBasic =
    accounts.loaded &&
    ((!application.applicationLevel && !accounts.list.length) ||
      !(
        accounts.list.length === 1 &&
        accounts.list[0].applicationLevel === ApplicationLevel.BASIC
      ));
  const isGodaddyPortal = environment.portal === PortalType.GODADDY;
  const isNotEhopperFlow = !environment.eHopperFlowEnabled;
  const applicationIdExists = applicationId ? !!application.id : true;
  const isNotPBD =
    applicationIdExists &&
    isOnlyOldIntents &&
    ((isNotBasic && isNotSingleAccountBasic) || !!application.createBusiness);

  return (
    ((!!environment.newSignupFlowEnabled && isNotPBD) ||
      (!!environment.newSignupFlowPbdEnabled && !isNotPBD)) &&
    isOnlyUS &&
    isGodaddyPortal &&
    isNotEhopperFlow
  );
}

export const useUxCore = () => {
  const company = useStore<CompanyReducer>(state => state.company);
  const accounts = useStore<AccountReducer>(state => state.accounts);
  const applicant = useStore<ApplicantReducer>(state => state.applicant);
  const environment = useStore<Environment>(state => state.environment);
  const application = useStore<ApplicationReducer>(state => state.application);

  return {
    isUxCoreEnabled: isUxCoreEnabled({
      company,
      environment,
      application,
      accounts
    }),
    company,
    accounts,
    applicant,
    environment,
    application
  };
};

export const useUxCoreStatePrefill = (wizardRoutes: string[]) => {
  const { resolvedId: applicationId } = useApplicationId();
  const dispatch = useDispatch();
  const categories = useStore<Category[]>(state => state.categories.list);
  const [isStatePrefilled, setIsStatePrefilled] = useState<boolean>(false);
  const {
    company,
    accounts,
    applicant,
    environment,
    application,
    isUxCoreEnabled
  } = useUxCore();

  const isApplicantNotReady = (applicant: Partial<ApplicantReducer>): boolean =>
    !applicant?.name?.lastName ||
    !applicant?.name?.firstName ||
    !applicant?.email?.emailAddress;

  const getSerializedState = (): string => {
    if (isApplicantNotReady(applicant)) {
      return '';
    }

    return JSON.stringify({
      applicant: {
        name: applicant.name,
        phone: applicant.phone,
        email: applicant.email,
        address: applicant.address,
        communicationLanguage: applicant.communicationLanguage
      },
      company: {
        dba: company.dba,
        name: company.name,
        site: company.site,
        phone: company.phone,
        segment: company.segment,
        address: company.address,
        location: company.location,
        currency: company.currency,
        mccDetails: company.mccDetails
      },
      application: {
        boStatus: application.boStatus
      }
    } as Store);
  };

  const isAppNotReady = () =>
    !isUxCoreEnabled ||
    !company.currency ||
    !accounts.loaded ||
    !categories.length ||
    !environment.loaded ||
    !wizardRoutes.some(url => location.pathname.endsWith(url));

  useEffect(() => {
    if (isAppNotReady() || isStatePrefilled) {
      return;
    }

    setIsStatePrefilled(true);

    dispatch(
      companyActions.saveCompanyDefaultState(
        storeStateHelper.getDefaultState<Partial<CompanyReducer>>(
          'company',
          company
        )
      )
    );
    dispatch(
      applicantActions.saveApplicantDefaultState(
        storeStateHelper.getDefaultState<Partial<ApplicantReducer>>(
          'applicant',
          company
        )
      )
    );
    dispatch(
      applicationActions.saveApplicationDefaultState(
        storeStateHelper.getDefaultState<Partial<ApplicationReducer>>(
          'application',
          company
        )
      )
    );

    analyticsHelper.trackCreateApplicationPageLoaded();
  }, [isAppNotReady()]);

  useEffect(() => {
    if (application.id) {
      storeStateHelper.removeDefaultState();

      return;
    }

    if (isAppNotReady()) {
      return;
    }

    const serializedState = getSerializedState();

    if (!serializedState) {
      return;
    }

    storeStateHelper.setDefaultState(serializedState);

    analyticsHelper.trackStartedFillingApplication();
  }, [isAppNotReady(), getSerializedState(), application.id]);

  useEffect(() => {
    if (isAppNotReady()) {
      return;
    }

    if (accounts.list.length || location.pathname.endsWith(wizardRoutes[0])) {
      return;
    }

    const localStorageCompany = storeStateHelper.getDefaultState<
      Partial<CompanyReducer>
    >('company', company);
    const applicant = storeStateHelper.getDefaultState<
      Partial<ApplicantReducer>
    >('applicant', company);
    const applicationRoute = applicationId ? '/' + applicationId : '';

    if (isApplicantNotReady(applicant)) {
      historyHelpers.redirectTo(
        referralsHelper.getUrlWithQuery(
          `${applicationRoute}${wizardRoutes[0]}`,
          location.search,
          location.pathname
        )
      );
    } else if (
      !localStorageCompany?.segment ||
      !localStorageCompany?.mccDetails?.label
    ) {
      historyHelpers.redirectTo(
        referralsHelper.getUrlWithQuery(
          `${applicationRoute}${wizardRoutes[1]}`,
          location.search,
          location.pathname
        )
      );
    }

    if (wizardRoutes.slice(3).some(url => location.pathname.endsWith(url))) {
      historyHelpers.redirectTo(
        referralsHelper.getUrlWithQuery(
          `${applicationRoute}${wizardRoutes[2]}`,
          location.search,
          location.pathname
        )
      );
    }
  }, [isAppNotReady()]);
};
