import axios from 'axios';

import { GovIdType } from 'enums/gov-id/type';

export const addGovernmentIdentifications = async (
  applicationId: string,
  value: string,
  sourceType: GovIdType
): Promise<void> => {
  await axios.post(
    `/api/v2/applications/${applicationId}/government-identifications`,
    {
      value,
      sourceType
    }
  );
};
