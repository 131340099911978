import { Dispatch } from 'redux';

import { CategoryType } from 'enums/category';
import { EHopperSolution } from 'enums/ehopper-order/solution';

import { saveCompanySegment, saveCompanyMccDetails } from 'actions/company';

import { PreparedOption } from 'helpers/categories/types';
import { Category, Industry } from 'reducers/categories/types';

export const setDefaultEHopperCompanySegment = (
  eHopperSolution: EHopperSolution | undefined,
  dispatch: Dispatch,
  categories: Category[] = []
) => {
  const defaultEhopperRestaurantSegment = '5812';

  if (eHopperSolution === EHopperSolution.RESTAURANT) {
    const industry = getBusinessCategoryIndustry(
      defaultEhopperRestaurantSegment,
      categories
    );

    dispatch(saveCompanySegment(defaultEhopperRestaurantSegment));
    dispatch(
      saveCompanyMccDetails({
        label: industry?.label || '',
        type: industry?.type || CategoryType.REGULAR
      })
    );
  }
};

export const getBusinessCategoryIndustry = (
  companySegment?: string,
  categories: Category[] = []
): Industry | undefined => {
  if (!companySegment) {
    return;
  }

  return categories
    .reduce((industries, category) => {
      if (category?.industries?.length) {
        industries.push(...(category.industries || []));
      }

      return industries;
    }, [] as Industry[])
    .reverse()
    .find(industry => industry.mcc === companySegment);
};

export const escapeValue = (value: string): string | RegExp => {
  return value?.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const prepareOptions = (
  options: Category[] = []
): {
  label: string;
  options: PreparedOption[];
}[] => {
  const categories = (options || []).sort((a, b) => {
    return (a.label || '').localeCompare(b.label || '');
  });

  return categories.map(category => {
    const industries = (category.industries || []).sort((a, b) => {
      return (a.label || '').localeCompare(b.label || '');
    });

    return {
      label: category.label,
      options: industries.map(industry => ({
        mcc: industry.mcc,
        keywords: industry.keywords || '',
        label: industry.label,
        category: category.label,
        value: industry.mcc,
        type: industry.type || (category.type as CategoryType)
      }))
    };
  });
};
