import { ButtonHTMLAttributes, FC, cloneElement } from 'react';

import { Props } from './Button.types';

import '@ux/button/styles';

import Styles from './Button.module.scss';

const Button: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  design,
  icon,
  type,
  text,
  children,
  outlined = false,
  size,
  className,
  tabIndex,
  ...rest
}) => {
  return (
    <button
      type={type}
      tabIndex={tabIndex}
      className={[
        Styles.button,
        className,
        outlined && Styles.outlined,
        design === 'simple' && Styles.simple,
        design === 'primary' && Styles.primary,
        design === 'secondary' && Styles.secondary,
        design === 'critical' && Styles.critical,
        design === 'link' && Styles.link,
        size === 'small' && Styles.sizeSmall
      ]
        .filter(className => className)
        .join(' ')}
      {...rest}>
      {children}
      {icon && (
        <span className={Styles.iconContainer}>
          {cloneElement(icon, { fill: 'currentColor' })}
        </span>
      )}
      {text}
    </button>
  );
};

export default Button;
