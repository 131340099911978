import { FC } from 'react';

import { PoyntTheme } from 'components/themes';
import { Title, Loader } from 'components/elements';

import { useTranslation } from 'helpers/translation';

import { Props } from './Overlay.types';

import Styles from './Overlay.module.scss';

const Overlay: FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const isAdditionalText =
    props.additionalText !== undefined ? props.additionalText : true;

  return (
    <PoyntTheme>
      <div
        className={[
          Styles.overlay,
          isAdditionalText && !props.lightLoader ? Styles.godaddyBackground : ''
        ].join(' ')}>
        <div className={Styles.container}>
          <Loader lightLoader={props.lightLoader} />
          {isAdditionalText ? (
            props.customAdditionalText ? (
              <Title
                increaseFont={props.increaseFont}
                subtitle={props.customSubTitle}>
                {props.customAdditionalText}
              </Title>
            ) : (
              <Title
                subtitle={t('components.elements.overlay.subtitle')}
                increaseFont={props.increaseFont}>
                {t('components.elements.overlay.title')}
              </Title>
            )
          ) : null}
        </div>
      </div>
    </PoyntTheme>
  );
};

export default Overlay;
