import validator from 'validator';

import { ValidatorType } from 'helpers/validators/types';

export const routingNumberLength = 9;
export const minAccountNumberLength = 4;
export const maxAccountNumberLength = 17;

export const isRoutingNumber = (value: ValidatorType): boolean => {
  if (value?.length !== routingNumberLength) {
    return false;
  }

  const chars = Array.from(value).map(char => parseInt(char, 10));

  const checkSum = [
    3 * (chars[0] + chars[3] + chars[6]),
    7 * (chars[1] + chars[4] + chars[7]),
    chars[2] + chars[5] + chars[8]
  ];

  return !(checkSum.reduce((a, b) => a + b, 0) % 10);
};

export const isAccountNumber = (value: ValidatorType): boolean => {
  return (
    !!value &&
    validator.isNumeric(value) &&
    validator.isLength(value, {
      min: minAccountNumberLength,
      max: maxAccountNumberLength
    })
  );
};
