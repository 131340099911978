import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  requested: false
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_CHANNEL_ID':
      return {
        ...state,
        id: action.payload,
        requested: false
      };
    case 'SAVE_CHANNEL_REQUESTED':
      return {
        ...state,
        requested: true
      };
    case 'SAVE_CHANNEL_NAME':
      return {
        ...state,
        name: action.payload
      };
    case 'SAVE_CHANNEL_TYPE':
      return {
        ...state,
        type: action.payload
      };
    case 'SAVE_CHANNEL_EXTERNAL_ID':
      return {
        ...state,
        externalId: action.payload
      };
    case 'SAVE_CHANNEL_EXTERNAL_TYPE':
      return {
        ...state,
        externalType: action.payload
      };
    default:
      return state;
  }
}
