import { AnyAction } from 'redux';

import { OrderType } from 'enums/order/type';
import { CountryType } from 'enums/address/country-type';

import { Address } from 'reducers/company/types';
import { Quantity, BundleQuantity, DisplayBundles } from 'reducers/order/types';

export function saveOrderAddress(address: Address): AnyAction {
  return {
    payload: {
      city: address.city || '',
      countryCode: address.countryCode || CountryType.US,
      line1: address.line1 || '',
      line2: address.line2 || '',
      territory: address.territory || '',
      zip: address.zip || ''
    },
    type: 'SAVE_ORDER_ADDRESS'
  };
}

export function saveOrderQuantity(quantity: Quantity): AnyAction {
  return {
    payload: quantity,
    type: 'SAVE_ORDER_QUANTITY'
  };
}

export function saveOrderType(type: OrderType): AnyAction {
  return {
    payload: type,
    type: 'SAVE_ORDER_TYPE'
  };
}

export function saveOrderId(orderId: string): AnyAction {
  return {
    payload: orderId,
    type: 'SAVE_ORDER_ID'
  };
}

export function getOrderType(id?: string): AnyAction {
  return {
    payload: id,
    type: 'GET_ORDER_TYPE_REQUEST'
  };
}

export function saveBundleQuantity(quantity: BundleQuantity): AnyAction {
  return {
    payload: quantity,
    type: 'SAVE_BUNDLE_QUANTITY'
  };
}

export function saveBundleDisplay(bundles: DisplayBundles): AnyAction {
  return {
    payload: bundles,
    type: 'SAVE_BUNDLE_DISPLAY'
  };
}
