import { FC, useEffect } from 'react';

import * as FullStoryComponent from '@fullstory/browser';

import Environments from 'helpers/environments';

const settings = {
  host: Environments.get<string>('fullStory.host'),
  debug: Environments.get<boolean>('fullStory.debug'),
  orgId: Environments.get<string>('fullStory.orgId'),
  script: Environments.get<string>('fullStory.script'),
  devMode: Environments.get<boolean>('fullStory.devMode'),
  namespace: Environments.get<string>('fullStory.namespace')
};

const FullStory: FC = () => {
  useEffect(() => {
    FullStoryComponent.init(settings);
  }, []);

  return <></>;
};
export default FullStory;
