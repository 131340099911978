import { AnyAction } from 'redux';

import { GovIdType } from 'enums/gov-id/type';
import { StepUpActionType } from 'enums/application/step-up';
import { ApplicationLevel } from 'enums/application/application-level';
import { ApplicationIntent } from 'enums/application/intent';
import { ApplicationStatusType } from 'enums/application/status';
import { BeneficialOwnersStatus } from 'enums/application/beneficial-owners-status';
import { GodaddySsoPermissions } from 'enums/godaddy-sso-permissions';

import * as applicationCacheHelper from 'helpers/application/cache';

import { GovernmentIdentification } from 'reducers/company/types';

import {
  InitApplicationArgsInterface,
  GetApplicationResponseInterface
} from 'rest/v2/application/interfaces';
import { State as ApplicationReducer } from 'reducers/application/types';

export function saveApplicationDefaultState(
  state: Partial<ApplicationReducer>
): AnyAction {
  return {
    payload: state,
    type: 'SAVE_APPLICATION_DEFAULT_STATE'
  };
}

export function createApplication(applicationId?: string): AnyAction {
  return {
    payload: {
      applicationId
    },
    type: 'CREATE_APPLICATION_V2_REQUEST'
  };
}

export function createApplicationSuccess(): AnyAction {
  return {
    type: 'CREATE_APPLICATION_V2_SUCCESS'
  };
}

export function createApplicationFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'SUBMIT_APPLICATION_V2_FAILURE'
  };
}

export function saveApplicationDecision(decision?: string): AnyAction {
  return {
    payload: decision,
    type: 'SAVE_APPLICATION_DECISION'
  };
}

export function saveApplicationStatus(
  applicationStatus?: ApplicationStatusType
) {
  return {
    payload: applicationStatus,
    type: 'SAVE_APPLICATION_STATUS'
  };
}

export function saveApplicationEnableDeposits(enableDeposits?: boolean) {
  return {
    payload: enableDeposits,
    type: 'SAVE_APPLICATION_ENABLE_DEPOSITS'
  };
}

export function saveApplicationStepUpDetails(
  stepUps?: StepUpActionType[]
): AnyAction {
  return {
    payload: stepUps,
    type: 'SAVE_APPLICATION_STEP_UP_DETAILS'
  };
}

export function saveApplicationId(id?: string): AnyAction {
  return {
    payload: id,
    type: 'SAVE_APPLICATION_ID'
  };
}

export function redirectApplicationRequest(
  applicationCompleted?: boolean
): AnyAction {
  return {
    payload: applicationCompleted,
    type: 'REDIRECT_APPLICATION_REQUEST'
  };
}

export function redirectApplicationSuccess(): AnyAction {
  return {
    type: 'SUBMIT_APPLICATION_SUCCESS'
  };
}

export function redirectApplicationFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'SUBMIT_APPLICATION_FAILURE'
  };
}

export function completeApplication(): AnyAction {
  return {
    type: 'COMPLETE_APPLICATION'
  };
}

export function parseApplicationStepUp(payload?: unknown): AnyAction {
  return {
    payload,
    type: 'PARSE_APPLICATION_STEP_UP'
  };
}

export function updateApplicationRequest(
  type: StepUpActionType,
  data: unknown
): AnyAction {
  return {
    payload: {
      type,
      data
    },
    type: 'UPDATE_APPLICATION_STEP_UP_V2_REQUEST'
  };
}

export function initApplicationSuccess(): AnyAction {
  return {
    type: 'INIT_APPLICATION_V2_SUCCESS'
  };
}

export function initApplicationFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'INIT_APPLICATION_V2_FAILURE'
  };
}

export function updateApplicationSuccess(): AnyAction {
  return {
    type: 'UPDATE_APPLICATION_V2_SUCCESS'
  };
}

export function updateApplicationFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'UPDATE_APPLICATION_V2_FAILURE'
  };
}

export function saveApplicationResumed() {
  return {
    type: 'SAVE_APPLICATION_RESUMED'
  };
}

export function saveApplicationResumeCheck(check = true) {
  return {
    payload: check,
    type: 'SAVE_APPLICATION_RESUME_CHECK'
  };
}

export function saveApplicationPrefillCheck() {
  return {
    type: 'SAVE_APPLICATION_PREFILL_STATUS'
  };
}

export function hideStandaloneOverviewPage() {
  return {
    type: 'HIDE_STANDALONE_PREVIEW'
  };
}

export function saveApplicationSource(source?: string): AnyAction {
  return {
    payload: source,
    type: 'SAVE_APPLICATION_SOURCE'
  };
}

export function saveApplicationMock(mock?: boolean): AnyAction {
  return {
    payload: mock,
    type: 'SAVE_APPLICATION_MOCK'
  };
}

export function saveApplicationAgentId(agentId?: string): AnyAction {
  return {
    payload: agentId,
    type: 'SAVE_APPLICATION_AGENT_ID'
  };
}

export function saveApplicationFirstTxnDate(firstTxnDate?: string): AnyAction {
  return {
    payload: firstTxnDate,
    type: 'SAVE_APPLICATION_FIRST_TXN_DATE'
  };
}

export function saveApplicationCreateBusinessMode(
  createBusiness?: boolean
): AnyAction {
  return {
    payload: createBusiness,
    type: 'SAVE_APPLICATION_CREATE_BUSINESS_MODE'
  };
}

export function saveApplicationApiVersion(apiVersion?: string): AnyAction {
  return {
    payload: apiVersion,
    type: 'SAVE_APPLICATION_API_VERSION'
  };
}

export function saveApplicationCreateStoreMode(
  createStore?: boolean
): AnyAction {
  return {
    payload: createStore,
    type: 'SAVE_APPLICATION_CREATE_STORE_MODE'
  };
}

export function saveApplicationStoreSwitcherMode(
  storeSwitcher?: boolean
): AnyAction {
  return {
    payload: storeSwitcher,
    type: 'SAVE_APPLICATION_STORE_SWITCHER_MODE'
  };
}

export function saveApplicationKycKybRequiredMode(
  kycKybRequired?: boolean
): AnyAction {
  return {
    payload: kycKybRequired,
    type: 'SAVE_APPLICATION_KYC_KYB_REQUIRED_MODE'
  };
}

export function saveApplicationMasterMid(isMasterMid?: boolean): AnyAction {
  return {
    payload: isMasterMid,
    type: 'SAVE_APPLICATION_MASTER_MID'
  };
}

export function saveApplicationMedium(medium?: string): AnyAction {
  return {
    payload: medium,
    type: 'SAVE_APPLICATION_MEDIUM'
  };
}

export function saveApplicationContext(context?: string): AnyAction {
  return {
    payload: context,
    type: 'SAVE_APPLICATION_CONTEXT'
  };
}

export function saveApplicationCampaignName(campaignName?: string): AnyAction {
  return {
    payload: campaignName,
    type: 'SAVE_APPLICATION_CAMPAIGN_NAME'
  };
}

export function saveApplicationToken(token?: string): AnyAction {
  return {
    payload: token,
    type: 'SAVE_APPLICATION_TOKEN'
  };
}

export function saveApplicationServiceType(serviceType?: string): AnyAction {
  return {
    payload: serviceType,
    type: 'SAVE_APPLICATION_SERVICE_TYPE'
  };
}

export function saveApplicationVentureId(ventureId?: string): AnyAction {
  return {
    payload: ventureId,
    type: 'SAVE_APPLICATION_VENTURE_ID'
  };
}

export function saveApplicationBusinessId(businessId?: string): AnyAction {
  return {
    payload: businessId,
    type: 'SAVE_APPLICATION_BUSINESS_ID'
  };
}

export function saveApplicationStoreId(storeId?: string): AnyAction {
  return {
    payload: storeId,
    type: 'SAVE_APPLICATION_STORE_ID'
  };
}

export function saveApplicationServiceId(serviceId?: string): AnyAction {
  return {
    payload: serviceId,
    type: 'SAVE_APPLICATION_SERVICE_ID'
  };
}

export function saveApplicationCustomerId(customerId?: string): AnyAction {
  return {
    payload: customerId,
    type: 'SAVE_APPLICATION_CUSTOMER_ID'
  };
}

export function getApplicationById(
  data: InitApplicationArgsInterface
): AnyAction {
  const payload = {
    payload: data,
    type: 'GET_APPLICATION_BY_ID_REQUEST'
  };
  const actionKey =
    applicationCacheHelper.getApplicationByIdCacheKey(payload) || '';

  if (applicationCacheHelper.getCacheGetApplicationById(actionKey)) {
    return {
      type: ''
    };
  }

  return payload;
}

export function getApplicationByIdSuccess(): AnyAction {
  return {
    type: 'GET_APPLICATION_BY_ID_SUCCESS'
  };
}

export function getApplicationByIdFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'GET_APPLICATION_BY_ID_FAILURE'
  };
}

export function onboardApplicationRequest(
  type: string,
  timeout?: number
): AnyAction {
  return {
    payload: {
      type,
      timeout
    },
    type: 'ONBOARD_APPLICATION_REQUEST'
  };
}

export function onboardApplicationSuccess(): AnyAction {
  return {
    type: 'ONBOARD_APPLICATION_SUCCESS'
  };
}

export function onboardApplicationFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'ONBOARD_APPLICATION_FAILURE'
  };
}

export function saveApplicationIntent(intent?: ApplicationIntent): AnyAction {
  return {
    payload: intent,
    type: 'SAVE_APPLICATION_INTENT'
  };
}

export function saveApplicationLevel(
  applicationLevel?: ApplicationLevel
): AnyAction {
  return {
    payload: applicationLevel,
    type: 'SAVE_APPLICATION_LEVEL'
  };
}

export function saveOriginalApplicationLevel(
  originalApplicationLevel?: ApplicationLevel
): AnyAction {
  return {
    payload: originalApplicationLevel,
    type: 'SAVE_ORIGINAL_APPLICATION_LEVEL'
  };
}

export function saveIsApplicationFetched(
  isApplicationFetched?: boolean
): AnyAction {
  return {
    payload: isApplicationFetched,
    type: 'IS_APPLICATION_FETCHED'
  };
}

export function saveIsApplicationPowerSeller(
  isPowerSeller?: boolean
): AnyAction {
  return {
    payload: isPowerSeller,
    type: 'SAVE_IS_APPLICATION_POWER_SELLER'
  };
}

export function saveParentApplication(
  parentApplication: GetApplicationResponseInterface
): AnyAction {
  return {
    payload: parentApplication,
    type: 'SAVE_PARENT_APPLICATION'
  };
}

export function saveApplicationOrganizationId(
  organizationId?: string
): AnyAction {
  return {
    payload: organizationId,
    type: 'SAVE_APPLICATION_ORGANIZATION_ID'
  };
}

export function setSsoPermissions(type: GodaddySsoPermissions) {
  return {
    payload: {
      type
    },
    type: 'SET_SSO_PERMISSIONS'
  };
}

export function saveApplicationBoStatus(
  boStatus?: BeneficialOwnersStatus
): AnyAction {
  return {
    payload: boStatus,
    type: 'SAVE_APPLICATION_BO_STATUS'
  };
}

export function saveApplicantIdentification(
  identification: GovernmentIdentification
): AnyAction {
  return {
    payload: identification,
    type: 'SAVE_APPLICANT_IDENTIFICATION'
  };
}

export function addGovId(taxId?: string, taxType?: GovIdType) {
  return {
    payload: {
      taxId,
      taxType
    },
    type: 'ADD_GOV_ID_REQUEST'
  };
}

export function saveAddProductIntent(): AnyAction {
  return {
    type: 'SAVE_ADD_PRODUCT_INTENT'
  };
}

export function updateApplicationRequestWithPersonaLongPolling(
  type: StepUpActionType,
  data: unknown
): AnyAction {
  return {
    payload: {
      type,
      data
    },
    type: 'UPDATE_APPLICATION_PERSONA_STEPUP_WITH_LONGPOLLING_REQUEST'
  };
}

export function updateApplicationRequestWithPersonaLongPollingSuccess(): AnyAction {
  return {
    type: 'UPDATE_APPLICATION_PERSONA_STEPUP_WITH_LONGPOLLING_SUCCESS'
  };
}

export function updateApplicationRequestWithPersonaLongPollingFailure(
  error?: string
): AnyAction {
  return {
    payload: error,
    type: 'UPDATE_APPLICATION_PERSONA_STEPUP_WITH_LONGPOLLING_FAILURE'
  };
}

export function getInquirySessionTokenById(
  inquiryId: string,
  isEinStepUp: boolean
): AnyAction {
  return {
    payload: { inquiryId, isEinStepUp },
    type: 'GET_INQUIRY_SESSION_TOKEN_BY_ID_REQUEST'
  };
}

export function getInquirySessionTokenByIdSuccess(): AnyAction {
  return {
    type: 'GET_INQUIRY_SESSION_TOKEN_BY_ID_SUCCESS'
  };
}

export function getInquirySessionTokenByIdFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'GET_INQUIRY_SESSION_TOKEN_BY_ID_FAILURE'
  };
}
