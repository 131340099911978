import { Currency } from 'enums/business/currency';

import { Address } from 'reducers/company/types';

import { Account } from 'rest/v2/accounts/interfaces';

import { poboxV2Identification } from '../validators/address';

import { ApplicationPrefill } from './types';

export const applicationPrefillParser = (
  data: URLSearchParams
): ApplicationPrefill =>
  [
    'applicationId',
    'agentId',
    'mcc',
    'name',
    'phone',
    'email',
    'mock',
    'token',
    'medium',
    'source',
    'context',
    'lead_id',
    'user_id',
    'campaign',
    'ventureId',
    'businessId',
    'serviceType',
    'serviceId',
    'masterMid',
    'customerId',
    'companyDba',
    'companyName',
    'powerSeller',
    'resumeEmail',
    'companyPhone',
    'kycKybRequired',
    'companySegment',
    'companyWebsite',
    'companyTerritory',
    'showStoreSwitcher',
    'companyAddressZip',
    'companyCountryCode',
    'companyAddressCity',
    'companyAddressLine1',
    'companyAddressLine2',
    'companyAddressState',
    'applicantTerritory',
    'applicantAddressZip',
    'applicantCountryCode',
    'applicantAddressCity',
    'applicantAddressLine1',
    'applicantAddressLine2',
    'applicantAddressState',
    'companyIdentificationType',
    'companyIdentificationValue',
    'createBusiness',
    'apiVersion',
    'channelName',
    'channelType'
  ].reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: data.get(currentValue) || ''
    }),
    {} as ApplicationPrefill
  );

const companyAddressCaTemplate = (
  data: ApplicationPrefill,
  source: 'company' | 'applicant'
): Record<string, string> => ({
  countryCode: data[`${source}CountryCode`],
  territory: data[`${source}Territory`],
  website: data[`${source}Website`]
});

const regularTemplate = (
  data: ApplicationPrefill,
  source: 'company' | 'applicant'
): Record<string, string> => ({
  city: data[`${source}AddressCity`],
  countryCode: data[`${source}CountryCode`],
  line1: data[`${source}AddressLine1`],
  line2: data[`${source}AddressLine2`],
  state: data[`${source}AddressState`],
  territory: data[`${source}Territory`],
  website: data[`${source}Website`],
  zip: data[`${source}AddressZip`]
});

const prepareAddress = (
  data: ApplicationPrefill,
  template: Record<string, string>
) =>
  Object.entries(template).reduce((previousValue, [key, value]) => {
    if (key === 'line1') {
      value = poboxV2Identification(value) ? '' : value;
    }

    if (key === 'line2') {
      value = poboxV2Identification(value) ? '' : value;
    }

    return {
      ...previousValue,
      ...(value ? { [key]: value } : {})
    };
  }, {} as Address);

export const parseAddress = (
  data: ApplicationPrefill,
  currency?: Currency
): [Address, Address] => {
  if (currency === Currency.CAD) {
    return [
      prepareAddress(data, regularTemplate(data, 'applicant')),
      prepareAddress(data, companyAddressCaTemplate(data, 'company'))
    ];
  }

  const companyAddress = prepareAddress(data, regularTemplate(data, 'company'));

  return [companyAddress, companyAddress];
};

export const parsePhoneNumber = (phoneNumber?: string, account?: Account) => {
  return (phoneNumber || account?.applicant?.phone?.[0]?.localPhoneNumber || '')
    .replace(/\D/g, '')
    .substring(0, 10);
};

export const parseEmail = (email?: string, account?: Account) => {
  return email || account?.applicant?.email?.[0]?.emailAddress || '';
};
