const invalidNames = [
  'DO NOT CONTACT',
  'BUSINESS USE ONLY',
  'FOR BUSINESS USE ONLY',
  'FOR OFFICIAL USE ONLY',
  'TRAVEL & EXPENSE ONLY',
  'CORPORATE T & E ONLY',
  'UC BUSINESS ONLY',
  'CORPORATE CARD',
  'TRANSFER ACCOUNT',
  'NULL',
  'BUSINESS ACCOUNT',
  'ACCTS PAYABLE',
  'EMERGENCY REPLACEMENT',
  'EMERGENCY CARD',
  'CENTRAL BILL',
  'BUSINESS CARD',
  'CARDMEMBER',
  'GENERIC CARD',
  'CARD CORPORATE',
  'COMPANY CARD',
  'FUEL ONLY',
  'CARD BUSINESS',
  'NEED FROM CLIENT',
  'PAYABLE',
  'ATTN',
  'ATTN .',
  'ATTN.',
  'ACCOUNTS PAYABLE',
  'IRA PLAN',
  'SCHOOL',
  'CO',
  'ACCOUNT CLOSED',
  'PLLC',
  'INC',
  'LP',
  'LTD',
  'LLP',
  'THE',
  'NA',
  'N.A.',
  'N A',
  'NOT REQUIRED',
  'NRQ',
  'NONE'
];

export const invalidBusinessNameRegExp = new RegExp(
  `^(${[...invalidNames].join('|')})$`
);
