import { AnyAction } from 'redux';

import { GovIdType } from 'enums/gov-id/type';
import { CategoryType } from 'enums/category';
import { BusinessType } from 'enums/business/type';

import { parseAddress } from 'helpers/parsers';

import { State } from './types';

const INITIAL_STATE: State = {
  address: {
    city: '',
    countryCode: '',
    line1: '',
    line2: '',
    state: '',
    territory: '',
    zip: '',
    website: ''
  },
  governmentIdentification: {
    type: BusinessType.BUSINESS,
    value: '',
    sourceType: GovIdType.FEIN
  },
  phone: {
    localPhoneNumber: ''
  },
  name: '',
  dba: '',
  segment: '',
  site: '',
  mccDetails: {
    label: '',
    type: CategoryType.REGULAR
  }
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_COMPANY_DEFAULT_STATE':
      return {
        ...state,
        ...action.payload
      };
    case 'SAVE_COMPANY_ADDRESS':
      return {
        ...state,
        address: parseAddress({ ...action.payload, id: state.address.id })
      };
    case 'SAVE_COMPANY_LOCATION':
      return {
        ...state,
        location: action.payload
      };
    case 'SAVE_COMPANY_PHONE':
      return {
        ...state,
        phone: {
          ...state.phone,
          localPhoneNumber: action.payload
        }
      };
    case 'SAVE_COMPANY_SITE':
      return {
        ...state,
        site: action.payload
      };
    case 'SAVE_COMPANY_NAME':
      return {
        ...state,
        name: action.payload
      };
    case 'SAVE_COMPANY_DBA':
      return {
        ...state,
        dba: action.payload
      };
    case 'SAVE_COMPANY_SEGMENT':
      return {
        ...state,
        segment: action.payload
      };
    case 'SAVE_COMPANY_IDENTIFICATION':
      return {
        ...state,
        governmentIdentification: {
          id: state.governmentIdentification.id,
          ...action.payload
        }
      };
    case 'SAVE_COMPANY_ID':
      return {
        ...state,
        id: action.payload
      };
    case 'SAVE_COMPANY_ADDRESS_ID':
      return {
        ...state,
        address: {
          ...state.address,
          id: action.payload
        }
      };
    case 'SAVE_COMPANY_GOVERNMENT_ID':
      return {
        ...state,
        governmentIdentification: {
          ...state.governmentIdentification,
          id: action.payload
        }
      };
    case 'SAVE_COMPANY_SUBMITTED':
      return {
        ...state,
        submitted: action.payload
      };
    case 'SAVE_COMPANY_CURRENCY':
      return {
        ...state,
        currency: action.payload
      };
    case 'SAVE_COMPANY_MCC_DETAILS':
      return {
        ...state,
        mccDetails: action.payload
      };
    case 'SAVE_COMPANY_PERSONA_INQUIRY_ID':
      return {
        ...state,
        personaInquiryId: action.payload
      };
    case 'SAVE_COMPANY_PERSONA_SESSION_TOKEN':
      return {
        ...state,
        personaSessionToken: action.payload
      };
    default:
      return state;
  }
}
