import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LocaleType } from 'enums/applicant/locale-type';

import enUS from './locales/en-US/translation.json';
import enCA from './locales/en-CA/translation.json';
import frCA from './locales/fr-CA/translation.json';

export const resources = {
  [LocaleType['en-US']]: {
    translation: enUS
  },
  [LocaleType['en-CA']]: {
    translation: enCA
  },
  [LocaleType['fr-CA']]: {
    translation: frCA
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    returnNull: false,
    fallbackLng: LocaleType['en-US'],
    debug: false,
    supportedLngs: ['en-US', 'en-CA', 'fr-CA'],
    detection: {
      lookupCookie: 'market',
      lookupSessionStorage: 'market',
      order: ['cookie', 'sessionStorage', 'navigator', 'querystring']
    }
  });

export default i18n;
