import * as companyTypes from 'reducers/company/types';

import { Address } from './types';
import { ReactGeosuggestGmap } from 'components/form/autocomplete/Autocomplete.types.tsx';

function parseGoodleAddress(
  components: google.maps.GeocoderAddressComponent[] = []
) {
  const address: Address = {};

  components.forEach(item => {
    if (item.types.includes('street_number')) {
      address.streetNumber = item.long_name;
    }

    if (item.types.includes('subpremise')) {
      address.aptNumber = item.long_name;
    }

    if (item.types.includes('route')) {
      address.route = item.long_name;
    }

    if (item.types.includes('locality') && item.types.includes('political')) {
      address.city = item.long_name;
    }

    if (
      item.types.includes('administrative_area_level_1') &&
      item.types.includes('political')
    ) {
      address.territory = item.short_name;
    }

    if (item.types.includes('postal_code')) {
      address.zip = item.long_name;
    }

    if (item.types.includes('country')) {
      address.countryCode = item.short_name;
    }
  });

  if (!address.city) {
    const isSublocality = components.some(
      item =>
        (item.types.includes('sublocality') ||
          item.types.includes('sublocality_level_1')) &&
        item.types.includes('political')
    );

    if (isSublocality) {
      const areaLevel1 = components.find(
        item =>
          item.types.includes('administrative_area_level_1') &&
          item.types.includes('political')
      );
      address.city = areaLevel1 ? areaLevel1.long_name : '';
    }
  }

  return address;
}

export function prepareAddress(data?: ReactGeosuggestGmap) {
  const addressComponents = data ? data.address_components : [];
  const address = parseGoodleAddress(addressComponents);

  if (data && data.website) {
    const { hostname } = new URL(data.website);
    address.website = hostname;
  }

  address.line1 = address.route
    ? `${address.streetNumber || ''} ${address.route} ${
        address.aptNumber || ''
      }`.trim()
    : '';

  return address;
}

export function prepareBusinessName(data?: ReactGeosuggestGmap) {
  return data?.name;
}

export function preparePhone(phoneNumber: string) {
  const match = (phoneNumber || '').match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `***-***-${match[3]}`;
  }
  return '';
}

export const unCaps = (string: string) => {
  if (!string.length) {
    return;
  }
  return (
    string.slice(0, 1).toUpperCase() +
    string.slice(1, string.length).toLowerCase()
  );
};

export function parseAddress(
  address?: companyTypes.Address
): companyTypes.Address {
  return {
    ...address,
    city: (address?.city || '').trim(),
    countryCode: (address?.countryCode || '').trim(),
    line1: (address?.line1 || '').replaceAll('NULL', '').trim(),
    line2: (address?.line2 || '').replaceAll('null', '').trim(),
    state: (address?.state || '').trim(),
    territory: (address?.territory || '').trim(),
    zip: (address?.zip || '').trim(),
    website: (address?.website || '').trim()
  };
}
