import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  attempt: 0,
  hasError: false,
  verified: false
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'RESET_OTP_STATE':
      return {
        ...state,
        hasError: false,
        verified: false
      };
    case 'CHANGE_OTP_STATE':
      return {
        ...state,
        verified: action.payload
      };
    case 'CHANGE_OTP_ATTEMPT':
      return {
        ...state,
        attempt: action.payload
      };
    case 'SAVE_OTP_ERROR':
      return {
        ...state,
        hasError: action.payload
      };
    default:
      return state;
  }
}
