import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  attempt: 0,
  verified: false,
  phoneAuthError: false,
  mobileVerificationError: false
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'CHANGE_PHONE_AUTH_STATE':
      return {
        ...state,
        verified: action.payload
      };
    case 'CHANGE_PHONE_AUTH_ATTEMPT':
      return {
        ...state,
        attempt: action.payload
      };
    case 'SAVE_PHONE_AUTH_ERROR':
      return {
        ...state,
        phoneAuthError: action.payload
      };
    case 'SAVE_MOBILE_VERIFICATION_ERROR':
      return {
        ...state,
        mobileVerificationError: action.payload
      };
    default:
      return state;
  }
}
