import axios from 'axios';
import { stringify } from 'qs';

import { ApplicationIntent } from 'enums/application/intent';

import { prepareApplicationRequest } from 'helpers/application/prepare-application-request';

import {
  CreateApplicationArgsInterface,
  ApplicationResponseInterface,
  ApplicationUpdateResponseInterface,
  GetApplicationResponseInterface
} from './interfaces';

export const getApplicationById = async (payload: {
  applicationId: string;
  basicInfo?: boolean;
  ownerId?: string;
}): Promise<GetApplicationResponseInterface> => {
  const response = await axios.get(
    `/api/v2/applications/${payload.applicationId}?${stringify(payload)}`
  );

  return response.data;
};

export const createApplication = async (
  data: CreateApplicationArgsInterface,
  basicApplication = false,
  createStore = false,
  accountId?: string,
  storeId?: string
): Promise<ApplicationResponseInterface> => {
  let forceIntent;

  if (!basicApplication) {
    forceIntent = ApplicationIntent.CREATE_BUSINESS;
  }

  if (createStore) {
    forceIntent = ApplicationIntent.ADD_STORE;
  }

  const queryParams = {
    'override-referral-url': true,
    'use-shopper-data': basicApplication,
    'force-intent': forceIntent,
    'find': !createStore && !storeId && basicApplication
  };

  const response = await axios.post(
    `/api/v2/applications?${stringify(queryParams)}`,
    prepareApplicationRequest(data, basicApplication, accountId, storeId),
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000',
        'x-referrer': document.referrer
      }
    }
  );

  return response.data;
};

export const updateApplication = async (
  applicationId: string,
  data: CreateApplicationArgsInterface
): Promise<ApplicationUpdateResponseInterface> => {
  const response = await axios.put(
    `/api/v2/applications/${applicationId}?override-referral-url=true`,
    prepareApplicationRequest(data),
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000',
        'x-referrer': document.referrer
      }
    }
  );

  return response.data;
};
