export const dobFormat = (dob: string) => {
  const yearLength = 4;

  const split = dob.split('/');
  return split[0].length === yearLength ? dob : switchDayMonth(dob);
};

const formatDate = (date: Date, format: string): string => {
  const pad = (num: number): string => num.toString().padStart(2, '0');
  const replacements: { [key: string]: string } = {
    'YYYY': date.getFullYear().toString(),
    'MM': pad(date.getMonth() + 1),
    'DD': pad(date.getDate())
  };

  return format.replace(/YYYY|MM|DD/g, match => replacements[match] || match);
};

export const convertDateFormat = (
  dateStr: string,
  existingFormat: string,
  newFormat: string
): string => {
  if (!dateStr) {
    return '';
  }

  const formatParts = existingFormat.match(/YYYY|MM|DD/g);

  if (!formatParts) {
    throw new Error('Invalid existing format.');
  }
  const dateParts = dateStr.split(/[^0-9]/).map(Number);
  const dateObj = {
    year: 1970,
    month: 0,
    day: 1
  };

  formatParts.forEach((part, index) => {
    switch (part) {
      case 'YYYY':
        dateObj.year = dateParts[index];
        break;
      case 'MM':
        dateObj.month = dateParts[index] - 1;
        break;
      case 'DD':
        dateObj.day = dateParts[index];
        break;
    }
  });

  const date = new Date(dateObj.year, dateObj.month, dateObj.day);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date value.');
  }

  return formatDate(date, newFormat);
};

export const switchDayMonth = (date: string) => {
  const dateArray = date.split('/');
  return [dateArray[1], dateArray[0], dateArray[2]].join('/');
};
