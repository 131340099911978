import { AnyAction } from 'redux';

import { Owner } from 'app/reducers/owners/types';

import { BeneficialOwnersStatus } from 'enums/application/beneficial-owners-status';
import { GovernmentIdentification } from 'reducers/company/types';
import { StepUpActionType } from 'enums/application/step-up';
import { StepUpOwnerActionType } from 'enums/owner/step-up';

export function processBOInfo(
  type: StepUpActionType,
  beneficialOwnersStatus: BeneficialOwnersStatus
): AnyAction {
  return {
    payload: beneficialOwnersStatus,
    type: 'PROCESS_BO_INFO'
  };
}

export function createOwnersRequest(): AnyAction {
  return {
    type: 'CREATE_OWNERS_REQUEST'
  };
}

export function saveOwners(owners: Partial<Owner>[]): AnyAction {
  return {
    payload: owners,
    type: 'SAVE_OWNERS'
  };
}

export function processOwnerIdentification(
  type: StepUpOwnerActionType,
  data: unknown
): AnyAction {
  return {
    payload: {
      type,
      data
    },
    type: 'PROCESS_OWNER_IDENTIFICATION'
  };
}

export function patchOwner(): AnyAction {
  return {
    type: 'PATCH_OWNER_REQUEST'
  };
}

export function patchOwnerSuccess(): AnyAction {
  return {
    type: 'PATCH_OWNER_SUCCESS'
  };
}

export function patchOwnerFail(type: string): AnyAction {
  return {
    payload: {
      type
    },
    type: 'PATCH_OWNER_FAIL'
  };
}

export function setOwnerIsPatching(value: boolean): AnyAction {
  return {
    payload: value,
    type: 'SET_OWNER_IS_PATCHING'
  };
}

export function onboardOwnerRequest(type: string): AnyAction {
  return {
    type: 'ONBOARD_OWNER_REQUEST',
    payload: {
      type
    }
  };
}

export function onboardOwnerFail(error?: string): AnyAction {
  return {
    payload: error,
    type: 'ONBOARD_OWNER_FAIL'
  };
}

export function onboardOwnerSuccess(): AnyAction {
  return {
    type: 'ONBOARD_OWNER_SUCCESS'
  };
}

export function saveOwnerStepUpDetails(
  stepUps?: StepUpOwnerActionType[]
): AnyAction {
  return {
    payload: stepUps,
    type: 'SAVE_OWNER_STEP_UP_DETAILS'
  };
}

export function updateOwnerStepUpRequest(
  type: StepUpOwnerActionType,
  data: unknown
): AnyAction {
  return {
    payload: {
      type,
      data
    },
    type: 'UPDATE_OWNER_STEP_UP_REQUEST'
  };
}

export function updateOwnerStepUpRequestSuccess(): AnyAction {
  return {
    type: 'UPDATE_OWNER_STEP_UP_REQUEST_SUCCESS'
  };
}

export function updateOwnerStepUpRequestFailure(error?: string): AnyAction {
  return {
    payload: error,
    type: 'UPDATE_OWNER_STEP_UP_REQUEST_FAILURE'
  };
}

export function parseOwnerStepUp(payload?: unknown): AnyAction {
  return {
    payload,
    type: 'PARSE_OWNER_STEP_UP'
  };
}

export function saveOwnerId(applicantId: string): AnyAction {
  return {
    payload: applicantId,
    type: 'SAVE_OWNER_ID'
  };
}

export function saveOwnerFirstName(firstName: string): AnyAction {
  return {
    payload: firstName,
    type: 'SAVE_OWNER_FIRST_NAME'
  };
}

export function saveOwnerLastName(lastName: string): AnyAction {
  return {
    payload: lastName,
    type: 'SAVE_OWNER_LAST_NAME'
  };
}

export function saveOwnerDateOfBirth(dob: string): AnyAction {
  return {
    payload: dob,
    type: 'SAVE_OWNER_DATE_OF_BIRTH'
  };
}

export function saveOwnerEmailAddress(email: string): AnyAction {
  return {
    payload: email,
    type: 'SAVE_OWNER_EMAIL_ADDRESS'
  };
}

export function saveOwnerPhoneNationalNumber(phone: string): AnyAction {
  return {
    payload: phone,
    type: 'SAVE_OWNER_PHONE_NATIONAL_NUMBER'
  };
}

export function saveOwnerIdentification(
  governmentIdentification: GovernmentIdentification
): AnyAction {
  return {
    payload: governmentIdentification,
    type: 'SAVE_OWNER_GOV_IDENTIFICATION'
  };
}

export function saveOwnerPersonaInquiryId(
  personaInquiryId: string | null
): AnyAction {
  return {
    payload: personaInquiryId,
    type: 'SAVE_OWNER_PERSONA_INQUIRY_ID'
  };
}

export function updateOwnerPersonaStepUpWithLongPolling(
  type: StepUpOwnerActionType,
  data: unknown
): AnyAction {
  return {
    payload: {
      type,
      data
    },
    type: 'UPDATE_OWNER_PERSONA_STEP_UP_WITH_LONG_POLLING_REQUEST'
  };
}

export function updateOwnerPersonaStepUpWithLongPollingSuccess(): AnyAction {
  return {
    type: 'UPDATE_OWNER_PERSONA_STEP_UP_WITH_LONG_POLLING_SUCCESS'
  };
}

export function updateOwnerPersonaStepUpWithLongPollingFailure(
  error?: string
): AnyAction {
  return {
    payload: error,
    type: 'UPDATE_OWNER_PERSONA_STEP_UP_WITH_LONG_POLLING_FAILURE'
  };
}

export function saveOwnerPersonaSessionToken(
  personaSessionToken: string
): AnyAction {
  return {
    payload: personaSessionToken,
    type: 'SAVE_OWNER_PERSONA_SESSION_TOKEN'
  };
}

export function getOwnerInquirySessionTokenById(inquiryId: string): AnyAction {
  return {
    payload: inquiryId,
    type: 'GET_ONWER_INQUIRY_SESSION_TOKEN_BY_ID_REQUEST'
  };
}

export function getOwnerInquirySessionTokenByIdSuccess(): AnyAction {
  return {
    type: 'GET_OWNER_INQUIRY_SESSION_TOKEN_BY_ID_SUCCESS'
  };
}

export function getOwnerInquirySessionTokenByIdFailure(
  error?: string
): AnyAction {
  return {
    payload: error,
    type: 'GET_OWNER_INQUIRY_SESSION_TOKEN_BY_ID_FAILURE'
  };
}
