import axios from 'axios';

export const trackAnalytics = (
  type: string,
  params: unknown[]
): Promise<unknown> => {
  return axios
    .request({
      method: 'post',
      url: `/api/v2/analytics`,
      data: {
        type,
        params
      }
    })
    .then(response => response.data);
};
