import { Dispatch } from 'redux';

import { Currency } from 'enums/business/currency';
import { EventType } from 'enums/analytics/event-type';
import { MixpanelTrackType } from 'enums/analytics/mixpanel/track-type';
import { AnalyticsPortalType } from 'enums/analytics/portal-type';

import * as deviceHelpers from 'helpers/device';

import * as analyticsActions from 'actions/analytics';

import { MixpanelTrackArgsInterface, MixpanelTrackData } from './../types';

class Mixpanel {
  private utm?: string;
  private dispatch?: Dispatch;
  private portal = AnalyticsPortalType.gdp;
  private mock?: boolean;
  private source?: string;
  private medium?: string;
  private currency?: Currency;
  private language?: string;
  private shopperId?: string;
  private serviceId?: string;
  private ventureId?: string;
  private businessId?: string;
  private customerId?: string;
  private serviceType?: string;
  private referralUrlId?: string;
  private inPersonPricingFirstEnabled?: boolean;
  private isPowerSeller?: boolean;
  private organizationId?: string;
  private isMultistepFlow?: boolean;
  private applicationLevel?: string;
  private beneficiaryOwnerEnabled?: boolean;
  private hasBeneficialOwners?: boolean;
  private dateOfBirthForUSAEnabled?: boolean;
  private originalApplicationLevel?: string;
  private newApplicationFormEnabled?: boolean;

  public initTrackData(
    trackData: MixpanelTrackArgsInterface,
    dispatch: Dispatch
  ) {
    this.dispatch = dispatch;
    this.mock = trackData.mock;
    this.source = trackData.source;
    this.medium = trackData.medium;
    this.portal = trackData.portal;
    this.currency = trackData.currency;
    this.language = trackData.language;
    this.shopperId = trackData.shopperId;
    this.serviceId = trackData.serviceId;
    this.ventureId = trackData.ventureId;
    this.businessId = trackData.businessId;
    this.customerId = trackData.customerId;
    this.serviceType = trackData.serviceType;
    this.referralUrlId = trackData.referralUrlId;
    this.inPersonPricingFirstEnabled = trackData.inPersonPricingFirstEnabled;
    this.isPowerSeller = !!trackData.isPowerSeller;
    this.organizationId = trackData.organizationId;
    this.isMultistepFlow = this.isMultistepFlow || trackData.isMultistepFlow;
    this.applicationLevel = trackData.applicationLevel;
    this.beneficiaryOwnerEnabled = trackData.beneficiaryOwnerEnabled;
    this.hasBeneficialOwners = trackData.hasBeneficialOwners;
    this.dateOfBirthForUSAEnabled = trackData.dateOfBirthForUSAEnabled;
    this.originalApplicationLevel = trackData.originalApplicationLevel;
    this.newApplicationFormEnabled = trackData.newApplicationFormEnabled;
  }

  public track(event: MixpanelTrackType, data?: MixpanelTrackData) {
    if (!this.dispatch) {
      return;
    }

    const preparedData = this.prepareData(event, data);

    this.dispatch(
      analyticsActions[preparedData.trackOnce ? 'trackOnce' : 'track'](
        EventType.FRONTEND_MIXPANEL_EVENT,
        preparedData
      )
    );
  }

  private getUtm(): string | undefined {
    if (this.utm) {
      return this.utm;
    }

    this.utm = new URLSearchParams(location.search).get('utm') || '';

    return this.utm;
  }

  private prepareData(
    event: MixpanelTrackType,
    data?: MixpanelTrackData
  ): MixpanelTrackData {
    const preparedData: MixpanelTrackData = {
      ...(data || {}),
      eventName: `${this.portal.toUpperCase()}_${event}`,
      ...deviceHelpers.getCurrentDeviceTypes()
    };

    const utm = this.getUtm();

    if (utm) {
      preparedData.utm = utm;
    }

    if (this.mock) {
      preparedData.mock = this.mock;
    }

    if (this.serviceId) {
      preparedData.serviceId = this.serviceId || '';
    }

    if (this.businessId || preparedData.applicationId) {
      preparedData.businessId =
        this.businessId ||
        ((preparedData.applicationId as string) || '').slice(8) ||
        '';
    }

    if (this.shopperId) {
      preparedData.shopperId = this.shopperId || '';
    }

    if (this.serviceType) {
      preparedData.serviceType = this.serviceType || '';
    }

    if (this.customerId) {
      preparedData.customerId = this.customerId || '';
    }

    if (this.ventureId) {
      preparedData.ventureId = this.ventureId || '';
    }

    if (this.referralUrlId) {
      preparedData.referralUrlId = this.referralUrlId || '';
    }

    if (this.organizationId) {
      preparedData.organizationId = this.organizationId || '';
    }

    if (this.applicationLevel && !preparedData.applicationLevel) {
      preparedData.applicationLevel = this.applicationLevel || '';
    }

    if (
      this.originalApplicationLevel &&
      !preparedData.originalApplicationLevel
    ) {
      preparedData.originalApplicationLevel =
        this.originalApplicationLevel || '';
    }

    if (this.inPersonPricingFirstEnabled) {
      preparedData.inPersonPricingFirstEnabled =
        this.inPersonPricingFirstEnabled;
    }

    if (this.isPowerSeller) {
      preparedData.isPowerSeller = this.isPowerSeller;
    }

    if (this.currency) {
      preparedData.currency = this.currency;
    }

    if (this.language) {
      preparedData.language = this.language;
    }

    if (this.source) {
      preparedData.source = this.source;
    }

    if (this.medium) {
      preparedData.medium = this.medium;
    }

    if (this.newApplicationFormEnabled) {
      preparedData.newApplicationFormEnabled = this.newApplicationFormEnabled;
    }

    if (this.beneficiaryOwnerEnabled) {
      preparedData.beneficiaryOwnerEnabled = this.beneficiaryOwnerEnabled;
    }

    if (this.hasBeneficialOwners) {
      preparedData.hasBeneficialOwners = this.hasBeneficialOwners;
    }

    if (this.dateOfBirthForUSAEnabled) {
      preparedData.dateOfBirthForUSAEnabled = this.dateOfBirthForUSAEnabled;
    }

    if (this.isMultistepFlow) {
      preparedData.isMultistepFlow = this.isMultistepFlow;
    }

    return preparedData;
  }
}

export default new Mixpanel();
