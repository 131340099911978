import axios from 'axios';

import { StepUpActionType } from 'enums/application/step-up';
import { StepUpType } from 'enums/application/step-up-type';

import { prepareApplicationRequest } from 'helpers/application/prepare-application-request';

import { verifyOTP } from './../otp';

import {
  ApplicationUpdateResponseInterface,
  CreateApplicationArgsInterface
} from './../interfaces';

export const updateApplicationWithStepUpInformation = async (
  applicationId: string,
  type: string,
  data: unknown
): Promise<ApplicationUpdateResponseInterface> => {
  let requestType;

  switch (type) {
    case StepUpActionType.OTP_SMS:
    case StepUpActionType.PHONE_AUTH:
      const request = (data as { code: string; skip: string }) || {
        code: '',
        skip: true
      };
      return verifyOTP(applicationId, request.code, request.skip);
    case StepUpActionType.FULL_SSN:
      requestType = 'full-ssn';
      break;
    case StepUpActionType.GOV_ID_SELFIE:
      requestType = 'identification';
      break;
    case StepUpActionType.EIN_DOC:
      requestType = 'verification';
      break;
    case StepUpActionType.BO_INFO:
      requestType = 'beneficial-owners';
      break;
    case StepUpActionType.BUSINESS_INFO:
      requestType = 'business-info';
      break;
  }

  const response = await axios.post(
    `/api/v2/applications/${applicationId}/step-ups/${requestType}`,
    data
  );

  return response.data;
};

export const updateApplicationWithFullOnboarding = async (
  applicationId: string,
  data: CreateApplicationArgsInterface
): Promise<{ applicationId: string }> => {
  const response = await axios.post(
    `/api/v2/applications/${applicationId}/step-ups/full-onboarding`,
    prepareApplicationRequest(data)
  );

  return response.data;
};

export const updateApplicantWithStepUpInformation = async (
  applicationId: string,
  applicantId: string,
  type: string,
  data: unknown
): Promise<ApplicationUpdateResponseInterface> => {
  let requestType;

  switch (type) {
    case StepUpActionType.GOV_ID_SELFIE:
      requestType = 'identification';
      break;
  }

  const response = await axios.post(
    `/api/v2/applications/${applicationId}/owners/${applicantId}/step-ups/${requestType}`,
    data
  );

  return response.data;
};

export const getPersonaInquirySessionToken = async (
  inquiryId: string,
  applicationId: string,
  type: StepUpType,
  applicantId?: string
): Promise<{ sessionToken: string; isInquiryCompleted: boolean }> => {
  const ownerSuffix = applicantId ? `owners/${applicantId}/` : '';

  const response = await axios.post(
    `/api/v2/applications/${applicationId}/${ownerSuffix}step-ups/${type}/sessions`,
    { inquiryId }
  );

  return response.data;
};
