import axios from 'axios';

import { GodaddySsoPermissions } from 'enums/godaddy-sso-permissions';

export const setSsoPermissions = (
  applicationId: string,
  type: GodaddySsoPermissions
) => {
  return axios
    .request({
      data: {
        type
      },
      method: 'post',
      url: `/api/v2/applications/${applicationId}/permissions`
    })
    .then(response => response.data);
};
