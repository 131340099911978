import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useStore } from 'helpers/store';
import { useTranslation } from 'helpers/translation';
import * as analyticsHelper from 'helpers/analytics/track';

import * as applicationActions from 'actions/application';

import { State as Environment } from 'reducers/environment/types';

export const useStepUpHook = (): string => {
  const [stepUp, setStepUp] = useState<string>(
    () => new URLSearchParams(location.search).get('step-up') || ''
  );

  useEffect(() => {
    setStepUp(new URLSearchParams(location.search).get('step-up') || '');
  }, [location.search]);

  return stepUp;
};

export const useApplicationId = (): {
  id: string | undefined;
  resolvedId: string | undefined;
} => {
  const { id } = useParams<{ id: string }>();

  const getId = (): string | undefined => {
    if (id?.startsWith('urn:app:')) {
      return id;
    }

    return /urn:app:(\w{4,12}-?){5}/.exec(location.pathname)?.[0] || '';
  };

  return { id, resolvedId: getId() };
};

export const useResumeHook = (params?: {
  skipResume?: boolean;
  forceResume?: boolean;
  accountsLoaded?: boolean;
  environmentLoaded?: boolean;
  skipStepUpParsing?: boolean;
  newSignupFormEnabled?: boolean;
  newSignupFlowPbdEnabled?: boolean;
}): { id?: string; applicationId?: string; skipResume?: boolean } => {
  const dispatch = useDispatch();
  const { resolvedId } = useApplicationId();

  const applicationId = useStore<string>(state => state.application.id || '');

  useEffect(() => {
    if (params?.skipResume) {
      return;
    }

    const isNewSignupFormEnabled =
      params?.newSignupFormEnabled === undefined
        ? true
        : params?.newSignupFormEnabled;
    const isNewSignupFlowPbdEnabled =
      params?.newSignupFlowPbdEnabled === undefined
        ? true
        : params?.newSignupFlowPbdEnabled;
    const isAccountsLoaded =
      params?.accountsLoaded === undefined ? true : params?.accountsLoaded;
    const isEnvironmentLoaded =
      params?.environmentLoaded === undefined
        ? true
        : params?.environmentLoaded;

    if (
      params?.forceResume ||
      (isAccountsLoaded &&
        isEnvironmentLoaded &&
        (isNewSignupFormEnabled || isNewSignupFlowPbdEnabled) &&
        !applicationId &&
        resolvedId)
    ) {
      if (!params?.forceResume) {
        analyticsHelper.trackResumeApplication(resolvedId);
      }

      dispatch(
        applicationActions.getApplicationById({
          applicationId: resolvedId,
          skipStepUpParsing: params?.skipStepUpParsing
        })
      );
    }
  }, [
    resolvedId,
    applicationId,
    params?.skipResume,
    params?.accountsLoaded,
    params?.environmentLoaded,
    params?.newSignupFormEnabled
  ]);

  return { id: resolvedId, applicationId, skipResume: params?.skipResume };
};

export const useRedirectHook = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const environment = useStore<Environment>(store => store.environment);

  const redirectUrl = useStore<string | undefined>(
    state => state.environment.redirectUrl
  );

  const redirectApplication = () => {
    dispatch(applicationActions.redirectApplicationRequest(true));
  };

  const redirectButtonName = (
    redirectButtonText?: string
  ): string | undefined => {
    return (
      environment.cobrand?.customFields?.[i18n.language]?.redirectName ||
      environment.redirectName ||
      redirectButtonText ||
      t('portal.complete.linkBank.redirect')
    );
  };

  return { redirectUrl, redirectApplication, redirectButtonName };
};
