import { FC, MouseEvent, useEffect, useRef } from 'react';

import ReactDOM from 'react-dom';

import { Design } from 'enums/design/design-type';

import { Props } from './Modal.types';

import Styles from './Modal.module.scss';

const Modal: FC<Props> = ({
  design,
  containerId,
  children,
  title,
  titleComponent,
  onClose,
  className,
  zIndex,
  positionAbsolute,
  extraPadding
}) => {
  const rootElemRef = useRef(document.createElement('div'));

  const handleContentClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const reference = rootElemRef.current;
    const parentElem = document.querySelector(`#modal-root`);

    if (parentElem) {
      parentElem.classList.add('opened');
      parentElem.appendChild(rootElemRef.current);
    }

    return () => {
      if (reference.parentNode && reference.parentNode.removeChild) {
        reference.parentNode.removeChild(reference);

        if (parentElem) {
          parentElem.classList.remove('opened');
        }
      }
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className={[
        Styles.modal,
        positionAbsolute ? Styles.positionAbsolute : '',
        extraPadding ? Styles.extraPadding : '',
        design === Design.NEW_DESIGN && Styles.newDesign
      ].join(' ')}
      style={{ zIndex: zIndex || 99 }}>
      <div
        id={containerId || 'modal-root-content'}
        className={[Styles.content, className].join(' ')}
        onClick={handleContentClick}>
        <div className={Styles.header}>
          {titleComponent ? titleComponent : title}
          {onClose ? <span className={Styles.close} onClick={onClose} /> : null}
        </div>
        {children}
      </div>
    </div>,
    rootElemRef.current
  );
};

export default Modal;
