import { AnyAction } from 'redux';

import { ChannelType } from 'enums/channel/type';

export function createChannelRequest(): AnyAction {
  return {
    type: 'CREATE_CHANNEL_REQUEST'
  };
}

export function createChannelSuccess(): AnyAction {
  return {
    type: 'CREATE_CHANNEL_SUCCESS'
  };
}

export function saveChannelId(id?: string): AnyAction {
  return {
    payload: id,
    type: 'SAVE_CHANNEL_ID'
  };
}

export function saveChannelName(name?: string): AnyAction {
  return {
    payload: name,
    type: 'SAVE_CHANNEL_NAME'
  };
}

export function saveChannelRequested(): AnyAction {
  return {
    type: 'SAVE_CHANNEL_REQUESTED'
  };
}

export function saveChannelType(type?: ChannelType): AnyAction {
  return {
    payload: type,
    type: 'SAVE_CHANNEL_TYPE'
  };
}

export function saveChannelExternalId(externalId?: string): AnyAction {
  return {
    payload: externalId,
    type: 'SAVE_CHANNEL_EXTERNAL_ID'
  };
}

export function saveChannelExternalType(externalType?: string): AnyAction {
  return {
    payload: externalType,
    type: 'SAVE_CHANNEL_EXTERNAL_TYPE'
  };
}
