import { Dispatch } from 'redux';
import validator from 'validator';

import { ApplicantType } from 'enums/applicant/type';
import { BusinessType } from 'enums/business/type';
import { StepUpActionType } from 'enums/application/step-up';
import { MultistepPageType } from 'enums/analytics/multistep-page-type';

import * as gtm from 'helpers/analytics/gtm';
import * as nps from 'helpers/analytics/nps';
import * as traffic from 'helpers/analytics/traffic';
import * as mixpanel from 'helpers/analytics/mixpanel';

import { State as ApplicationReducer } from 'reducers/application/types';
import { Owner } from 'reducers/owners/types';

import { Store } from 'rest/v2/accounts/stores/interfaces';
import { Account } from 'rest/v2/accounts/interfaces';

import { NPSTrackEventArgsInterface } from 'helpers/analytics/nps/types';
import { InitTrackDataArgsInterface } from './types';

export const initTracking = (gtmId?: string) => {
  gtm.initGTM(gtmId);
  nps.initNPS();
  traffic.initTraffic();
};

export const initTrackData = (
  trackData: InitTrackDataArgsInterface,
  dispatch: Dispatch
) => {
  traffic.initTrackData(trackData.traffic);
  mixpanel.initTrackData(trackData.mixpanel, dispatch);
};

export const trackSignUpStart = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  traffic.trackSignUpStart(applicationId, applicantId, applicantType);
  mixpanel.trackSignUpStart(applicationId, applicantId, applicantType);
};

export const trackVirtualPathChange = (virtualPath: string) => {
  traffic.trackVirtualPathChange(virtualPath);
};

export const trackEditEnterSSN = () => {
  traffic.trackEditEnterSSN();
  mixpanel.trackEditEnterSSN();
};

export const trackEditEnterEIN = () => {
  traffic.trackEditEnterEIN();
  mixpanel.trackEditEnterEIN();
};

export const trackEditEnterCN = (value: string, province: string) => {
  const isAlpha = validator.isAlpha(value);
  const isNumeric = validator.isNumeric(value);
  const isAlphanumeric = !isAlpha && !isNumeric;
  const length = value.length;

  traffic.trackEditEnterCN(
    length,
    isAlpha,
    isNumeric,
    isAlphanumeric,
    province
  );
  mixpanel.trackEditEnterCN(
    length,
    isAlpha,
    isNumeric,
    isAlphanumeric,
    province
  );
};

export const trackViewTOS = () => {
  traffic.trackViewTOS();
  mixpanel.trackViewTOS();
};

export const trackError = (error: string) => {
  traffic.trackError(error);
  mixpanel.trackError(error);
};

export const trackResumeApplication = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  traffic.trackResumeApplication(applicationId, applicantId, applicantType);
  mixpanel.trackResumeApplication(applicationId, applicantId, applicantType);
};

export const trackResumeApplicationByEmail = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  traffic.trackResumeApplicationByEmail(
    applicationId,
    applicantId,
    applicantType
  );
  mixpanel.trackResumeApplicationByEmail(
    applicationId,
    applicantId,
    applicantType
  );
};

export const trackSignUpApplicationByReminderEmail = (
  applicationId?: string,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  traffic.trackSignUpApplicationByReminderEmail(
    applicationId,
    applicantId,
    applicantType
  );
  mixpanel.trackSignUpApplicationByReminderEmail(
    applicationId,
    applicantId,
    applicantType
  );
};

export const trackPlaidStarted = () => {
  traffic.trackPlaidStarted();
  mixpanel.trackPlaidStarted();
};

export const trackPlaidFinished = () => {
  traffic.trackPlaidFinished();
  mixpanel.trackPlaidFinished();
};

export const trackManualBankAccountStarted = () => {
  traffic.trackManualBankAccountStarted();
  mixpanel.trackManualBankAccountStarted();
};

export const trackManualBankAccountFinished = () => {
  nps.sendNPS({ bankAccountAdded: true });
  traffic.trackManualBankAccountFinished();
  mixpanel.trackManualBankAccountFinished();
};

export const trackBankAccountCanceled = () => {
  traffic.trackBankAccountCanceled();
  mixpanel.trackBankAccountCanceled();
};

export const trackOtpAuthenticationProcessStarted = (
  application: ApplicationReducer
) => {
  traffic.trackOtpAuthenticationProcessStarted(application);
  mixpanel.trackOtpAuthenticationProcessStarted(application);
};

export const trackOtpAuthenticationProcessCancelled = () => {
  traffic.trackOtpAuthenticationProcessCancelled();
  mixpanel.trackOtpAuthenticationProcessCancelled();
};

export const trackOtpResendOtp = () => {
  traffic.trackOtpResendOtp();
  mixpanel.trackOtpResendOtp();
};

export const trackOtpCodeEntered = (otpCode: string) => {
  traffic.trackOtpCodeEntered(otpCode);
  mixpanel.trackOtpCodeEntered(otpCode);
};

export const trackOtpAuthenticationNoOfAttempts = (attempt: number) => {
  traffic.trackOtpAuthenticationNoOfAttempts(attempt);
  mixpanel.trackOtpAuthenticationNoOfAttempts(attempt);
};

export const trackOtpAuthenticationFailed = () => {
  traffic.trackOtpAuthenticationFailed();
  mixpanel.trackOtpAuthenticationFailed();
};

export const trackIdAuthenticationStarted = (
  application: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  traffic.trackIdAuthenticationStarted(application, applicantId, applicantType);
  mixpanel.trackIdAuthenticationStarted(
    application,
    applicantId,
    applicantType
  );
};

export const trackIdAuthenticationCompleted = (
  application?: ApplicationReducer,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  traffic.trackIdAuthenticationCompleted(
    application,
    applicantId,
    applicantType
  );
  mixpanel.trackIdAuthenticationCompleted(
    application,
    applicantId,
    applicantType
  );
};

export const trackFullSsnFormSubmitAttempt = () => {
  traffic.trackFullSsnFormSubmitAttempt();
  mixpanel.trackFullSsnFormSubmitAttempt();
};

export const trackFullSsnFormLanded = () => {
  traffic.trackFullSsnFormLanded();
  mixpanel.trackFullSsnFormLanded();
};

export const trackEinVerificationStarted = (
  application: ApplicationReducer
) => {
  traffic.trackEinVerificationStarted(application);
  mixpanel.trackEinVerificationStarted(application);
};

export const trackEinVerificationCompleted = () => {
  traffic.trackEinVerificationCompleted();
  mixpanel.trackEinVerificationCompleted();
};

export const trackEinVerificationFailed = () => {
  traffic.trackEinVerificationFailed();
  mixpanel.trackEinVerificationFailed();
};

export const trackOnboardStart = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  traffic.trackOnboardStart(application, stepUp);
  mixpanel.trackOnboardStart(application, stepUp);
};

export const trackOnboardSuccess = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  traffic.trackOnboardSuccess(application, stepUp);
  mixpanel.trackOnboardSuccess(application, stepUp);
};

export const trackOnboardError = (
  application: ApplicationReducer,
  stepUp?: string
) => {
  traffic.trackOnboardError(application, stepUp);
  mixpanel.trackOnboardError(application, stepUp);
};

export const trackStepUpSuccess = (
  application: ApplicationReducer,
  type: StepUpActionType,
  isPhoneAuthEnabled?: boolean,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  switch (type) {
    case StepUpActionType.OTP_SMS:
    case StepUpActionType.PHONE_AUTH:
      if (isPhoneAuthEnabled) {
        traffic.trackPhoneAuthenticationSuccess(application);
        mixpanel.trackPhoneAuthenticationSuccess(application);
      } else {
        traffic.trackOtpAuthenticationSuccess(application);
        mixpanel.trackOtpAuthenticationSuccess(application);
      }

      break;
    case StepUpActionType.GOV_ID_SELFIE:
      traffic.trackIdAuthenticationSuccess(
        application,
        applicantId,
        applicantType
      );
      mixpanel.trackIdAuthenticationSuccess(
        application,
        applicantId,
        applicantType
      );
      break;
    case StepUpActionType.EIN_DOC:
      traffic.trackEinVerificationSuccess(application);
      mixpanel.trackEinVerificationSuccess(application);
      break;
    case StepUpActionType.BO_INFO:
      traffic.trackBoInfoSuccess(application);
      mixpanel.trackBoInfoSuccess(application);
      break;
  }
};

export const trackStepUpError = (
  applicationId: string,
  type: StepUpActionType,
  isPhoneAuthEnabled?: boolean,
  applicantId?: string,
  applicantType?: ApplicantType
) => {
  switch (type) {
    case StepUpActionType.OTP_SMS:
    case StepUpActionType.PHONE_AUTH:
      if (isPhoneAuthEnabled) {
        traffic.trackPhoneAuthenticationError(applicationId);
        mixpanel.trackPhoneAuthenticationError(applicationId);
      } else {
        traffic.trackOtpAuthenticationError(applicationId);
        mixpanel.trackOtpAuthenticationError(applicationId);
      }

      break;
    case StepUpActionType.GOV_ID_SELFIE:
      traffic.trackIdAuthenticationError(
        applicationId,
        applicantId,
        applicantType
      );
      mixpanel.trackIdAuthenticationError(
        applicationId,
        applicantId,
        applicantType
      );
      break;
    case StepUpActionType.EIN_DOC:
      traffic.trackEinVerificationError(applicationId);
      mixpanel.trackEinVerificationError(applicationId);
      break;
    case StepUpActionType.BO_INFO:
      traffic.trackBoInfoError(applicationId);
      mixpanel.trackBoInfoError(applicationId);
      break;
  }
};

export const trackNPS = (event: NPSTrackEventArgsInterface) =>
  nps.sendNPS(event);

export const trackBreatherStart = () => {
  traffic.trackBreatherStart();
  mixpanel.trackBreatherStart();
};

export const trackBreatherConfirm = () => {
  traffic.trackBreatherConfirm();
  mixpanel.trackBreatherConfirm();
};

export const trackAccountSwitcherLoaded = () => {
  traffic.trackAccountSwitcherLoaded();
  mixpanel.trackAccountSwitcherLoaded();
};

export const trackAccountSwitcherApplicationSelected = (account: Account) => {
  traffic.trackAccountSwitcherApplicationSelected(account);
  mixpanel.trackAccountSwitcherApplicationSelected(account);
};

export const trackAccountSwitcherCreateNewApplication = () => {
  traffic.trackAccountSwitcherCreateNewApplication();
  mixpanel.trackAccountSwitcherCreateNewApplication();
};

export const trackAccountSwitcherApplicationConfirmed = (account: Account) => {
  traffic.trackAccountSwitcherApplicationConfirmed(account);
  mixpanel.trackAccountSwitcherApplicationConfirmed(account);
};

export const trackStoreSwitcherLoaded = (stores: Store[]) => {
  traffic.trackStoreSwitcherLoaded(stores);
  mixpanel.trackStoreSwitcherLoaded(stores);
};

export const trackStoreSwitcherUsedExistingStore = (store: Store) => {
  traffic.trackStoreSwitcherUsedExistingStore(store);
  mixpanel.trackStoreSwitcherUsedExistingStore(store);
};

export const trackStoreSwitcherUsedNewStore = () => {
  traffic.trackStoreSwitcherUsedNewStore();
  mixpanel.trackStoreSwitcherUsedNewStore();
};

export const trackStoreSwitcherNewBusinessCreationShown = () => {
  traffic.trackStoreSwitcherNewBusinessCreationShown();
  mixpanel.trackStoreSwitcherNewBusinessCreationShown();
};

export const trackStoreSwitcherSelectedBusinessCreation = () => {
  traffic.trackStoreSwitcherSelectedBusinessCreation();
  mixpanel.trackStoreSwitcherSelectedBusinessCreation();
};

export const trackStoreSwitcherScrollingUsage = () => {
  traffic.trackStoreSwitcherScrollingUsage();
  mixpanel.trackStoreSwitcherScrollingUsage();
};

export const trackStoreSwitcherWentBack = () => {
  traffic.trackStoreSwitcherWentBack();
  mixpanel.trackStoreSwitcherWentBack();
};

export const trackKycKybPageLoaded = (firstTxnDate?: string) => {
  const referrer = document.referrer;
  traffic.trackKycKybPageLoaded(firstTxnDate, referrer);
  mixpanel.trackKycKybPageLoaded(firstTxnDate, referrer);
};

export const trackCreateApplicationPageLoaded = () => {
  traffic.trackCreateApplicationPageLoaded();
  mixpanel.trackCreateApplicationPageLoaded();
};

export const trackAutoAttachStore = (data: Record<string, string>) => {
  traffic.trackAutoAttachStore(data);
  mixpanel.trackAutoAttachStore(data);
};

export const trackStartedFillingApplication = () => {
  traffic.trackStartedFillingApplication();
  mixpanel.trackStartedFillingApplication();
};

export const trackApplicationValidationErrors = (errors: {
  [key: string]: string;
}) => {
  traffic.trackApplicationValidationErrors(errors);
  mixpanel.trackApplicationValidationErrors(errors);
};

export const trackApplicationSubmitAttempt = () => {
  traffic.trackApplicationSubmitAttempt();
  mixpanel.trackApplicationSubmitAttempt();
};

export const trackEinByEmailSubmitAttempt = () => {
  traffic.trackEinByEmailSubmitAttempt();
  mixpanel.trackEinByEmailSubmitAttempt();
};

export const trackEinByEmailFormLanded = () => {
  traffic.trackEinByEmailFormLanded();
  mixpanel.trackEinByEmailFormLanded();
};

export const trackBusinessCategorySelect = (label: string, type: string) => {
  traffic.trackBusinessCategorySelect(label, type);
  mixpanel.trackBusinessCategorySelect(label, type);
};

export const trackBusinessTypeSelect = (businessType: BusinessType) => {
  traffic.trackBusinessTypeSelect(businessType);
  mixpanel.trackBusinessTypeSelect(businessType);
};

export const trackBoApplicantErrorPageLoaded = () => {
  traffic.trackBoApplicantErrorPageLoaded();
  mixpanel.trackBoApplicantErrorPageLoaded();
};

export const trackAddOwnersPageLoaded = () => {
  traffic.trackAddOwnersPageLoaded();
  mixpanel.trackAddOwnersPageLoaded();
};

export const trackAddOwnersPageClickAddPartner = () => {
  traffic.trackAddOwnersPageClickAddPartner();
  mixpanel.trackAddOwnersPageClickAddPartner();
};

export const trackAddOwnersPageClickDeletePartner = () => {
  traffic.trackAddOwnersPageClickDeletePartner();
  mixpanel.trackAddOwnersPageClickDeletePartner();
};

export const trackAddOwnersPageClickSubmitPartners = () => {
  traffic.trackAddOwnersPageClickSubmitPartners();
  mixpanel.trackAddOwnersPageClickSubmitPartners();
};

export const trackAddOwnersPageClickShowSkipPartnersModal = () => {
  traffic.trackAddOwnersPageClickShowSkipPartnersModal();
  mixpanel.trackAddOwnersPageClickShowSkipPartnersModal();
};

export const trackAddOwnersPageClickCloseSkipPartnersModal = () => {
  traffic.trackAddOwnersPageClickCloseSkipPartnersModal();
  mixpanel.trackAddOwnersPageClickCloseSkipPartnersModal();
};

export const trackAddOwnersPageClickSkipPartners = () => {
  traffic.trackAddOwnersPageClickSkipPartners();
  mixpanel.trackAddOwnersPageClickSkipPartners();
};

export const trackOwnerOnboardStart = (owner: Owner, stepUp?: string) => {
  traffic.trackOwnerOnboardStart(owner, stepUp);
  mixpanel.trackOwnerOnboardStart(owner, stepUp);
};

export const trackOwnerOnboardSuccess = (owner: Owner, stepUp?: string) => {
  traffic.trackOwnerOnboardSuccess(owner, stepUp);
  mixpanel.trackOwnerOnboardSuccess(owner, stepUp);
};

export const trackOwnerOnboardError = (owner: Owner, stepUp?: string) => {
  traffic.trackOwnerOnboardError(owner, stepUp);
  mixpanel.trackOwnerOnboardError(owner, stepUp);
};

export const trackOwnerPageLoaded = (ownerId: string) => {
  traffic.trackOwnerPageLoaded(ownerId);
  mixpanel.trackOwnerPageLoaded(ownerId);
};

export const trackOwnerPageClickSupport = (ownerId: string) => {
  traffic.trackOwnerPageClickSupport(ownerId);
  mixpanel.trackOwnerPageClickSupport(ownerId);
};

export const trackOwnerPageClickSubmit = (ownerId: string) => {
  traffic.trackOwnerPageClickSubmit(ownerId);
  mixpanel.trackOwnerPageClickSubmit(ownerId);
};

export const trackOwnerSuccessPageLoaded = (ownerId: string) => {
  traffic.trackOwnerSuccessPageLoaded(ownerId);
  mixpanel.trackOwnerSuccessPageLoaded(ownerId);
};

export const trackOwnerSuccessPageClickCTA = (ownerId: string) => {
  traffic.trackOwnerSuccessPageClickCTA(ownerId);
  mixpanel.trackOwnerSuccessPageClickCTA(ownerId);
};

export const trackOverlapCurrencyError = () => {
  traffic.trackOverlapCurrencyError();
  mixpanel.trackOverlapCurrencyError();
};

export const trackBusinessInfoFormLanded = () => {
  traffic.trackBusinessInfoFormLanded();
  mixpanel.trackBusinessInfoFormLanded();
};

export const trackBusinessInfoFormSubmitAttempt = () => {
  traffic.trackBusinessInfoFormSubmitAttempt();
  mixpanel.trackBusinessInfoFormSubmitAttempt();
};

export const trackPhoneAuthenticationProcessStarted = (
  application: ApplicationReducer
) => {
  traffic.trackPhoneAuthenticationProcessStarted(application);
  mixpanel.trackPhoneAuthenticationProcessStarted(application);
};

export const trackPhoneAuthenticationProcessCancelled = () => {
  traffic.trackPhoneAuthenticationProcessCancelled();
  mixpanel.trackPhoneAuthenticationProcessCancelled();
};

export const trackPhoneResendLink = () => {
  traffic.trackPhoneResendLink();
  mixpanel.trackPhoneResendLink();
};

export const trackPhoneAuthenticationFailed = () => {
  traffic.trackPhoneAuthenticationFailed();
  mixpanel.trackPhoneAuthenticationFailed();
};

export const trackMultistepApplicantPageLoaded = () => {
  traffic.trackMultistepApplicantPageLoaded();
  mixpanel.trackMultistepApplicantPageLoaded();
};

export const trackMultistepWizardPageSubmit = (
  page: MultistepPageType,
  current: number,
  remaining: number
) => {
  traffic.trackMultistepWizardPageSubmit(page, current, remaining);
  mixpanel.trackMultistepWizardPageSubmit(page, current, remaining);
};

export const trackMultistepWizardPageError = (
  page: MultistepPageType,
  current: number
) => {
  traffic.trackMultistepWizardPageError(page, current);
  mixpanel.trackMultistepWizardPageError(page, current);
};

export const trackMultistepStepupVerifyClick = (stepup: StepUpActionType) => {
  traffic.trackMultistepStepupVerifyClick(stepup);
  mixpanel.trackMultistepStepupVerifyClick(stepup);
};

export const trackMultistepStepupDoThisLaterClick = (
  stepup: StepUpActionType
) => {
  traffic.trackMultistepStepupDoThisLaterClick(stepup);
  mixpanel.trackMultistepStepupDoThisLaterClick(stepup);
};

export const trackMultistepAddBankClick = () => {
  traffic.trackMultistepAddBankClick();
  mixpanel.trackMultistepAddBankClick();
};

export const trackMultistepDashboardClick = () => {
  traffic.trackMultistepDashboardClick();
  mixpanel.trackMultistepDashboardClick();
};

export const trackMultistepCountrySwitcherLoaded = () => {
  traffic.trackMultistepCountrySwitcherLoaded();
  mixpanel.trackMultistepCountrySwitcherLoaded();
};

export const trackMultistepCountrySwitcherCanceled = () => {
  traffic.trackMultistepCountrySwitcherCanceled();
  mixpanel.trackMultistepCountrySwitcherCanceled();
};

export const trackMultistepCountrySwitcherYesClicked = () => {
  traffic.trackMultistepCountrySwitcherYesClicked();
  mixpanel.trackMultistepCountrySwitcherYesClicked();
};

export const trackMultistepCountrySwitcherNoClicked = () => {
  traffic.trackMultistepCountrySwitcherNoClicked();
  mixpanel.trackMultistepCountrySwitcherNoClicked();
};

export const trackSignupIncorrectUrl = () => {
  traffic.trackSignupIncorrectUrl();
  mixpanel.trackSignupIncorrectUrl();
};

export const trackCashAdvanceOffersPageLoaded = (businessId?: string) => {
  traffic.trackCashAdvanceOffersPageLoaded(businessId);
  mixpanel.trackCashAdvanceOffersPageLoaded(businessId);
};

export const trackCashAdvanceOffersLoaded = (
  businessId?: string,
  offersAmount?: number
) => {
  traffic.trackCashAdvanceOffersLoaded(businessId, offersAmount);
  mixpanel.trackCashAdvanceOffersLoaded(businessId, offersAmount);
};

export const trackCashAdvanceOffersAcceptClick = (
  businessId?: string,
  offerId?: string
) => {
  traffic.trackCashAdvanceOffersAcceptClick(businessId, offerId);
  mixpanel.trackCashAdvanceOffersAcceptClick(businessId, offerId);
};

export const trackCashAdvanceOffersBackClick = (businessId?: string) => {
  traffic.trackCashAdvanceOffersBackClick(businessId);
  mixpanel.trackCashAdvanceOffersBackClick(businessId);
};
