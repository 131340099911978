import validator from 'validator';
import { ValidatorType } from '../types';

const poboxVariants = [
  'P0ST 0FFICE B0X',
  'Box#',
  'PO BO',
  'Post Office Bohx',
  'PO BX',
  'P.O.',
  'PO Boc',
  'p o bx',
  'p 0 bx',
  'p.o bx',
  'p.0 bx',
  'p o. bx',
  'po. bx'
];

export const poboxV1 = new RegExp(
  / *(?:p\.?\s*o\.?|(post|postal)\s+(office|))(\s+)?(?:box|[0-9]*)? *(number|num|no)? *\d+/gim
);
export const poboxV2 =
  /(?:P(?:ost(?:al)?)?[.\-\s]*(?:(?:[O|0](?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|[o|0](?:ffice|\b|\.)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i;

export const maxUsZipcodeLength = 5;
export const minUsZipcodeLength = 5;

const lookUpPOBox = (addressString = '') => {
  return poboxVariants
    .map(item => addressString.toLowerCase().includes(item.toLowerCase()))
    .some(item => item === true);
};

export const poboxV2Identification = (value: string) => poboxV2.exec(value);

export const maxLine1Length = (value: ValidatorType): boolean =>
  validator.isLength(value || '', { max: 300 });

export const pobox = (value: ValidatorType) => {
  return (
    !poboxV1.test(value || '') &&
    !poboxV2Identification(value || '') &&
    !lookUpPOBox(value || '')
  );
};

export const hasBuildingNumber = (value: ValidatorType): boolean => {
  const hasNumbers = /(\d)/;

  return !!hasNumbers.exec(value || '');
};

export const hasLetters = (value: ValidatorType): boolean => {
  const hasLetters = /[a-zA-Z]/;

  return !!hasLetters.exec(value || '');
};

export const startsWithNumbersAndHasLetters = (
  value: ValidatorType
): boolean => {
  const startsWithNumber = /^\d+\s\w+/;

  return !!startsWithNumber.exec(value || '');
};

export const zipCodeUs = (value: ValidatorType): boolean => {
  return (
    validator.isLength(value || '', {
      min: minUsZipcodeLength,
      max: maxUsZipcodeLength
    }) && validator.isNumeric(value || '')
  );
};
