import axios from 'axios';

import { Payload } from './types';

export const createPayment = (
  applicationId: string,
  financialInstrumentId: string,
  amount: number
): Promise<Payload> => {
  return axios
    .request({
      data: {
        amount,
        financialInstrumentId
      },
      method: 'post',
      url: `/api/v2/applications/${applicationId}/payments`
    })
    .then(response => response.data);
};
