import { AnyAction } from 'redux';

import { State } from './types';

const INITIAL_STATE: State = {
  loaded: false,
  list: [],
  stores: {
    loaded: false,
    list: []
  }
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_ACCOUNTS':
      return {
        ...state,
        list: action.payload,
        loaded: true
      };
    case 'CLEAR_STORES_FOR_ACCOUNT':
      return {
        ...state,
        stores: {
          ...state.stores,
          list: [],
          loaded: false
        }
      };
    case 'SAVE_STORES_FOR_ACCOUNT':
      return {
        ...state,
        stores: {
          ...state.stores,
          list: action.payload,
          loaded: true
        }
      };
    default:
      return state;
  }
}
