import { Currency } from 'enums/business/currency';
import { CountryType } from 'enums/address/country-type';

import Environments from 'helpers/environments';

import { Store } from 'reducers/types';
import { State as CompanyReducer } from 'reducers/company/types';

const getStateName = (company?: CompanyReducer): string => {
  const referralUrlId = Environments.get<string>('cobrand.referralUrlId');
  return `state${referralUrlId ? '/' + referralUrlId : ''}/${company?.currency || Currency.USD}/${company?.address?.countryCode || CountryType.US}`;
};

export const getDefaultState = <T>(
  key: string,
  company?: CompanyReducer
): T => {
  const state = localStorage.getItem(getStateName(company))
    ? (JSON.parse(localStorage.getItem(getStateName()) as string) as Store)
    : {};

  return (state[key] || {}) as T;
};

export const removeDefaultState = () => {
  localStorage.removeItem(getStateName());
};

export const setDefaultState = (serializedState: string) => {
  localStorage.setItem(getStateName(), serializedState);
};
