import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import { getI18n } from 'react-i18next';

import Environments from 'helpers/environments';
import * as resellersHelpers from 'helpers/resellers';
import * as countryResolverHelper from 'helpers/country/country-resolver';

import { TerminalType } from 'enums/order/terminal';
import { OrderType } from 'enums/order/type';
import { Currency } from 'enums/business/currency';
import { LocaleType } from 'enums/applicant/locale-type';

import * as configsApi from 'rest/v2/configs';

import * as companyActions from 'actions/company';
import * as applicantActions from 'actions/applicant';

import { Store } from 'reducers/types';
import {
  Godaddy,
  State as EnvironmentReducer
} from 'reducers/environment/types';

import { CountrySetting } from 'helpers/country/types';

import i18n from './../../../i18n';

const getCurrentEnvironment = (state: Store) => state.environment;

export function* getEnvironment(action: AnyAction) {
  try {
    const { referralUrlId, params } = action.payload;

    const godaddyHost = Environments.get<string>('godaddyCookieHost');

    //isGoDaddy will always return false on localhost. Tweak this manually if you need to get godaddy environment on localhost
    const isGoDaddy = location.host.includes(godaddyHost);

    const currency = countryResolverHelper.resolveCurrencyByLanguage(
      i18n.language,
      isGoDaddy
    );

    const storageName = `configuration${
      referralUrlId ? `/${referralUrlId}` : ''
    }${
      currency ? `/${currency}` : ''
    }/${countryResolverHelper.resolveCountryCodeByLanguage(i18n.language)}`;

    let configuration = JSON.parse(sessionStorage.getItem(storageName) || '{}');

    if (!configuration || !Object.keys(configuration).length) {
      configuration = yield call(configsApi.getConfigs, referralUrlId, {
        ...params,
        currency
      });
    }

    if (!configuration.impersonationMode) {
      sessionStorage.setItem(storageName, JSON.stringify(configuration));
    }

    const currentEnvironment: EnvironmentReducer = yield select(
      getCurrentEnvironment
    );

    if (configuration.multiLanguageEnabled) {
      let currentCountry: CountrySetting | undefined = Object.values(
        countryResolverHelper.countrySettingsMap
      ).find(settings => settings.languages[i18n.language]);

      if (
        !currentCountry ||
        currentCountry?.currency !== configuration?.pricing?.currency
      ) {
        currentCountry = countryResolverHelper.resolveCountryByCurrency(
          configuration?.pricing?.currency
        );

        if (!currentCountry) {
          const errorMessage = i18n.t('api.errors.environment.notSupport');

          yield call(toast.error, errorMessage);

          yield put({
            errorMessage,
            type: 'GET_ENVIRONMENT_FAILURE'
          });

          return;
        }
      }

      if (!currentCountry?.languages[i18n.language]) {
        yield call(i18n.changeLanguage, currentCountry?.languages.default);
      }
    } else {
      yield call(getI18n().changeLanguage, LocaleType['en-US']);
      yield call(
        applicantActions.saveApplicantCommunicationLanguage,
        LocaleType['en-US']
      );

      if (configuration?.pricing?.currency) {
        configuration.pricing.currency = Currency.USD;
      }
    }

    configuration = {
      multiLanguageEnabled: configuration.multiLanguageEnabled,
      cobrand: {
        referralUrlId: configuration.referralUrlId,
        organizationId: configuration.organizationId,
        logo: configuration.logo,
        maskLogo: configuration.maskLogo,
        logoCss: configuration.logoCss,
        maskCss: configuration.maskCss,
        applicationName: configuration.applicationName,
        trialAdditional: configuration.order?.trialAdditional,
        trialHeader: configuration.order?.trialHeader,
        trialInfo: configuration.order?.trialInfo,
        shippingInfo: configuration.order?.shippingInfo,
        trialTerms: configuration.order?.trialTerms,
        buyOptionTrialAdditional: configuration.order?.buyOptionTrialAdditional,
        buyOptionTrialHeader: configuration.order?.buyOptionTrialHeader,
        buyOptionTrialInfo: configuration.order?.buyOptionTrialInfo,
        buyOptionTrialTerms: configuration.order?.buyOptionTrialTerms,
        noDeviceOptionTrialAdditional:
          configuration.order?.noDeviceOptionTrialAdditional,
        noDeviceOptionTrialHeader:
          configuration.order?.noDeviceOptionTrialHeader,
        noDeviceOptionTrialInfo: configuration.order?.noDeviceOptionTrialInfo,
        noDeviceOptionTrialTerms: configuration.order?.noDeviceOptionTrialTerms,
        standaloneFlowEnable: configuration.standaloneFlowEnable,
        breatherPageTitle: configuration.breatherPageTitle,
        breatherPageDescription: configuration.breatherPageDescription,
        signupPageTitle: configuration.signupPageTitle,
        signupPageSubtitle: configuration.signupPageSubtitle,
        setupPageTitle: configuration.setupPageTitle,
        setupPageSubtitle: configuration.setupPageSubtitle,
        customFields: configuration.customFields
      },
      isPbdEnabled:
        currentEnvironment.isPbdEnabled || configuration.isPbdEnabled,
      isPartner: configuration.isPartner,
      impersonationMode: configuration.impersonationMode,
      terminalsType: configuration.order?.terminalsType,
      paymentsType: configuration.order?.paymentsType,
      orderTypes: configuration.order?.orderTypes,
      shippingCost: configuration.order?.shippingCost,
      plans: configuration.order?.plans,
      pricing: configuration.pricing,
      resellerType: configuration.resellerType,
      buyN910Amount: configuration.order?.buyN910Amount,
      buyPoyntCAmount: configuration.order?.buyPoyntCAmount,
      terms: configuration.terms,
      redirectName: configuration.redirectName,
      redirectUrl: configuration.redirectUrl,
      redirectUrlForSelectedAccount:
        configuration.redirectUrlForSelectedAccount,
      onAddStorePagesEnabled: configuration.onAddStorePagesEnabled,
      portal: configuration.portalType,
      godaddy: {
        ...Environments.get<Godaddy>('godaddy'),
        ...(configuration.godaddy || {})
      },
      godaddyCookieHost: configuration.godaddyCookieHost,
      eHopperFlowEnabled: configuration.eHopperFlowEnabled || false,
      eHopperBundles: configuration.eHopperFlowEnabled
        ? configuration.order
        : {} || {},
      eHopperSolution: configuration.order?.payfacResellerEHopperType,
      newApplicationFormEnabled: configuration.newApplicationFormEnabled,
      enableMasterMidForCanada: configuration.enableMasterMidForCanada,
      beneficiaryOwnerEnabled: configuration.beneficiaryOwnerEnabled,
      hasBoSubLabelTextExperiment: configuration.hasBoSubLabelTextExperiment,
      dateOfBirthForUSAEnabled: configuration.dateOfBirthForUSAEnabled,
      enableEditApplicationForPoynt:
        configuration.enableEditApplicationForPoynt,
      inPersonPricingFirstEnabled: configuration.inPersonPricingFirstEnabled,
      isPhoneAuthEnabled: configuration.isPhoneAuthEnabled,
      isPersonaWebhooksEnabled: configuration.isPersonaWebhooksEnabled,
      newSignupFlowEnabled: configuration.newSignupFlowEnabled,
      newSignupFlowPbdEnabled: configuration.newSignupFlowPbdEnabled
    };

    yield put({
      payload: configuration,
      type: 'SAVE_ENVIRONMENT_CONFIGURATION'
    });

    Environments.update(configuration);

    if (!isGoDaddy) {
      let orderType = configuration.orderTypes?.rent ? OrderType.RENT : null;

      orderType =
        !orderType && configuration.orderTypes?.buy ? OrderType.BUY : orderType;

      orderType =
        !orderType && configuration.orderTypes?.noDevice
          ? OrderType.NO_DEVICE
          : orderType;

      yield put({
        payload: {
          NO_DEVICE:
            configuration.orderTypes?.noDevice &&
            !configuration.orderTypes?.rent &&
            !configuration.orderTypes?.buy
              ? 1
              : 0,
          N910: resellersHelpers.isTerminalFirstInList(TerminalType.N910)
            ? 1
            : 0,
          PoyntC: resellersHelpers.isTerminalFirstInList(TerminalType.POYNTC)
            ? 1
            : 0
        },
        type: 'SAVE_ORDER_QUANTITY'
      });

      yield put({
        payload: orderType,
        type: 'SAVE_ORDER_TYPE'
      });
    }

    if (configuration.eHopperFlowEnabled) {
      yield put({
        payload: OrderType.BUY,
        type: 'SAVE_ORDER_TYPE'
      });
    }

    yield put(
      companyActions.saveCompanyCurrency(
        configuration.pricing.currency || Currency.USD
      )
    );

    yield put({
      type: 'GET_ENVIRONMENT_SUCCESS'
    });
  } catch (error) {
    const { referralUrlId } = action.payload;
    const storageName = `configuration${
      referralUrlId ? `-${referralUrlId}` : ''
    }`;

    yield call(toast.error, i18n.t('api.errors.environment.getConfig'));

    yield put({
      error: error.message,
      type: 'GET_ENVIRONMENT_FAILURE'
    });

    sessionStorage.removeItem(storageName);
  }
}

export function* getSaasPlans(action: AnyAction) {
  try {
    const plans = yield call(
      configsApi.getSaasPlans,
      action.payload.businessId
    );

    if (plans?.length) {
      yield put({ payload: plans, type: 'SAVE_SAAS_PLANS' });
    }
  } catch (error) {
    yield put({ payload: [], type: 'SAVE_SAAS_PLANS' });
  }
}

export function* getWebsite(action: AnyAction) {
  try {
    const website = yield call(configsApi.getWebsite, action.payload.websiteId);

    yield put({ payload: website.id || null, type: 'SAVE_WEBSITE_ID' });
    yield put({ type: 'GET_WEBSITE_REQUEST_SUCCESS' });
  } catch (error) {
    yield put({ type: 'GET_WEBSITE_REQUEST_FAILED' });
  }
}
