import { FC, useMemo } from 'react';

import { Props } from './PoyntTheme.types';

import ResetPoyntCss from './../../../../assets/css/ResetPoyntTheme.css?inline';

const cachedTheme = ResetPoyntCss;

const PoyntTheme: FC<Props> = ({ children }: Props) => {
  const renderedCSS = useMemo(
    () => <style>{cachedTheme}</style>,
    [cachedTheme]
  );

  return (
    <>
      {renderedCSS}
      {children}
    </>
  );
};

export default PoyntTheme;
