import axios from 'axios';
import { stringify } from 'qs';

import { Currency } from 'enums/business/currency';
import { CountryType } from 'enums/address/country-type';
import { ApplicantType } from 'enums/applicant/type';
import { StepUpActionType } from 'enums/application/step-up';
import { ApplicationLevel } from 'enums/application/application-level';
import { ApplicationIntent } from 'enums/application/intent';
import { BeneficialOwnersStatus } from 'enums/application/beneficial-owners-status';
import { CommerceAccountFeatures } from 'enums/application/commerce-account-features';

import { GovernmentIdentification } from 'reducers/company/types';

import { CreateApplicationArgsInterface } from '../../../v2/application/interfaces';

import {
  Address,
  BusinessApplication,
  BusinessInfoStepUpData,
  StoreApplication
} from './interfaces';

const resolveCompanyGovernmentIdentification = (
  request: CreateApplicationArgsInterface
) => {
  if (request.featureFlags?.newApplicationFormEnabled) {
    return request.company.governmentIdentification;
  }

  return request.company.governmentIdentification.value.length
    ? request.company.governmentIdentification
    : request.applicant.governmentIdentification;
};

export const createApplication = async <T>(
  application: T,
  onboard = false,
  mock = false
): Promise<T | undefined> => {
  const queryParams = {
    'onboard': onboard,
    'mock': mock
  };

  const response = await axios.post<T>(
    `/api/v1/commerce/onboarding-applications?${stringify(queryParams)}`,
    application,
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000',
        'x-referrer': document.referrer
      }
    }
  );

  if (response.data) {
    return response.data;
  }
};

export const createApplicationThroughProxyMethod = async (
  data: CreateApplicationArgsInterface,
  basicApplication = false,
  createStore = false,
  accountId?: string,
  storeId?: string
): Promise<unknown> => {
  const applicantAddress: Address | undefined =
    data.company.address.countryCode === CountryType.US
      ? undefined
      : {
          addressLine1: data.applicant.address.line1 || undefined,
          addressLine2: data.applicant.address.line2 || undefined,
          adminArea1: data.applicant.address.city || undefined,
          adminArea2: data.applicant.address.territory || undefined,
          postalCode: data.applicant.address.zip || undefined,
          countryCode: data.applicant.address.countryCode || CountryType.US
        };

  const firstName = (data.applicant.name.firstName || '').trim();
  const lastName = (data.applicant.name.lastName || '').trim();
  const companyName = (data.company.name || `${firstName} ${lastName}`).trim();
  const companyDba = (data.company.dba || '').trim();

  let applicantGovernmentIdentification, companyGovernmentIdentification;

  switch (data.company.address.countryCode) {
    case CountryType.US:
      applicantGovernmentIdentification =
        data.applicant.governmentIdentification;
      companyGovernmentIdentification =
        resolveCompanyGovernmentIdentification(data);
      break;
    case CountryType.CA:
      companyGovernmentIdentification = data.company.governmentIdentification
        .value.length
        ? data.company.governmentIdentification
        : undefined;
      applicantGovernmentIdentification = data.applicant
        .governmentIdentification.value.length
        ? data.applicant.governmentIdentification
        : undefined;
      break;
    default:
      applicantGovernmentIdentification =
        data.applicant.governmentIdentification;
      companyGovernmentIdentification = data.company.governmentIdentification;
  }

  let application: BusinessApplication | StoreApplication;

  const commerceAccountFeatures: string[] = [];

  if (!data.application.godaddyCustomerId) {
    commerceAccountFeatures.push(
      CommerceAccountFeatures.EXISTS_ONLY_WITH_PAYMENTS
    );
  }

  if (basicApplication && accountId) {
    application = {
      store: {
        address: {
          countryCode: data.company.address.countryCode || CountryType.US
        },
        name: companyName,
        ventureId: data.application.godaddyVentureId || undefined,
        website: data.company.site || undefined
      },
      metadata: {
        context: data.application.context,
        medium: data.application.medium,
        source: data.application.source,
        referralUrlId: data.application.referralUrlId,
        applicationLevel: ApplicationLevel.BASIC,
        businessId: accountId
      },
      intent: ApplicationIntent.ADD_STORE,
      products: [
        {
          name: 'PAYMENTS',
          features: []
        }
      ],
      features: []
    };
  } else {
    application = {
      individuals: [
        {
          address: applicantAddress,
          customerId: data.application.godaddyCustomerId || undefined,
          email: data.applicant.email.emailAddress || undefined,
          phone: {
            countryCode: '1',
            nationalNumber: data.applicant.phone.localPhoneNumber
          },
          firstName: firstName || undefined,
          lastName: lastName || undefined,
          leadId: data.applicant.leadId || undefined,
          type: ApplicantType.APPLICANT_OWNER,
          userId: data.applicant.userId || undefined,
          dob: data.applicant.dob || undefined,
          communicationLanguage: data.applicant.communicationLanguage,
          ...(applicantGovernmentIdentification
            ? { governmentIdentification: applicantGovernmentIdentification }
            : {})
        }
      ],
      company: {
        address: {
          addressLine1: data.company.address.line1 || undefined,
          addressLine2: data.company.address.line2 || undefined,
          adminArea1: data.company.address.city || undefined,
          adminArea2: data.company.address.territory || undefined,
          postalCode: data.company.address.zip || undefined,
          countryCode: data.company.address.countryCode || CountryType.US
        },
        dba: companyDba || undefined,
        legalName: companyName || undefined,
        mcc: data.company.segment || undefined,
        currency: data.company.currency || Currency.USD,
        phone: data.company?.phone?.localPhoneNumber
          ? {
              countryCode: '1',
              nationalNumber: data.company?.phone?.localPhoneNumber
            }
          : undefined,
        website: data.company.site || undefined,
        mccDetails: data.company.mccDetails || undefined,
        ...(companyGovernmentIdentification
          ? { governmentIdentification: companyGovernmentIdentification }
          : {})
      },
      metadata: {
        boStatus: data.application.boStatus || undefined,
        context: data.application.context || undefined,
        medium: data.application.medium || undefined,
        source: data.application.source || undefined,
        referralUrlId: data.application.referralUrlId || undefined,
        applicationLevel: ApplicationLevel.FULL,
        businessId: data.application.businessId
      },
      intent: !data.application.businessId
        ? ApplicationIntent.CREATE_BUSINESS
        : ApplicationIntent.ADD_PRODUCT,
      products: [
        {
          name: 'PAYMENTS',
          features: []
        }
      ],
      commerceAccountFeatures
    };
  }

  const mock = data.application.mock || false;
  const createdApplication = await createApplication<
    BusinessApplication | StoreApplication
  >(application, false, mock);

  if (createdApplication) {
    const applicantId = (createdApplication as BusinessApplication)
      .individuals?.[0]?.id;

    return {
      application: {
        originalIntent: createdApplication.intent,
        applicationId: createdApplication.id,
        decision: createdApplication.metadata.riskDecision,
        stepUpDetails: createdApplication.metadata.stepUpDetails,
        applicationLevel: createdApplication.metadata.applicationLevel,
        originalApplicationLevel:
          createdApplication.metadata.originalApplicationLevel
      },
      applicantId
    };
  }
};

export const updateApplication = async (
  applicationId: string,
  data: CreateApplicationArgsInterface
): Promise<unknown> => {
  const firstName = (data.applicant.name.firstName || '').trim();
  const lastName = (data.applicant.name.lastName || '').trim();
  const companyName = (data.company.name || `${firstName} ${lastName}`).trim();
  const companyDba = (data.company.dba || '').trim();

  const applicantAddress =
    data.company.address.countryCode === CountryType.US
      ? undefined
      : {
          addressLine1: data.applicant.address.line1 || undefined,
          addressLine2: data.applicant.address.line2 || undefined,
          adminArea1: data.applicant.address.city || undefined,
          adminArea2: data.applicant.address.territory || undefined,
          postalCode: data.applicant.address.zip || undefined,
          countryCode: data.applicant.address.countryCode || CountryType.US
        };

  let applicantGovernmentIdentification, companyGovernmentIdentification;

  switch (data.company.address.countryCode) {
    case CountryType.US:
      applicantGovernmentIdentification =
        data.applicant.governmentIdentification;
      companyGovernmentIdentification =
        resolveCompanyGovernmentIdentification(data);
      break;
    case CountryType.CA:
      companyGovernmentIdentification = data.company.governmentIdentification;
      applicantGovernmentIdentification = data.applicant
        .governmentIdentification.value.length
        ? data.applicant.governmentIdentification
        : undefined;
      break;
    default:
      applicantGovernmentIdentification =
        data.applicant.governmentIdentification;
      companyGovernmentIdentification = data.company.governmentIdentification;
  }

  const changes: unknown[] = [
    {
      op: 'replace',
      path: '/individuals/0/firstName',
      value: firstName || undefined
    },
    {
      op: 'replace',
      path: '/individuals/0/lastName',
      value: lastName || undefined
    },
    {
      op: 'replace',
      path: '/individuals/0/email',
      value: data.applicant.email.emailAddress || undefined
    },
    {
      op: 'replace',
      path: '/individuals/0/type',
      value: ApplicantType.APPLICANT_OWNER
    },
    {
      op: 'add',
      path: '/individuals/0/phone',
      value: {
        countryCode: '1',
        nationalNumber: data.applicant.phone.localPhoneNumber || undefined
      }
    },
    {
      op: 'replace',
      path: '/company/address',
      value: {
        addressLine1: data.company.address.line1 || undefined,
        addressLine2: data.company.address.line2 || undefined,
        adminArea1: data.company.address.city || undefined,
        adminArea2: data.company.address.territory || undefined,
        postalCode: data.company.address.zip || undefined,
        countryCode: data.company.address.countryCode || CountryType.US
      }
    },
    {
      op: 'replace',
      path: '/company/legalName',
      value: companyName || undefined
    },
    {
      op: 'replace',
      path: '/company/mcc',
      value: data.company.segment || undefined
    },
    {
      op: 'replace',
      path: '/metadata/applicationLevel',
      value: 'FULL'
    },
    {
      op: 'replace',
      path: '/company/mccDetails',
      value: {
        label: data.company.mccDetails?.label || undefined,
        type: data.company.mccDetails?.type || undefined
      }
    }
  ];

  if (data.application.medium) {
    changes.push({
      op: 'replace',
      path: '/metadata/medium',
      value: data.application.medium
    });
  }

  if (data.application.source) {
    changes.push({
      op: 'replace',
      path: '/metadata/source',
      value: data.application.source
    });
  }

  changes.push({
    op: 'replace',
    path: '/metadata/referralUrlId',
    value: data.application.referralUrlId || null
  });

  if (data.application.boStatus !== BeneficialOwnersStatus.NONE) {
    changes.push({
      op: 'replace',
      path: '/metadata/boStatus',
      value: data.application.boStatus || BeneficialOwnersStatus.NONE
    });
  }

  if (applicantGovernmentIdentification) {
    changes.push({
      op: 'add',
      path: '/individuals/0/governmentIdentification',
      value: applicantGovernmentIdentification
    });
  }

  if (applicantAddress) {
    changes.push({
      op: 'add',
      path: '/individuals/0/address',
      value: applicantAddress
    });
  }

  if (data.applicant.dob) {
    changes.push({
      op: 'replace',
      path: '/individuals/0/dob',
      value: data.applicant.dob
    });
  }

  if (companyDba) {
    changes.push({
      op: 'add',
      path: '/company/dba',
      value: companyDba
    });
  }

  if (data.company?.phone?.localPhoneNumber) {
    changes.push({
      op: 'add',
      path: '/company/phone',
      value: {
        countryCode: '1',
        nationalNumber: data.company.phone.localPhoneNumber
      }
    });
  }

  if (data.company.site) {
    changes.push({
      op: 'add',
      path: '/company/website',
      value: data.company.site
    });
  }

  if (companyGovernmentIdentification) {
    changes.push({
      op: 'add',
      path: '/company/governmentIdentification',
      value: companyGovernmentIdentification
    });
  }

  const response = await axios.patch(
    `/api/v1/commerce/onboarding-applications/${applicationId}`,
    changes,
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000',
        'x-referrer': document.referrer
      }
    }
  );

  if (response.data) {
    return {
      application: {
        applicationId: response.data.id,
        decision: response.data.metadata.riskDecision,
        stepUpDetails: response.data.metadata.stepUpDetails,
        applicationLevel: response.data.metadata.applicationLevel,
        originalApplicationLevel:
          response.data.metadata.originalApplicationLevel
      }
    };
  }
};

export const updateApplicationWithStepUpInformation = async (
  applicationId: string,
  type: string,
  data: {
    inquiryId?: string;
    code?: string;
    skip?: boolean;
    boStatus?: BeneficialOwnersStatus;
    applicantId?: string;
    ssn9?: GovernmentIdentification;
    businessInfo?: BusinessInfoStepUpData;
  }
): Promise<unknown> => {
  const patchData: {
    op: string;
    path: string;
    value: unknown;
  }[] = [];

  switch (type) {
    case StepUpActionType.OTP_SMS:
    case StepUpActionType.PHONE_AUTH: {
      patchData.push({
        op: 'add',
        path: '/individuals/0/otpCode',
        value: data.skip ? null : data.code
      });
      break;
    }
    case StepUpActionType.FULL_SSN: {
      patchData.push({
        op: 'add',
        path: '/individuals/0/governmentIdentification',
        value: data.ssn9
      });
      break;
    }
    case StepUpActionType.GOV_ID_SELFIE: {
      patchData.push({
        op: 'add',
        path: '/individuals/0/personaInquiryId',
        value: data.inquiryId
      });
      break;
    }
    case StepUpActionType.EIN_DOC: {
      patchData.push({
        op: 'add',
        path: '/company/personaInquiryId',
        value: data.inquiryId
      });
      break;
    }
    case StepUpActionType.BO_INFO: {
      patchData.push({
        op: 'add',
        path: '/metadata/boStatus',
        value: data.boStatus
      });

      break;
    }
    case StepUpActionType.BUSINESS_INFO: {
      const companyAddress = data.businessInfo?.address;
      companyAddress &&
        patchData.push({
          op: 'replace',
          path: '/company/address',
          value: {
            addressLine1: companyAddress?.line1 || undefined,
            addressLine2: companyAddress?.line2 || undefined,
            adminArea1: companyAddress?.city || undefined,
            adminArea2: companyAddress?.territory || undefined,
            postalCode: companyAddress?.zip || undefined,
            countryCode: companyAddress?.countryCode || CountryType.US
          }
        });

      data.businessInfo?.dba &&
        patchData.push({
          op: 'replace',
          path: '/company/dba',
          value: data.businessInfo?.dba
        });

      data.businessInfo?.name &&
        patchData.push({
          op: 'replace',
          path: '/company/legalName',
          value: data.businessInfo?.name
        });

      data.businessInfo?.governmentIdentification &&
        patchData.push({
          op: 'replace',
          path: '/company/governmentIdentification',
          value: {
            sourceType: data.businessInfo?.governmentIdentification?.sourceType,
            type: data.businessInfo?.governmentIdentification?.type,
            value: data.businessInfo?.governmentIdentification?.value
          }
        });

      break;
    }
  }

  const response = await axios.patch(
    `/api/v1/commerce/onboarding-applications/${applicationId}`,
    patchData,
    {
      headers: {
        'x-envoy-upstream-rq-timeout-ms': '300000',
        'x-referrer': document.referrer
      }
    }
  );

  if (response.data) {
    return {
      applicationId: response.data.id
    };
  }
};
