import { AnyAction } from 'redux';

import { PortalType } from 'enums/portal/type';
import { CountryType } from 'enums/address/country-type';

import Environments from 'helpers/environments';

import {
  Cobrand,
  Godaddy,
  OrderTypes,
  Plaid,
  Plans,
  Pricing,
  SaasPlan,
  SignupFlow,
  State,
  Terms
} from './types';

const INITIAL_STATE: State = {
  isPbdEnabled: Environments.get<boolean>('isPbdEnabled'),
  multiLanguageEnabled: Environments.get<boolean>('multiLanguageEnabled'),
  buyN910Amount: Environments.get<number>('buyN910Amount'),
  buyPoyntCAmount: Environments.get<number>('buyPoyntCAmount'),
  cobrand: Environments.get<Cobrand>('cobrand') || {},
  customEcommFeeLabel: Environments.get<string>('customEcommFeeLabel'),
  debitCardCustomization: Environments.get<boolean>('debitCardCustomization'),
  debitCards: Environments.get<boolean>('debitCards'),
  enableWebsiteCollection: Environments.get<boolean>('enableWebsiteCollection'),
  godaddy: Environments.get<Godaddy>('godaddy'),
  poynt: Environments.get<Godaddy>('poynt'),
  isPartner: Environments.get<boolean>('isPartner'),
  impersonationMode: Environments.get<boolean>('impersonationMode'),
  isShowCustomEcomm: Environments.get<boolean>('isShowCustomEcomm'),
  isShowEcheckFee: Environments.get<boolean>('isShowEcheckFee'),
  loaded: false,
  orderTypes: Environments.get<OrderTypes>('orderTypes'),
  otpFlow: Environments.get<boolean>('otpFlow'),
  paymentsType: Environments.get<string>('paymentsType'),
  plaid: Environments.get<Plaid>('plaid') || {},
  plans: Environments.get<Plans>('plans') || {},
  saasPlans: Environments.get<SaasPlan[]>('saasPlans') || [],
  onAddStorePagesEnabled: Environments.get<boolean>('onAddStorePagesEnabled'),
  portal: Environments.get<PortalType>('portal') || PortalType.POYNT,
  country: Environments.get<PortalType>('country') || CountryType.US,
  pricing: Environments.get<Pricing>('pricing') || {},
  resellerType: Environments.get<string>('resellerType'),
  shippingCost: Environments.get<number>('shippingCost'),
  signupFlow: Environments.get<SignupFlow>('signupFlow'),
  terminalsType: Environments.get<string>('terminalsType'),
  terms: Environments.get<Terms>('terms') || {},
  twoStepsFlow: Environments.get<boolean>('twoStepsFlow'),
  godaddyCookieHost: Environments.get<string>('godaddyCookieHost'),
  newApplicationFormEnabled: Environments.get<boolean>(
    'newApplicationFormEnabled'
  ),
  enableMasterMidForCanada: Environments.get<boolean>(
    'enableMasterMidForCanada'
  ),
  enableEditApplicationForPoynt: Environments.get<boolean>(
    'enableEditApplicationForPoynt'
  ),
  inPersonPricingFirstEnabled: Environments.get<boolean>(
    'inPersonPricingFirstEnabled'
  ),
  isPhoneAuthEnabled: Environments.get<boolean>('isPhoneAuthEnabled'),
  beneficiaryOwnerEnabled: Environments.get<boolean>('beneficiaryOwnerEnabled'),
  hasBoSubLabelTextExperiment: Environments.get<boolean>(
    'hasBoSubLabelTextExperiment'
  ),
  newSignupFlowEnabled: Environments.get<boolean>('newSignupFlowEnabled'),
  newSignupFlowPbdEnabled: Environments.get<boolean>('newSignupFlowPbdEnabled'),
  dateOfBirthForUSAEnabled: Environments.get<boolean>(
    'dateOfBirthForUSAEnabled'
  )
};

export default function (
  state: State = INITIAL_STATE,
  action: AnyAction
): State {
  switch (action.type) {
    case 'SAVE_ENVIRONMENT_CONFIGURATION':
      return {
        multiLanguageEnabled:
          action.payload.multiLanguageEnabled || state.multiLanguageEnabled,
        isPbdEnabled: action.payload.isPbdEnabled || state.isPbdEnabled,
        buyN910Amount: action.payload.buyN910Amount || state.buyN910Amount,
        buyPoyntCAmount:
          action.payload.buyPoyntCAmount || state.buyPoyntCAmount,
        cobrand: action.payload.cobrand || state.cobrand,
        customEcommFeeLabel:
          action.payload.customEcommFeeLabel || state.customEcommFeeLabel,
        debitCardCustomization:
          action.payload.debitCardCustomization || state.debitCardCustomization,
        debitCards: action.payload.debitCards || state.debitCards,
        enableWebsiteCollection:
          action.payload.enableWebsiteCollection ||
          state.enableWebsiteCollection,
        godaddy: { ...state.godaddy, ...action.payload.godaddy },
        onAddStorePagesEnabled: action.payload.onAddStorePagesEnabled,
        poynt: { ...state.poynt, ...action.payload.poynt },
        impersonationMode:
          action.payload.impersonationMode || state.impersonationMode,
        isPartner: action.payload.isPartner || state.isPartner,
        isShowCustomEcomm:
          action.payload.isShowCustomEcomm || state.isShowCustomEcomm,
        isShowEcheckFee:
          action.payload.isShowEcheckFee || state.isShowEcheckFee,
        isShowKeyedInFees:
          action.payload.isShowKeyedInFees || state.isShowKeyedInFees,
        loaded: true,
        orderTypes: action.payload.orderTypes || state.orderTypes,
        otpFlow: action.payload.otpFlow || state.otpFlow,
        paymentsType: action.payload.paymentsType || state.paymentsType,
        plaid: { ...state.plaid, ...action.payload.plaid },
        plans: { ...state.plans, ...action.payload.plans },
        portal: action.payload.portal || state.portal,
        country: action.payload.country || state.country,
        pricing: action.payload.pricing || state.pricing,
        redirectName: action.payload.redirectName || state.redirectName,
        redirectUrl: action.payload.redirectUrl || state.redirectUrl,
        redirectUrlForSelectedAccount:
          action.payload.redirectUrlForSelectedAccount ||
          state.redirectUrlForSelectedAccount,
        resellerType: action.payload.resellerType || state.resellerType,
        shippingCost: action.payload.shippingCost || state.shippingCost,
        signupFlow: action.payload.signupFlow || state.signupFlow,
        terminalsType: action.payload.terminalsType || state.terminalsType,
        terms: action.payload.terms || state.terms,
        twoStepsFlow: action.payload.twoStepsFlow || state.twoStepsFlow,
        godaddyCookieHost:
          action.payload.godaddyCookieHost || state.godaddyCookieHost,
        eHopperFlowEnabled:
          action.payload.eHopperFlowEnabled || state.eHopperFlowEnabled,
        eHopperBundles: action.payload.eHopperBundles || state.eHopperBundles,
        eHopperSolution:
          action.payload.eHopperSolution || state.eHopperSolution,
        newApplicationFormEnabled: action.payload.newApplicationFormEnabled,
        enableMasterMidForCanada: action.payload.enableMasterMidForCanada,
        beneficiaryOwnerEnabled: action.payload.beneficiaryOwnerEnabled,
        hasBoSubLabelTextExperiment: action.payload.hasBoSubLabelTextExperiment,
        dateOfBirthForUSAEnabled: action.payload.dateOfBirthForUSAEnabled,
        enableEditApplicationForPoynt:
          action.payload.enableEditApplicationForPoynt,
        inPersonPricingFirstEnabled: action.payload.inPersonPricingFirstEnabled,
        isPhoneAuthEnabled: action.payload.isPhoneAuthEnabled,
        isPersonaWebhooksEnabled: action.payload.isPersonaWebhooksEnabled,
        newSignupFlowEnabled: action.payload.newSignupFlowEnabled,
        newSignupFlowPbdEnabled: action.payload.newSignupFlowPbdEnabled
      };
    case 'CHANGE_ENVIRONMENT_PROPERTY':
      return {
        ...state,
        [action.payload.propertyName]: action.payload.value
      };
    case 'SAVE_SAAS_PLANS':
      return {
        ...state,
        saasPlans: action.payload
      };
    case 'SAVE_WEBSITE_ID':
      return {
        ...state,
        websiteId: action.payload
      };
    default:
      return state;
  }
}
