import validator from 'validator';
import { isValid } from 'ein-validator';

type ValueType = string | null | undefined;

//isValidGovId checks for: 9 digits; not 9 of the same number; not sequential number
const isValidGovId =
  /^(?!(\d)\1{8}$)(?!(123456789|012345678|987654321|876543210)$)\d{9}$/;

export const ssnLength = 4;
export const identificationLength = 9;

export const checkCompanyIdentification = (
  value: ValueType,
  max: number,
  min?: number
) => {
  const companyIdentification = validator.blacklist(value || '', '-');
  return (
    validator.isNumeric(companyIdentification) &&
    parseFloat(companyIdentification) !== 0 &&
    validator.isLength(companyIdentification, { min: min || max, max })
  );
};

export const isCompanyIdentification = (value: ValueType): boolean =>
  checkCompanyIdentification(value, identificationLength);

export const isCompanyIdentificationNotRequired = (
  value: ValueType
): boolean =>
  value ? checkCompanyIdentification(value, identificationLength) : true;

export const isShortEIN = (value: ValueType): boolean =>
  checkCompanyIdentification(value, ssnLength);

export const isValidEIN = (value: ValueType): boolean => {
  return isValid(value) && isValidGovId.test((value || '').replace(/\D/g, ''));
};

export const isValidSsn = (value: ValueType): boolean => {
  return isValidGovId.test((value || '').replace(/\D/g, ''));
};
