import axios from 'axios';
import { stringify } from 'qs';

import { ApplicationAuthCode } from './../interfaces';

export const getAuthCodeForApplication = async (
  applicationId: string,
  context?: string,
  channelId?: string
): Promise<ApplicationAuthCode> => {
  const queryParams = {
    'context': context || '',
    'channelId': channelId
  };

  const response = await axios.get(
    `/api/v2/applications/${applicationId}/auth-code?${stringify(queryParams)}`
  );

  return response.data;
};
