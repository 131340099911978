import { Currency } from 'enums/business/currency';
import { TrafficTrackType } from 'enums/analytics/traffic/track-type';
import { AnalyticsPortalType } from 'enums/analytics/portal-type';
import { ChatterboxProductTypes } from 'enums/chatterbox/product-types';

import Environments from 'helpers/environments';
import * as deviceHelpers from 'helpers/device';

import {
  TrafficDataInterface,
  TrafficInitTrackArgsInterface,
  TrafficTrackArgsInterface
} from './../types';

class Traffic {
  private utm?: string;
  private portal = AnalyticsPortalType.gdp;
  private source?: string;
  private medium?: string;
  private currency?: Currency;
  private language?: string;
  private available: boolean;
  private shopperId?: string;
  private serviceId?: string;
  private businessId?: string;
  private customerId?: string;
  private serviceType?: string;
  private inPersonPricingFirstEnabled?: boolean;
  private isPowerSeller?: boolean;
  private isMultistepFlow?: boolean;
  private applicationLevel?: string;
  private beneficiaryOwnerEnabled?: boolean;
  private dateOfBirthForUSAEnabled?: boolean;
  private hasBeneficialOwners?: boolean;
  private originalApplicationLevel?: string;

  constructor() {
    this.available = Environments.get<boolean>('traffic.enabled');
  }

  insertScriptsTrafficJs() {
    //Configure global values in Traffic
    const scriptConfig = document.createElement('script');

    scriptConfig.setAttribute('type', 'text/javascript');

    const codeInsideConfig = `
      var _gaDataLayer = _gaDataLayer || [];
      var _expDataLayer = _expDataLayer || [];
    `;
    scriptConfig.append(codeInsideConfig);
    document.head.appendChild(scriptConfig);
    //End configure global values in Traffic

    //Fill script
    const scriptFilling = document.createElement('script');
    const codeInsideFilling = `
      window._gaDataLayer.push({ 'app': 'diplomacy-application' });
      window._gaDataLayer.push({ 'isc': 'gtnime15' });
      window._gaDataLayer.push({ 'status': '200' });
      window._gaDataLayer.push({ 'tcc.spa': true });
      window._gaDataLayer.push({ 'tcc.pageId': '12345' });
      window._gaDataLayer.push({ 'debug': false });`;

    scriptFilling.append(codeInsideFilling);
    document.head.appendChild(scriptFilling);
    //End Filling script

    return () => {
      document.head.removeChild(scriptConfig);
      document.head.removeChild(scriptFilling);
    };
  }

  public initTrackData(trackData: TrafficInitTrackArgsInterface) {
    this.portal = trackData.portal;
    this.source = trackData.source;
    this.medium = trackData.medium;
    this.currency = trackData.currency;
    this.language = trackData.language;
    this.shopperId = trackData.shopperId;
    this.serviceId = trackData.serviceId;
    this.businessId = trackData.businessId;
    this.customerId = trackData.customerId;
    this.serviceType = trackData.serviceType;
    this.inPersonPricingFirstEnabled = trackData.inPersonPricingFirstEnabled;
    this.isPowerSeller = !!trackData.isPowerSeller;
    this.isMultistepFlow = this.isMultistepFlow || trackData.isMultistepFlow;
    this.applicationLevel = trackData.applicationLevel;
    this.beneficiaryOwnerEnabled = trackData.beneficiaryOwnerEnabled;
    this.dateOfBirthForUSAEnabled = trackData.dateOfBirthForUSAEnabled;
    this.hasBeneficialOwners = trackData.hasBeneficialOwners;
    this.originalApplicationLevel = trackData.originalApplicationLevel;
  }

  public trackEvent(
    trackData: TrafficTrackArgsInterface,
    schema?: string,
    version?: string
  ) {
    if (!this.isInit()) {
      return;
    }

    const trafficData: TrafficDataInterface = {
      eid: this.generateEid(
        trackData.eventName,
        trackData.section,
        trackData.actionType
      ),
      type: trackData.actionType,
      custom_properties: {
        ...(trackData.data || {}),
        ...deviceHelpers.getCurrentDeviceTypes()
      }
    };
    const utm = this.getUtm();

    if (utm) {
      trafficData.custom_properties.utm = utm;
    }

    if (this.serviceId) {
      trafficData.custom_properties.serviceId = this.serviceId || '';
    }

    if (this.businessId) {
      trafficData.custom_properties.businessId = this.businessId || '';
    }

    if (this.serviceType) {
      trafficData.custom_properties.serviceType = this.serviceType || '';
      trafficData.custom_properties.customServiceType = this.prepareServiceType(
        this.serviceType || ''
      );
    }

    if (this.customerId) {
      trafficData.custom_properties.customerId = this.customerId || '';
    }

    if (
      this.applicationLevel &&
      !trafficData.custom_properties.applicationLevel
    ) {
      trafficData.custom_properties.applicationLevel =
        this.applicationLevel || '';
    }

    if (
      this.originalApplicationLevel &&
      !trafficData.custom_properties.originalApplicationLevel
    ) {
      trafficData.custom_properties.originalApplicationLevel =
        this.originalApplicationLevel || '';
    }

    if (this.inPersonPricingFirstEnabled) {
      trafficData.custom_properties.inPersonPricingFirstEnabled =
        this.inPersonPricingFirstEnabled;
    }

    if (this.isPowerSeller) {
      trafficData.custom_properties.isPowerSeller = this.isPowerSeller;
    }

    if (this.currency) {
      trafficData.custom_properties.currency = this.currency;
    }

    if (this.language) {
      trafficData.custom_properties.language = this.language;
    }

    if (this.source) {
      trafficData.custom_properties.source = this.source;
    }

    if (this.medium) {
      trafficData.custom_properties.medium = this.medium;
    }

    if (trackData.virtual_path) {
      trafficData.virtual_path = trackData.virtual_path;
    }

    if (this.beneficiaryOwnerEnabled) {
      trafficData.custom_properties.beneficiaryOwnerEnabled =
        this.beneficiaryOwnerEnabled;
    }

    if (this.hasBeneficialOwners) {
      trafficData.custom_properties.hasBeneficialOwners =
        this.hasBeneficialOwners;
    }

    if (this.dateOfBirthForUSAEnabled) {
      trafficData.custom_properties.dateOfBirthForUSAEnabled =
        this.dateOfBirthForUSAEnabled;
    }

    if (this.isMultistepFlow) {
      trafficData.custom_properties.isMultistepFlow = this.isMultistepFlow;
    }

    _expDataLayer.push({
      data: trafficData,
      schema: schema || 'add_event',
      version: version || 'v2'
    });
  }

  private getUtm(): string | undefined {
    if (this.utm) {
      return this.utm;
    }

    this.utm = new URLSearchParams(location.search).get('utm') || '';

    return this.utm;
  }

  private isInit(): boolean {
    return this.available && typeof _expDataLayer !== 'undefined';
  }

  private prepareServiceType(serviceType?: string, source?: string): string {
    const type = (serviceType || '').toLowerCase();

    if (type.endsWith('.mobilepayments')) {
      return 'Mobile Payments';
    } else if (type.startsWith('mobile.')) {
      return 'Mobile';
    } else if (type.endsWith('.over')) {
      return 'GD Studio';
    } else if (type.endsWith('.sa')) {
      return 'Standalone';
    } else if (type.startsWith('domain.')) {
      return 'Domain';
    } else if (type.startsWith('gopay.')) {
      return 'WAM';
    } else if (type.startsWith('hub.')) {
      return 'Invoice';
    } else if (
      type.startsWith('mwp.') ||
      source === ChatterboxProductTypes.MANAGED_WORDPRESS
    ) {
      return 'Woo';
    } else if (
      type.startsWith('mwc') ||
      source === ChatterboxProductTypes.MWCS
    ) {
      return 'MWCS';
    }

    return 'Other';
  }

  private generateEid(
    eventName: TrafficTrackType,
    section: string,
    action: string
  ): string {
    return `gdp.signup.${this.portal}_${eventName}.${section}.${action}`;
  }
}

export default new Traffic();
