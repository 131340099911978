import { OrderType } from 'enums/order/type';

import { Cobrand, Plans } from 'reducers/environment/types';
import { Trial } from 'reducers/order/types';

export const hasTrialPeriod = (
  plans: Plans,
  orderType: OrderType,
  n910Amount?: number,
  poyntCAmount?: number
) => {
  const n910TrialPeriod = !!plans.n910.trialPeriodDays;
  const poyntCTrialPeriod = !!plans.poyntC.trialPeriodDays;
  const buyTrialPeriod = !!plans.buy.trialPeriodDays;
  const noDeviceTrialPeriod = !!plans.noDevice.trialPeriodDays;

  const amountPresent =
    (n910Amount === undefined && poyntCAmount === undefined) ||
    !!n910Amount ||
    !!poyntCAmount;

  return (
    (orderType === OrderType.RENT &&
      (n910TrialPeriod || poyntCTrialPeriod) &&
      amountPresent) ||
    (orderType === OrderType.BUY && buyTrialPeriod && amountPresent) ||
    (orderType === OrderType.NO_DEVICE && noDeviceTrialPeriod)
  );
};

export const getActiveTrial = (
  plans: Plans,
  orderType: OrderType,
  cobrand: Cobrand
): Trial => {
  switch (orderType) {
    case OrderType.BUY:
      return {
        additional: cobrand.buyOptionTrialAdditional || '',
        days: plans.buy.trialPeriodDays || 0,
        header: cobrand.buyOptionTrialHeader || '',
        info: cobrand.buyOptionTrialInfo || '',
        terms: cobrand.buyOptionTrialTerms || ''
      };
    case OrderType.NO_DEVICE:
      return {
        additional: cobrand.noDeviceOptionTrialAdditional || '',
        days: plans.noDevice.trialPeriodDays || 0,
        header: cobrand.noDeviceOptionTrialHeader || '',
        info: cobrand.noDeviceOptionTrialInfo || '',
        terms: cobrand.noDeviceOptionTrialTerms || ''
      };
    default:
      return {
        additional: cobrand.trialAdditional || '',
        days: plans.n910.trialPeriodDays || plans.poyntC.trialPeriodDays || 0,
        header: cobrand.trialHeader || '',
        info: cobrand.trialInfo || '',
        terms: cobrand.trialTerms || ''
      };
  }
};
