import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import * as accountsActions from 'actions/account';
import * as applicationsActions from 'actions/application';

import { DecisionType } from 'enums/application/decision';
import { ApplicationLevel } from 'enums/application/application-level';

import * as analyticsHelper from 'helpers/analytics/track';

import * as accountsApi from 'rest/v2/accounts';
import * as storesApi from 'rest/v2/accounts/stores';

import { Account } from 'rest/v2/accounts/interfaces';
import { Store } from 'rest/v2/accounts/stores/interfaces';

import { Store as RootStore } from 'reducers/types';
import { State as ApplicationReducer } from 'reducers/application/types';

const getApplication = (state: RootStore) => state.application;
const getAccountsList = (state: RootStore) => state.accounts.list;

export function* fetchAccounts() {
  try {
    const application: ApplicationReducer = yield select(getApplication);

    let accounts: Account[] = yield call(accountsApi.getAccounts);

    if (!accounts?.length) {
      yield put(accountsActions.saveAccounts([]));
      yield put(accountsActions.fetchAccountsSuccess());
      return;
    }

    let applications = accounts;

    accounts.forEach(account => {
      if (account.linkedApplications && account.linkedApplications.length) {
        applications = applications.concat(account.linkedApplications);
      }
    });

    // try to find needed account by provided businessId
    const commerceAccountApplication = applications.find(
      item =>
        item.businessId === application.businessId && !application.storeSwitcher
    );

    // try to find existing payment application
    const existingApplication = applications.find(
      item =>
        item.serviceId &&
        item.serviceType &&
        application.serviceId &&
        application.serviceType &&
        item.serviceId === application.serviceId &&
        item.serviceType === application.serviceType
    );

    // if application already exists we will redirect customer to this application
    if (existingApplication || commerceAccountApplication) {
      yield put(
        applicationsActions.getApplicationById({
          applicationId:
            existingApplication?.applicationId ||
            commerceAccountApplication?.applicationId
        })
      );
      yield put(accountsActions.saveAccounts([]));
      yield put(accountsActions.fetchAccountsSuccess());
      return;
    }

    const approved: Account[] = [];
    const pbd: Account[] = [];
    const inReview: Account[] = [];
    const incomplete: Account[] = [];

    accounts.forEach(account => {
      if (account.riskDecision === DecisionType.APPROVED) {
        approved.push(account);
        return;
      }

      if (account.riskDecision === DecisionType.PENDING) {
        incomplete.push(account);
        return;
      }

      if (
        account.riskDecision === DecisionType.APPROVED_PENDING &&
        account.applicationLevel === ApplicationLevel.BASIC
      ) {
        pbd.push(account);
        return;
      }

      if (
        account.riskDecision === DecisionType.APPROVED_PENDING &&
        account.applicationLevel === ApplicationLevel.FULL
      ) {
        inReview.push(account);
        return;
      }
    });

    accounts = [...approved, ...pbd, ...inReview, ...incomplete];

    yield put(accountsActions.saveAccounts(accounts));
    yield put(accountsActions.fetchAccountsSuccess());
  } catch (error) {
    const message =
      error?.response?.data.message ||
      error?.response?.data.error ||
      error.message;

    yield put(accountsActions.fetchAccountsFailure(message));
  }
}

export function* fetchAccountInfo(action: AnyAction) {
  const accountId = action.payload.accountId;

  try {
    const account: Account = yield call(accountsApi.getAccountInfo, accountId);
    const accountsList: Account[] = yield select(getAccountsList);

    if (account) {
      yield put(
        accountsActions.saveAccounts(
          accountsList.map(item => {
            if (item.applicationId !== account.applicationId) {
              return item;
            }

            return {
              ...account,
              loaded: true
            };
          })
        )
      );
    }

    yield put(accountsActions.fetchAccountInfoSuccess());
  } catch (error) {
    const message =
      error?.response?.data.message ||
      error?.response?.data.error ||
      error.message;

    yield put(accountsActions.fetchAccountInfoFailure());
    yield call(analyticsHelper.trackError, message);
    yield call(toast.error, message);
  }
}

export function* fetchStoresForAccount(action: AnyAction) {
  try {
    const application: ApplicationReducer = yield select(getApplication);

    const stores: Store[] = yield call(
      storesApi.getStores,
      action.payload.accountId
    );

    if (!stores?.length) {
      yield put(accountsActions.fetchStoresForAccountSuccess());
      return;
    }

    const domainServiceTypes = [
      'domain.entitlements',
      'domain.nucleus-project-created'
    ];

    stores.forEach(store => {
      if (!application.serviceId) {
        store.attachable = true;
      } else if (store.serviceType === application.serviceType) {
        store.attachable = false;
      } else if (
        !store.serviceId ||
        store.serviceId === store.id ||
        domainServiceTypes.includes(store.serviceType)
      ) {
        store.attachable = true;
      } else {
        store.attachable = false;
      }
    });

    yield put(accountsActions.saveStoresForAccount(stores));
    yield put(accountsActions.fetchStoresForAccountSuccess());
  } catch (error) {
    const message =
      error?.response?.data.message ||
      error?.response?.data.error ||
      error.message;

    yield put(accountsActions.fetchStoresForAccountFailure(message));
  }
}
