import axios from 'axios';
import { stringify } from 'qs';

import { Payload } from './types';

export const getCashAdvanceOffers = (
  businessId?: string
): Promise<Payload[]> => {
  return axios
    .request({
      method: 'get',
      url: `/api/v2/cash-advance-offers?${stringify({ businessId })}`
    })
    .then(response => response.data);
};
