import axios from 'axios';

import { Account } from './interfaces';

export const getAccounts = async (): Promise<Account[]> => {
  const response = await axios.get(`/api/v2/accounts`);
  return response.data;
};

export const getAccountInfo = async (accountId: string): Promise<Account> => {
  const response = await axios.get(`/api/v2/accounts/${accountId}/more-info`);
  return response.data;
};
